<template>
	<ext-dialog
		v-model="value"
		:title="$t('expense')"
		:primaryButtonText="$t('save')"
		@primaryButtonAction="saveAction"
		@closed="closeAction"
	>
		<template #body v-if="currentItem">
			<v-form ref="form" v-model="valid">
				<v-text-field v-model="currentItem.name" :label="$t('title')" :rules="rules.name" />
				<v-text-field
					v-model="currentItem.quantity"
					:label="$t('quantity')"
					:rules="rules.quantity"
					type="number"
					min="0"
				/>
				<v-text-field
					v-model="currentItem.amount"
					:label="$t('amount')"
					:rules="rules.amount"
					type="number"
					min="0"
					:suffix="shipment.currency.symbol"
				/>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import remote from "../../../../data/remote";
import cloneDeep from "lodash.clonedeep";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	props: { value: Boolean, item: Object, shipment: Object, container: Object },
	components: { ExtDialog },
	data() {
		return {
			valid: null,
			rules: {
				name: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("title"),
							});
						} else if (v.length > 240) {
							return this.$t("$validation.max_length", {
								item: this.$t("title"),
								length: "240",
							});
						}
						return true;
					},
				],
				quantity: [
					(v) => (!isNaN(v) && Number(v) > 0) || this.$t("$validation.required", { item: this.$t("quantity") }),
				],
				amount: [(v) => !Number.isNaN(v) || this.$t("$validation.required", { item: this.$t("amount") })],
			},
			currentItem: null,
			saving: false,
		};
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item) this.currentItem = cloneDeep(this.item);
			else this.useItemTemplate();
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
		},
		useItemTemplate() {
			this.currentItem = {
				name: "",
				quantity: null,
				amount: null,
			};
		},
		saveAction() {
			if (!this.saving) {
				if (this.valid) {
					const onSuccess = (result) => this.$emit("saved", result);
					if (this.currentItem.amount == null || this.currentItem.amount === "") this.currentItem.amount = 0;
					if (this.currentItem.id) {
						remote.shipments.containers.expenses.update(this.shipment.id, this.container.id, this.currentItem, {
							onSuccess,
						});
					} else {
						remote.shipments.containers.expenses.create(this.shipment.id, this.container.id, this.currentItem, {
							onSuccess,
						});
					}
				} else this.$refs.form.validate();
			}
		},
		closeAction() {
			if (!this.saving) this.$emit("input", false);
		},
	},
	mounted() {
		this.handleProps();
	},
};
</script>
