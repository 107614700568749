export default {
	$accessibility: {
		archived: "Arquivado",
		present: "Presente",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Oculto do Receptor",
			hidden_from_sender: "Oculto do Remetente",
			private: "Privado",
			shared_with_custom: "Compartilhado com Personalizado",
			shared_with_everyone: "Compartilhado com Todos",
			shared_with_receiver: "Compartilhado com o Receptor",
			shared_with_sender: "Compartilhado com o Remetente",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB de Armazenamento",
		limitMBStorage: "{limit}MB de Armazenamento",
		limitUser: "{limit} Usuário",
		limitUsers: "{limit} Usuários",
		renewAtPricePerUnit: "Renovará em {currency}{price} por {unit}",
		unlimitedModule: "{module} Ilimitado",
		upToLimitModule: "Até {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURQUIA",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1spt!2str!4v1701805110461!5m2!1spt!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"Acelere o seu negócio com Antrego. Gerencie seus processos de envio digitalmente, de forma segura e fácil.",
	},
	$country: {
		afghanistan: "Afeganistão",
		aland: "Ilhas Åland",
		albania: "Albânia",
		algeria: "Argélia",
		american_samoa: "Samoa Americana",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antártica",
		antigua_and_barbuda: "Antígua e Barbuda",
		argentina: "Argentina",
		armenia: "Armênia",
		aruba: "Aruba",
		australia: "Austrália",
		austria: "Áustria",
		azerbaijan: "Azerbaijão",
		bahamas: "Bahamas",
		bahrain: "Bahrein",
		bangladesh: "Bangladesh",
		barbados: "Barbados",
		belarus: "Bielorrússia",
		belgium: "Bélgica",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermudas",
		bhutan: "Butão",
		bolivia: "Bolívia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Santo Eustáquio e Saba",
		bosnia_and_herzegovina: "Bósnia e Herzegovina",
		botswana: "Botsuana",
		bouvet_island: "Ilha Bouvet",
		brazil: "Brasil",
		british_indian_ocean_territory: "Território Britânico do Oceano Índico",
		british_virgin_islands: "Ilhas Virgens Britânicas",
		brunei: "Brunei",
		bulgaria: "Bulgária",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Cabo Verde",
		cambodia: "Camboja",
		cameroon: "Camarões",
		canada: "Canadá",
		cayman_islands: "Ilhas Cayman",
		central_african_republic: "República Centro-Africana",
		chad: "Chade",
		chile: "Chile",
		china: "China",
		christmas_island: "Ilha Christmas",
		cocos_islands: "Ilhas Cocos [Keeling]",
		colombia: "Colômbia",
		comoros: "Comores",
		congo_republic: "República do Congo",
		cook_islands: "Ilhas Cook",
		costa_rica: "Costa Rica",
		croatia: "Croácia",
		cuba: "Cuba",
		curacao: "Curaçao",
		cyprus: "Chipre",
		czechia: "Tchéquia",
		denmark: "Dinamarca",
		djibouti: "Djibouti",
		dominica: "Dominica",
		dominican_republic: "República Dominicana",
		dr_congo: "República Democrática do Congo",
		ecuador: "Equador",
		egypt: "Egito",
		el_salvador: "El Salvador",
		equatorial_guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		estonia: "Estônia",
		eswatini: "Essuatíni",
		ethiopia: "Etiópia",
		falkland_islands: "Ilhas Falkland",
		faroe_islands: "Ilhas Faroé",
		fiji: "Fiji",
		finland: "Finlândia",
		france: "França",
		french_guiana: "Guiana Francesa",
		french_polynesia: "Polinésia Francesa",
		french_southern_territories: "Territórios Franceses do Sul",
		gabon: "Gabão",
		gambia: "Gâmbia",
		georgia: "Geórgia",
		germany: "Alemanha",
		ghana: "Gana",
		gibraltar: "Gibraltar",
		greece: "Grécia",
		greenland: "Groenlândia",
		grenada: "Granada",
		guadeloupe: "Guadalupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guiné",
		guinea_bissau: "Guiné-Bissau",
		guyana: "Guiana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Ilha Heard e Ilhas McDonald",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Hungria",
		iceland: "Islândia",
		india: "Índia",
		indonesia: "Indonésia",
		iran: "Irã",
		iraq: "Iraque",
		ireland: "Irlanda",
		isle_of_man: "Ilha de Man",
		israel: "Israel",
		italy: "Itália",
		ivory_coast: "Costa do Marfim",
		jamaica: "Jamaica",
		japan: "Japão",
		jersey: "Jersey",
		jordan: "Jordânia",
		kazakhstan: "Cazaquistão",
		kenya: "Quênia",
		kiribati: "Quiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Quirguistão",
		laos: "Laos",
		latvia: "Letônia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		liechtenstein: "Liechtenstein",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		macao: "Macau",
		madagascar: "Madagascar",
		malawi: "Malawi",
		malaysia: "Malásia",
		maldives: "Maldivas",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Ilhas Marshall",
		martinique: "Martinica",
		mauritania: "Mauritânia",
		mauritius: "Maurício",
		mayotte: "Mayotte",
		mexico: "México",
		micronesia: "Micronésia",
		moldova: "Moldávia",
		monaco: "Mônaco",
		mongolia: "Mongólia",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Marrocos",
		mozambique: "Moçambique",
		myanmar: "Mianmar",
		namibia: "Namíbia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Países Baixos",
		new_caledonia: "Nova Caledônia",
		new_zealand: "Nova Zelândia",
		nicaragua: "Nicarágua",
		niger: "Níger",
		nigeria: "Nigéria",
		niue: "Niue",
		norfolk_island: "Ilha Norfolk",
		north_korea: "Coreia do Norte",
		north_macedonia: "Macedônia do Norte",
		northern_mariana_islands: "Ilhas Marianas do Norte",
		norway: "Noruega",
		oman: "Omã",
		pakistan: "Paquistão",
		palau: "Palau",
		palestine: "Palestina",
		panama: "Panamá",
		papua_new_guinea: "Papua Nova Guiné",
		paraguay: "Paraguai",
		peru: "Peru",
		philippines: "Filipinas",
		pitcairn_islands: "Ilhas Pitcairn",
		poland: "Polônia",
		portugal: "Portugal",
		puerto_rico: "Porto Rico",
		qatar: "Catar",
		reunion: "Reunião",
		romania: "Romênia",
		russia: "Rússia",
		rwanda: "Ruanda",
		saint_barthelemy: "São Bartolomeu",
		saint_helena: "Santa Helena",
		saint_lucia: "Santa Lúcia",
		saint_martin: "São Martinho",
		saint_pierre_and_miquelon: "São Pedro e Miquelão",
		samoa: "Samoa",
		san_marino: "São Marino",
		sao_tome_and_principe: "São Tomé e Príncipe",
		saudi_arabia: "Arábia Saudita",
		senegal: "Senegal",
		serbia: "Sérvia",
		seychelles: "Seicheles",
		sierra_leone: "Serra Leoa",
		singapore: "Singapura",
		sint_maarten: "Sint Maarten",
		slovakia: "Eslováquia",
		slovenia: "Eslovênia",
		solomon_islands: "Ilhas Salomão",
		somalia: "Somália",
		south_africa: "África do Sul",
		south_georgia_and_south_sandwich_islands: "Ilhas Geórgia do Sul e Sandwich do Sul",
		south_korea: "Coreia do Sul",
		south_sudan: "Sudão do Sul",
		spain: "Espanha",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "São Cristóvão e Nevis",
		st_vincent_and_grenadines: "São Vicente e Granadinas",
		sudan: "Sudão",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Svalbard e Jan Mayen",
		sweden: "Suécia",
		switzerland: "Suíça",
		syria: "Síria",
		taiwan: "Taiwan",
		tajikistan: "Tajiquistão",
		tanzania: "Tanzânia",
		thailand: "Tailândia",
		timor_leste: "Timor-Leste",
		togo: "Togo",
		tokelau: "Toquelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad e Tobago",
		tunisia: "Tunísia",
		turkiye: "Turquia",
		turkmenistan: "Turcomenistão",
		turks_and_caicos_islands: "Ilhas Turcas e Caicos",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ucrânia",
		united_arab_emirates: "Emirados Árabes Unidos",
		united_kingdom: "Reino Unido",
		united_states: "Estados Unidos",
		uruguay: "Uruguai",
		us_minor_outlying_islands: "Ilhas Menores Distantes dos EUA",
		us_virgin_islands: "Ilhas Virgens dos EUA",
		uzbekistan: "Uzbequistão",
		vanuatu: "Vanuatu",
		vatican_city: "Cidade do Vaticano",
		venezuela: "Venezuela",
		vietnam: "Vietnã",
		wallis_and_futuna: "Wallis e Futuna",
		western_sahara: "Saara Ocidental",
		yemen: "Iémen",
		zambia: "Zâmbia",
		zimbabwe: "Zimbábue",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Lira Turca",
		us_dollar: "Dólar Americano",
	},
	$employeeType: {
		admin: "Administrador",
		default: "Padrão",
		exporter: "Exportador",
		importer: "Importador",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:ss",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Crie sua empresa aqui.",
			create_company_title: "Deseja adicionar sua empresa?",
			description: "Você fez login com sucesso. Agora você precisa ingressar em uma empresa ou criar uma nova.",
			join_company_subtitle: "Digite seu código e ingresse na empresa aqui.",
			join_company_title: "Deseja ingressar em uma empresa existente?",
			title: "Bem-vindo {name}",
		},
		$unauthorized: {
			description: "Mantenha seus processos de exportação e importação sob controle usando o Antrego",
			forget_password_subtitle: "Digite seu e-mail para redefinir a senha.",
			sign_in_subtitle: "Faça login aqui",
			sign_in_title: "Já tem uma conta?",
			sign_up_subtitle: "Inscreva-se aqui",
			sign_up_title: "Quer criar uma conta?",
			title: "Bem-vindo ao Antrego",
		},
	},
	$invitationType: {
		company_partner: "Convite de Cooperação Comercial",
		customs_partner: "Convite de Parceiro Aduaneiro",
		employee: "Convite de Funcionário",
	},
	$landing: {
		$benefits: {
			benefits_title: "Benefícios",
			business_process: "Acelera seus processos empresariais.",
			control_title: "Assuma o controle com o Antrego.",
			highlight_word: "controle",
			risk_of_error: "Reduz o risco de erros.",
			text: "O Antrego é um programa de rastreamento de envios que ajuda empresas de transporte, seus clientes e corretores de alfândega a acompanhar os processos de envio através de uma plataforma compartilhada.",
			time_and_cost_savings: "Economiza tempo e custos.",
		},
		$content: {
			$text: {
				0: "Acompanhe todos os seus envios em um só lugar e controle suas finanças com relatórios dinâmicos.",
				1: "Compartilhe suas atualizações em tempo real com seus clientes para melhorar a qualidade do seu serviço.",
				2: "Veja e compartilhe todos os documentos relacionados ao seu envio em um único sistema.",
				3: "Controle facilmente seu processo acessando o Antrego de todos os dispositivos e locais.",
				4: "Armazene todos os seus dados usando a tecnologia de criptografia SHA-256. Tenha tranquilidade quanto à segurança.",
				5: "Engaje no rastreamento conjunto com todos os stakeholders no processo empresarial. Gerencie o processo de forma mais eficiente.",
			},
			$title: {
				0: "Gestão de Envio",
				1: "Relações com Clientes",
				2: "Gestão de Documentos",
				3: "Acesso Fácil",
				4: "Segurança de Dados",
				5: "Rastreamento Conjunto",
			},
		},
		$home: {
			text: "Escolha-nos para controle conjunto e rastreamento digital perfeito.",
			title: "Gerencie Todos os Seus Processos de Envio Digitalmente",
		},
		$lifecycle: {
			headline: "Processo",
			info_button: "Obter Informações",
			lifecycle_texts: {
				0: "Carregue arquivos adicionais para criar sua fatura proforma e compartilhe-a instantaneamente com seu cliente.",
				1: "Insira os detalhes do seu envio e adicione documentos. Notifique o seu cliente e gerencie todos os processos de envio de forma conjunta através de um único canal.",
				2: "Acompanhe sua situação financeira relacionada a importação e exportação em tempo real.",
			},
			lifecycle_titles: {
				0: "Faça login e crie uma fatura proforma",
				1: "Crie Envio",
				2: "Acompanhe Atividades Financeiras",
			},
			productivity_title: "Aumente a produtividade e reduza a complexidade e erros em 3 etapas.",
			tracking_guide_title: "Guia de Rastreamento Fácil",
		},
		$logistics: {
			logistics_title: "Tipos de Logística Suportados",
			solutions_title: "A solução certa para cada carga",
			text: "Com o Antrego, seu parceiro digital em todos os tipos de transporte, estamos ao seu lado em todos os seus movimentos globais.",
		},
		$process: {
			digital_future: "Afaste-se dos métodos tradicionais e entre no futuro digital",
			digital_shipping: "Rastreamento Digital de Envio com Antrego",
			digital_shipping_texts: {
				0: "As faturas proforma para pedidos recebidos são criadas e rastreadas facilmente através de um único canal.",
				1: "As faturas proforma são enviadas, notificações vão para o cliente instantaneamente e são aprovadas através do Antrego.",
				2: "Os processos de documentos são gerenciados através do Antrego. Eles não são perdidos, não ocorrem atrasos e o potencial de erros é minimizado.",
				3: "Fornecedores, clientes e corretores de alfândega podem ver instantaneamente os detalhes de envio e rastreá-los em conjunto.",
				4: "Relatórios financeiros são gerados automaticamente mensal e anualmente.",
			},
			question_title: "Como o Antrego Mudará Sua Vida?",
			tracking_method: "Digitalize seus métodos de rastreamento e acelere seus processos.",
			traditional_shipping: "Rastreamento Tradicional de Envio",
			traditional_shipping_texts: {
				0: "Os pedidos são coletados via e-mail, mensagens ou telefone por diferentes canais, causando dificuldades de rastreamento interno.",
				1: "A fatura proforma é criada usando ferramentas como Excel, e o processo de aprovação é tratado manualmente por e-mail, WhatsApp ou telefone.",
				2: "Os processos manuais de documentos levam a erros e atrasos, causando perdas de tempo e financeiras.",
				3: "Não há uma plataforma comum para fornecedores, clientes e corretores de alfândega rastrearem os detalhes de envio.",
				4: "Os dados financeiros são verificados individualmente após cada envio, dificultando o relatório.",
			},
		},
		$question: {
			$text: {
				0: "<p>É um programa de rastreamento de envios que ajuda empresas e seus clientes com rastreamento conjunto, evitando perdas devido a atrasos na fase de rastreamento.</p>",
				1: "<p>- Compartilhe todas as suas atualizações em tempo real com o seu cliente. Melhore a qualidade do seu serviço.<br>\n      - Veja e compartilhe todos os documentos relacionados ao seu envio em um único sistema.<br>\n      - Acompanhe todos os seus envios de um só lugar. Controle suas finanças com relatórios dinâmicos.</p>",
				2: "<p>- Marítimo<br>\n      - Aéreo<br>\n      - Terrestre<br>\n      - Ferroviário</p>",
				3: "<p>Com seus serviços, está sempre à mão para facilitar o seu processo de envio.<br>\n      <strong>1. Acesso Fácil</strong><br>\n      Acessível de todos os dispositivos e locais via navegador da web.<br>\n      <strong>2. Segurança de Dados</strong><br>\n      Todos os dados são armazenados usando a tecnologia de criptografia SHA-256.<br>\n      <strong>3. Rastreamento Conjunto</strong><br>\n      Ele oferece oportunidades de rastreamento para todos os stakeholders no processo.</strong>",
				4: "<p>Sim, é um aplicativo pago. Digitalizando todos os seus processos de envio, queremos facilitar o seu trabalho e continuar fazendo desenvolvimentos úteis para você. Para garantir a sustentabilidade dessas melhorias e o espaço no servidor que você usa, cobramos uma taxa mensal.</p>",
				5: "<p>Não. Seus clientes não precisam pagar nenhuma taxa para rastrear os envios que você faz.</p>",
			},
			$title: {
				0: "O que é Antrego?",
				1: "O que o Antrego vai contribuir para a minha empresa durante o processo de envio?",
				2: "Que tipos de logística o Antrego suporta?",
				3: "Por que devo usar o Antrego?",
				4: "O Antrego é uma aplicação paga?",
				5: "Meus clientes precisam pagar pelo Antrego?",
			},
			save_percentage: "Economize 16% Mensalmente",
			text: "Acesse facilmente todas as informações com o Antrego e sinta-se à vontade para nos contatar para outras perguntas que possa ter.",
			title: "Saiba rapidamente o que você está curioso",
		},
		cancel_anytime: "Cancele a qualquer momento",
		customize_package:
			"Você pode adquirir armazenamento adicional e usuários e personalizar seu pacote de acordo com suas necessidades",
		free_features_1: "10 Envios",
		free_features_2: "100 MB de Armazenamento",
		free_features_3: "Parceiros Comerciais Ilimitados",
		free_features_4: "1 Usuário",
		free_plan: "Grátis",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Mensal",
		premium_annual_features_1: "20 Usuários",
		premium_annual_features_2: "100 Parceiros",
		premium_annual_features_3: "1000 Envios",
		premium_annual_features_4: "1000 Faturas",
		premium_annual_price: "$83,25",
		premium_annual_regeneration: "Renovará anualmente por $999",
		premium_annual_title: "Anual",
		premium_monthly_features_1: "Envios Ilimitados",
		premium_monthly_features_2: "Parceiros Comerciais Ilimitados",
		premium_monthly_features_3: "2GB de Armazenamento",
		premium_monthly_features_4: "3 Usuários",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Renovará mensalmente por $99",
		premium_monthly_title: "Mensal",
		premium_plan: "Plano Premium",
		premium_plus_annual_features_1: "Envios Ilimitados",
		premium_plus_annual_features_2: "Parceiros Comerciais Ilimitados",
		premium_plus_annual_features_3: "5GB de Armazenamento",
		premium_plus_annual_features_4: "5 Usuários",
		premium_plus_annual_price: "$83,25",
		premium_plus_annual_regeneration: "Renovará anualmente por $999",
		premium_plus_annual_title: "Anual",
		premium_plus_monthly_features_1: "Envios Ilimitados",
		premium_plus_monthly_features_2: "Parceiros Comerciais Ilimitados",
		premium_plus_monthly_features_3: "5GB de Armazenamento",
		premium_plus_monthly_features_4: "5 Usuários",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Renovará mensalmente por $249",
		premium_plus_monthly_title: "Mensal",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Podemos atender às suas necessidades da melhor forma sem ultrapassar o seu orçamento.",
		pricing_title: "O Antrego garante oferecer o melhor pacote e preço para você.",
		save_percentage: "Economize 16% Mensalmente",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Árabe",
		de: "Alemão",
		en: "Inglês",
		es: "Espanhol",
		fr: "Francês",
		hi: "Hindi",
		ind: "Indonésio",
		it: "Italiano",
		ja: "Japonês",
		ko: "Coreano",
		pt: "Português",
		ru: "Russo",
		tr: "Turco",
		vi: "Vietnamita",
		zh: "Chinês",
	},
	$message: {
		cannot_delete_verified_partner: "Você não pode excluir um parceiro verificado.",
		confirm: "Você tem certeza de {function}?",
		created: "{item} criado.",
		deleted: "{item} excluído.",
		error_on_view_file: "Ocorreu um erro ao visualizar o arquivo.",
		file_oversize: "O tamanho do arquivo deve ser no máximo {size}.",
		invalid: "{item} inválido",
		migrated: "{item} migrado. ({value})",
		process_has_been_started: "Seu {item} foi iniciado.",
		sent: "{item} enviado.",
		sent_email: "E-mail enviado. ({value})",
		sign_in_failed: "E-mail ou senha incorretos",
		unsupported_file_type: "Tipo de arquivo não suportado",
		updated: "{item} atualizado.",
	},
	$pageTitles: {
		accept_invitation: "Aceitar Convite",
		company_profile: "Perfil da Empresa",
		home: "Início",
		landing_page: "Rastreamento digital contínuo, controle compartilhado",
		partners: "Parceiros",
		product_groups: "Grupos de Produtos",
		products: "Produtos",
		proforma_invoices: "Faturas Proforma",
		shipment_details: "Detalhes do Envio",
		shipments: "Envios",
		sign_in: "Entrar",
		sign_up: "Inscrever-se",
		subscription: "Assinatura",
		user_profile: "Perfil do Usuário",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Parceiros não registrados, você criou o perfil deles em nossa plataforma, para que você possa visualizar e editar suas informações.",
		createdHereTitle: "Criado aqui:",
		description: "Você pode acompanhar o status da sua relação com seus parceiros. Aqui estão as diferenças:",
		verifiedPartnersDescription:
			"Esses parceiros aceitaram seu convite e se registraram na plataforma. Suas informações são apenas para visualização e não podem ser editadas.",
		verifiedPartnersTitle: "Parceiros verificados:",
	},
	$productGroup: {
		add_child: "Adicionar Subgrupo",
		add_new_product_group: "Adicionar novo grupo de produtos",
		add_to_root: "Adicionar à Raiz",
		has_children_fail_message: "Um grupo de produtos com um subgrupo não pode ser excluído",
		initial_message: "Use este botão {0} para criar seu primeiro grupo de produtos.",
		name: "Nome",
		number_of_product: "Número de produtos",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} aprovado.",
			create: "Criado por {name}.",
			create_attachment: "{name} criou um anexo.",
			delete_attachment: "{name} excluiu um anexo.",
			demand_revise: "{name} solicitou revisão.",
			mark_as_processed: "{name} marcado como processado.",
			reject: "{name} rejeitado.",
			revoke: "{name} revogado.",
			send: "{name} enviado ao receptor para avaliação.",
			update: "Atualizado por {name}.",
			update_attachment: "{name} atualizou um anexo.",
		},
		$actionDescription2: {
			approve: '{name} marcado como "Aprovado".',
			demand_revise: '{name} marcado como "Revisão Pendente".',
			mark_as_processed: '{name} marcado como "Processado".',
			reject: "{name} rejeitado.",
			revoke: '{name} marcado como "Revogado".',
			send: '{name} marcado como "Enviado".',
		},
		$actions: {
			approve: "Aprovar",
			demand_revise: "Solicitar Revisão",
			mark_as_approved: "Aprovado",
			mark_as_processed: "Processado",
			mark_as_rejected: "Rejeitado",
			mark_as_revise_demanded: "Revisão Solicitada",
			mark_as_revoked: "Revogado",
			mark_as_sent: "Enviado",
			reject: "Rejeitar",
			revoke: "Revogar",
			send: "Enviar",
		},
		$status: {
			approved: "Aprovado",
			pending_approval: "Aguardando Aprovação",
			pending_revise: "Revisão Pendente",
			processed: "Processado",
			rejected: "Rejeitado",
			revoked: "Revogado",
			template: "Modelo",
		},
		approve_confirm_message: "Tem certeza de que deseja aprovar a fatura proforma?",
		approve_success_message: "A fatura proforma foi aprovada.",
		demand_revise_description_message: "Por favor, forneça alguns detalhes sobre sua expectativa de revisão:",
		demand_revise_success_message: "A fatura proforma foi enviada de volta ao remetente para revisão.",
		mark_as_processed_confirm_message: "Você confirma marcar a fatura proforma como processada?",
		mark_as_processed_success_message: "A fatura proforma foi marcada como processada.",
		no_attachment_text: "Nenhum anexo adicionado ainda.",
		reject_confirm_message: "Tem certeza de que deseja rejeitar a fatura proforma?",
		reject_success_message: "A fatura proforma foi rejeitada.",
		revoke_confirm_message: "Tem certeza de que deseja revogar a fatura proforma?",
		revoke_success_message: "A fatura proforma foi revogada.",
		send_confirm_message: "Você confirma o envio da fatura proforma ao receptor?",
		send_success_message: "A fatura proforma foi enviada.",
	},
	$serviceMessage: {
		already_subs_module: "Já existe uma assinatura para este módulo.",
		bad_request: "Requisição Inválida",
		code_already_used: "O código já foi usado.",
		container_status_not_available: "O status do contêiner não está disponível.",
		existing_email_warning: "O e-mail já existe.",
		existing_employee_error: "Este usuário já foi adicionado à sua empresa.",
		existing_employee_for_email_warning: "O código de convite já foi enviado para este e-mail",
		existing_invitation_warning: "O código de convite já foi enviado para este e-mail.",
		existing_token_already_have_time: "O token existente já tem tempo suficiente para ser usado.",
		existing_user: "O usuário já existe.",
		five_min_reset_password_mail:
			"Você deve esperar pelo menos 5 minutos depois de solicitar um e-mail de redefinição de senha uma vez.",
		forbidden: "Proibido",
		have_no_corporation: "Você não tem uma corporação",
		have_no_custom_agency: "Você não tem uma agência de alfândega",
		have_no_employee: "Você não tem funcionário",
		instance_can_no_delete_existing_booking_code:
			"A instância não pode mais ser excluída devido ao código de reserva existente.",
		instance_cannot_delete: "A instância não pode mais ser excluída.",
		instance_not_found: "Instância não encontrada.",
		internal_server_error: "Erro Interno do Servidor",
		invalid_current_password: "Senha atual inválida.",
		invalid_identifier_or_password: "Identificador ou senha inválidos",
		invalid_invitation_code: "O código do convite está incorreto.",
		invalid_moving_request: "Solicitação de movimento inválida",
		invalid_operation: "Operação inválida",
		invalid_refresh_token: "Token de atualização inválido.",
		invalid_unit: "Unidade inválida",
		invitation_accepting_is_due_to_the_admin: "A aceitação do convite é de responsabilidade do administrador.",
		invitation_already_discarded: "O convite já foi descartado.",
		invitation_already_used: "O convite já foi usado.",
		invitation_has_been_used: "O convite foi usado.",
		invitation_not_exist_email: "O convite não existe no endereço de e-mail.",
		invitation_not_for_company: "Este convite não é para uma empresa.",
		invitation_not_for_custom_agency: "Este convite não é para uma agência de alfândega.",
		invitation_not_found: "O convite não foi encontrado.",
		invitation_not_valid: "O convite não é mais válido.",
		link_has_been_expired: "O link expirou.",
		link_not_be_used_anymore: "Este link não pode mais ser usado",
		missing_required_fields: "Campos obrigatórios ausentes.",
		no_employee_found: "Nenhum funcionário encontrado",
		no_proper_partner_found: "Nenhum parceiro adequado encontrado.",
		not_acceptable: "Não Aceitável",
		not_allowed_action: "Você não tem permissão para essa ação.",
		not_allowed_use_api: "Você não tem permissão para usar essa API.",
		not_found: "Não Encontrado",
		not_have_purchase_subscription:
			"Seu plano de assinatura atingiu o limite máximo para definições. Para adicionar mais, considere atualizar seu plano de assinatura.",
		not_permitted_use_api: "Você não tem permissão para usar essa API.",
		own_invitation_cannot_accept: "Você não pode aceitar seu próprio convite.",
		parent_group_not_found: "Grupo Pai não encontrado",
		partnerId_required: "ID do Parceiro é necessário.",
		partnership_already_exists: "O parceiro já foi adicionado.",
		password_cant_involve_space_char: "A senha não pode conter espaços.",
		password_min_chars_least: "A senha deve ter no mínimo 6 caracteres.",
		payment_required: "Por favor, assine para este processo.",
		paytr_integration_error: "Erro na integração com PayTR",
		ports_are_required: "Portos são necessários.",
		product_group_tree_not_found: "Árvore do Grupo de Produtos não encontrada",
		proforma_invoice_action_not_available: "O status da fatura proforma não está disponível para esta ação.",
		proforma_invoice_template_update: "A fatura proforma deve ser um modelo para ser atualizada.",
		shipment_email_not_exist: "Por favor, insira um e-mail válido.",
		shipment_method_is_required: "O método de envio é necessário.",
		shipping_term_is_required: "O termo de envio é necessário.",
		someone_else_uses_this_email: "Alguém mais está usando este endereço de e-mail.",
		someone_else_uses_this_username: "Alguém mais está usando este nome de usuário.",
		something_went_wrong: "Algo deu errado",
		type_can_be_abstract_company: 'A propriedade "tipo" só pode ser "abstract_company"',
		unauthorized: "Não Autorizado",
		undefined_result: "Resultado indefinido",
		unexpected_error_occurred: "Ocorreu um erro inesperado",
		unit_already_added: "A unidade já foi adicionada.",
		user_already_employee: "O usuário já é funcionário.",
		user_already_exists: "O usuário já existe em uma empresa.",
		user_does_not_exist: "O usuário não existe.",
		user_not_found: "Usuário não encontrado.",
		username_min_chars_error: 'O "nome de usuário" deve ter no mínimo 6 caracteres.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} adicionou um veículo.",
				delete_container: "{name} excluiu um veículo.",
				update_container: "{name} atualizou um veículo.",
			},
			create: "Criado por {name}.",
			create_attachment: "{name} adicionou um anexo.",
			create_container: "{name} adicionou um contêiner.",
			delete_attachment: "{name} excluiu um anexo.",
			delete_container: "{name} excluiu um contêiner.",
			update: "Atualizado por {name}.",
			update_attachment: "{name} atualizou um anexo.",
			update_container: "{name} atualizou um contêiner.",
		},
		$containerStatus: {
			arrived: "Chegou",
			off_load: "Descarregando",
			on_load: "Carregando",
			on_way: "A Caminho",
			reserved: "Reservado",
		},
		$method: {
			aerial: "Aéreo",
			land: "Terrestre",
			railway: "Ferroviário",
			sea: "Marítimo",
		},
		$vehicle: {
			license_number: "Placa do Veículo",
		},
		archiving_success_message: "O envio foi arquivado.",
		leave_message: "Deixe sua mensagem ou comentário",
		no_booking_info: "Nenhuma informação de reserva foi definida ainda. Use o botão {0} para adicionar.",
		no_transportation_info: "Nenhuma informação de transporte foi definida ainda. Use o botão {0} para definir.",
		un_archiving_success_message: "O envio foi desarquivado.",
	},
	$shortening: {
		number: "Nº",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Sua assinatura não pôde ser renovada. Verifique seu método de pagamento.",
			fail: "Ocorreu um erro inesperado. Tente novamente mais tarde. Se o problema persistir, por favor, reporte enviando um e-mail para info@antrego.net.",
			ok: "O processo de compra foi iniciado. O resultado do processo será notificado por e-mail. Sua assinatura será iniciada quando o pagamento for concluído com sucesso.",
			payment_process_is_ongoing:
				"Seu processo de pagamento está em andamento. O resultado do processo será notificado por e-mail. Sua assinatura será iniciada quando o pagamento for concluído com sucesso.",
			success: "Sua assinatura foi criada com sucesso. Você pode começar a usar o Antrego facilmente.",
			unsubscribed: "Cancelado",
			you_dont_have_any_subscription:
				"Você não tem nenhuma assinatura. Escolha um pacote na página de assinatura e comece a usá-lo facilmente.",
			you_dont_have_any_subscription_text: "Escolha um plano para continuar.",
			you_dont_have_any_subscription_title: "Você não tem nenhuma assinatura ativa.",
		},
	},
	$unit: {
		acres: "Acres",
		boxes: "Caixas",
		cubic_meter: "Metro Cúbico",
		dozen: "Dúzia",
		feet: "Pés",
		fluid_ounces: "Onças Líquidas",
		gallons: "Galões",
		gram: "Grama",
		hectare: "Hectare",
		inches: "Polegadas",
		kilogram: "Quilograma",
		kilometer: "Quilômetro",
		liter: "Litro",
		meter: "Metro",
		miles: "Milhas",
		milligram: "Miligrama",
		milliliter: "Mililitro",
		millimeter: "Milímetro",
		ounces: "Onças",
		pallets: "Paletes",
		pieces: "Peças",
		pounds: "Libras",
		quarts: "Quartos",
		square_feet: "Pés Quadrados",
		square_inches: "Polegadas Quadradas",
		square_kilometer: "Quilômetro Quadrado",
		square_meter: "Metro Quadrado",
		square_miles: "Milhas Quadradas",
		square_yards: "Jardas Quadradas",
		ton: "Toneladas",
		yards: "Jardas",
	},
	$validation: {
		email: "Por favor, insira um e-mail válido",
		invalid: "{item} inválido",
		max_length: "{item} deve ter menos de {length} caracteres",
		min_length: "{item} deve ter mais de {length} caracteres",
		must_length: "{item} deve ter {length} caracteres",
		only_number: "{item} deve ser um número",
		password_repeat_warning: "A repetição da senha não é igual à nova senha.",
		required: "{item} é obrigatório",
		required_for_default_language: "{item} é obrigatório para o idioma padrão da empresa.",
		you_must_approve_distance_sales_agreement: "Você deve aprovar o Contrato de Vendas à Distância",
	},
	about: "Sobre",
	accept_invitation: "Aceitar Convite",
	accept_invitation_success_message: "Você utilizou o convite com sucesso.",
	accessibility: "acessibilidade",
	actions: "Ações",
	active: "Ativo",
	activities: "Atividades",
	add: "Adicionar",
	add_item: "Adicionar {item}",
	add_new_employee: "Adicionar novo funcionário",
	add_new_product: "Adicionar novo produto",
	address: "Endereço",
	again: "Novamente",
	alert: "Alerta",
	already_have_employee_message: "Você já é um funcionário de uma empresa.",
	amount: "Quantia",
	approve: "Aprovar",
	archive: "Arquivar",
	attachment: "Anexo",
	attachments: "Anexos",
	bank: "Banco",
	benefits: "Benefícios",
	booking: "Reserva",
	booking_code: "Código de Reserva",
	booking_details: "Detalhes da Reserva",
	business_partner: "Parceiro Comercial",
	business_partners: "Parceiros Comerciais",
	cancel: "Cancelar",
	change_password: "Alterar Senha",
	checking_invitation_code_message: "Verificando o código de convite",
	choose_image: "Escolher imagem",
	choose_your_plan: "Escolha seu plano",
	clear: "Limpar",
	click_to_play_the_video: "Clique para reproduzir o vídeo",
	close: "Fechar",
	code: "Código",
	commercial: "Comercial",
	commercial_partner: "Parceiro Comercial",
	commercial_partners: "Parceiros Comerciais",
	companies: "Empresas",
	company: "Empresa",
	company_name: "Nome da empresa",
	company_profile: "Perfil da Empresa",
	configuration: "Configuração",
	confirm: "Confirmar",
	confirm_distance_selling_agreement: "Confirme o Contrato de Vendas à Distância.",
	consult_us: "Consulte-nos",
	contact: "Contato",
	contact_info: "Informações de Contato",
	contact_text: "Fique tranquilo, entraremos em contato com você o mais rápido possível.",
	contact_title: "Consulte-nos, simplifique seus processos",
	contact_to_us: "Contate-nos",
	container: "Contêiner",
	container_no: "N.º do Contêiner",
	containers: "Contêineres",
	content: "Conteúdo",
	contract: "Contrato",
	copy_link: "Copiar link",
	countries: "Países",
	country: "País",
	create: "Criar",
	create_a_new_account: "Criar uma Nova Conta",
	create_company: "Criar Empresa",
	created_at: "Criado em",
	created_here: "Criado aqui",
	credit_card_info: "Informações do Cartão de Crédito",
	credit_card_no: "Número do Cartão de Crédito",
	currencies: "Moedas",
	currency: "Moeda",
	current_password: "Senha Atual",
	current_plan: "Plano atual",
	customs: "Alfândega",
	customs_agencies: "Agências de Alfândega",
	customs_agency: "Agência de Alfândega",
	cvv: "CVV",
	daily: "Diário",
	dashboard: "Painel",
	date: "Data",
	day: "Dia",
	default: "Padrão",
	default_language: "Idioma Padrão",
	delete: "Excluir",
	delete_account: "Excluir conta",
	delete_confirm_message: "Você confirma a exclusão?",
	description: "Descrição",
	detail: "Detalhe",
	discard: "Descartar",
	distance_sales_agreement: "Contrato de Vendas à Distância",
	document_number: "Número do Documento",
	download: "Baixar",
	edit: "Editar",
	edit_item: "Editar {item}",
	edit_product: "Editar produto",
	edit_profile: "Editar perfil",
	edit_your_profile_picture: "Editar sua foto de perfil",
	email: "E-mail",
	employee: "Funcionário",
	employee_type: "Tipo de Funcionário",
	employees: "Funcionários",
	estimated_arrival_day: "Data Estimada de Chegada",
	expense: "Despesa",
	expenses: "Despesas",
	expires_at: "Expira em",
	exported_containers: "Contêineres Exportados",
	fail: "Falhar",
	failed: "Falhou",
	faq: "F.A.Q.",
	feedback: "Feedback",
	file: "Arquivo",
	file_preview: "Pré-visualização do Arquivo",
	file_view: "Visualizar Arquivo",
	filter: "Filtrar",
	first_name: "Nome",
	first_name_last_name: "Nome Sobrenome",
	forget_password: "Esqueceu a Senha",
	forget_password_message: "Esqueceu sua senha?",
	forget_password_subtitle: "Por favor, insira seu endereço de e-mail para redefinir sua senha.",
	free: "Grátis",
	from: "De",
	get_started_now: "Comece agora",
	give_us_feedback: "Nos dê feedback",
	go_to_panel: "Ir para o Painel",
	hide: "Ocultar",
	home_page: "Página Inicial",
	i_have_invitation_code: "Eu tenho um código de convite",
	imported_containers: "Contêineres Importados",
	in_progress: "Em Andamento",
	incoming: "Entrante",
	incoming_shipments: "Envios Entrantes",
	initial: "Inicial",
	invitation: "Convite",
	invitation_code: "Código de Convite",
	invitation_mail_sent: "E-mail de convite enviado",
	invitations: "Convites",
	join: "Entrar",
	join_company: "Juntar-se à Empresa",
	language: "Idioma",
	language_options: "Opções de Idioma",
	last_name: "Sobrenome",
	last_one_year: "Último 1 Ano",
	latitude: "Latitude",
	left_a_comment: "Deixou um comentário",
	link_copied: "Link copiado",
	link_copy_failed: "Falha ao copiar o link",
	loading_country: "Carregando País",
	loading_point: "Ponto de Carregamento",
	loading_port: "Porto de Carregamento",
	log_in: "Entrar",
	logo: "Logo",
	longitude: "Longitude",
	mail_sent: "E-mail enviado",
	manage_profile: "Gerenciar perfil",
	message: "Mensagem",
	message_company_create: "Empresa criada.",
	message_company_join: "Por favor, insira o código de convite.",
	message_company_joined: "Você entrou na empresa",
	message_employee_delete: "Funcionário excluído.",
	message_employee_save: "Funcionário salvo.",
	message_join_company: "Digite seu código para entrar em uma empresa existente",
	method: "Método",
	migrate: "Migrar",
	migrate_partner: "Migrar Empresa",
	mobile_number: "Número de Celular",
	month: "Mês",
	month_lowercase: "mês",
	monthly: "Mensal",
	my_profile: "Meu Perfil",
	my_role: "Meu Papel",
	name: "Nome",
	new: "Novo",
	new_company_package: "Grátis",
	new_password: "Nova Senha",
	new_shipment: "Nova remessa",
	next: "Próximo",
	no: "Não",
	no_content: "Sem Conteúdo",
	no_entity: "Nenhuma entidade",
	no_entity_message: "Nenhuma entidade encontrada.",
	no_text: "Sem Texto",
	not_found_page_message: "Desculpe, não consegui encontrar esta página :(",
	note: "Nota",
	notes: "Notas",
	ok: "OK",
	operation_fail: "Operação Falhou",
	outgoing: "Saída",
	outgoing_shipments: "Envios de Saída",
	packages: "Pacotes",
	parent_group: "Grupo Pai",
	partner: "Parceiro",
	partners: "Parceiros",
	partnership_status: "Status da parceria",
	passive: "Passivo",
	password: "Senha",
	pay: "Pagar",
	payback: "Reembolso",
	payback_confirm_message: "Você confirma o reembolso?",
	payback_warning_message: "Se você continuar, sua assinatura será encerrada.",
	payment: "Pagamento",
	payment_amount: "Valor do Pagamento",
	payment_awaited: "Pagamento Aguardado",
	payment_history: "Histórico de Pagamento",
	payment_success: "Pagamento Concluído",
	payment_summary: "Resumo de Pagamento",
	payment_will_be_made_3d_secure: "*Seu pagamento será realizado com 3D Secure.",
	pending_invitations: "Convites pendentes",
	per_month: "Por mês",
	phone_number: "Número de Telefone",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Anterior",
	pricing: "Preços",
	process: "Processo",
	product: "Produto",
	product_group: "Grupo de Produtos",
	product_group_name: "Nome do grupo de produtos",
	product_groups: "Grupos de Produtos",
	product_name: "Nome do produto",
	products: "Produtos",
	profile: "Perfil",
	proforma_invoice: "Fatura Proforma",
	proforma_invoices: "Faturas Proforma",
	proforma_number: "Nº da Fatura Proforma",
	purchase: "Compra",
	quantity: "Quantidade",
	receiver: "Receptor",
	receiver_company: "Empresa Receptora",
	receiver_customs_agency: "Agência de Alfândega Receptora",
	redirection_to_home_link: "Início",
	redirection_to_home_message: "Você também pode ir para ",
	redirection_to_sign_in_message: "Já tem uma conta?",
	redirection_to_sign_up_message: "Ainda não tem uma conta?",
	reload: "Recarregar",
	renewal: "Renovação",
	repaid: "Reembolsado",
	reset_password: "Redefinir Senha",
	rows_per_page: "Linhas por Página",
	sales: "Vendas",
	save: "Salvar",
	save_changes: "Salvar alterações",
	saved_card_info: "Informações do Cartão Salvas",
	search: "Pesquisar",
	select: "Selecionar",
	select_file: "Selecionar Arquivo",
	select_file_place_holder: "Por favor, selecione uma imagem",
	select_language: "Selecionar idioma",
	selected_partner: "Parceiro Selecionado",
	send: "Enviar",
	send_again: "Enviar Novamente",
	send_email: "Enviar E-mail",
	send_feedback: "Enviar Feedback",
	send_invitation: "Enviar Convite",
	sender: "Remetente",
	sender_company: "Empresa Remetente",
	sender_customs_agency: "Agência de Alfândega Remetente",
	settings: "Configurações",
	shipment: "Envio",
	shipment_method: "Método de Envio",
	shipment_type: "Tipo de Envio",
	shipments: "Envios",
	shipments_filter: "Filtro de Envios",
	shipping_term: "Termo de Envio",
	shipping_terms: "Termos de Envio",
	show: "Mostrar",
	sign_in: "Entrar",
	sign_out: "Sair",
	sign_out_confirm_message: "Você confirma a saída?",
	sign_out_success_message: "Adeus.",
	sign_up: "Inscrever-se",
	social_media: "Mídias Sociais",
	social_media_account: "Conta de Mídias Sociais",
	start_time: "Hora de Início",
	status: "Status",
	subscribe: "Assinar",
	subscription: "Assinatura",
	subscription_info_message:
		"Você pode se beneficiar de todos os serviços ilimitadamente durante o período de assinatura.",
	subscription_warning_message:
		"**Suas informações de cartão serão armazenadas com segurança e sua assinatura será renovada regularmente, a menos que você a cancele.",
	success: "Sucesso",
	super: "Super",
	supported_files_img: "Arquivos suportados: JPG, PNG.",
	switch_language: "Mudar Idioma",
	switch_theme: "Mudar Tema",
	target: "Meta",
	target_partner: "Parceiro Alvo",
	tax_number: "Número de Imposto",
	theme: "Tema",
	this_month: "Este Mês",
	this_year: "Este Ano",
	title: "Título",
	to: "Para",
	toDelete: "Excluir",
	total: "Total",
	total_amount: "Quantia Total",
	transaction_no: "Número da Transação",
	transactions: "Transações",
	try_for_free: "Experimente Grátis",
	type: "Tipo",
	types: "Tipos",
	un_archive: "Desarquivar",
	unit: "Unidade",
	units: "Unidades",
	unloading_country: "País de Descarregamento",
	unloading_point: "Ponto de Descarregamento",
	unloading_port: "Porto de Descarregamento",
	unsubscribe: "Cancelar Assinatura",
	unsubscribe_confirm_message:
		"Se você cancelar a assinatura, não poderá usar os recursos do sistema de forma eficaz. Tem certeza de que deseja cancelar a assinatura?",
	upload_new_image: "Carregar nova imagem",
	use_code: "Usar Código",
	use_invitation_code: "Usar Código de Convite",
	use_invitation_code_description: "Digite seu código de convite enviado para sua caixa de correio.",
	use_invitation_code_success_message: "O código de convite foi usado com sucesso.",
	user_profile: "Meu Perfil",
	vehicle: "Veículo",
	vehicles: "Veículos",
	verified_partner: "Parceiro Verificado",
	verify_password: "Verificar senha",
	visibility: "Visibilidade",
	warehouse: "Armazém",
	warehouses: "Armazéns",
	website: "Site",
	week: "Semana",
	welcome: "Bem-vindo",
	what_we_are_doing: "O que estamos fazendo",
	year: "Ano",
	year_lowercase: "ano",
	yearly: "Anual",
	yes: "Sim",
	you_dont_have_any_active_subscription: "Você não tem nenhuma assinatura ativa",
};
