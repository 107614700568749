<template>
	<v-list-item
		:class="{ 'accent background--text': isActive }"
		@click="clickAction"
		:link="isLinkButton"
		:to="isLinkButton ? url : null"
	>
		<v-list-item-action>
			<v-icon class="px-1">{{ icon }}</v-icon>
		</v-list-item-action>
		<v-list-item-content>
			<v-list-item-title class="button">
				{{ title }}
			</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			required: true,
		},
		url: {
			type: String,
			default: null,
		},
		additionalActivePaths: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		isLinkButton() {
			return this.url !== null;
		},
		isActive() {
			if (!this.url) return false;
			const currentPath = this.$route.path.replace(/\/$/, "");
			const itemPath = this.url.replace(/\/$/, "");
			if (currentPath === itemPath || currentPath.startsWith(`${itemPath}/`)) {
				return true;
			}
			return this.additionalActivePaths.some((path) => {
				const cleanPath = path.replace(/\/$/, "");
				return currentPath === cleanPath || currentPath.startsWith(`${cleanPath}/`);
			});
		},
	},
	methods: {
		clickAction() {
			if (!this.isLinkButton) {
				this.$emit("click", true);
			}
		},
	},
};
</script>
