<template>
	<v-dialog v-model="value" persistent max-width="360">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("migrate_partner") }}
			</v-card-title>
			<v-card-text>
				<v-form v-model="valid" ref="form">
					<partner-select
						v-model="currentItem.selectedId"
						:label="$t('selected_partner')"
						:eager="true"
						:types="[partnerTypes.AbstractCompany]"
						:rules="rules.selectedPartner"
					/>
					<partner-select
						v-model="currentItem.targetId"
						:label="$t('target_partner')"
						:eager="true"
						:types="[partnerTypes.Company]"
						:rules="rules.targetPartner"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn @click="cancelButtonAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn color="accent" @click="migrateButtonAction">
					{{ $t("ok") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import PartnerSelect from "../../../controls/PartnerSelect.vue";
import remote from "../../../../data/remote";
import { partnerTypes } from "../../../../utils/enum";
import { mapState } from "vuex";

export default {
	mixins: [uiMixin],
	props: { value: Boolean, item: Object },
	components: { PartnerSelect },
	data() {
		return {
			valid: null,
			currentItem: {
				selectedId: null,
				targetId: null,
			},
			rules: {
				selectedPartner: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("selected_partner"),
						}),
				],
				targetPartner: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("target_partner"),
						}),
				],
			},
		};
	},
	computed: {
		...mapState(["corporation"]),
		partnerTypes: () => partnerTypes,
	},
	watch: {
		value(val) {
			if (!val)
				this.currentItem = {
					selectedId: null,
					targetId: null,
				};
		},
	},
	methods: {
		migrateButtonAction() {
			if (this.valid) {
				this.saving = true;
				remote.corporations.partners.migrate(this.corporation.id, this.currentItem, {
					onSuccess: (result) => {
						this.saving = false;
						this.$emit("migrated", result);
					},
					onFail: () => this.hideProgressDialog(),
				});
			} else this.$refs.form.validate();
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
	},
};
</script>
