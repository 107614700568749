export default {
	$accessibility: {
		archived: "Архивирован",
		present: "Доступен",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Скрыт от получателя",
			hidden_from_sender: "Скрыт от отправителя",
			private: "Приватный",
			shared_with_custom: "Доступен по выбору",
			shared_with_everyone: "Доступен всем",
			shared_with_receiver: "Доступен получателю",
			shared_with_sender: "Доступен отправителю",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}ГБ памяти",
		limitMBStorage: "{limit}МБ памяти",
		limitUser: "{limit} пользователь",
		limitUsers: "{limit} пользователей",
		renewAtPricePerUnit: "Будет возобновлено за {currency}{price} за {unit}",
		unlimitedModule: "Неограниченный {module}",
		upToLimitModule: "До {limit} {module}",
	},
	$company: {
		address: "ул. Котекли Мах., Денизли Йолу Бул., Технопарк Ситеси Б Блок №4B, Внутр. №14, Ментеше / Мугла, ТУРЦИЯ",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sru!2str!4v1701805110461!5m2!1sru!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"Ускорьте свой бизнес с помощью Antrego. Управляйте процессами доставки цифровым, безопасным и простым способом.",
	},
	$country: {
		afghanistan: "Афганистан",
		aland: "Аландские острова",
		albania: "Албания",
		algeria: "Алжир",
		american_samoa: "Американское Самоа",
		andorra: "Андорра",
		angola: "Ангола",
		anguilla: "Ангилья",
		antarctica: "Антарктида",
		antigua_and_barbuda: "Антигуа и Барбуда",
		argentina: "Аргентина",
		armenia: "Армения",
		aruba: "Аруба",
		australia: "Австралия",
		austria: "Австрия",
		azerbaijan: "Азербайджан",
		bahamas: "Багамы",
		bahrain: "Бахрейн",
		bangladesh: "Бангладеш",
		barbados: "Барбадос",
		belarus: "Беларусь",
		belgium: "Бельгия",
		belize: "Белиз",
		benin: "Бенин",
		bermuda: "Бермуды",
		bhutan: "Бутан",
		bolivia: "Боливия",
		bonaire_sint_eustatius_and_saba: "Бонэйр, Синт-Эстатиус и Саба",
		bosnia_and_herzegovina: "Босния и Герцеговина",
		botswana: "Ботсвана",
		bouvet_island: "Остров Буве",
		brazil: "Бразилия",
		british_indian_ocean_territory: "Британская территория в Индийском океане",
		british_virgin_islands: "Британские Виргинские острова",
		brunei: "Бруней",
		bulgaria: "Болгария",
		burkina_faso: "Буркина-Фасо",
		burundi: "Бурунди",
		cabo_verde: "Кабо-Верде",
		cambodia: "Камбоджа",
		cameroon: "Камерун",
		canada: "Канада",
		cayman_islands: "Каймановы острова",
		central_african_republic: "Центральноафриканская Республика",
		chad: "Чад",
		chile: "Чили",
		china: "Китай",
		christmas_island: "Остров Рождества",
		cocos_islands: "Кокосовые острова",
		colombia: "Колумбия",
		comoros: "Коморы",
		congo_republic: "Республика Конго",
		cook_islands: "Острова Кука",
		costa_rica: "Коста-Рика",
		croatia: "Хорватия",
		cuba: "Куба",
		curacao: "Кюрасао",
		cyprus: "Кипр",
		czechia: "Чехия",
		denmark: "Дания",
		djibouti: "Джибути",
		dominica: "Доминика",
		dominican_republic: "Доминиканская Республика",
		dr_congo: "Демократическая Республика Конго",
		ecuador: "Эквадор",
		egypt: "Египет",
		el_salvador: "Сальвадор",
		equatorial_guinea: "Экваториальная Гвинея",
		eritrea: "Эритрея",
		estonia: "Эстония",
		eswatini: "Эсватини",
		ethiopia: "Эфиопия",
		falkland_islands: "Фолклендские острова",
		faroe_islands: "Фарерские острова",
		fiji: "Фиджи",
		finland: "Финляндия",
		france: "Франция",
		french_guiana: "Французская Гвиана",
		french_polynesia: "Французская Полинезия",
		french_southern_territories: "Французские Южные и Антарктические территории",
		gabon: "Габон",
		gambia: "Гамбия",
		georgia: "Грузия",
		germany: "Германия",
		ghana: "Гана",
		gibraltar: "Гибралтар",
		greece: "Греция",
		greenland: "Гренландия",
		grenada: "Гренада",
		guadeloupe: "Гваделупа",
		guam: "Гуам",
		guatemala: "Гватемала",
		guernsey: "Гернси",
		guinea: "Гвинея",
		guinea_bissau: "Гвинея-Бисау",
		guyana: "Гайана",
		haiti: "Гаити",
		heard_island_and_mcdonald_islands: "Остров Херд и острова Макдональд",
		honduras: "Гондурас",
		hong_kong: "Гонконг",
		hungary: "Венгрия",
		iceland: "Исландия",
		india: "Индия",
		indonesia: "Индонезия",
		iran: "Иран",
		iraq: "Ирак",
		ireland: "Ирландия",
		isle_of_man: "Остров Мэн",
		israel: "Израиль",
		italy: "Италия",
		ivory_coast: "Кот-д’Ивуар",
		jamaica: "Ямайка",
		japan: "Япония",
		jersey: "Джерси",
		jordan: "Иордания",
		kazakhstan: "Казахстан",
		kenya: "Кения",
		kiribati: "Кирибати",
		kosovo: "Косово",
		kuwait: "Кувейт",
		kyrgyzstan: "Киргизия",
		laos: "Лаос",
		latvia: "Латвия",
		lebanon: "Ливан",
		lesotho: "Лесото",
		liberia: "Либерия",
		libya: "Ливия",
		liechtenstein: "Лихтенштейн",
		lithuania: "Литва",
		luxembourg: "Люксембург",
		macao: "Макао",
		madagascar: "Мадагаскар",
		malawi: "Малави",
		malaysia: "Малайзия",
		maldives: "Мальдивы",
		mali: "Мали",
		malta: "Мальта",
		marshall_islands: "Маршалловы острова",
		martinique: "Мартиника",
		mauritania: "Мавритания",
		mauritius: "Маврикий",
		mayotte: "Майотта",
		mexico: "Мексика",
		micronesia: "Микронезия",
		moldova: "Молдова",
		monaco: "Монако",
		mongolia: "Монголия",
		montenegro: "Черногория",
		montserrat: "Монтсеррат",
		morocco: "Марокко",
		mozambique: "Мозамбик",
		myanmar: "Мьянма",
		namibia: "Намибия",
		nauru: "Науру",
		nepal: "Непал",
		netherlands: "Нидерланды",
		new_caledonia: "Новая Каледония",
		new_zealand: "Новая Зеландия",
		nicaragua: "Никарагуа",
		niger: "Нигер",
		nigeria: "Нигерия",
		niue: "Ниуэ",
		norfolk_island: "Остров Норфолк",
		north_korea: "Северная Корея",
		north_macedonia: "Северная Македония",
		northern_mariana_islands: "Северные Марианские острова",
		norway: "Норвегия",
		oman: "Оман",
		pakistan: "Пакистан",
		palau: "Палау",
		palestine: "Палестина",
		panama: "Панама",
		papua_new_guinea: "Папуа-Новая Гвинея",
		paraguay: "Парагвай",
		peru: "Перу",
		philippines: "Филиппины",
		pitcairn_islands: "Острова Питкэрн",
		poland: "Польша",
		portugal: "Португалия",
		puerto_rico: "Пуэрто-Рико",
		qatar: "Катар",
		reunion: "Реюньон",
		romania: "Румыния",
		russia: "Россия",
		rwanda: "Руанда",
		saint_barthelemy: "Сен-Бартелеми",
		saint_helena: "Остров Святой Елены",
		saint_lucia: "Сент-Люсия",
		saint_martin: "Сен-Мартен",
		saint_pierre_and_miquelon: "Сен-Пьер и Микелон",
		samoa: "Самоа",
		san_marino: "Сан-Марино",
		sao_tome_and_principe: "Сан-Томе и Принсипи",
		saudi_arabia: "Саудовская Аравия",
		senegal: "Сенегал",
		serbia: "Сербия",
		seychelles: "Сейшельские острова",
		sierra_leone: "Сьерра-Леоне",
		singapore: "Сингапур",
		sint_maarten: "Синт-Мартен",
		slovakia: "Словакия",
		slovenia: "Словения",
		solomon_islands: "Соломоновы острова",
		somalia: "Сомали",
		south_africa: "Южно-Африканская Республика",
		south_georgia_and_south_sandwich_islands: "Южная Георгия и Южные Сандвичевы острова",
		south_korea: "Южная Корея",
		south_sudan: "Южный Судан",
		spain: "Испания",
		sri_lanka: "Шри-Ланка",
		st_kitts_and_nevis: "Сент-Китс и Невис",
		st_vincent_and_grenadines: "Сент-Винсент и Гренадины",
		sudan: "Судан",
		suriname: "Суринам",
		svalbard_and_jan_mayen: "Шпицберген и Ян-Майен",
		sweden: "Швеция",
		switzerland: "Швейцария",
		syria: "Сирия",
		taiwan: "Тайвань",
		tajikistan: "Таджикистан",
		tanzania: "Танзания",
		thailand: "Таиланд",
		timor_leste: "Тимор-Лешти",
		togo: "Того",
		tokelau: "Токелау",
		tonga: "Тонга",
		trinidad_and_tobago: "Тринидад и Тобаго",
		tunisia: "Тунис",
		turkiye: "Турция",
		turkmenistan: "Туркменистан",
		turks_and_caicos_islands: "Теркс и Кайкос",
		tuvalu: "Тувалу",
		uganda: "Уганда",
		ukraine: "Украина",
		united_arab_emirates: "Объединенные Арабские Эмираты",
		united_kingdom: "Великобритания",
		united_states: "Соединенные Штаты",
		uruguay: "Уругвай",
		us_minor_outlying_islands: "Малые отдаленные острова США",
		us_virgin_islands: "Американские Виргинские острова",
		uzbekistan: "Узбекистан",
		vanuatu: "Вануату",
		vatican_city: "Ватикан",
		venezuela: "Венесуэла",
		vietnam: "Вьетнам",
		wallis_and_futuna: "Уоллис и Футуна",
		western_sahara: "Западная Сахара",
		yemen: "Йемен",
		zambia: "Замбия",
		zimbabwe: "Зимбабве",
	},
	$currency: {
		euro: "Евро",
		turkish_lira: "Турецкая лира",
		us_dollar: "Доллар США",
	},
	$employeeType: {
		admin: "Администратор",
		default: "Обычный",
		exporter: "Экспортер",
		importer: "Импортер",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:ss",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Создайте свою компанию здесь.",
			create_company_title: "Хотите добавить свою компанию?",
			description: "Вы успешно вошли в систему. Теперь вам нужно присоединиться к компании или создать новую.",
			join_company_subtitle: "Введите свой код и присоединяйтесь к компании здесь.",
			join_company_title: "Хотите присоединиться к существующей компании?",
			title: "Добро пожаловать {name}",
		},
		$unauthorized: {
			description: "Контролируйте свои процессы экспорта и импорта с помощью Antrego",
			forget_password_subtitle: "Введите свою почту для сброса пароля.",
			sign_in_subtitle: "Войдите здесь",
			sign_in_title: "Уже есть аккаунт?",
			sign_up_subtitle: "Зарегистрируйтесь здесь",
			sign_up_title: "Хотите создать аккаунт?",
			title: "Добро пожаловать в Antrego",
		},
	},
	$invitationType: {
		company_partner: "Приглашение к коммерческому сотрудничеству",
		customs_partner: "Приглашение к сотрудничеству с таможней",
		employee: "Приглашение сотрудника",
	},
	$landing: {
		$benefits: {
			benefits_title: "Преимущества",
			business_process: "Ускоряет ваши бизнес-процессы.",
			control_title: "Контролируйте с помощью Antrego.",
			highlight_word: "контроль",
			risk_of_error: "Снижает риск ошибок.",
			text: "Antrego — это программа для отслеживания отправок, которая помогает транспортным компаниям, их клиентам и таможенным брокерам отслеживать процессы отправки через общую платформу.",
			time_and_cost_savings: "Экономит время и деньги.",
		},
		$content: {
			$text: {
				0: "Отслеживайте все свои отправки в одном месте и контролируйте свои финансы с помощью динамической отчетности.",
				1: "Делитесь своими обновлениями в реальном времени с клиентами, чтобы улучшить качество обслуживания.",
				2: "Просматривайте и делитесь всеми документами, связанными с вашей отправкой, в одной системе.",
				3: "Легко контролируйте процесс, получая доступ к Antrego с любых устройств и мест.",
				4: "Храните все свои данные с использованием технологии шифрования SHA-256. Спите спокойно за безопасность.",
				5: "Участвуйте в совместном отслеживании со всеми участниками бизнес-процесса. Управляйте процессом более эффективно.",
			},
			$title: {
				0: "Управление отправками",
				1: "Отношения с клиентами",
				2: "Управление документами",
				3: "Легкий доступ",
				4: "Безопасность данных",
				5: "Совместное отслеживание",
			},
		},
		$home: {
			text: "Выберите нас для безупречного цифрового отслеживания и совместного контроля.",
			title: "Управляйте всеми процессами доставки в цифровом виде",
		},
		$lifecycle: {
			headline: "Процесс",
			info_button: "Получить информацию",
			lifecycle_texts: {
				0: "Загрузите дополнительные файлы для создания проформа-счета и мгновенно поделитесь им с клиентом.",
				1: "Введите детали отправки и добавьте документы. Уведомьте своего клиента и совместно управляйте всеми процессами отправки через один канал.",
				2: "Отслеживайте свое финансовое положение, связанное с импортом и экспортом, в режиме реального времени.",
			},
			lifecycle_titles: {
				0: "Войдите и создайте проформа-счет",
				1: "Создайте отправку",
				2: "Отслеживайте финансовую деятельность",
			},
			productivity_title: "Повышайте производительность и снижайте сложность и ошибки за 3 шага.",
			tracking_guide_title: "Руководство по легкому отслеживанию",
		},
		$logistics: {
			logistics_title: "Поддерживаемые виды логистики",
			solutions_title: "Правильное решение для каждого груза",
			text: "С Antrego, вашим цифровым партнером во всех видах транспорта, мы всегда рядом с вами во всех ваших глобальных движениях.",
		},
		$process: {
			digital_future: "Откажитесь от традиционных методов и вступайте в цифровое будущее",
			digital_shipping: "Цифровое отслеживание отправок с помощью Antrego",
			digital_shipping_texts: {
				0: "Проформа-счета для полученных заказов создаются и отслеживаются легко через один канал.",
				1: "Проформа-счета отправляются, уведомления мгновенно поступают к клиенту, и они утверждаются через Antrego.",
				2: "Процессы обработки документов управляются через Antrego. Они не теряются, задержек не происходит, а вероятность ошибок минимизирована.",
				3: "Поставщики, клиенты и таможенные брокеры могут мгновенно видеть детали отправки и совместно их отслеживать.",
				4: "Финансовые отчеты автоматически создаются ежемесячно и ежегодно.",
			},
			question_title: "Как Antrego изменит вашу жизнь?",
			tracking_method: "Цифровизируйте свои методы отслеживания и ускорьте свои процессы.",
			traditional_shipping: "Традиционное отслеживание отправок",
			traditional_shipping_texts: {
				0: "Заказы собираются по электронной почте, сообщениям или телефону через разные каналы, что вызывает трудности с внутренним отслеживанием.",
				1: "Проформа-счет создается с помощью таких инструментов, как Excel, а процесс утверждения осуществляется вручную по электронной почте, WhatsApp или телефону.",
				2: "Ручные процессы обработки документов приводят к ошибкам и задержкам, вызывая потери времени и финансов.",
				3: "Нет общей платформы для поставщиков, клиентов и таможенных брокеров для отслеживания деталей отправки.",
				4: "Финансовые данные проверяются индивидуально после каждой отправки, что затрудняет отчетность.",
			},
		},
		$question: {
			$text: {
				0: "<p>Это программа для отслеживания отправок, которая помогает компаниям и их клиентам совместно отслеживать, предотвращая потери из-за задержек на этапе отслеживания.</p>",
				1: "<p>- Делитесь всеми своими обновлениями в реальном времени с клиентом. Улучшите качество обслуживания.<br>\n      - Просматривайте и делитесь всеми документами, связанными с вашей отправкой, в одной системе.<br>\n      - Отслеживайте все свои отправки из одного места. Контролируйте свои финансы с помощью динамической отчетности.</p>",
				2: "<p>- Морские<br>\n      - Воздушные<br>\n      - Наземные<br>\n      - Железнодорожные</p>",
				3: "<p>С его услугами он всегда под рукой, чтобы упростить процесс доставки.<br>\n      <strong>1. Легкий доступ</strong><br>\n      Доступен с любых устройств и мест через веб-браузер.<br>\n      <strong>2. Безопасность данных</strong><br>\n      Все данные хранятся с использованием технологии шифрования SHA-256.<br>\n      <strong>3. Совместное отслеживание</strong><br>\n      Он предоставляет возможности отслеживания для всех участников процесса.</strong>",
				4: "<p>Да, это платное приложение. Цифровизируя все процессы доставки, мы хотим упростить вашу работу и продолжить полезные разработки для вас. Чтобы обеспечить устойчивость этих улучшений и пространства на сервере, которое вы используете, мы взимаем ежемесячную плату.</p>",
				5: "<p>Нет. Вашим клиентам не нужно платить за отслеживание ваших отправок.</p>",
			},
			$title: {
				0: "Что такое Antrego?",
				1: "Как Antrego поможет моей компании в процессе доставки?",
				2: "Какие виды логистики поддерживает Antrego?",
				3: "Почему стоит использовать Antrego?",
				4: "Antrego — это платное приложение?",
				5: "Мои клиенты должны платить за Antrego?",
			},
			save_percentage: "Экономьте 16% ежемесячно",
			text: "Легко получайте всю информацию с помощью Antrego и обращайтесь к нам по любым другим вопросам.",
			title: "Быстро узнайте, что вас интересует",
		},
		cancel_anytime: "Отмените в любое время",
		customize_package:
			"Вы можете приобрести дополнительное хранилище и пользователей и настроить свой пакет в соответствии с вашими потребностями",
		free_features_1: "10 отправок",
		free_features_2: "100 МБ памяти",
		free_features_3: "Неограниченные коммерческие партнеры",
		free_features_4: "1 пользователь",
		free_plan: "Бесплатно",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Ежемесячно",
		premium_annual_features_1: "20 пользователей",
		premium_annual_features_2: "100 партнеров",
		premium_annual_features_3: "1000 отправок",
		premium_annual_features_4: "1000 счетов-фактур",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "Будет возобновляться ежегодно за $999",
		premium_annual_title: "Ежегодно",
		premium_monthly_features_1: "Неограниченные отправки",
		premium_monthly_features_2: "Неограниченные коммерческие партнеры",
		premium_monthly_features_3: "2 ГБ памяти",
		premium_monthly_features_4: "3 пользователя",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Будет возобновляться ежемесячно за $99",
		premium_monthly_title: "Ежемесячно",
		premium_plan: "Премиум-план",
		premium_plus_annual_features_1: "Неограниченные отправки",
		premium_plus_annual_features_2: "Неограниченные коммерческие партнеры",
		premium_plus_annual_features_3: "5 ГБ памяти",
		premium_plus_annual_features_4: "5 пользователей",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "Будет возобновляться ежегодно за $999",
		premium_plus_annual_title: "Ежегодно",
		premium_plus_monthly_features_1: "Неограниченные отправки",
		premium_plus_monthly_features_2: "Неограниченные коммерческие партнеры",
		premium_plus_monthly_features_3: "5 ГБ памяти",
		premium_plus_monthly_features_4: "5 пользователей",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Будет возобновляться ежемесячно за $249",
		premium_plus_monthly_title: "Ежемесячно",
		premium_plus_plan: "Премиум Плюс",
		pricing_text: "Мы можем удовлетворить ваши потребности наилучшим образом без ущерба для вашего бюджета.",
		pricing_title: "Antrego гарантирует предложить вам лучший пакет и цену.",
		save_percentage: "Экономьте 16% ежемесячно",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Арабский",
		de: "Немецкий",
		en: "Английский",
		es: "Испанский",
		fr: "Французский",
		hi: "Хинди",
		ind: "Индонезийский",
		it: "Итальянский",
		ja: "Японский",
		ko: "Корейский",
		pt: "Португальский",
		ru: "Русский",
		tr: "Турецкий",
		vi: "Вьетнамский",
		zh: "Китайский",
	},
	$message: {
		cannot_delete_verified_partner: "Вы не можете удалить проверенного партнера.",
		confirm: "Вы уверены, что хотите {function}?",
		created: "{item} создан.",
		deleted: "{item} удален.",
		error_on_view_file: "Произошла ошибка при просмотре файла.",
		file_oversize: "Размер файла должен быть не более {size}.",
		invalid: "{item} недействителен",
		migrated: "{item} перемещен. ({value})",
		process_has_been_started: "Ваш {item} был начат.",
		sent: "{item} отправлен.",
		sent_email: "Электронное письмо отправлено. ({value})",
		sign_in_failed: "Электронная почта или пароль неверны",
		unsupported_file_type: "Неподдерживаемый тип файла",
		updated: "{item} обновлен.",
	},
	$pageTitles: {
		accept_invitation: "Принять приглашение",
		company_profile: "Профиль компании",
		home: "Главная",
		landing_page: "Бесшовное цифровое отслеживание, общий контроль",
		partners: "Партнеры",
		product_groups: "Группы продуктов",
		products: "Продукты",
		proforma_invoices: "Проформа-счета",
		shipment_details: "Детали отправки",
		shipments: "Отправки",
		sign_in: "Войти",
		sign_up: "Зарегистрироваться",
		subscription: "Подписка",
		user_profile: "Профиль пользователя",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Незарегистрированные партнеры, вы создали их профиль на нашей платформе, поэтому вы можете просматривать и редактировать их информацию.",
		createdHereTitle: "Создано здесь:",
		description: "Вы можете отслеживать статус своих отношений с партнерами. Вот различия:",
		verifiedPartnersDescription:
			"Эти партнеры приняли ваше приглашение и зарегистрировались на платформе. Их информация доступна только для просмотра и не может быть отредактирована.",
		verifiedPartnersTitle: "Проверенные партнеры:",
	},
	$productGroup: {
		add_child: "Добавить подгруппу",
		add_new_product_group: "Добавить новую группу продуктов",
		add_to_root: "Добавить в корень",
		has_children_fail_message: "Группа продуктов с дочерними элементами не может быть удалена",
		initial_message: "Используйте эту кнопку {0}, чтобы создать свою первую группу продуктов.",
		name: "Название",
		number_of_product: "Количество продуктов",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} утверждено.",
			create: "Создано {name}.",
			create_attachment: "{name} создал вложение.",
			delete_attachment: "{name} удалил вложение.",
			demand_revise: "{name} запросил пересмотр.",
			mark_as_processed: "{name} отмечено как обработано.",
			reject: "{name} отклонено.",
			revoke: "{name} отозвано.",
			send: "{name} отправлено получателю для оценки.",
			update: "Обновлено {name}.",
			update_attachment: "{name} обновил вложение.",
		},
		$actionDescription2: {
			approve: '{name} отмечено как "Утверждено".',
			demand_revise: '{name} отмечено как "Запрос на пересмотр".',
			mark_as_processed: '{name} отмечено как "Обработано".',
			reject: "{name} отклонено.",
			revoke: '{name} отмечено как "Отозвано".',
			send: '{name} отмечено как "Отправлено".',
		},
		$actions: {
			approve: "Утвердить",
			demand_revise: "Запросить пересмотр",
			mark_as_approved: "Утверждено",
			mark_as_processed: "Обработано",
			mark_as_rejected: "Отклонено",
			mark_as_revise_demanded: "Требуется пересмотр",
			mark_as_revoked: "Отозвано",
			mark_as_sent: "Отправлено",
			reject: "Отклонить",
			revoke: "Отозвать",
			send: "Отправить",
		},
		$status: {
			approved: "Утверждено",
			pending_approval: "Ожидает утверждения",
			pending_revise: "Ожидает пересмотра",
			processed: "Обработано",
			rejected: "Отклонено",
			revoked: "Отозвано",
			template: "Шаблон",
		},
		approve_confirm_message: "Вы уверены, что хотите утвердить проформа-счет?",
		approve_success_message: "Проформа-счет был утвержден.",
		demand_revise_description_message: "Пожалуйста, укажите детали ваших ожиданий по пересмотру:",
		demand_revise_success_message: "Проформа-счет был отправлен обратно отправителю для пересмотра.",
		mark_as_processed_confirm_message: "Вы подтверждаете, что хотите отметить проформа-счет как обработанный?",
		mark_as_processed_success_message: "Проформа-счет был отмечен как обработанный.",
		no_attachment_text: "Вложений пока нет.",
		reject_confirm_message: "Вы уверены, что хотите отклонить проформа-счет?",
		reject_success_message: "Проформа-счет был отклонен.",
		revoke_confirm_message: "Вы уверены, что хотите отозвать проформа-счет?",
		revoke_success_message: "Проформа-счет был отозван.",
		send_confirm_message: "Вы подтверждаете отправку проформа-счета получателю?",
		send_success_message: "Проформа-счет был отправлен.",
	},
	$serviceMessage: {
		already_subs_module: "Подписка на этот модуль уже существует.",
		bad_request: "Неверный запрос",
		code_already_used: "Код уже был использован.",
		container_status_not_available: "Статус контейнера недоступен.",
		existing_email_warning: "Электронная почта уже существует.",
		existing_employee_error: "Этот пользователь уже добавлен в вашу компанию.",
		existing_employee_for_email_warning: "Код приглашения уже отправлен на этот электронный адрес",
		existing_invitation_warning: "Код приглашения уже отправлен на этот электронный адрес.",
		existing_token_already_have_time: "Существующий токен уже имеет достаточно времени для использования.",
		existing_user: "Пользователь уже существует.",
		five_min_reset_password_mail:
			"Вы должны подождать как минимум 5 минут после того, как запросите письмо для сброса пароля один раз.",
		forbidden: "Запрещено",
		have_no_corporation: "У вас нет корпорации",
		have_no_custom_agency: "У вас нет таможенного агентства",
		have_no_employee: "У вас нет сотрудников",
		instance_can_no_delete_existing_booking_code:
			"Этот экземпляр больше нельзя удалить из-за существующего кода бронирования.",
		instance_cannot_delete: "Этот экземпляр больше нельзя удалить.",
		instance_not_found: "Экземпляр не найден.",
		internal_server_error: "Внутренняя ошибка сервера",
		invalid_current_password: "Недействительный текущий пароль.",
		invalid_identifier_or_password: "Неверный идентификатор или пароль",
		invalid_invitation_code: "Код приглашения неверен.",
		invalid_moving_request: "Недействительный запрос на перемещение",
		invalid_operation: "Недопустимая операция",
		invalid_refresh_token: "Недействительный токен обновления.",
		invalid_unit: "Недопустимая единица измерения",
		invitation_accepting_is_due_to_the_admin: "Принятие приглашения зависит от администратора.",
		invitation_already_discarded: "Приглашение уже отклонено.",
		invitation_already_used: "Приглашение уже использовано.",
		invitation_has_been_used: "Приглашение было использовано.",
		invitation_not_exist_email: "Приглашение не существует на данном адресе электронной почты.",
		invitation_not_for_company: "Это приглашение не для компании.",
		invitation_not_for_custom_agency: "Это приглашение не для таможенного агентства.",
		invitation_not_found: "Приглашение не найдено.",
		invitation_not_valid: "Приглашение больше не действительно.",
		link_has_been_expired: "Ссылка истекла.",
		link_not_be_used_anymore: "Эта ссылка больше не может быть использована",
		missing_required_fields: "Отсутствуют обязательные поля.",
		no_employee_found: "Сотрудники не найдены",
		no_proper_partner_found: "Подходящий партнер не найден.",
		not_acceptable: "Неприемлемо",
		not_allowed_action: "Вам не разрешено выполнять это действие.",
		not_allowed_use_api: "Вам не разрешено использовать этот API.",
		not_found: "Не найдено",
		not_have_purchase_subscription:
			"Ваш план подписки достиг максимального лимита для добавлений. Чтобы добавить больше, рассмотрите возможность обновления вашего плана подписки.",
		not_permitted_use_api: "Вам не разрешено использовать этот API.",
		own_invitation_cannot_accept: "Вы не можете принять собственное приглашение.",
		parent_group_not_found: "Группа-родитель не найдена",
		partnerId_required: "Необходим идентификатор партнера.",
		partnership_already_exists: "Партнер уже был добавлен.",
		password_cant_involve_space_char: "Пароль не может содержать пробелы.",
		password_min_chars_least: "Пароль должен содержать не менее 6 символов.",
		payment_required: "Пожалуйста, оформите подписку для этого процесса.",
		paytr_integration_error: "Ошибка интеграции PayTR",
		ports_are_required: "Порты обязательны.",
		product_group_tree_not_found: "Дерево групп продуктов не найдено",
		proforma_invoice_action_not_available: "Статус проформа-счета недоступен для этого действия.",
		proforma_invoice_template_update: "Проформа-счет должен быть шаблоном для обновления.",
		shipment_email_not_exist: "Пожалуйста, введите действительный электронный адрес.",
		shipment_method_is_required: "Требуется метод доставки.",
		shipping_term_is_required: "Требуется условие доставки.",
		someone_else_uses_this_email: "Кто-то другой использует этот электронный адрес.",
		someone_else_uses_this_username: "Кто-то другой использует это имя пользователя.",
		something_went_wrong: "Что-то пошло не так",
		type_can_be_abstract_company: 'Свойство "тип" может быть только "abstract_company"',
		unauthorized: "Неавторизован",
		undefined_result: "Неопределенный результат",
		unexpected_error_occurred: "Произошла неожиданная ошибка",
		unit_already_added: "Единица измерения уже добавлена.",
		user_already_employee: "Пользователь уже является сотрудником.",
		user_already_exists: "Пользователь уже существует в компании.",
		user_does_not_exist: "Пользователь не существует.",
		user_not_found: "Пользователь не найден.",
		username_min_chars_error: "Имя пользователя должно содержать не менее 6 символов.",
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} добавил транспортное средство.",
				delete_container: "{name} удалил транспортное средство.",
				update_container: "{name} обновил транспортное средство.",
			},
			create: "Создано {name}.",
			create_attachment: "{name} добавил вложение.",
			create_container: "{name} добавил контейнер.",
			delete_attachment: "{name} удалил вложение.",
			delete_container: "{name} удалил контейнер.",
			update: "Обновлено {name}.",
			update_attachment: "{name} обновил вложение.",
			update_container: "{name} обновил контейнер.",
		},
		$containerStatus: {
			arrived: "Прибыл",
			off_load: "На разгрузке",
			on_load: "На загрузке",
			on_way: "В пути",
			reserved: "Зарезервировано",
		},
		$method: {
			aerial: "Воздушный",
			land: "Наземный",
			railway: "Железнодорожный",
			sea: "Морской",
		},
		$vehicle: {
			license_number: "Номерной знак",
		},
		archiving_success_message: "Отправка была архивирована.",
		leave_message: "Оставьте свое сообщение или комментарий",
		no_booking_info: "Информация о бронировании еще не установлена. Используйте кнопку {0}, чтобы добавить.",
		no_transportation_info:
			"Информация о транспортировке еще не установлена. Используйте кнопку {0}, чтобы установить.",
		un_archiving_success_message: "Отправка была разархивирована.",
	},
	$shortening: {
		number: "№",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Не удалось продлить вашу подписку. Пожалуйста, проверьте ваш способ оплаты.",
			fail: "Произошла неожиданная ошибка. Пожалуйста, попробуйте позже. Если проблема не исчезнет, ​​сообщите, отправив письмо на info@antrego.net.",
			ok: "Процесс покупки был начат. Результат процесса будет уведомлен по электронной почте. Ваша подписка начнется, когда оплата будет успешно завершена.",
			payment_process_is_ongoing:
				"Ваш процесс оплаты продолжается. Результат процесса будет уведомлен по электронной почте. Ваша подписка начнется, когда оплата будет успешно завершена.",
			success: "Ваша подписка была успешно создана. Вы можете начать легко использовать Antrego.",
			unsubscribed: "Отменена подписка",
			you_dont_have_any_subscription:
				"У вас нет подписки. Пожалуйста, выберите пакет на странице подписки и начните использовать его легко.",
			you_dont_have_any_subscription_text: "Выберите план, чтобы продолжить.",
			you_dont_have_any_subscription_title: "У вас нет активной подписки.",
		},
	},
	$unit: {
		acres: "Акры",
		boxes: "Коробки",
		cubic_meter: "Кубический метр",
		dozen: "Дюжины",
		feet: "Футы",
		fluid_ounces: "Жидкие унции",
		gallons: "Галлоны",
		gram: "Грамм",
		hectare: "Гектар",
		inches: "Дюймы",
		kilogram: "Килограмм",
		kilometer: "Километр",
		liter: "Литр",
		meter: "Метр",
		miles: "Мили",
		milligram: "Миллиграмм",
		milliliter: "Миллилитр",
		millimeter: "Миллиметр",
		ounces: "Унции",
		pallets: "Паллеты",
		pieces: "Штуки",
		pounds: "Фунты",
		quarts: "Кварты",
		square_feet: "Квадратные футы",
		square_inches: "Квадратные дюймы",
		square_kilometer: "Квадратный километр",
		square_meter: "Квадратный метр",
		square_miles: "Квадратные мили",
		square_yards: "Квадратные ярды",
		ton: "Тонны",
		yards: "Ярды",
	},
	$validation: {
		email: "Пожалуйста, введите действующий электронный адрес",
		invalid: "{item} недействителен",
		max_length: "{item} должен быть меньше {length} символов",
		min_length: "{item} должен быть больше {length} символов",
		must_length: "{item} должен быть {length} символов",
		only_number: "{item} должен быть числом",
		password_repeat_warning: "Повтор пароля не совпадает с новым паролем.",
		required: "{item} обязателен",
		required_for_default_language: "{item} обязателен для языка по умолчанию компании.",
		you_must_approve_distance_sales_agreement: "Вы должны одобрить договор дистанционной продажи",
	},
	about: "О нас",
	accept_invitation: "Принять приглашение",
	accept_invitation_success_message: "Вы успешно использовали приглашение.",
	accessibility: "доступность",
	actions: "Действия",
	active: "Активен",
	activities: "Деятельность",
	add: "Добавить",
	add_item: "Добавить {item}",
	add_new_employee: "Добавить нового сотрудника",
	add_new_product: "Добавить новый продукт",
	address: "Адрес",
	again: "Снова",
	alert: "Предупреждение",
	already_have_employee_message: "Вы уже являетесь сотрудником компании.",
	amount: "Сумма",
	approve: "Утвердить",
	archive: "Архивировать",
	attachment: "Вложение",
	attachments: "Вложения",
	bank: "Банк",
	benefits: "Преимущества",
	booking: "Бронирование",
	booking_code: "Код бронирования",
	booking_details: "Детали бронирования",
	business_partner: "Коммерческий партнер",
	business_partners: "Коммерческие партнеры",
	cancel: "Отмена",
	change_password: "Сменить пароль",
	checking_invitation_code_message: "Проверка кода приглашения",
	choose_image: "Выбрать изображение",
	choose_your_plan: "Выберите свой план",
	clear: "Очистить",
	click_to_play_the_video: "Нажмите, чтобы воспроизвести видео",
	close: "Закрыть",
	code: "Код",
	commercial: "Коммерческий",
	commercial_partner: "Коммерческий партнер",
	commercial_partners: "Коммерческие партнеры",
	companies: "Компании",
	company: "Компания",
	company_name: "Название компании",
	company_profile: "Профиль компании",
	configuration: "Конфигурация",
	confirm: "Подтвердить",
	confirm_distance_selling_agreement: "Подтвердите договор дистанционной продажи.",
	consult_us: "Консультируйтесь с нами",
	contact: "Контакт",
	contact_info: "Контактная информация",
	contact_text: "Не беспокойтесь, мы свяжемся с вами как можно скорее.",
	contact_title: "Свяжитесь с нами, упростите свои процессы",
	contact_to_us: "Свяжитесь с нами",
	container: "Контейнер",
	container_no: "Номер контейнера",
	containers: "Контейнеры",
	content: "Содержание",
	contract: "Контракт",
	copy_link: "Копировать ссылку",
	countries: "Страны",
	country: "Страна",
	create: "Создать",
	create_a_new_account: "Создать новую учетную запись",
	create_company: "Создать компанию",
	created_at: "Создано в",
	created_here: "Создано здесь",
	credit_card_info: "Информация о кредитной карте",
	credit_card_no: "Номер кредитной карты",
	currencies: "Валюты",
	currency: "Валюта",
	current_password: "Текущий пароль",
	current_plan: "Текущий план",
	customs: "Таможня",
	customs_agencies: "Таможенные агентства",
	customs_agency: "Таможенное агентство",
	cvv: "CVV",
	daily: "Ежедневно",
	dashboard: "Панель управления",
	date: "Дата",
	day: "День",
	default: "По умолчанию",
	default_language: "Язык по умолчанию",
	delete: "Удалить",
	delete_account: "Удалить аккаунт",
	delete_confirm_message: "Вы подтверждаете удаление?",
	description: "Описание",
	detail: "Детали",
	discard: "Отменить",
	distance_sales_agreement: "Договор дистанционной продажи",
	document_number: "Номер документа",
	download: "Загрузить",
	edit: "Редактировать",
	edit_item: "Редактировать {item}",
	edit_product: "Редактировать продукт",
	edit_profile: "Редактировать профиль",
	edit_your_profile_picture: "Изменить фото профиля",
	email: "Электронная почта",
	employee: "Сотрудник",
	employee_type: "Тип сотрудника",
	employees: "Сотрудники",
	estimated_arrival_day: "Ожидаемый день прибытия",
	expense: "Расход",
	expenses: "Расходы",
	expires_at: "Истекает",
	exported_containers: "Экспортированные контейнеры",
	fail: "Неудача",
	failed: "Не удалось",
	faq: "Часто задаваемые вопросы",
	feedback: "Обратная связь",
	file: "Файл",
	file_preview: "Предварительный просмотр файла",
	file_view: "Просмотр файла",
	filter: "Фильтр",
	first_name: "Имя",
	first_name_last_name: "Имя Фамилия",
	forget_password: "Забыли пароль",
	forget_password_message: "Забыли свой пароль?",
	forget_password_subtitle: "Пожалуйста, введите свой адрес электронной почты, чтобы сбросить пароль.",
	free: "Бесплатно",
	from: "От",
	get_started_now: "Начните сейчас",
	give_us_feedback: "Оставьте нам отзыв",
	go_to_panel: "Перейти к панели управления",
	hide: "Скрыть",
	home_page: "Главная страница",
	i_have_invitation_code: "У меня есть код приглашения",
	imported_containers: "Импортированные контейнеры",
	in_progress: "В процессе",
	incoming: "Входящие",
	incoming_shipments: "Входящие отправки",
	initial: "Первоначальный",
	invitation: "Приглашение",
	invitation_code: "Код приглашения",
	invitation_mail_sent: "Письмо с приглашением отправлено",
	invitations: "Приглашения",
	join: "Присоединиться",
	join_company: "Присоединиться к компании",
	language: "Язык",
	language_options: "Языковые настройки",
	last_name: "Фамилия",
	last_one_year: "Последний 1 год",
	latitude: "Широта",
	left_a_comment: "Оставил комментарий",
	link_copied: "Ссылка скопирована",
	link_copy_failed: "Не удалось скопировать ссылку",
	loading_country: "Страна загрузки",
	loading_point: "Точка загрузки",
	loading_port: "Порт загрузки",
	log_in: "Войти",
	logo: "Логотип",
	longitude: "Долгота",
	mail_sent: "Электронное письмо отправлено",
	manage_profile: "Управление профилем",
	message: "Сообщение",
	message_company_create: "Компания создана.",
	message_company_join: "Пожалуйста, введите код приглашения.",
	message_company_joined: "Вы присоединились к компании",
	message_employee_delete: "Сотрудник удален.",
	message_employee_save: "Сотрудник сохранен.",
	message_join_company: "Введите код, чтобы присоединиться к существующей компании",
	method: "Метод",
	migrate: "Мигрировать",
	migrate_partner: "Мигрировать компанию",
	mobile_number: "Мобильный номер",
	month: "Месяц",
	month_lowercase: "месяц",
	monthly: "Ежемесячно",
	my_profile: "Мой профиль",
	my_role: "Моя роль",
	name: "Имя",
	new: "Новый",
	new_company_package: "Бесплатно",
	new_password: "Новый пароль",
	new_shipment: "Новая поставка",
	next: "Следующий",
	no: "Нет",
	no_content: "Нет содержимого",
	no_entity: "Нет сущностей",
	no_entity_message: "Сущности не найдены.",
	no_text: "Нет текста",
	not_found_page_message: "Извините, не могу найти эту страницу :(",
	note: "Заметка",
	notes: "Заметки",
	ok: "OK",
	operation_fail: "Неудача операции",
	outgoing: "Исходящие",
	outgoing_shipments: "Исходящие отправки",
	packages: "Пакеты",
	parent_group: "Родительская группа",
	partner: "Партнер",
	partners: "Партнеры",
	partnership_status: "Статус партнерства",
	passive: "Пассивный",
	password: "Пароль",
	pay: "Оплатить",
	payback: "Возврат",
	payback_confirm_message: "Вы подтверждаете возврат?",
	payback_warning_message: "Если вы продолжите, ваша подписка будет прекращена.",
	payment: "Оплата",
	payment_amount: "Сумма оплаты",
	payment_awaited: "Ожидается оплата",
	payment_history: "История платежей",
	payment_success: "Оплата успешно завершена",
	payment_summary: "Сводка платежей",
	payment_will_be_made_3d_secure: "*Ваш платеж будет произведен с использованием 3D Secure.",
	pending_invitations: "Ожидающие приглашения",
	per_month: "В месяц",
	phone_number: "Номер телефона",
	premium: "Премиум",
	premium_plus: "Премиум Плюс",
	previous: "Предыдущий",
	pricing: "Цены",
	process: "Процесс",
	product: "Продукт",
	product_group: "Группа продуктов",
	product_group_name: "Название группы продуктов",
	product_groups: "Группы продуктов",
	product_name: "Название продукта",
	products: "Продукты",
	profile: "Профиль",
	proforma_invoice: "Проформа-счет",
	proforma_invoices: "Проформа-счета",
	proforma_number: "Номер проформа-счета",
	purchase: "Покупка",
	quantity: "Количество",
	receiver: "Получатель",
	receiver_company: "Компания получателя",
	receiver_customs_agency: "Таможенное агентство получателя",
	redirection_to_home_link: "Главная",
	redirection_to_home_message: "Вы также можете перейти на ",
	redirection_to_sign_in_message: "Уже есть аккаунт?",
	redirection_to_sign_up_message: "Еще нет аккаунта?",
	reload: "Перезагрузить",
	renewal: "Продление",
	repaid: "Возвращено",
	reset_password: "Сброс пароля",
	rows_per_page: "Строк на страницу",
	sales: "Продажи",
	save: "Сохранить",
	save_changes: "Сохранить изменения",
	saved_card_info: "Сохраненная информация о карте",
	search: "Поиск",
	select: "Выбрать",
	select_file: "Выбрать файл",
	select_file_place_holder: "Пожалуйста, выберите изображение",
	select_language: "Выбрать язык",
	selected_partner: "Выбранный партнер",
	send: "Отправить",
	send_again: "Отправить снова",
	send_email: "Отправить электронное письмо",
	send_feedback: "Отправить отзыв",
	send_invitation: "Отправить приглашение",
	sender: "Отправитель",
	sender_company: "Компания отправителя",
	sender_customs_agency: "Таможенное агентство отправителя",
	settings: "Настройки",
	shipment: "Отправка",
	shipment_method: "Метод отправки",
	shipment_type: "Тип отправки",
	shipments: "Отправки",
	shipments_filter: "Фильтр отправок",
	shipping_term: "Условия доставки",
	shipping_terms: "Условия доставки",
	show: "Показать",
	sign_in: "Войти",
	sign_out: "Выйти",
	sign_out_confirm_message: "Вы подтверждаете выход?",
	sign_out_success_message: "До свидания.",
	sign_up: "Зарегистрироваться",
	social_media: "Социальные сети",
	social_media_account: "Аккаунт в социальных сетях",
	start_time: "Время начала",
	status: "Статус",
	subscribe: "Подписаться",
	subscription: "Подписка",
	subscription_info_message: "Вы можете пользоваться всеми услугами неограниченно в течение периода подписки.",
	subscription_warning_message:
		"**Информация о вашей карте будет надежно сохранена, и ваша подписка будет регулярно продлеваться, если вы не отмените ее.",
	success: "Успех",
	super: "Супер",
	supported_files_img: "Поддерживаемые файлы: JPG, PNG.",
	switch_language: "Сменить язык",
	switch_theme: "Сменить тему",
	target: "Цель",
	target_partner: "Целевой партнер",
	tax_number: "Налоговый номер",
	theme: "Тема",
	this_month: "Этот месяц",
	this_year: "Этот год",
	title: "Заголовок",
	to: "К",
	toDelete: "Удалить",
	total: "Всего",
	total_amount: "Общая сумма",
	transaction_no: "Номер транзакции",
	transactions: "Транзакции",
	try_for_free: "Попробуйте бесплатно",
	type: "Тип",
	types: "Типы",
	un_archive: "Разархивировать",
	unit: "Единица",
	units: "Единицы",
	unloading_country: "Страна разгрузки",
	unloading_point: "Точка разгрузки",
	unloading_port: "Порт разгрузки",
	unsubscribe: "Отписаться",
	unsubscribe_confirm_message:
		"Если вы отпишетесь, вы не сможете эффективно использовать функции системы. Вы уверены, что хотите отписаться?",
	upload_new_image: "Загрузить новое изображение",
	use_code: "Использовать код",
	use_invitation_code: "Использовать код приглашения",
	use_invitation_code_description: "Введите свой код приглашения, отправленный на вашу почту.",
	use_invitation_code_success_message: "Код приглашения был успешно использован.",
	user_profile: "Мой профиль",
	vehicle: "Транспортное средство",
	vehicles: "Транспортные средства",
	verified_partner: "Проверенный партнер",
	verify_password: "Подтвердите пароль",
	visibility: "Видимость",
	warehouse: "Склад",
	warehouses: "Склады",
	website: "Вебсайт",
	week: "Неделя",
	welcome: "Добро пожаловать",
	what_we_are_doing: "Что мы делаем",
	year: "Год",
	year_lowercase: "год",
	yearly: "Ежегодно",
	yes: "Да",
	you_dont_have_any_active_subscription: "У вас нет активной подписки",
};
