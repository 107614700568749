<template>
	<v-container fluid class="d-flex align-center justify-center text-center">
		<v-col>
			<v-row class="d-flex justify-center align-center">
				<v-card-subtitle
					:class="isMobile ? 'v-typography--title1' : 'v-typography--title2'"
					class="accent--text title-with-line pa-0"
				>
					{{ $t(`$landing.$logistics.logistics_title`) }}
				</v-card-subtitle>
			</v-row>
			<v-row class="d-flex justify-center align-center">
				<v-card-title :class="isMobile ? 'v-typography--title' : 'v-typography--title3'">
					{{ $t(`$landing.$logistics.solutions_title`) }}
				</v-card-title>
			</v-row>
			<v-row class="d-flex justify-center align-center">
				<v-card-subtitle :class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'" class="pa-0">
					{{ $t(`$landing.$logistics.text`) }}
				</v-card-subtitle>
			</v-row>

			<v-row class="mt-8">
				<v-col cols="12" sm="0" md="2" lg="2"></v-col>
				<v-col
					cols="12"
					sm="3"
					md="2"
					lg="2"
					v-for="(item, index) in items"
					:key="index"
					class="d-flex justify-center align-center"
				>
					<v-card class="card-style">
						<img :src="item.src" :alt="getAltText(index)" loading="lazy" class="mt-4 card-image" />
						<v-card-text class="v-typography--title1 background--text">
							{{ item.name }}
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="0" sm="6" md="2" lg="2"></v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
import plane from "@/assets/plane.webp";
import ship from "@/assets/ship.webp";
import train from "@/assets/train.webp";
import truck from "@/assets/truck.webp";

export default {
	props: { isMobile: Boolean },
	data() {
		return {
			items: [
				{ src: plane, name: this.$t("$shipment.$method.aerial") },
				{ src: ship, name: this.$t("$shipment.$method.sea") },
				{ src: train, name: this.$t("$shipment.$method.railway") },
				{ src: truck, name: this.$t("$shipment.$method.land") },
			],
		};
	},
	methods: {
		getAltText(index) {
			const altTexts = ["Plane Icon", "Ship Icon", "Train Icon", "Truck Icon"];
			return altTexts[index];
		},
	},
};
</script>

<style scoped>
.card-style {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #004265;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	width: 230px;
	height: 150px;
	border-radius: 8px;
}

.card-style-mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #004265;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	width: 60px;
	height: 60px;
	border-radius: 8px;
}

.card-image-mobile {
	width: 30px;
	height: 30px;
}

.card-image {
	width: 75px;
	height: 60px;
	object-fit: contain;
}

.title-with-line {
	color: #005d8f;
	position: relative;
	display: inline-block;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.2);
	transform-origin: left;
	margin-left: 1px;
}

.text-h4 {
	font-size: 1.5rem;
}

.text-h6 {
	font-size: 1rem;
}

.text-h5 {
	font-size: 1.25rem;
}

.text-body1 {
	font-size: 1rem;
}

.text-body2 {
	font-size: 0.75rem;
}

.v-main {
	max-width: 100%;
}

@media screen and (max-width: 600px) {
	.title-with-line::after {
		height: 0px;
	}
}
</style>
