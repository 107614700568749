<template>
	<v-main>
		<v-navigation-drawer
			v-if="corporation"
			v-model="$store.state.ui.navigationDrawer.state"
			app
			color="primary"
			dark
			:width="!isMobile ? (miniMode ? 70 : 240) : 240"
		>
			<v-list dense v-if="isAuthenticated">
				<v-list-item class="d-flex justify-end">
					<v-btn class="pb-2" icon @click="toggleSidebar" plain :height="isMobile ? 48 : 56">
						<v-icon>
							icon-right-arrow
							{{ isMobile ? "rotate-icon-left" : miniMode ? "rotate-icon-right" : "rotate-icon-left" }}
						</v-icon>
					</v-btn>
				</v-list-item>

				<v-divider class="mb-16" />

				<list-item
					v-for="(item, i) in listItems"
					:key="i"
					:icon="item.icon"
					:title="item.title"
					:url="item.url"
					:additional-active-paths="item.additionalActivePaths"
					:miniMode="miniMode"
				/>
			</v-list>
		</v-navigation-drawer>

		<router-view />
	</v-main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ListItem from "../controls/ListItem.vue";

export default {
	components: { ListItem },
	data() {
		return {
			listItems: [],
			miniMode: false,
		};
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		...mapGetters(["company", "customsAgency", "hasSubscription"]),
		...mapState(["corporation"]),
		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
		showSubscriptionButton() {
			return (
				!this.customsAgency &&
				(!this.hasSubscription || (this.hasSubscription && !this.corporation?.subscription.payTrInfo?.uToken))
			);
		},
	},
	methods: {
		toggleSidebar() {
			if (this.isMobile) {
				this.$store.state.ui.navigationDrawer.state = !this.$store.state.ui.navigationDrawer.state;
			} else {
				this.miniMode = !this.miniMode;
			}
		},
		prepareList() {
			this.listItems.push({
				icon: "icon-dashboard",
				title: this.$t("dashboard"),
				url: "/home",
			});
			if (this.company) {
				this.listItems.push({
					icon: "icon-products",
					title: this.$t("products"),
					url: "/products",
				});
				this.listItems.push({
					icon: "icon-product-groups",
					title: this.$t("product_groups"),
					url: "/product_groups",
				});
				this.listItems.push({
					icon: "icon-proforma-invoices",
					title: this.$t("proforma_invoices"),
					url: "/proforma_invoices",
				});
			}
			this.listItems.push({
				icon: "icon-truck-side",
				title: this.$t("shipments"),
				url: "/shipments",
				additionalActivePaths: ["/shipment"], 
			});
			this.listItems.push({
				icon: "icon-partners",
				title: this.$t("partners"),
				url: "/partners",
			});
			if (this.showSubscriptionButton)
				this.listItems.push({
					icon: "icon-credit-card",
					title: this.$t("subscription"),
					url: "/subscription",
				});
		},
	},
	created() {
		this.prepareList();
	},
};
</script>

<style scoped>
.rotate-icon-left {
	transform: rotate(180deg);
}

.rotate-icon-right {
	transform: rotate(0deg);
}
</style>
