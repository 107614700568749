<template>
	<v-container>
		<common-table
			:headers="headers"
			:items="invitations"
			:items-length="pagination.totalItemCount"
			:loading="loading"
			:options.sync="tableOptions"
			:items-per-page-options="tableFooterProps.itemsPerPageOptions"
			:entity-name="$t('invitation')"
			@row-click="dialog.show"
		>
			<template v-slot:actions>
				<v-btn color="accent" outlined large elevation="0" @click="useCodeDialogVisibility = true">
					<v-icon class="mr-2" start size="medium">icon-check</v-icon>
					{{ $t("use_code") }}
				</v-btn>
			</template>
			<template #item.type="{ item }">
				{{ $t(`$invitationType.${item.type}`) }}
			</template>
		</common-table>

		<invitation-dialog
			v-if="dialog.visibility"
			v-model="dialog.visibility"
			:item="dialog.item"
			:isEmployee="isEmployee"
			@saved="dialog.saved"
			@deleted="dialog.deleted"
		/>
		<use-code-dialog v-model="useCodeDialogVisibility" @accepted="useCodeAccepted" />
	</v-container>
</template>

<script>
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import InvitationDialog from "../../company_profile/dialogs/InvitationDialog.vue";
import UseCodeDialog from "../dialogs/UseCodeDialog.vue";
import { invitationTypes } from "../../../../utils/enum";
import CommonTable from "../../../partials/CommonTable.vue";

export default {
	mixins: [paginationMixin, uiMixin],
	props: {
		isEmployee: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		InvitationDialog,
		UseCodeDialog,
		CommonTable,
	},
	data() {
		return {
			loading: true,
			invitations: [],
			useCodeDialogVisibility: false,
			headers: [
				{ text: this.$t("type"), value: "type" },
				{ text: this.$t("email"), value: "email" },
				{ text: this.$t("code"), value: "code" },
			],
			dialog: {
				visibility: false,
				item: null,
				show: (invitation) => {
					this.dialog.item = invitation;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
				saved: () => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: () => {
					this.dialog.hide();
					this.loadItems();
				},
			},
		};
	},
	methods: {
		useCodeAccepted(result) {
			this.loadItems();
			this.$emit("invitationCodeDialogClosed");
		},
		loadItems() {
			this.loading = true;
			remote.invitations.filter(
				{
					status: "active",
					types: this.isEmployee
						? `${invitationTypes.Employee}`
						: `${invitationTypes.CompanyPartner},${invitationTypes.CustomsPartner}`,
				},
				{
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.invitations = result.items;
						this.pagination = result.pagination;
						this.loading = false;
					},
					onFail: (error) => {
						if (error) console.error(error);
						this.loading = false;
					},
				}
			);
		},
	},
	created() {
		this.loadItems();
	},
};
</script>
