export default {
	$accessibility: {
		archived: "Archiviert",
		present: "Anwesend",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Vor dem Empfänger verborgen",
			hidden_from_sender: "Vor dem Absender verborgen",
			private: "Privat",
			shared_with_custom: "Mit benutzerdefinierten Personen geteilt",
			shared_with_everyone: "Mit allen geteilt",
			shared_with_receiver: "Mit dem Empfänger geteilt",
			shared_with_sender: "Mit dem Absender geteilt",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB Speicher",
		limitMBStorage: "{limit}MB Speicher",
		limitUser: "{limit} Benutzer",
		limitUsers: "{limit} Benutzer",
		renewAtPricePerUnit: "Wird bei {currency}{price} pro {unit} erneuert",
		unlimitedModule: "Unbegrenzte {module}",
		upToLimitModule: "Bis zu {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TÜRKEI",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sen!2str!4v1701805110461!5m2!1sen!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"Beschleunigen Sie Ihr Geschäft mit Antrego. Verwalten Sie Ihre Versandprozesse digital, sicher und einfach.",
	},
	$country: {
		afghanistan: "Afghanistan",
		aland: "Åland",
		albania: "Albanien",
		algeria: "Algerien",
		american_samoa: "Amerikanisch-Samoa",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antarktis",
		antigua_and_barbuda: "Antigua und Barbuda",
		argentina: "Argentinien",
		armenia: "Armenien",
		aruba: "Aruba",
		australia: "Australien",
		austria: "Österreich",
		azerbaijan: "Aserbaidschan",
		bahamas: "Bahamas",
		bahrain: "Bahrain",
		bangladesh: "Bangladesch",
		barbados: "Barbados",
		belarus: "Weißrussland",
		belgium: "Belgien",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermuda",
		bhutan: "Bhutan",
		bolivia: "Bolivien",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius und Saba",
		bosnia_and_herzegovina: "Bosnien und Herzegowina",
		botswana: "Botswana",
		bouvet_island: "Bouvetinsel",
		brazil: "Brasilien",
		british_indian_ocean_territory: "Britisches Territorium im Indischen Ozean",
		british_virgin_islands: "Britische Jungferninseln",
		brunei: "Brunei",
		bulgaria: "Bulgarien",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Kap Verde",
		cambodia: "Kambodscha",
		cameroon: "Kamerun",
		canada: "Kanada",
		cayman_islands: "Kaimaninseln",
		central_african_republic: "Zentralafrikanische Republik",
		chad: "Tschad",
		chile: "Chile",
		china: "China",
		christmas_island: "Weihnachtsinsel",
		cocos_islands: "Kokosinseln",
		colombia: "Kolumbien",
		comoros: "Komoren",
		congo_republic: "Republik Kongo",
		cook_islands: "Cookinseln",
		costa_rica: "Costa Rica",
		croatia: "Kroatien",
		cuba: "Kuba",
		curacao: "Curaçao",
		cyprus: "Zypern",
		czechia: "Tschechien",
		denmark: "Dänemark",
		djibouti: "Dschibuti",
		dominica: "Dominica",
		dominican_republic: "Dominikanische Republik",
		dr_congo: "Demokratische Republik Kongo",
		ecuador: "Ecuador",
		egypt: "Ägypten",
		el_salvador: "El Salvador",
		equatorial_guinea: "Äquatorialguinea",
		eritrea: "Eritrea",
		estonia: "Estland",
		eswatini: "Eswatini",
		ethiopia: "Äthiopien",
		falkland_islands: "Falklandinseln",
		faroe_islands: "Färöer",
		fiji: "Fidschi",
		finland: "Finnland",
		france: "Frankreich",
		french_guiana: "Französisch-Guayana",
		french_polynesia: "Französisch-Polynesien",
		french_southern_territories: "Französische Süd- und Antarktisgebiete",
		gabon: "Gabun",
		gambia: "Gambia",
		georgia: "Georgien",
		germany: "Deutschland",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greece: "Griechenland",
		greenland: "Grönland",
		grenada: "Grenada",
		guadeloupe: "Guadeloupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guinea",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Heard und McDonaldinseln",
		honduras: "Honduras",
		hong_kong: "Hongkong",
		hungary: "Ungarn",
		iceland: "Island",
		india: "Indien",
		indonesia: "Indonesien",
		iran: "Iran",
		iraq: "Irak",
		ireland: "Irland",
		isle_of_man: "Isle of Man",
		israel: "Israel",
		italy: "Italien",
		ivory_coast: "Elfenbeinküste",
		jamaica: "Jamaika",
		japan: "Japan",
		jersey: "Jersey",
		jordan: "Jordanien",
		kazakhstan: "Kasachstan",
		kenya: "Kenia",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgisistan",
		laos: "Laos",
		latvia: "Lettland",
		lebanon: "Libanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libyen",
		liechtenstein: "Liechtenstein",
		lithuania: "Litauen",
		luxembourg: "Luxemburg",
		macao: "Macao",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		malaysia: "Malaysia",
		maldives: "Malediven",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Marshallinseln",
		martinique: "Martinique",
		mauritania: "Mauretanien",
		mauritius: "Mauritius",
		mayotte: "Mayotte",
		mexico: "Mexiko",
		micronesia: "Mikronesien",
		moldova: "Moldawien",
		monaco: "Monaco",
		mongolia: "Mongolei",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Marokko",
		mozambique: "Mosambik",
		myanmar: "Myanmar",
		namibia: "Namibia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Niederlande",
		new_caledonia: "Neukaledonien",
		new_zealand: "Neuseeland",
		nicaragua: "Nicaragua",
		niger: "Niger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Norfolkinsel",
		north_korea: "Nordkorea",
		north_macedonia: "Nordmazedonien",
		northern_mariana_islands: "Nördliche Marianen",
		norway: "Norwegen",
		oman: "Oman",
		pakistan: "Pakistan",
		palau: "Palau",
		palestine: "Palästina",
		panama: "Panama",
		papua_new_guinea: "Papua-Neuguinea",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippinen",
		pitcairn_islands: "Pitcairninseln",
		poland: "Polen",
		portugal: "Portugal",
		puerto_rico: "Puerto Rico",
		qatar: "Katar",
		reunion: "Réunion",
		romania: "Rumänien",
		russia: "Russland",
		rwanda: "Ruanda",
		saint_barthelemy: "Saint Barthélemy",
		saint_helena: "St. Helena",
		saint_lucia: "St. Lucia",
		saint_martin: "Saint Martin",
		saint_pierre_and_miquelon: "Saint Pierre und Miquelon",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "São Tomé und Príncipe",
		saudi_arabia: "Saudi-Arabien",
		senegal: "Senegal",
		serbia: "Serbien",
		seychelles: "Seychellen",
		sierra_leone: "Sierra Leone",
		singapore: "Singapur",
		sint_maarten: "Sint Maarten",
		slovakia: "Slowakei",
		slovenia: "Slowenien",
		solomon_islands: "Salomonen",
		somalia: "Somalia",
		south_africa: "Südafrika",
		south_georgia_and_south_sandwich_islands: "Südgeorgien und die Südlichen Sandwichinseln",
		south_korea: "Südkorea",
		south_sudan: "Südsudan",
		spain: "Spanien",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "St. Kitts und Nevis",
		st_vincent_and_grenadines: "St. Vincent und die Grenadinen",
		sudan: "Sudan",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Spitzbergen und Jan Mayen",
		sweden: "Schweden",
		switzerland: "Schweiz",
		syria: "Syrien",
		taiwan: "Taiwan",
		tajikistan: "Tadschikistan",
		tanzania: "Tansania",
		thailand: "Thailand",
		timor_leste: "Osttimor",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad und Tobago",
		tunisia: "Tunesien",
		turkiye: "Türkei",
		turkmenistan: "Turkmenistan",
		turks_and_caicos_islands: "Turks- und Caicosinseln",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ukraine",
		united_arab_emirates: "Vereinigte Arabische Emirate",
		united_kingdom: "Vereinigtes Königreich",
		united_states: "Vereinigte Staaten",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Amerikanische Außengebiete",
		us_virgin_islands: "Amerikanische Jungferninseln",
		uzbekistan: "Usbekistan",
		vanuatu: "Vanuatu",
		vatican_city: "Vatikanstadt",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		wallis_and_futuna: "Wallis und Futuna",
		western_sahara: "Westsahara",
		yemen: "Jemen",
		zambia: "Sambia",
		zimbabwe: "Simbabwe",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Türkische Lira",
		us_dollar: "US-Dollar",
	},
	$employeeType: {
		admin: "Administrator",
		default: "Standard",
		exporter: "Exporteur",
		importer: "Importeur",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:ss",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Erstellen Sie hier Ihr Unternehmen.",
			create_company_title: "Möchten Sie Ihr Unternehmen hinzufügen?",
			description:
				"Sie haben sich erfolgreich angemeldet. Jetzt müssen Sie einem Unternehmen beitreten oder ein neues erstellen.",
			join_company_subtitle: "Geben Sie Ihren Code ein und treten Sie hier dem Unternehmen bei.",
			join_company_title: "Möchten Sie einem bestehenden Unternehmen beitreten?",
			title: "Willkommen {name}",
		},
		$unauthorized: {
			description: "Behalten Sie Ihre Export-Import-Prozesse mit Antrego im Griff",
			forget_password_subtitle: "Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
			sign_in_subtitle: "Hier anmelden",
			sign_in_title: "Haben Sie bereits ein Konto?",
			sign_up_subtitle: "Hier registrieren",
			sign_up_title: "Möchten Sie ein Konto erstellen?",
			title: "Willkommen bei Antrego",
		},
	},
	$invitationType: {
		company_partner: "Einladung zur kommerziellen Zusammenarbeit",
		customs_partner: "Einladung des Zollpartners",
		employee: "Mitarbeitereinladung",
	},
	$landing: {
		$benefits: {
			benefits_title: "Vorteile",
			business_process: "Beschleunigt Ihre Geschäftsprozesse.",
			control_title: "Übernehmen Sie die Kontrolle mit Antrego.",
			highlight_word: "Kontrolle",
			risk_of_error: "Reduziert das Fehlerrisiko.",
			text: "Antrego ist ein Programm zur Sendungsverfolgung, das Versandunternehmen, ihren Kunden und Zollmaklern hilft, den Versandprozess über eine gemeinsame Plattform zu verfolgen.",
			time_and_cost_savings: "Spart Zeit und Kosten.",
		},
		$content: {
			$text: {
				0: "Verfolgen Sie alle Ihre Sendungen an einem Ort und kontrollieren Sie Ihre Finanzen mit dynamischen Berichten.",
				1: "Teilen Sie Ihre Echtzeit-Updates mit Ihren Kunden, um Ihre Servicequalität zu verbessern.",
				2: "Sehen und teilen Sie alle Dokumente zu Ihrer Sendung in einem System.",
				3: "Kontrollieren Sie Ihren Prozess einfach, indem Sie von allen Geräten und Standorten auf Antrego zugreifen.",
				4: "Speichern Sie alle Ihre Daten mit SHA-256-Verschlüsselungstechnologie. Seien Sie sicher bezüglich der Sicherheit.",
				5: "Beteiligen Sie sich an der gemeinsamen Verfolgung mit allen Beteiligten im Geschäftsprozess. Verwalten Sie den Prozess effizienter.",
			},
			$title: {
				0: "Sendungsverwaltung",
				1: "Kundenbeziehungen",
				2: "Dokumentenverwaltung",
				3: "Einfacher Zugang",
				4: "Datensicherheit",
				5: "Gemeinsame Verfolgung",
			},
		},
		$home: {
			text: "Wählen Sie uns für nahtloses digitales Tracking und gemeinsame Kontrolle.",
			title: "Verwalten Sie alle Ihre Versandprozesse digital",
		},
		$lifecycle: {
			headline: "Prozess",
			info_button: "Informationen erhalten",
			lifecycle_texts: {
				0: "Laden Sie zusätzliche Dateien hoch, um Ihre Proforma-Rechnung zu erstellen, und teilen Sie diese sofort mit Ihrem Kunden.",
				1: "Geben Sie Ihre Versanddetails ein und fügen Sie Dokumente hinzu. Benachrichtigen Sie Ihren Kunden und verwalten Sie alle Versandprozesse gemeinsam über einen einzigen Kanal.",
				2: "Verfolgen Sie Ihre finanzielle Situation im Zusammenhang mit Import und Export in Echtzeit.",
			},
			lifecycle_titles: {
				0: "Anmelden und Proforma-Rechnung erstellen",
				1: "Sendung erstellen",
				2: "Finanzielle Aktivitäten verfolgen",
			},
			productivity_title: "Steigern Sie die Produktivität und reduzieren Sie die Komplexität in 3 Schritten.",
			tracking_guide_title: "Einfache Verfolgungsanleitung",
		},
		$logistics: {
			logistics_title: "Unterstützte Logistikarten",
			solutions_title: "Die richtige Lösung für jede Last",
			text: "Mit Antrego, Ihrem digitalen Partner für alle Arten des Transports, stehen wir Ihnen für all Ihre globalen Bewegungen zur Seite.",
		},
		$process: {
			digital_future: "Verabschieden Sie sich von traditionellen Methoden und betreten Sie die digitale Zukunft",
			digital_shipping: "Digitale Sendungsverfolgung mit Antrego",
			digital_shipping_texts: {
				0: "Proforma-Rechnungen für eingehende Bestellungen werden einfach über einen einzigen Kanal erstellt und verfolgt.",
				1: "Proforma-Rechnungen werden gesendet, Benachrichtigungen gehen sofort an den Kunden und sie werden über Antrego genehmigt.",
				2: "Dokumentenprozesse werden über Antrego verwaltet. Sie gehen nicht verloren, es treten keine Verzögerungen auf und das Fehlerrisiko wird minimiert.",
				3: "Lieferanten, Kunden und Zollmakler können die Versanddetails sofort sehen und gemeinsam verfolgen.",
				4: "Finanzberichte werden automatisch monatlich und jährlich erstellt.",
			},
			question_title: "Wie wird Antrego Ihr Leben verändern?",
			tracking_method: "Digitalisieren Sie Ihre Verfolgungsmethoden und beschleunigen Sie Ihre Prozesse.",
			traditional_shipping: "Traditionelle Sendungsverfolgung",
			traditional_shipping_texts: {
				0: "Bestellungen werden über E-Mail, Nachrichten oder Telefon über verschiedene Kanäle gesammelt, was interne Verfolgungsprobleme verursacht.",
				1: "Die Proforma-Rechnung wird mit Tools wie Excel erstellt und der Genehmigungsprozess wird manuell über E-Mail, WhatsApp oder Telefon abgewickelt.",
				2: "Manuelle Dokumentenprozesse führen zu Fehlern und Verzögerungen, was Zeit- und finanzielle Verluste verursacht.",
				3: "Es gibt keine gemeinsame Plattform für Lieferanten, Kunden und Zollmakler, um Versanddetails zu verfolgen.",
				4: "Finanzielle Daten werden nach jeder Sendung einzeln überprüft, was die Berichterstellung erschwert.",
			},
		},
		$question: {
			$text: {
				0: "<p>Es ist ein Programm zur Sendungsverfolgung, das Unternehmen und ihren Kunden bei der gemeinsamen Verfolgung hilft, um Verluste durch Verzögerungen in der Verfolgungsphase zu vermeiden.</p>",
				1: "<p>- Teilen Sie alle Ihre Echtzeit-Updates mit Ihrem Kunden. Verbessern Sie Ihre Servicequalität.<br>\n          - Sehen und teilen Sie alle Dokumente zu Ihrer Sendung in einem System.<br>\n          - Verfolgen Sie alle Ihre Sendungen an einem Ort. Kontrollieren Sie Ihre Finanzen mit dynamischen Berichten.</p>",
				2: "<p>- See<br>\n          - Luft<br>\n          - Land<br>\n          - Zug</p>",
				3: "<p>Mit seinen Dienstleistungen steht es Ihnen immer zur Verfügung, um Ihren Versandprozess zu erleichtern.<br>\n          <strong>1. Einfache Bedienung</strong><br>\n          Von allen Geräten und Standorten über einen Webbrowser zugänglich.<br>\n          <strong>2. Datensicherheit</strong><br>\n          Alle Daten werden mit SHA-256-Verschlüsselungstechnologie gespeichert.<br>\n          <strong>3. Gemeinsames Tracking</strong><br>\n          Es bietet allen Beteiligten im Prozess Verfolgungsmöglichkeiten.</strong>",
				4: "<p>Ja, es ist eine kostenpflichtige Anwendung. Indem wir alle Ihre Versandprozesse digitalisieren, möchten wir Ihre Arbeit erleichtern und nützliche Entwicklungen für Sie fortsetzen. Um die Nachhaltigkeit dieser Verbesserungen und den von Ihnen genutzten Serverplatz sicherzustellen, erheben wir eine monatliche Gebühr.</p>",
				5: "<p>Nein. Ihre Kunden müssen keine Gebühren zahlen, um die von Ihnen getätigten Sendungen zu verfolgen.</p>",
			},
			$title: {
				0: "Was ist Antrego?",
				1: "Was wird Antrego meinem Unternehmen während des Versandprozesses beitragen?",
				2: "Welche Logistikarten unterstützt Antrego?",
				3: "Warum sollte ich Antrego verwenden?",
				4: "Ist Antrego eine kostenpflichtige Anwendung?",
				5: "Müssen meine Kunden für Antrego bezahlen?",
			},
			save_percentage: "Sparen Sie 16% pro Monat",
			text: "Zugreifen Sie mühelos auf alle Informationen mit Antrego und kontaktieren Sie uns für weitere Fragen.",
			title: "Erfahren Sie schnell, was Sie interessiert",
		},
		cancel_anytime: "Jederzeit kündigen",
		customize_package:
			"Sie können zusätzlichen Speicherplatz und Benutzer erwerben und Ihr Paket nach Ihren Bedürfnissen anpassen",
		free_features_1: "10 Sendungen",
		free_features_2: "100 MB Speicherplatz",
		free_features_3: "Unbegrenzte Geschäftspartner",
		free_features_4: "1 Benutzer",
		free_plan: "Kostenlos",
		free_price: "0€",
		free_regeneration: "",
		free_title: "Monatlich",
		premium_annual_features_1: "20 Benutzer",
		premium_annual_features_2: "100 Partner",
		premium_annual_features_3: "1000 Sendungen",
		premium_annual_features_4: "1000 Rechnungen",
		premium_annual_price: "83,25€",
		premium_annual_regeneration: "Wird jährlich für 999€ erneuert",
		premium_annual_title: "Jährlich",
		premium_monthly_features_1: "Unbegrenzte Sendungen",
		premium_monthly_features_2: "Unbegrenzte Geschäftspartner",
		premium_monthly_features_3: "2 GB Speicherplatz",
		premium_monthly_features_4: "3 Benutzer",
		premium_monthly_price: "99€",
		premium_monthly_regeneration: "Wird monatlich für 99€ erneuert",
		premium_monthly_title: "Monatlich",
		premium_plan: "Premium-Plan",
		premium_plus_annual_features_1: "Unbegrenzte Sendungen",
		premium_plus_annual_features_2: "Unbegrenzte Geschäftspartner",
		premium_plus_annual_features_3: "5 GB Speicherplatz",
		premium_plus_annual_features_4: "5 Benutzer",
		premium_plus_annual_price: "83,25€",
		premium_plus_annual_regeneration: "Wird jährlich für 999€ erneuert",
		premium_plus_annual_title: "Jährlich",
		premium_plus_monthly_features_1: "Unbegrenzte Sendungen",
		premium_plus_monthly_features_2: "Unbegrenzte Geschäftspartner",
		premium_plus_monthly_features_3: "5 GB Speicherplatz",
		premium_plus_monthly_features_4: "5 Benutzer",
		premium_plus_monthly_price: "249€",
		premium_plus_monthly_regeneration: "Wird monatlich für 249€ erneuert",
		premium_plus_monthly_title: "Monatlich",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Wir können Ihre Bedürfnisse bestmöglich erfüllen, ohne Ihr Budget zu sprengen.",
		pricing_title: "Antrego garantiert, das beste Paket und den besten Preis für Sie anzubieten.",
		save_percentage: "Sparen Sie 16% monatlich",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Arabisch",
		de: "Deutsch",
		en: "Englisch",
		es: "Spanisch",
		fr: "Französisch",
		hi: "Hindi",
		ind: "Indonesisch",
		it: "Italienisch",
		ja: "Japanisch",
		ko: "Koreanisch",
		pt: "Portugiesisch",
		ru: "Russisch",
		tr: "Türkisch",
		vi: "Vietnamesisch",
		zh: "Chinesisch",
	},
	$message: {
		cannot_delete_verified_partner: "Sie können einen verifizierten Partner nicht löschen.",
		confirm: "Sind Sie sicher, dass Sie {function} möchten?",
		created: "{item} erstellt.",
		deleted: "{item} gelöscht.",
		error_on_view_file: "Beim Anzeigen der Datei ist ein Fehler aufgetreten.",
		file_oversize: "Die Dateigröße darf maximal {size} betragen.",
		invalid: "Ungültiges {item}",
		migrated: "{item} migriert. ({value})",
		process_has_been_started: "Ihr {item} wurde gestartet.",
		sent: "{item} gesendet.",
		sent_email: "E-Mail gesendet. ({value})",
		sign_in_failed: "E-Mail oder Passwort ist falsch",
		unsupported_file_type: "Nicht unterstützter Dateityp",
		updated: "{item} aktualisiert.",
	},
	$pageTitles: {
		accept_invitation: "Einladung annehmen",
		company_profile: "Firmenprofil",
		home: "Startseite",
		landing_page: "Nahtloses digitales Tracking, gemeinsame Kontrolle",
		partners: "Partner",
		product_groups: "Produktgruppen",
		products: "Produkte",
		proforma_invoices: "Proforma-Rechnungen",
		shipment_details: "Sendungsdetails",
		shipments: "Sendungen",
		sign_in: "Anmelden",
		sign_up: "Registrieren",
		subscription: "Abonnement",
		user_profile: "Benutzerprofil",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Nicht registrierte Partner, Sie haben ihr Profil auf unserer Plattform erstellt, sodass Sie ihre Informationen anzeigen und bearbeiten können.",
		createdHereTitle: "Hier erstellt:",
		description: "Sie können den Status Ihrer Beziehung zu Ihren Partnern verfolgen. Hier sind die Unterschiede:",
		verifiedPartnersDescription:
			"Diese Partner haben Ihre Einladung angenommen und sich auf der Plattform registriert. Ihre Informationen sind nur zur Ansicht und können nicht bearbeitet werden.",
		verifiedPartnersTitle: "Verifizierte Partner:",
	},
	$productGroup: {
		add_child: "Kind hinzufügen",
		add_new_product_group: "Neue Produktgruppe hinzufügen",
		add_to_root: "Zur Wurzel hinzufügen",
		has_children_fail_message: "Eine Produktgruppe mit Kind kann nicht gelöscht werden",
		initial_message: "Verwenden Sie diese {0}-Schaltfläche, um Ihre erste Produktgruppe zu erstellen.",
		name: "Produktgruppenname",
		number_of_product: "Anzahl der Produkte",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} genehmigt.",
			create: "Erstellt von {name}.",
			create_attachment: "{name} hat einen Anhang erstellt.",
			delete_attachment: "{name} hat einen Anhang gelöscht.",
			demand_revise: "{name} forderte eine Überarbeitung.",
			mark_as_processed: "{name} als bearbeitet markiert.",
			reject: "{name} abgelehnt.",
			revoke: "{name} widerrufen.",
			send: "{name} an den Empfänger zur Bewertung gesendet.",
			update: "Aktualisiert von {name}.",
			update_attachment: "{name} hat einen Anhang aktualisiert.",
		},
		$actionDescription2: {
			approve: '{name} als "Genehmigt" markiert.',
			demand_revise: '{name} als "Überarbeitung ausstehend" markiert.',
			mark_as_processed: '{name} als "Bearbeitet" markiert.',
			reject: "{name} abgelehnt.",
			revoke: '{name} als "Widerrufen" markiert.',
			send: '{name} als "Gesendet" markiert.',
		},
		$actions: {
			approve: "Genehmigen",
			approved: "Genehmigt",
			demand_revise: "Überarbeitung fordern",
			mark_as_approved: "Genehmigt",
			mark_as_processed: "Bearbeitet",
			mark_as_rejected: "Abgelehnt",
			mark_as_revise_demanded: "Überarbeitung gefordert",
			mark_as_revoked: "Widerrufen",
			mark_as_sent: "Gesendet",
			pending_approval: "Genehmigung ausstehend",
			pending_revise: "Überarbeitung ausstehend",
			reject: "Ablehnen",
			revoke: "Widerrufen",
			send: "Senden",
		},
		$status: {
			approved: "Genehmigt",
			pending_approval: "Genehmigung ausstehend",
			pending_revise: "Überarbeitung ausstehend",
			processed: "Bearbeitet",
			rejected: "Abgelehnt",
			revoked: "Widerrufen",
			template: "Vorlage",
		},
		approve_confirm_message: "Sind Sie sicher, dass Sie die Proforma-Rechnung genehmigen möchten?",
		approve_success_message: "Die Proforma-Rechnung wurde genehmigt.",
		demand_revise_description_message: "Bitte geben Sie einige Details zu Ihrer Überarbeitungserwartung an:",
		demand_revise_success_message: "Die Proforma-Rechnung wurde zur Überarbeitung an den Absender zurückgesendet.",
		mark_as_processed_confirm_message: "Bestätigen Sie, dass die Proforma-Rechnung als bearbeitet markiert wird?",
		mark_as_processed_success_message: "Die Proforma-Rechnung wurde als bearbeitet markiert.",
		no_attachment_text: "Es wurde noch kein Anhang hinzugefügt.",
		reject_confirm_message: "Sind Sie sicher, dass Sie die Proforma-Rechnung ablehnen möchten?",
		reject_success_message: "Die Proforma-Rechnung wurde abgelehnt.",
		revoke_confirm_message: "Sind Sie sicher, dass Sie die Proforma-Rechnung widerrufen möchten?",
		revoke_success_message: "Die Proforma-Rechnung wurde widerrufen.",
		send_confirm_message: "Bestätigen Sie das Senden der Proforma-Rechnung an den Empfänger?",
		send_success_message: "Die Proforma-Rechnung wurde gesendet.",
	},
	$serviceMessage: {
		already_subs_module: "Es gibt bereits ein Abonnement für dieses Modul.",
		bad_request: "Fehlerhafte Anfrage",
		code_already_used: "Der Code wurde bereits verwendet.",
		container_status_not_available: "Containerstatus ist nicht verfügbar.",
		existing_email_warning: "E-Mail existiert bereits.",
		existing_employee_error: "Dieser Benutzer wurde bereits zu Ihrem Unternehmen hinzugefügt.",
		existing_employee_for_email_warning: "Einladungscode wurde bereits an diese E-Mail gesendet",
		existing_invitation_warning: "Einladungscode wurde bereits an diese E-Mail gesendet.",
		existing_token_already_have_time: "Der bestehende Token hat bereits genügend Zeit zur Verwendung.",
		existing_user: "Benutzer existiert bereits.",
		five_min_reset_password_mail:
			"Sie müssen mindestens 5 Minuten warten, nachdem Sie einmal eine E-Mail zur Passwortzurücksetzung angefordert haben.",
		forbidden: "Verboten",
		have_no_corporation: "Sie haben keine Firma",
		have_no_custom_agency: "Sie haben keine Zollbehörde",
		have_no_employee: "Sie haben keinen Mitarbeiter",
		instance_can_no_delete_existing_booking_code:
			"Die Instanz kann aufgrund eines vorhandenen Buchungscodes nicht gelöscht werden.",
		instance_cannot_delete: "Die Instanz kann nicht mehr gelöscht werden.",
		instance_not_found: "Instanz nicht gefunden.",
		internal_server_error: "Interner Serverfehler",
		invalid_current_password: "Ungültiges aktuelles Passwort.",
		invalid_identifier_or_password: "Ungültiger Bezeichner oder Passwort",
		invalid_invitation_code: "Der Einladungscode ist falsch.",
		invalid_moving_request: "Ungültige Umzugsanfrage",
		invalid_operation: "Ungültiger Vorgang",
		invalid_refresh_token: "Ungültiger Aktualisierungs-Token.",
		invalid_unit: "Ungültige Einheit",
		invitation_accepting_is_due_to_the_admin: "Die Annahme der Einladung erfolgt durch den Administrator.",
		invitation_already_discarded: "Die Einladung wurde bereits verworfen.",
		invitation_already_used: "Die Einladung wurde bereits verwendet.",
		invitation_has_been_used: "Die Einladung wurde verwendet.",
		invitation_not_exist_email: "Die Einladung existiert nicht an der E-Mail-Adresse.",
		invitation_not_for_company: "Diese Einladung ist nicht für ein Unternehmen.",
		invitation_not_for_custom_agency: "Diese Einladung ist nicht für eine Zollbehörde.",
		invitation_not_found: "Die Einladung wurde nicht gefunden.",
		invitation_not_valid: "Die Einladung ist nicht mehr gültig.",
		link_has_been_expired: "Link ist abgelaufen.",
		link_not_be_used_anymore: "Dieser Link darf nicht mehr verwendet werden",
		missing_required_fields: "Erforderliche Felder fehlen.",
		no_employee_found: "Kein Mitarbeiter gefunden",
		no_proper_partner_found: "Kein geeigneter Partner gefunden.",
		not_acceptable: "Nicht akzeptabel",
		not_allowed_action: "Sie sind für diese Aktion nicht berechtigt.",
		not_allowed_use_api: "Sie sind nicht berechtigt, diese API zu verwenden.",
		not_found: "Nicht gefunden",
		not_have_purchase_subscription:
			"Ihr Abonnement hat das maximale Limit für Definitionen erreicht. Um weitere hinzuzufügen, erwägen Sie bitte, Ihr Abonnement zu aktualisieren.",
		not_permitted_use_api: "Sie dürfen diese API nicht verwenden.",
		own_invitation_cannot_accept: "Sie können Ihre eigene Einladung nicht annehmen.",
		parent_group_not_found: "Übergeordnete Gruppe nicht gefunden",
		partnerId_required: "Partner-ID erforderlich.",
		partnership_already_exists: "Die Partnerschaft wurde bereits hinzugefügt.",
		password_cant_involve_space_char: "Das Passwort darf keine Leerzeichen enthalten.",
		password_min_chars_least: "Das Passwort muss mindestens 6 Zeichen lang sein.",
		payment_required: "Bitte abonnieren Sie für diesen Prozess.",
		paytr_integration_error:
			"Es gab ein Problem mit der Zahlungsinfrastruktur. Es wird so schnell wie möglich behoben.",
		ports_are_required: "Häfen sind erforderlich.",
		product_group_tree_not_found: "Produktgruppenbaum nicht gefunden",
		proforma_invoice_action_not_available: "Der Status der Proforma-Rechnung ist für diese Aktion nicht verfügbar.",
		proforma_invoice_template_update: "Proforma-Rechnung muss Vorlage sein, um aktualisiert zu werden.",
		shipment_email_not_exist: "Bitte geben Sie eine gültige E-Mail ein.",
		shipment_method_is_required: "Versandmethode erforderlich.",
		shipping_term_is_required: "Versandbedingungen erforderlich.",
		someone_else_uses_this_email: "Jemand anderes verwendet diese E-Mail-Adresse.",
		someone_else_uses_this_username: "Jemand anderes verwendet diesen Benutzernamen.",
		something_went_wrong: "Etwas ist schiefgelaufen",
		type_can_be_abstract_company: '"type"-Eigenschaft kann nur "abstract_company" sein',
		unauthorized: "Nicht autorisiert",
		undefined_result: "Unbestimmtes Ergebnis",
		unexpected_error_occurred: "Ein unerwarteter Fehler ist aufgetreten",
		unit_already_added: "Einheit wurde bereits hinzugefügt.",
		user_already_employee: "Der Benutzer ist bereits Mitarbeiter.",
		user_already_exists: "Der Benutzer existiert bereits in einem Unternehmen.",
		user_does_not_exist: "Benutzer existiert nicht.",
		user_not_found: "Benutzer nicht gefunden.",
		username_min_chars_error: '"Benutzername" muss mindestens 6 Zeichen lang sein.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} hat ein Fahrzeug hinzugefügt.",
				delete_container: "{name} hat ein Fahrzeug gelöscht.",
				update_container: "{name} hat ein Fahrzeug aktualisiert.",
			},
			create: "Erstellt von {name}.",
			create_attachment: "{name} hat einen Anhang hinzugefügt.",
			create_container: "{name} hat einen Container hinzugefügt.",
			delete_attachment: "{name} hat einen Anhang gelöscht.",
			delete_container: "{name} hat einen Container gelöscht.",
			update: "Aktualisiert von {name}.",
			update_attachment: "{name} hat einen Anhang aktualisiert.",
			update_container: "{name} hat einen Container aktualisiert.",
		},
		$containerStatus: {
			arrived: "Angekommen",
			off_load: "Entladen",
			on_load: "Geladen",
			on_way: "Unterwegs",
			reserved: "Reserviert",
		},
		$method: {
			aerial: "Luft",
			land: "Land",
			railway: "Eisenbahn",
			sea: "See",
		},
		$vehicle: {
			license_number: "Kennzeichen",
		},
		archiving_success_message: "Die Sendung wurde archiviert.",
		leave_message: "Hinterlassen Sie Ihre Nachricht oder Ihren Kommentar",
		no_booking_info:
			"Es wurden noch keine Buchungsinformationen festgelegt. Verwenden Sie die {0}-Schaltfläche, um hinzuzufügen.",
		no_transportation_info:
			"Es wurden noch keine Detailinformationen festgelegt. Verwenden Sie die {0}-Schaltfläche, um festzulegen.",
		un_archiving_success_message: "Die Sendung wurde wiederhergestellt.",
	},
	$shortening: {
		number: "Nr.",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method:
				"Ihr Abonnement konnte nicht erneuert werden. Bitte überprüfen Sie Ihre Zahlungsmethode.",
			fail: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, melden Sie es bitte per E-Mail an info@antrego.net.",
			ok: "Der Kaufprozess wurde eingeleitet. Das Ergebnis des Prozesses wird Ihnen per E-Mail mitgeteilt. Ihr Abonnement wird gestartet, sobald die Zahlung erfolgreich abgeschlossen ist.",
			payment_process_is_ongoing:
				"Ihr Zahlungsvorgang läuft. Das Ergebnis des Prozesses wird Ihnen per E-Mail mitgeteilt. Ihr Abonnement wird gestartet, sobald die Zahlung erfolgreich abgeschlossen ist.",
			success: "Ihr Abonnement wurde erfolgreich erstellt. Sie können Antrego jetzt problemlos nutzen.",
			unsubscribed: "Abgemeldet",
			you_dont_have_any_subscription:
				"Sie haben kein Abonnement. Bitte wählen Sie ein Paket auf der Abonnementseite und beginnen Sie, es problemlos zu nutzen.",
			you_dont_have_any_subscription_text:
				"Wählen Sie das beste Mitgliedschaftsprogramm für sich aus und nutzen Sie alle Möglichkeiten.",
			you_dont_have_any_subscription_title: "Mehr machen mit unseren Plänen",
		},
	},
	$unit: {
		acres: "Acres",
		boxes: "Kisten",
		cubic_meter: "Kubikmeter",
		dozen: "Dutzend",
		feet: "Fuß",
		fluid_ounces: "Flüssigunzen",
		gallons: "Gallonen",
		gram: "Gramm",
		hectare: "Hektar",
		inches: "Zoll",
		kilogram: "Kilogramm",
		kilometer: "Kilometer",
		liter: "Liter",
		meter: "Meter",
		miles: "Meilen",
		milligram: "Milligramm",
		milliliter: "Milliliter",
		millimeter: "Millimeter",
		ounces: "Unzen",
		pallets: "Paletten",
		pieces: "Stücke",
		pounds: "Pfund",
		quarts: "Quarts",
		square_feet: "Quadratfuß",
		square_inches: "Quadratzoll",
		square_kilometer: "Quadratkilometer",
		square_meter: "Quadratmeter",
		square_miles: "Quadratmeilen",
		square_yards: "Quadratyards",
		ton: "Tonnen",
		yards: "Yards",
	},
	$validation: {
		email: "Bitte geben Sie eine gültige E-Mail ein",
		invalid: "Ungültiges {item}",
		max_length: "{item} muss weniger als {length} Zeichen lang sein",
		min_length: "{item} muss mehr als {length} Zeichen lang sein",
		must_length: "{item} muss {length} Zeichen lang sein",
		only_number: "{item} sollte eine Zahl sein",
		password_repeat_warning: "Passwortwiederholung stimmt nicht mit dem neuen Passwort überein.",
		required: "{item} ist erforderlich",
		required_for_default_language: "{item} ist für die Standardsprache des Unternehmens erforderlich.",
		you_must_approve_distance_sales_agreement: "Sie müssen dem Fernabsatzvertrag zustimmen.",
	},
	about: "Über uns",
	accept_invitation: "Einladung annehmen",
	accept_invitation_success_message: "Sie haben die Einladung erfolgreich genutzt.",
	accessibility: "Barrierefreiheit",
	actions: "Aktionen",
	active: "Aktiv",
	activities: "Aktivitäten",
	add: "Hinzufügen",
	add_item: "{item} hinzufügen",
	add_new_employee: "Neuen Mitarbeiter hinzufügen",
	add_new_product: "Neues Produkt hinzufügen",
	address: "Adresse",
	again: "Wieder",
	alert: "Warnung",
	already_have_employee_message: "Sie sind bereits Mitarbeiter eines Unternehmens.",
	amount: "Betrag",
	approve: "Genehmigen",
	archive: "Archiv",
	attachment: "Anhang",
	attachments: "Anhänge",
	bank: "Bank",
	benefits: "Vorteile",
	booking: "Buchung",
	booking_code: "Buchungscode",
	booking_details: "Buchungsdetails",
	business_partner: "Geschäftspartner",
	business_partners: "Geschäftspartner",
	cancel: "Abbrechen",
	change_password: "Passwort ändern",
	checking_invitation_code_message: "Einladungscode wird überprüft",
	choose_image: "Bild auswählen",
	choose_your_plan: "Wählen Sie Ihren Plan",
	clear: "Löschen",
	click_to_play_the_video: "Klicken Sie, um das Video abzuspielen",
	close: "Schließen",
	code: "Code",
	commercial: "Kommerziell",
	commercial_partner: "Geschäftspartner",
	commercial_partners: "Geschäftspartner",
	companies: "Unternehmen",
	company: "Unternehmen",
	company_name: "Firmenname",
	company_profile: "Firmenprofil",
	configuration: "Konfiguration",
	confirm: "Bestätigen",
	confirm_distance_selling_agreement: "Bestätigen Sie den Fernabsatzvertrag.",
	consult_us: "Beraten Sie uns",
	contact: "Kontakt",
	contact_info: "Kontaktinformationen",
	contact_text: "Keine Sorge, wir werden uns so schnell wie möglich bei Ihnen melden.",
	contact_title: "Beraten Sie uns, vereinfachen Sie Ihre Prozesse",
	contact_to_us: "Kontaktieren Sie uns",
	container: "Container",
	container_no: "Container-Nr.",
	containers: "Container",
	content: "Inhalt",
	contract: "Vertrag",
	copy_link: "Link kopieren",
	countries: "Länder",
	country: "Land",
	create: "Erstellen",
	create_a_new_account: "Ein neues Konto erstellen",
	create_company: "Unternehmen erstellen",
	created_at: "Erstellt am",
	created_here: "Hier erstellt",
	credit_card_info: "Kreditkarteninformationen",
	credit_card_no: "Kreditkartennummer",
	currencies: "Währungen",
	currency: "Währung",
	current_password: "Aktuelles Passwort",
	current_plan: "Aktueller Plan",
	customs: "Zoll",
	customs_agencies: "Zollagenturen",
	customs_agency: "Zollagentur",
	cvv: "CVV",
	daily: "Täglich",
	dashboard: "Dashboard",
	date: "Datum",
	day: "Tag",
	default: "Standard",
	default_language: "Standardsprache",
	delete: "Löschen",
	delete_account: "Konto löschen",
	delete_confirm_message: "Bestätigen Sie das Löschen?",
	description: "Beschreibung",
	detail: "Detail",
	discard: "Verwerfen",
	distance_sales_agreement: "Der Fernabsatzvertrag",
	document_number: "Dokumentnummer",
	download: "Herunterladen",
	edit: "Bearbeiten",
	edit_item: "{item} bearbeiten",
	edit_product: "Produkt bearbeiten",
	edit_profile: "Profil bearbeiten",
	edit_your_profile_picture: "Bearbeiten Sie Ihr Profilbild",
	email: "E-Mail",
	employee: "Mitarbeiter",
	employee_type: "Mitarbeiterart",
	employees: "Mitarbeiter",
	estimated_arrival_day: "Geschätzter Ankunftstag",
	expense: "Ausgabe",
	expenses: "Ausgaben",
	expires_at: "Läuft ab am",
	exported_containers: "Exportierte Container",
	fail: "Fehlschlag",
	failed: "Fehlgeschlagen",
	faq: "F.A.Q.",
	feedback: "Feedback",
	file: "Datei",
	file_preview: "Dateivorschau",
	file_view: "Datei anzeigen",
	filter: "Filter",
	first_name: "Vorname",
	first_name_last_name: "Vorname Nachname",
	forget_password: "Passwort vergessen",
	forget_password_message: "Haben Sie Ihr Passwort vergessen?",
	forget_password_subtitle: "Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.",
	free: "Kostenlos",
	from: "Von",
	get_started_now: "Jetzt loslegen",
	give_us_feedback: "Geben Sie uns Feedback",
	go_to_panel: "Zum Panel gehen",
	hide: "Verstecken",
	home_page: "Startseite",
	i_have_invitation_code: "Ich habe einen Einladungscode",
	imported_containers: "Importierte Container",
	in_progress: "In Bearbeitung",
	incoming: "Eingehend",
	incoming_shipments: "Eingehende Sendungen",
	initial: "Initial",
	invitation: "Einladung",
	invitation_code: "Einladungscode",
	invitation_mail_sent: "Einladung per E-Mail gesendet",
	invitations: "Einladungen",
	join: "Beitreten",
	join_company: "Unternehmen beitreten",
	language: "Sprache",
	language_options: "Sprachoptionen",
	last_name: "Nachname",
	last_one_year: "Letztes 1 Jahr",
	latitude: "Breitengrad",
	left_a_comment: "Hat einen Kommentar hinterlassen",
	link_copied: "Link kopiert",
	link_copy_failed: " Link kopieren fehlgeschlagen",
	loading_country: "Lade-Land",
	loading_point: "Ladepunkt",
	loading_port: "Ladehafen",
	log_in: "einloggen",
	logo: "Logo",
	longitude: "Längengrad",
	mail_sent: "E-Mail gesendet",
	manage_profile: "Profil verwalten",
	message: "Nachricht",
	message_company_create: "Unternehmen erstellt.",
	message_company_join: "Bitte geben Sie den Einladungscode ein.",
	message_company_joined: "Sie sind dem Unternehmen beigetreten",
	message_employee_delete: "Mitarbeiter gelöscht.",
	message_employee_save: "Mitarbeiter gespeichert.",
	message_join_company: "Geben Sie Ihren Code ein, um einem bestehenden Unternehmen beizutreten",
	method: "Methode",
	migrate: "Migrieren",
	migrate_partner: "Partner migrieren",
	mobile_number: "Mobilnummer",
	month: "Monat",
	month_lowercase: "monat",
	monthly: "Monatlich",
	my_profile: "mein Profil",
	my_role: "Meine Rolle",
	name: "Name",
	new: "Neu",
	new_company_package: "kostenlos",
	new_password: "Neues Passwort",
	new_shipment: "Neue Sendung",
	next: "Weiter",
	no: "Nein",
	no_content: "Kein Inhalt",
	no_entity: "Kein {entityName} vorhanden",
	no_entity_message: "Erstellen Sie {entityName}, und seine Details werden hier angezeigt.",
	no_text: "Kein Text",
	not_found_page_message: "Entschuldigung, ich konnte diese Seite nicht finden :(",
	note: "Notiz",
	notes: "Notizen",
	ok: "OK",
	operation_fail: "Vorgang fehlgeschlagen",
	outgoing: "Ausgehend",
	outgoing_shipments: "Ausgehende Sendungen",
	packages: "Pakete",
	parent_group: "Übergeordnete Gruppe",
	partner: "Partner",
	partners: "Partner",
	partnership_status: "Partnerschaftsstatus",
	passive: "Passiv",
	password: "Passwort",
	pay: "Bezahlen",
	payback: "Rückzahlung",
	payback_confirm_message: "Bestätigen Sie die Rückzahlung?",
	payback_warning_message: "Wenn Sie fortfahren, wird Ihr Abonnement beendet.",
	payment: "Zahlung",
	payment_amount: "Zahlungsbetrag",
	payment_awaited: "Zahlung erwartet",
	payment_history: "Zahlungsverlauf",
	payment_success: "Zahlung erfolgreich",
	payment_summary: "Zahlungsübersicht",
	payment_will_be_made_3d_secure: "*Ihre Zahlung wird mit 3D Secure durchgeführt.",
	pending_invitations: "Ausstehende Einladungen",
	per_month: "pro Monat",
	phone_number: "Telefonnummer",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Zurück",
	pricing: "Preise",
	process: "Prozess",
	product: "Produkt",
	product_group: "Produktgruppe",
	product_group_name: "Name der Produktgruppe",
	product_groups: "Produktgruppen",
	product_name: "Produktname",
	products: "Produkte",
	profile: "Profil",
	proforma_invoice: "Proforma-Rechnung",
	proforma_invoices: "Proforma-Rechnungen",
	proforma_number: "Proforma-Nummer",
	purchase: "Kauf",
	quantity: "Menge",
	receiver: "Empfänger",
	receiver_company: "Empfängerfirma",
	receiver_customs_agency: "Empfänger-Zollagentur",
	redirection_to_home_link: "Startseite",
	redirection_to_home_message: "Sie können auch zu ",
	redirection_to_sign_in_message: "Haben Sie ein Konto?",
	redirection_to_sign_up_message: "Haben Sie noch kein Konto?",
	reload: "Neu laden",
	renewal: "Erneuerung",
	repaid: "Zurückgezahlt",
	reset_password: "Passwort zurücksetzen",
	rows_per_page: "Zeilen pro Seite",
	sales: "Verkäufe",
	save: "Speichern",
	save_changes: "Änderungen speichern",
	saved_card_info: "Gespeicherte Karteninformationen",
	search: "Suchen",
	select: "Auswählen",
	select_file: "Datei auswählen",
	select_file_place_holder: "Bitte wählen Sie ein Bild aus",
	select_language: "Sprache auswählen",
	selected_partner: "Ausgewählter Partner",
	send: "Senden",
	send_again: "Erneut senden",
	send_email: "E-Mail senden",
	send_feedback: "Feedback senden",
	send_invitation: "Einladung senden",
	sender: "Absender",
	sender_company: "Absenderfirma",
	sender_customs_agency: "Absender-Zollagentur",
	settings: "Einstellungen",
	shipment: "Sendung",
	shipment_method: "Versandmethode",
	shipment_type: "Sendungstyp",
	shipments: "Sendungen",
	shipments_filter: "Sendungsfilter",
	shipping_term: "Versandbedingungen",
	shipping_terms: "Versandbedingungen",
	show: "Anzeigen",
	sign_in: "Anmelden",
	sign_out: "Abmelden",
	sign_out_confirm_message: "Bestätigen Sie das Abmelden?",
	sign_out_success_message: "Auf Wiedersehen.",
	sign_up: "Registrieren",
	social_media: "Soziale Medien",
	social_media_account: "Sozialmedia-Konto",
	start_time: "Startzeit",
	status: "Status",
	subscribe: "Abonnieren",
	subscription: "Abonnement",
	subscription_info_message:
		"Sie können während der Abonnementlaufzeit uneingeschränkt von allen Diensten profitieren.",
	subscription_warning_message:
		"**Ihre Kartendaten werden sicher gespeichert und Ihr Abonnement wird regelmäßig erneuert, es sei denn, Sie kündigen es.",
	success: "Erfolg",
	super: "Super",
	supported_files_img: "Unterstützte Dateitypen: JPG, PNG-Dateien unter 20 MB",
	switch_language: "Sprache wechseln",
	switch_theme: "Thema wechseln",
	target: "Ziel",
	target_partner: "Zielpartner",
	tax_number: "Steuernummer",
	theme: "Thema",
	this_month: "Dieser Monat",
	this_year: "Dieses Jahr",
	title: "Titel",
	to: "An",
	toDelete: "Löschen",
	total: "Gesamt",
	total_amount: "Gesamtbetrag",
	transaction_no: "Transaktionsnummer",
	transactions: "Transaktionen",
	try_for_free: "kostenlos ausprobieren",
	type: "Typ",
	types: "Typen",
	un_archive: "Wiederherstellen",
	unit: "Einheit",
	units: "Einheiten",
	unloading_country: "Entlade-Land",
	unloading_point: "Entladepunkt",
	unloading_port: "Entladehafen",
	unsubscribe: "Abbestellen",
	unsubscribe_confirm_message:
		"Wenn Sie das Abonnement kündigen, können Sie die Systemfunktionen nicht mehr effektiv nutzen. Sind Sie sicher, dass Sie das Abonnement kündigen möchten?",
	upload_new_image: "Neues Bild hochladen",
	use_code: "Code verwenden",
	use_invitation_code: "Einladungscode verwenden",
	use_invitation_code_description: "Geben Sie Ihren Einladungscode ein, der an Ihre Mailbox gesendet wurde.",
	use_invitation_code_success_message: "Der Einladungscode wurde erfolgreich verwendet.",
	user_profile: "Mein Profil",
	vehicle: "Fahrzeug",
	vehicles: "Fahrzeuge",
	verified_partner: "Verifizierter Partner",
	verify_password: "Passwort bestätigen",
	visibility: "Sichtbarkeit",
	warehouse: "Lager",
	warehouses: "Lagerhäuser",
	website: "Webseite",
	week: "Woche",
	welcome: "Willkommen",
	what_we_are_doing: "Was wir tun",
	year: "Jahr",
	year_lowercase: "jahr",
	yearly: "Jährlich",
	yes: "Ja",
	you_dont_have_any_active_subscription: "Sie haben kein aktives Abonnement.",
};
