<template>
	<ext-dialog
		v-model="value"
		title="filter"
		:width="360"
		:primaryButtonText="$t('ok')"
		:secondaryButtonText="$t('clear')"
		@primaryButtonAction="okButtonAction"
		@secondaryButtonAction="clearButtonAction"
		@closed="cancelButtonAction"
		v-if="currentItem"
	>
		<template #body>
			<partner-select
				v-model="currentItem.referenceId"
				:label="$t('partner')"
				:eager="true"
				:types="[partnerTypes.Company, partnerTypes.AbstractCompany]"
				:clearable="true"
			/>
			<v-autocomplete
				v-model="currentItem.statuses"
				:label="$t('status')"
				:items="statusOptions"
				multiple
				small-chips
				deletable-chips
				outlined
				clearable
				append-icon="icon-angle-down"
				:item-text="(i) => (i.value ? $t(`$proformaInvoice.$status.${i.value}`) : '')"
			/>
		</template>
	</ext-dialog>
</template>

<script>
import PartnerSelect from "../../../controls/PartnerSelect";
import { partnerTypes } from "../../../../utils/enum";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	props: { value: Boolean, data: Object },
	components: { PartnerSelect, ExtDialog },
	data() {
		return {
			statuses: [
				{ value: "approved" },
				{ value: "pending_approval" },
				{ value: "pending_revise" },
				{ value: "processed" },
				{ value: "rejected" },
				{ value: "revoked" },
				{ value: "template" },
			],
			currentItem: {
				role: undefined,
				referenceId: undefined,
				statuses: [],
			},
		};
	},
	computed: {
		partnerTypes: () => partnerTypes,
		statusOptions() {
			if (this.data.role === "receiver") return this.statuses.filter((i) => i.value !== "template");
			else return this.statuses;
		},
	},
	watch: {
		value(val) {
			if (val) {
				this.currentItem.role = this.data.role;
				this.currentItem.referenceId = this.data.referenceId;
				this.currentItem.statuses = this.data.statuses;
			}
		},
	},
	methods: {
		okButtonAction() {
			this.$emit("done", {
				role: this.currentItem.role,
				referenceId: this.currentItem.referenceId,
				statuses: this.currentItem.statuses,
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
		clearButtonAction() {
			this.$emit("done", {
				role: this.currentItem.role,
				referenceId: null,
				statuses: [],
			});
		},
	},
};
</script>
