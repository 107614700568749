export default {
	$accessibility: {
		archived: "Archivado",
		present: "Presente",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Oculto del receptor",
			hidden_from_sender: "Oculto del remitente",
			private: "Privado",
			shared_with_custom: "Compartido con personalización",
			shared_with_everyone: "Compartido con todos",
			shared_with_receiver: "Compartido con el receptor",
			shared_with_sender: "Compartido con el remitente",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB de almacenamiento",
		limitMBStorage: "{limit}MB de almacenamiento",
		limitUser: "{limit} Usuario",
		limitUsers: "{limit} Usuarios",
		renewAtPricePerUnit: "Se renovará a {currency}{price} por {unit}",
		unlimitedModule: "{module} Ilimitado",
		upToLimitModule: "Hasta {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURQUÍA",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1ses!2str!4v1701805110461!5m2!1ses!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "Acelera tu negocio con Antrego. Gestiona tus procesos de envío de manera digital, segura y fácil.",
	},
	$country: {
		afghanistan: "Afganistán",
		aland: "Åland",
		albania: "Albania",
		algeria: "Argelia",
		american_samoa: "Samoa Americana",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguila",
		antarctica: "Antártida",
		antigua_and_barbuda: "Antigua y Barbuda",
		argentina: "Argentina",
		armenia: "Armenia",
		aruba: "Aruba",
		australia: "Australia",
		austria: "Austria",
		azerbaijan: "Azerbaiyán",
		bahamas: "Bahamas",
		bahrain: "Baréin",
		bangladesh: "Bangladesh",
		barbados: "Barbados",
		belarus: "Bielorrusia",
		belgium: "Bélgica",
		belize: "Belice",
		benin: "Benín",
		bermuda: "Bermudas",
		bhutan: "Bután",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, San Eustaquio y Saba",
		bosnia_and_herzegovina: "Bosnia y Herzegovina",
		botswana: "Botsuana",
		bouvet_island: "Isla Bouvet",
		brazil: "Brasil",
		british_indian_ocean_territory: "Territorio Británico del Océano Índico",
		british_virgin_islands: "Islas Vírgenes Británicas",
		brunei: "Brunéi",
		bulgaria: "Bulgaria",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Cabo Verde",
		cambodia: "Camboya",
		cameroon: "Camerún",
		canada: "Canadá",
		cayman_islands: "Islas Caimán",
		central_african_republic: "República Centroafricana",
		chad: "Chad",
		chile: "Chile",
		china: "China",
		christmas_island: "Isla de Navidad",
		cocos_islands: "Islas Cocos [Keeling]",
		colombia: "Colombia",
		comoros: "Comoras",
		congo_republic: "República del Congo",
		cook_islands: "Islas Cook",
		costa_rica: "Costa Rica",
		croatia: "Croacia",
		cuba: "Cuba",
		curacao: "Curaçao",
		cyprus: "Chipre",
		czechia: "Chequia",
		denmark: "Dinamarca",
		djibouti: "Yibuti",
		dominica: "Dominica",
		dominican_republic: "República Dominicana",
		dr_congo: "República Democrática del Congo",
		ecuador: "Ecuador",
		egypt: "Egipto",
		el_salvador: "El Salvador",
		equatorial_guinea: "Guinea Ecuatorial",
		eritrea: "Eritrea",
		estonia: "Estonia",
		eswatini: "Esuatini",
		ethiopia: "Etiopía",
		falkland_islands: "Islas Malvinas",
		faroe_islands: "Islas Feroe",
		fiji: "Fiyi",
		finland: "Finlandia",
		france: "Francia",
		french_guiana: "Guayana Francesa",
		french_polynesia: "Polinesia Francesa",
		french_southern_territories: "Territorios Australes Franceses",
		gabon: "Gabón",
		gambia: "Gambia",
		georgia: "Georgia",
		germany: "Alemania",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greece: "Grecia",
		greenland: "Groenlandia",
		grenada: "Granada",
		guadeloupe: "Guadalupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guinea",
		guinea_bissau: "Guinea-Bisáu",
		guyana: "Guyana",
		haiti: "Haití",
		heard_island_and_mcdonald_islands: "Islas Heard y McDonald",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Hungría",
		iceland: "Islandia",
		india: "India",
		indonesia: "Indonesia",
		iran: "Irán",
		iraq: "Irak",
		ireland: "Irlanda",
		isle_of_man: "Isla de Man",
		israel: "Israel",
		italy: "Italia",
		ivory_coast: "Costa de Marfil",
		jamaica: "Jamaica",
		japan: "Japón",
		jersey: "Jersey",
		jordan: "Jordania",
		kazakhstan: "Kazajistán",
		kenya: "Kenia",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirguistán",
		laos: "Laos",
		latvia: "Letonia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Liberia",
		libya: "Libia",
		liechtenstein: "Liechtenstein",
		lithuania: "Lituania",
		luxembourg: "Luxemburgo",
		macao: "Macao",
		madagascar: "Madagascar",
		malawi: "Malaui",
		malaysia: "Malasia",
		maldives: "Maldivas",
		mali: "Malí",
		malta: "Malta",
		marshall_islands: "Islas Marshall",
		martinique: "Martinica",
		mauritania: "Mauritania",
		mauritius: "Mauricio",
		mayotte: "Mayotte",
		mexico: "México",
		micronesia: "Micronesia",
		moldova: "Moldavia",
		monaco: "Mónaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Marruecos",
		mozambique: "Mozambique",
		myanmar: "Myanmar",
		namibia: "Namibia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Países Bajos",
		new_caledonia: "Nueva Caledonia",
		new_zealand: "Nueva Zelanda",
		nicaragua: "Nicaragua",
		niger: "Níger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Isla Norfolk",
		north_korea: "Corea del Norte",
		north_macedonia: "Macedonia del Norte",
		northern_mariana_islands: "Islas Marianas del Norte",
		norway: "Noruega",
		oman: "Omán",
		pakistan: "Pakistán",
		palau: "Palaos",
		palestine: "Palestina",
		panama: "Panamá",
		papua_new_guinea: "Papúa Nueva Guinea",
		paraguay: "Paraguay",
		peru: "Perú",
		philippines: "Filipinas",
		pitcairn_islands: "Islas Pitcairn",
		poland: "Polonia",
		portugal: "Portugal",
		puerto_rico: "Puerto Rico",
		qatar: "Catar",
		reunion: "Reunión",
		romania: "Rumanía",
		russia: "Rusia",
		rwanda: "Ruanda",
		saint_barthelemy: "San Bartolomé",
		saint_helena: "Santa Elena",
		saint_lucia: "Santa Lucía",
		saint_martin: "San Martín",
		saint_pierre_and_miquelon: "San Pedro y Miquelón",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "Santo Tomé y Príncipe",
		saudi_arabia: "Arabia Saudita",
		senegal: "Senegal",
		serbia: "Serbia",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leona",
		singapore: "Singapur",
		sint_maarten: "Sint Maarten",
		slovakia: "Eslovaquia",
		slovenia: "Eslovenia",
		solomon_islands: "Islas Salomón",
		somalia: "Somalia",
		south_africa: "Sudáfrica",
		south_georgia_and_south_sandwich_islands: "Islas Georgias del Sur y Sandwich del Sur",
		south_korea: "Corea del Sur",
		south_sudan: "Sudán del Sur",
		spain: "España",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "San Cristóbal y Nieves",
		st_vincent_and_grenadines: "San Vicente y las Granadinas",
		sudan: "Sudán",
		suriname: "Surinam",
		svalbard_and_jan_mayen: "Svalbard y Jan Mayen",
		sweden: "Suecia",
		switzerland: "Suiza",
		syria: "Siria",
		taiwan: "Taiwán",
		tajikistan: "Tayikistán",
		tanzania: "Tanzania",
		thailand: "Tailandia",
		timor_leste: "Timor-Leste",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad y Tobago",
		tunisia: "Túnez",
		turkiye: "Turquía",
		turkmenistan: "Turkmenistán",
		turks_and_caicos_islands: "Islas Turcas y Caicos",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ucrania",
		united_arab_emirates: "Emiratos Árabes Unidos",
		united_kingdom: "Reino Unido",
		united_states: "Estados Unidos",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Islas Ultramarinas Menores de EE. UU.",
		us_virgin_islands: "Islas Vírgenes de EE. UU.",
		uzbekistan: "Uzbekistán",
		vanuatu: "Vanuatu",
		vatican_city: "Ciudad del Vaticano",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		wallis_and_futuna: "Wallis y Futuna",
		western_sahara: "Sáhara Occidental",
		yemen: "Yemen",
		zambia: "Zambia",
		zimbabwe: "Zimbabue",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Lira turca",
		us_dollar: "Dólar estadounidense",
	},
	$employeeType: {
		admin: "Administrador",
		default: "Predeterminado",
		exporter: "Exportador",
		importer: "Importador",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:ss",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Crea tu empresa aquí.",
			create_company_title: "¿Quieres agregar tu empresa?",
			description: "Has iniciado sesión correctamente. Ahora necesitas unirte a una empresa o crear una nueva.",
			join_company_subtitle: "Introduce tu código y únete a la empresa aquí.",
			join_company_title: "¿Quieres unirte a una empresa existente?",
			title: "Bienvenido {name}",
		},
		$unauthorized: {
			description: "Mantén tus procesos de importación y exportación bajo control utilizando Antrego",
			forget_password_subtitle: "Introduce tu correo electrónico para restablecer tu contraseña.",
			sign_in_subtitle: "Inicia sesión aquí",
			sign_in_title: "¿Ya tienes una cuenta?",
			sign_up_subtitle: "Regístrate aquí",
			sign_up_title: "¿Quieres crear una cuenta?",
			title: "Bienvenido a Antrego",
		},
	},
	$invitationType: {
		company_partner: "Invitación de cooperación comercial",
		customs_partner: "Invitación de socio aduanero",
		employee: "Invitación de empleado",
	},
	$landing: {
		$benefits: {
			benefits_title: "Beneficios",
			business_process: "Acelera tus procesos empresariales.",
			control_title: "Toma el control con Antrego.",
			highlight_word: "control",
			risk_of_error: "Reduce el riesgo de errores.",
			text: "Antrego es un programa de seguimiento de envíos que ayuda a las empresas de transporte, a sus clientes y a los agentes de aduanas a seguir los procesos de envío a través de una plataforma compartida.",
			time_and_cost_savings: "Ahorra tiempo y costos.",
		},
		$content: {
			$text: {
				0: "Rastrea todos tus envíos en un solo lugar, y controla tus finanzas con informes dinámicos.",
				1: "Comparte tus actualizaciones en tiempo real con tus clientes para mejorar la calidad de tu servicio.",
				2: "Visualiza y comparte todos los documentos relacionados con tu envío en un solo sistema.",
				3: "Controla fácilmente tu proceso accediendo a Antrego desde todos los dispositivos y ubicaciones.",
				4: "Almacena todos tus datos utilizando la tecnología de cifrado SHA-256. Ten la seguridad de que están protegidos.",
				5: "Participa en el seguimiento conjunto con todas las partes interesadas en el proceso comercial. Gestiona el proceso de forma más eficiente.",
			},
			$title: {
				0: "Gestión de envíos",
				1: "Relaciones con clientes",
				2: "Gestión de documentos",
				3: "Acceso fácil",
				4: "Seguridad de los datos",
				5: "Seguimiento conjunto",
			},
		},
		$home: {
			text: "Elíjenos para un seguimiento digital sin interrupciones y control conjunto.",
			title: "Gestiona fácilmente todos tus procesos de envío de forma digital",
		},
		$lifecycle: {
			headline: "Proceso",
			info_button: "Obtener información",
			lifecycle_texts: {
				0: "Sube archivos adicionales para crear tu factura proforma y compártela instantáneamente con tu cliente.",
				1: "Introduce los detalles de tu envío y agrega documentos. Notifica a tu cliente y gestiona conjuntamente todos los procesos de envío a través de un solo canal.",
				2: "Controla tu situación financiera relacionada con la importación y exportación en tiempo real.",
			},
			lifecycle_titles: {
				0: "Iniciar sesión y crear factura proforma",
				1: "Crear envío",
				2: "Rastrear actividades financieras",
			},
			productivity_title: "Aumenta la productividad y reduce la complejidad y los errores en 3 pasos.",
			tracking_guide_title: "Guía de seguimiento fácil",
		},
		$logistics: {
			logistics_title: "Tipos de logística soportados",
			solutions_title: "La solución adecuada para cada carga",
			text: "Con Antrego, tu socio digital en todo tipo de transportes, estamos a tu lado para todos tus movimientos globales.",
		},
		$process: {
			digital_future: "Aléjate de los métodos tradicionales y da un paso hacia el futuro digital",
			digital_shipping: "Seguimiento digital de envíos con Antrego",
			digital_shipping_texts: {
				0: "Las facturas proforma para los pedidos entrantes se crean y rastrean fácilmente a través de un solo canal.",
				1: "Las facturas proforma se envían, las notificaciones van al cliente instantáneamente, y se aprueban a través de Antrego.",
				2: "Los procesos de documentos se gestionan a través de Antrego. No se pierden, no ocurren retrasos y se minimiza el riesgo de errores.",
				3: "Proveedores, clientes y agentes de aduanas pueden ver instantáneamente los detalles del envío y seguirlos conjuntamente.",
				4: "Los informes financieros se generan automáticamente de manera mensual y anual.",
			},
			question_title: "¿Cómo cambiará Antrego tu vida?",
			tracking_method: "Digitaliza tus métodos de seguimiento y acelera tus procesos.",
			traditional_shipping: "Seguimiento de envíos tradicional",
			traditional_shipping_texts: {
				0: "Los pedidos se recogen por correo electrónico, mensajes o teléfono a través de diferentes canales, lo que provoca dificultades de seguimiento interno.",
				1: "La factura proforma se crea utilizando herramientas como Excel, y el proceso de aprobación se gestiona manualmente por correo electrónico, WhatsApp o teléfono.",
				2: "Los procesos manuales de documentos conducen a errores y retrasos, lo que provoca pérdidas de tiempo y financieras.",
				3: "No existe una plataforma común para que proveedores, clientes y agentes de aduanas sigan los detalles del envío.",
				4: "Los datos financieros se comprueban individualmente después de cada envío, lo que dificulta la elaboración de informes.",
			},
		},
		$question: {
			$text: {
				0: "<p>Es un programa de seguimiento de envíos que ayuda a las empresas y a sus clientes con el seguimiento conjunto, evitando pérdidas debido a retrasos en la fase de seguimiento.</p>",
				1: "<p>- Comparte todas tus actualizaciones en tiempo real con tu cliente. Mejora la calidad de tu servicio.<br>\n        - Visualiza y comparte todos los documentos relacionados con tu envío en un solo sistema.<br>\n        - Controla todos tus envíos desde un solo lugar. Controla tus finanzas con informes dinámicos.</p>",
				2: "<p>- Marítimo<br>\n        - Aéreo<br>\n        - Terrestre<br>\n        - Tren</p>",
				3: "<p>Con sus servicios, siempre está a tu disposición para facilitar tu proceso de envío.<br>\n        <strong>1. Acceso fácil</strong><br>\n        Accesible desde todos los dispositivos y ubicaciones a través de un navegador web.<br>\n        <strong>2. Seguridad de los datos</strong><br>\n        Todos los datos se almacenan utilizando la tecnología de cifrado SHA-256.<br>\n        <strong>3. Seguimiento conjunto</strong><br>\n        Ofrece oportunidades de seguimiento a todas las partes interesadas en el proceso.</strong>",
				4: "<p>Sí, es una aplicación de pago. Al digitalizar todos tus procesos de envío, queremos facilitar tu trabajo y seguir haciendo desarrollos útiles para ti. Para garantizar la sostenibilidad de estas mejoras y el espacio de servidor que utilizas, cobramos una tarifa mensual.</p>",
				5: "<p>No. Tus clientes no necesitan pagar ninguna tarifa para seguir los envíos que realizas.</p>",
			},
			$title: {
				0: "¿Qué es Antrego?",
				1: "¿Qué aportará Antrego a mi empresa durante el proceso de envío?",
				2: "¿Qué tipos de logística admite Antrego?",
				3: "¿Por qué debo usar Antrego?",
				4: "¿Es Antrego una aplicación de pago?",
				5: "¿Mis clientes tienen que pagar por Antrego?",
			},
			save_percentage: "Ahorra un 16% al mes",
			text: "Accede fácilmente a toda la información con Antrego, y no dudes en contactarnos para cualquier otra pregunta que tengas.",
			title: "Aprende rápidamente todo lo que te interese",
		},
		cancel_anytime: "Cancelar en cualquier momento",
		customize_package:
			"Puedes comprar almacenamiento adicional y usuarios y personalizar tu paquete según tus necesidades",
		free_features_1: "10 Envíos",
		free_features_2: "100 MB de almacenamiento",
		free_features_3: "Socios comerciales ilimitados",
		free_features_4: "1 Usuario",
		free_plan: "Gratis",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Mensual",
		premium_annual_features_1: "20 Usuarios",
		premium_annual_features_2: "100 Socios",
		premium_annual_features_3: "1000 Envíos",
		premium_annual_features_4: "1000 Facturas",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "Se renovará anualmente a $999",
		premium_annual_title: "Anual",
		premium_monthly_features_1: "Envíos ilimitados",
		premium_monthly_features_2: "Socios comerciales ilimitados",
		premium_monthly_features_3: "2GB de almacenamiento",
		premium_monthly_features_4: "3 Usuarios",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Se renovará mensualmente a $99",
		premium_monthly_title: "Mensual",
		premium_plan: "Plan Premium",
		premium_plus_annual_features_1: "Envíos ilimitados",
		premium_plus_annual_features_2: "Socios comerciales ilimitados",
		premium_plus_annual_features_3: "5GB de almacenamiento",
		premium_plus_annual_features_4: "5 Usuarios",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "Se renovará anualmente a $999",
		premium_plus_annual_title: "Anual",
		premium_plus_monthly_features_1: "Envíos ilimitados",
		premium_plus_monthly_features_2: "Socios comerciales ilimitados",
		premium_plus_monthly_features_3: "5GB de almacenamiento",
		premium_plus_monthly_features_4: "5 Usuarios",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Se renovará mensualmente a $249",
		premium_plus_monthly_title: "Mensual",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Podemos satisfacer tus necesidades de la mejor manera sin afectar tu presupuesto.",
		pricing_title: "Antrego garantiza ofrecer el mejor paquete y precio para ti.",
		save_percentage: "Ahorra un 16% al mes",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Árabe",
		de: "Alemán",
		en: "Inglés",
		es: "Español",
		fr: "Francés",
		hi: "Hindi",
		ind: "Indonesio",
		it: "Italiano",
		ja: "Japonés",
		ko: "Coreano",
		pt: "Portugués",
		ru: "Ruso",
		tr: "Turco",
		vi: "Vietnamita",
		zh: "Chino",
	},
	$message: {
		cannot_delete_verified_partner: "No puedes eliminar un socio verificado.",
		confirm: "¿Estás seguro de {function}?",
		created: "{item} creado.",
		deleted: "{item} eliminado.",
		error_on_view_file: "Ocurrió un error al ver el archivo.",
		file_oversize: "El tamaño del archivo debe ser como máximo {size}.",
		invalid: "{item} inválido",
		migrated: "{item} migrado. ({value})",
		process_has_been_started: "Tu {item} ha comenzado.",
		sent: "{item} enviado.",
		sent_email: "Correo electrónico enviado. ({value})",
		sign_in_failed: "El correo electrónico o la contraseña son incorrectos",
		unsupported_file_type: "Tipo de archivo no soportado",
		updated: "{item} actualizado.",
	},
	$pageTitles: {
		accept_invitation: "Aceptar invitación",
		company_profile: "Perfil de la empresa",
		home: "Inicio",
		landing_page: "Seguimiento digital sin interrupciones, control compartido",
		partners: "Socios",
		product_groups: "Grupos de productos",
		products: "Productos",
		proforma_invoices: "Facturas proforma",
		shipment_details: "Detalles del envío",
		shipments: "Envíos",
		sign_in: "Iniciar sesión",
		sign_up: "Registrarse",
		subscription: "Suscripción",
		user_profile: "Perfil del usuario",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Socios no registrados, creaste su perfil en nuestra plataforma, por lo que puedes ver y editar su información.",
		createdHereTitle: "Creado aquí:",
		description: "Puedes seguir el estado de tu relación con tus socios. Estas son las diferencias:",
		verifiedPartnersDescription:
			"Estos socios han aceptado tu invitación y se han registrado en la plataforma. Su información es solo de lectura y no puede ser editada.",
		verifiedPartnersTitle: "Socios verificados:",
	},
	$productGroup: {
		add_child: "Agregar hijo",
		add_new_product_group: "Añadir nueva categoría de producto",
		add_to_root: "Agregar a la raíz",
		has_children_fail_message: "Un grupo de productos con un hijo no se puede eliminar",
		initial_message: "Utiliza este botón {0} para crear tu primer grupo de productos.",
		name: "Nombre",
		number_of_product: "Número de productos",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} aprobado.",
			create: "Creado por {name}.",
			create_attachment: "{name} creó un archivo adjunto.",
			delete_attachment: "{name} eliminó un archivo adjunto.",
			demand_revise: "{name} solicitó revisión.",
			mark_as_processed: "{name} marcado como procesado.",
			reject: "{name} rechazado.",
			revoke: "{name} revocado.",
			send: "{name} enviado al receptor para evaluación.",
			update: "Actualizado por {name}.",
			update_attachment: "{name} actualizó un archivo adjunto.",
		},
		$actionDescription2: {
			approve: '{name} marcado como "Aprobado".',
			demand_revise: '{name} marcado como "Pendiente de revisión".',
			mark_as_processed: '{name} marcado como "Procesado".',
			reject: "{name} rechazado.",
			revoke: '{name} marcado como "Revocado".',
			send: '{name} marcado como "Enviado".',
		},
		$actions: {
			approve: "Aprobar",
			demand_revise: "Solicitar revisión",
			mark_as_approved: "Aprobado",
			mark_as_processed: "Procesado",
			mark_as_rejected: "Rechazado",
			mark_as_revise_demanded: "Revisión solicitada",
			mark_as_revoked: "Revocado",
			mark_as_sent: "Enviado",
			reject: "Rechazar",
			revoke: "Revocar",
			send: "Enviar",
		},
		$status: {
			approved: "Aprobado",
			pending_approval: "Pendiente de aprobación",
			pending_revise: "Pendiente de revisión",
			processed: "Procesado",
			rejected: "Rechazado",
			revoked: "Revocado",
			template: "Plantilla",
		},
		approve_confirm_message: "¿Está seguro de aprobar la factura proforma?",
		approve_success_message: "La factura proforma ha sido aprobada.",
		demand_revise_description_message: "Por favor, proporciona detalles sobre tu expectativa de revisión:",
		demand_revise_success_message: "La factura proforma ha sido enviada al remitente para su revisión.",
		mark_as_processed_confirm_message: "¿Confirma marcar la factura proforma como procesada?",
		mark_as_processed_success_message: "La factura proforma ha sido marcada como procesada.",
		no_attachment_text: "No se ha añadido ningún archivo adjunto.",
		reject_confirm_message: "¿Está seguro de rechazar la factura proforma?",
		reject_success_message: "La factura proforma ha sido rechazada.",
		revoke_confirm_message: "¿Está seguro de revocar la factura proforma?",
		revoke_success_message: "La factura proforma ha sido revocada.",
		send_confirm_message: "¿Confirma enviar la factura proforma al receptor?",
		send_success_message: "La factura proforma ha sido enviada.",
	},
	$serviceMessage: {
		already_subs_module: "Ya existe una suscripción para este módulo.",
		bad_request: "Solicitud incorrecta",
		code_already_used: "El código ya ha sido utilizado.",
		container_status_not_available: "El estado del contenedor no está disponible.",
		existing_email_warning: "El correo electrónico ya existe.",
		existing_employee_error: "Este usuario ya ha sido agregado a tu empresa.",
		existing_employee_for_email_warning: "El código de invitación ya ha sido enviado a este correo electrónico",
		existing_invitation_warning: "El código de invitación ya ha sido enviado a este correo electrónico.",
		existing_token_already_have_time: "El token existente ya tiene suficiente tiempo para ser utilizado.",
		existing_user: "El usuario ya existe.",
		five_min_reset_password_mail:
			"Debe esperar al menos 5 minutos después de solicitar un correo de restablecimiento de contraseña una vez.",
		forbidden: "Prohibido",
		have_no_corporation: "No tienes ninguna corporación",
		have_no_custom_agency: "No tienes ninguna agencia de aduanas",
		have_no_employee: "No tienes ningún empleado",
		instance_can_no_delete_existing_booking_code:
			"La instancia ya no se puede eliminar debido a un código de reserva existente.",
		instance_cannot_delete: "La instancia ya no se puede eliminar.",
		instance_not_found: "Instancia no encontrada.",
		internal_server_error: "Error interno del servidor",
		invalid_current_password: "Contraseña actual inválida.",
		invalid_identifier_or_password: "Identificador o contraseña inválidos",
		invalid_invitation_code: "El código de invitación es incorrecto.",
		invalid_moving_request: "Solicitud de movimiento inválida",
		invalid_operation: "Operación inválida",
		invalid_refresh_token: "Token de actualización inválido.",
		invalid_unit: "Unidad inválida",
		invitation_accepting_is_due_to_the_admin: "La aceptación de la invitación depende del administrador.",
		invitation_already_discarded: "La invitación ya ha sido descartada.",
		invitation_already_used: "La invitación ya ha sido utilizada.",
		invitation_has_been_used: "La invitación ha sido utilizada.",
		invitation_not_exist_email: "La invitación no existe para esta dirección de correo electrónico.",
		invitation_not_for_company: "Esta invitación no es para una empresa.",
		invitation_not_for_custom_agency: "Esta invitación no es para una agencia de aduanas.",
		invitation_not_found: "No se encontró la invitación.",
		invitation_not_valid: "La invitación ya no es válida.",
		link_has_been_expired: "El enlace ha expirado.",
		link_not_be_used_anymore: "Este enlace no puede ser utilizado más",
		missing_required_fields: "Faltan campos obligatorios.",
		no_employee_found: "No se encontró ningún empleado",
		no_proper_partner_found: "No se encontró un socio adecuado.",
		not_acceptable: "No aceptable",
		not_allowed_action: "No tienes permiso para realizar esta acción.",
		not_allowed_use_api: "No tienes permiso para usar esta API.",
		not_found: "No encontrado",
		not_have_purchase_subscription:
			"Su plan de suscripción ha alcanzado el límite máximo de definiciones. Para agregar más, considere actualizar su plan de suscripción.",
		not_permitted_use_api: "No tienes permiso para usar esta API.",
		own_invitation_cannot_accept: "No puedes aceptar tu propia invitación.",
		parent_group_not_found: "No se encontró el grupo principal",
		partnerId_required: "Se requiere ID de socio.",
		partnership_already_exists: "El socio ya ha sido agregado.",
		password_cant_involve_space_char: "La contraseña no puede incluir caracteres de espacio.",
		password_min_chars_least: "La contraseña debe tener al menos 6 caracteres.",
		payment_required: "Por favor, suscríbete para este proceso.",
		paytr_integration_error: "Error en la integración de PayTR",
		ports_are_required: "Se requieren puertos.",
		product_group_tree_not_found: "No se encontró el árbol del grupo de productos",
		proforma_invoice_action_not_available: "El estado de la factura proforma no está disponible para esta acción.",
		proforma_invoice_template_update: "La factura proforma debe ser una plantilla para ser actualizada.",
		shipment_email_not_exist: "Por favor, introduce un correo electrónico válido.",
		shipment_method_is_required: "Se requiere el método de envío.",
		shipping_term_is_required: "Se requiere el término de envío.",
		someone_else_uses_this_email: "Alguien más usa esta dirección de correo electrónico.",
		someone_else_uses_this_username: "Alguien más usa este nombre de usuario.",
		something_went_wrong: "Algo salió mal",
		type_can_be_abstract_company: 'La propiedad "tipo" solo puede ser "empresa_abstracta"',
		unauthorized: "No autorizado",
		undefined_result: "Resultado indefinido",
		unexpected_error_occurred: "Ocurrió un error inesperado",
		unit_already_added: "La unidad ya ha sido agregada.",
		user_already_employee: "El usuario ya es un empleado.",
		user_already_exists: "El usuario ya existe en una empresa.",
		user_does_not_exist: "El usuario no existe.",
		user_not_found: "Usuario no encontrado.",
		username_min_chars_error: 'El "nombre de usuario" debe tener al menos 6 caracteres.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} agregó un vehículo.",
				delete_container: "{name} eliminó un vehículo.",
				update_container: "{name} actualizó un vehículo.",
			},
			create: "Creado por {name}.",
			create_attachment: "{name} agregó un archivo adjunto.",
			create_container: "{name} agregó un contenedor.",
			delete_attachment: "{name} eliminó un archivo adjunto.",
			delete_container: "{name} eliminó un contenedor.",
			update: "Actualizado por {name}.",
			update_attachment: "{name} actualizó un archivo adjunto.",
			update_container: "{name} actualizó un contenedor.",
		},
		$containerStatus: {
			arrived: "Llegó",
			off_load: "Descargado",
			on_load: "Cargando",
			on_way: "En camino",
			reserved: "Reservado",
		},
		$method: {
			aerial: "Aéreo",
			land: "Terrestre",
			railway: "Ferroviario",
			sea: "Marítimo",
		},
		$vehicle: {
			license_number: "Matrícula",
		},
		archiving_success_message: "El envío ha sido archivado.",
		leave_message: "Deja tu mensaje o comentario",
		no_booking_info: "No se ha establecido información de reserva todavía. Usa el botón {0} para agregar.",
		no_transportation_info: "No se ha establecido información detallada todavía. Usa el botón {0} para establecer.",
		un_archiving_success_message: "El envío ha sido desarchivado.",
	},
	$shortening: {
		number: "N.º",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Tu suscripción no se pudo renovar. Por favor, revisa tu método de pago.",
			fail: "Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde. Si el problema persiste, repórtalo enviando un correo electrónico a info@antrego.net.",
			ok: "El proceso de compra ha comenzado. El resultado del proceso será notificado por correo electrónico. Tu suscripción comenzará cuando el pago se complete con éxito.",
			payment_process_is_ongoing:
				"Tu proceso de pago está en curso. El resultado del proceso será notificado por correo electrónico. Tu suscripción comenzará cuando el pago se complete con éxito.",
			success: "Tu suscripción se ha creado con éxito. Puedes comenzar a usar Antrego fácilmente.",
			unsubscribed: "Dado de baja",
			you_dont_have_any_subscription:
				"No tienes ninguna suscripción. Por favor, elige un paquete en la página de suscripción y comienza a usarlo fácilmente.",
			you_dont_have_any_subscription_text: "Por favor, selecciona un plan para continuar.",
			you_dont_have_any_subscription_title: "No tienes ninguna suscripción activa.",
		},
	},
	$unit: {
		acres: "Acres",
		boxes: "Cajas",
		cubic_meter: "Metro cúbico",
		dozen: "Docena",
		feet: "Pies",
		fluid_ounces: "Onzas líquidas",
		gallons: "Galones",
		gram: "Gramo",
		hectare: "Hectárea",
		inches: "Pulgadas",
		kilogram: "Kilogramo",
		kilometer: "Kilómetro",
		liter: "Litro",
		meter: "Metro",
		miles: "Millas",
		milligram: "Miligramo",
		milliliter: "Mililitro",
		millimeter: "Milímetro",
		ounces: "Onzas",
		pallets: "Palés",
		pieces: "Piezas",
		pounds: "Libras",
		quarts: "Cuartos",
		square_feet: "Pies cuadrados",
		square_inches: "Pulgadas cuadradas",
		square_kilometer: "Kilómetro cuadrado",
		square_meter: "Metro cuadrado",
		square_miles: "Millas cuadradas",
		square_yards: "Yardas cuadradas",
		ton: "Toneladas",
		yards: "Yardas",
	},
	$validation: {
		email: "Por favor, introduce un correo electrónico válido",
		invalid: "{item} no válido",
		max_length: "{item} debe tener menos de {length} caracteres",
		min_length: "{item} debe tener más de {length} caracteres",
		must_length: "{item} debe tener {length} caracteres",
		only_number: "{item} debe ser un número",
		password_repeat_warning: "La repetición de la contraseña no coincide con la nueva contraseña.",
		required: "{item} es obligatorio",
		required_for_default_language: "{item} es obligatorio para el idioma predeterminado de la empresa.",
		you_must_approve_distance_sales_agreement: "Debes aprobar el Contrato de Venta a Distancia",
	},
	about: "Acerca de",
	accept_invitation: "Aceptar invitación",
	accept_invitation_success_message: "Has utilizado la invitación con éxito.",
	accessibility: "accesibilidad",
	actions: "Acciones",
	active: "Activo",
	activities: "Actividades",
	add: "Agregar",
	add_item: "Agregar {item}",
	add_new_employee: "Añadir nuevo empleado",
	add_new_product: "Añadir nuevo producto",
	address: "Dirección",
	again: "De nuevo",
	alert: "Alerta",
	already_have_employee_message: "Ya eres empleado de una empresa.",
	amount: "Monto",
	approve: "Aprobar",
	archive: "Archivo",
	attachment: "Adjunto",
	attachments: "Adjuntos",
	bank: "Banco",
	benefits: "Beneficios",
	booking: "Reserva",
	booking_code: "Código de reserva",
	booking_details: "Detalles de la reserva",
	business_partner: "Socio comercial",
	business_partners: "Socios comerciales",
	cancel: "Cancelar",
	change_password: "Cambiar contraseña",
	checking_invitation_code_message: "Verificando el código de invitación",
	choose_image: "Elegir imagen",
	choose_your_plan: "Elige tu plan",
	clear: "Limpiar",
	click_to_play_the_video: "Haz clic para reproducir el vídeo",
	close: "Cerrar",
	code: "Código",
	commercial: "Comercial",
	commercial_partner: "Socio comercial",
	commercial_partners: "Socios comerciales",
	companies: "Empresas",
	company: "Empresa",
	company_name: "Nombre de la empresa",
	company_profile: "Perfil de la empresa",
	configuration: "Configuración",
	confirm: "Confirmar",
	confirm_distance_selling_agreement: "Confirma el Contrato de Venta a Distancia.",
	consult_us: "Consúltanos",
	contact: "Contacto",
	contact_info: "Información de contacto",
	contact_text: "Ten la seguridad de que nos pondremos en contacto contigo lo antes posible.",
	contact_title: "Consúltanos, simplifica tus procesos",
	contact_to_us: "Contáctanos",
	container: "Contenedor",
	container_no: "No. de contenedor",
	containers: "Contenedores",
	content: "Contenido",
	contract: "Contrato",
	copy_link: "Copiar enlace",
	countries: "Países",
	country: "País",
	create: "Crear",
	create_a_new_account: "Crear una nueva cuenta",
	create_company: "Crear empresa",
	created_at: "Creado el",
	created_here: "Creado aquí",
	credit_card_info: "Información de la tarjeta de crédito",
	credit_card_no: "N.º de tarjeta de crédito",
	currencies: "Monedas",
	currency: "Moneda",
	current_password: "Contraseña actual",
	current_plan: "Plan actual",
	customs: "Aduanas",
	customs_agencies: "Agencias de aduanas",
	customs_agency: "Agencia de aduanas",
	cvv: "CVV",
	daily: "Diario",
	dashboard: "Panel",
	date: "Fecha",
	day: "Día",
	default: "Predeterminado",
	default_language: "Idioma predeterminado",
	delete: "Eliminar",
	delete_account: "Eliminar cuenta",
	delete_confirm_message: "¿Confirmas la eliminación?",
	description: "Descripción",
	detail: "Detalle",
	discard: "Descartar",
	distance_sales_agreement: "El Contrato de Venta a Distancia",
	document_number: "N.º de documento",
	download: "Descargar",
	edit: "Editar",
	edit_item: "Editar {item}",
	edit_product: "Editar producto",
	edit_profile: "Editar perfil",
	edit_your_profile_picture: "Editar tu foto de perfil",
	email: "Correo electrónico",
	employee: "Empleado",
	employee_type: "Tipo de empleado",
	employees: "Empleados",
	estimated_arrival_day: "Día estimado de llegada",
	expense: "Gasto",
	expenses: "Gastos",
	expires_at: "Expira el",
	exported_containers: "Contenedores exportados",
	fail: "Fallido",
	failed: "Fallido",
	faq: "Preguntas frecuentes",
	feedback: "Comentarios",
	file: "Archivo",
	file_preview: "Vista previa del archivo",
	file_view: "Ver archivo",
	filter: "Filtro",
	first_name: "Nombre",
	first_name_last_name: "Nombre y Apellidos",
	forget_password: "Olvidé mi contraseña",
	forget_password_message: "¿Olvidaste tu contraseña?",
	forget_password_subtitle: "Por favor, ingrese su dirección de correo electrónico para restablecer su contraseña.",
	free: "Gratis",
	from: "De",
	get_started_now: "Comienza ahora",
	give_us_feedback: "Danos tu opinión",
	go_to_panel: "Ir al panel",
	hide: "Ocultar",
	home_page: "Página principal",
	i_have_invitation_code: "Tengo un código de invitación",
	imported_containers: "Contenedores importados",
	in_progress: "En progreso",
	incoming: "Entrante",
	incoming_shipments: "Envíos entrantes",
	initial: "Inicial",
	invitation: "Invitación",
	invitation_code: "Código de invitación",
	invitation_mail_sent: "Correo de invitación enviado",
	invitations: "Invitaciones",
	join: "Unirse",
	join_company: "Unirse a la compañía",
	language: "Idioma",
	language_options: "Opciones de idioma",
	last_name: "Apellidos",
	last_one_year: "Último 1 año",
	latitude: "Latitud",
	left_a_comment: "Dejó un comentario",
	link_copied: "Enlace copiado",
	link_copy_failed: "Error al copiar el enlace",
	loading_country: "País de carga",
	loading_point: "Punto de carga",
	loading_port: "Puerto de carga",
	log_in: "iniciar sesión",
	logo: "Logotipo",
	longitude: "Longitud",
	mail_sent: "Correo electrónico enviado",
	manage_profile: "Gestionar perfil",
	message: "Mensaje",
	message_company_create: "Empresa creada.",
	message_company_join: "Por favor, ingrese el código de invitación.",
	message_company_joined: "Te has unido a la empresa",
	message_employee_delete: "Empleado eliminado.",
	message_employee_save: "Empleado guardado.",
	message_join_company: "Introduce tu código para unirte a una empresa existente",
	method: "Método",
	migrate: "Migrar",
	migrate_partner: "Migrar empresa",
	mobile_number: "Número de teléfono móvil",
	month: "Mes",
	month_lowercase: "mes",
	monthly: "Mensual",
	my_profile: "mi perfil",
	my_role: "Mi rol",
	name: "Nombre",
	new: "Nuevo",
	new_company_package: "gratis",
	new_password: "Nueva contraseña",
	new_shipment: "Nuevo envío",
	next: "Siguiente",
	no: "No",
	no_content: "Sin contenido",
	no_entity: "Sin entidad",
	no_entity_message: "No se ha encontrado ninguna entidad.",
	no_text: "Sin texto",
	not_found_page_message: "Lo siento, no pude encontrar esta página :(",
	note: "Nota",
	notes: "Notas",
	ok: "OK",
	operation_fail: "Fallo en la operación",
	outgoing: "Saliente",
	outgoing_shipments: "Envíos salientes",
	packages: "Paquetes",
	parent_group: "Grupo principal",
	partner: "Socio",
	partners: "Socios",
	partnership_status: "Estado de la asociación",
	passive: "Pasivo",
	password: "Contraseña",
	pay: "Pagar",
	payback: "Devolución",
	payback_confirm_message: "¿Confirmas la devolución?",
	payback_warning_message: "Si continúas, tu suscripción será cancelada.",
	payment: "Pago",
	payment_amount: "Monto del pago",
	payment_awaited: "Pago pendiente",
	payment_history: "Historial de pagos",
	payment_success: "Pago exitoso",
	payment_summary: "Resumen de pago",
	payment_will_be_made_3d_secure: "*Tu pago se realizará con 3d Secure.",
	pending_invitations: "Invitaciones pendientes",
	per_month: "Por mes",
	phone_number: "Número de teléfono",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Anterior",
	pricing: "Precios",
	process: "Proceso",
	product: "Producto",
	product_group: "Grupo de productos",
	product_group_name: "Nombre de la categoría de producto",
	product_groups: "Grupos de productos",
	product_name: "Nombre del producto",
	products: "Productos",
	profile: "Perfil",
	proforma_invoice: "Factura proforma",
	proforma_invoices: "Facturas proforma",
	proforma_number: "N.º de proforma",
	purchase: "Comprar",
	quantity: "Cantidad",
	receiver: "Receptor",
	receiver_company: "Empresa receptora",
	receiver_customs_agency: "Agencia de aduanas receptora",
	redirection_to_home_link: "Inicio",
	redirection_to_home_message: "También puedes ir a ",
	redirection_to_sign_in_message: "¿Tienes una cuenta?",
	redirection_to_sign_up_message: "¿Aún no tienes una cuenta?",
	reload: "Recargar",
	renewal: "Renovación",
	repaid: "Reembolsado",
	reset_password: "Restablecer contraseña",
	rows_per_page: "Filas por página",
	sales: "Ventas",
	save: "Guardar",
	save_changes: "Guardar cambios",
	saved_card_info: "Información de tarjeta guardada",
	search: "Buscar",
	select: "Seleccionar",
	select_file: "Seleccionar archivo",
	select_file_place_holder: "Por favor selecciona una imagen",
	select_language: "Selecciona idioma",
	selected_partner: "Socio seleccionado",
	send: "Enviar",
	send_again: "Enviar de nuevo",
	send_email: "Enviar correo electrónico",
	send_feedback: "Enviar comentario",
	send_invitation: "Enviar invitación",
	sender: "Remitente",
	sender_company: "Empresa remitente",
	sender_customs_agency: "Agencia de aduanas remitente",
	settings: "Configuración",
	shipment: "Envío",
	shipment_method: "Método de envío",
	shipment_type: "Tipo de envío",
	shipments: "Envíos",
	shipments_filter: "Filtro de envíos",
	shipping_term: "Término de envío",
	shipping_terms: "Términos de envío",
	show: "Mostrar",
	sign_in: "Iniciar sesión",
	sign_out: "Cerrar sesión",
	sign_out_confirm_message: "¿Confirmas cerrar sesión?",
	sign_out_success_message: "Adiós.",
	sign_up: "Registrarse",
	social_media: "Redes sociales",
	social_media_account: "Cuenta de redes sociales",
	start_time: "Hora de inicio",
	status: "Estado",
	subscribe: "Suscribirse",
	subscription: "Suscripción",
	subscription_info_message:
		"Puedes beneficiarte de todos los servicios ilimitadamente durante el período de suscripción.",
	subscription_warning_message:
		"**Tu información de tarjeta será almacenada de forma segura y tu suscripción se renovará regularmente a menos que la canceles.",
	success: "Éxito",
	super: "Súper",
	supported_files_img: "Formatos de archivo admitidos: JPG, PNG.",
	switch_language: "Cambiar idioma",
	switch_theme: "Cambiar tema",
	target: "Objetivo",
	target_partner: "Socio objetivo",
	tax_number: "Número de impuesto",
	theme: "Tema",
	this_month: "Este mes",
	this_year: "Este año",
	title: "Título",
	to: "A",
	toDelete: "Eliminar",
	total: "Total",
	total_amount: "Monto total",
	transaction_no: "N.º de transacción",
	transactions: "Transacciones",
	try_for_free: "prueba gratis",
	type: "Tipo",
	types: "Tipos",
	un_archive: "Desarchivar",
	unit: "Unidad",
	units: "Unidades",
	unloading_country: "País de descarga",
	unloading_point: "Punto de descarga",
	unloading_port: "Puerto de descarga",
	unsubscribe: "Cancelar suscripción",
	unsubscribe_confirm_message:
		"Si cancelas la suscripción, no podrás utilizar las funciones del sistema de forma efectiva. ¿Estás seguro de que deseas cancelar la suscripción?",
	upload_new_image: "Subir nueva imagen",
	use_code: "Usar código",
	use_invitation_code: "Usar código de invitación",
	use_invitation_code_description: "Escribe tu código de invitación que se envió a tu buzón.",
	use_invitation_code_success_message: "El código de invitación se ha utilizado con éxito.",
	user_profile: "Mi perfil",
	vehicle: "Vehículo",
	vehicles: "Vehículos",
	verified_partner: "Socio verificado",
	verify_password: "Verificar contraseña",
	visibility: "Visibilidad",
	warehouse: "Almacén",
	warehouses: "Almacenes",
	website: "Sitio web",
	week: "Semana",
	welcome: "Bienvenido",
	what_we_are_doing: "Qué estamos haciendo",
	year: "Año",
	year_lowercase: "año",
	yearly: "Anual",
	yes: "Sí",
	you_dont_have_any_active_subscription: "No tienes ninguna suscripción activa.",
};
