import { getSetting, setSetting, Setting } from "../../data/local/ls";

export const pagination = {
	data() {
		return {
			tableOptions: {
				page: this.$route.query.page ? Number(this.$route.query.page) : 1,
				itemsPerPage: getSetting(Setting.data_table_page_size) || 10,
				sortBy: [],
				sortDesc: [],
				groupBy: [],
				groupDesc: [],
				multiSort: false,
				mustSort: false,
			},
			tableFooterProps: {
				showFirstLastPage: true,
				firstIcon: "icon-double-left",
				lastIcon: "icon-double-right",
				itemsPerPageOptions: [5, 10, 15, 20, 50],
			},
			pagination: {
				totalItemCount: 0,
			},
		};
	},
	watch: {
		tableOptions(newValue, oldValue) {
			if (this.pagination && (newValue.itemsPerPage != oldValue.itemsPerPage || newValue.page != oldValue.page)) {
				this.loadItems();
				let query = Object.assign({}, this.$route.query);
				if (newValue.page != query.page) {
					query.page = newValue.page;
					this.$router.replace({ query });
				}
				setSetting(Setting.data_table_page_size, newValue.itemsPerPage);
			}
		},
	},
};

const shipmentMethodIcons = {
	sea: "icon-ship",
	aerial: "icon-plane",
	land: "icon-truck",
	railway: "icon-train",
};

export const iconHelper = {
	methods: {
		getShipmentMethodIcon(method, defaultIcon) {
			return shipmentMethodIcons[method] || (defaultIcon !== undefined ? defaultIcon : "icon-shuffle");
		},
	},
};

export const getProformaChipColor = function (status) {
	const colorMap = {
		template: "grey lighten-2",
		revoked: "yellow lighten-4",
		processed: "purple lighten-4",
		rejected: "red lighten-4",
		approved: "green lighten-4",
		pending_approval: "blue lighten-4",
		pending_revise: "orange lighten-4",
	};
	return colorMap[status] || "grey lighten-2";
};

export const getProformaChipTextColor = function (status) {
	const textColorMap = {
		template: "grey darken-4",
		revoked: "grey darken-4",
		processed: "purple darken-2",
		rejected: "red darken-2",
		approved: "green darken-2",
		pending_approval: "blue darken-2",
		pending_revise: "orange darken-2",
	};
	return textColorMap[status] || "grey darken-4";
};
