<template>
	<v-container>
		<v-card class="ant-card pa-2" elevation="0">
			<v-toolbar flat dense>
				<v-toolbar-title class="v-typography--title">
					{{ $t("products") }}
				</v-toolbar-title>
			</v-toolbar>

			<v-divider class="mb-4"></v-divider>

			<v-card-text class="pb-0 px-0">
				<common-table
					:options.sync="tableOptions"
					:headers="productListHeaders"
					:items="currentItem.products"
					:hide-pagination="true"
					@row-click="productDialog.show"
				>
					>
					<template v-slot:item.name="{ item }">
						{{ item.name[$lang] && item.name[$lang].length ? item.name[$lang] : item.name["_default"] }}
					</template>
					<template v-slot:item.quantity="{ item }"> {{ item.quantity }} {{ item.unitCode }} </template>
					<template v-slot:item.amount="{ item }">
						{{
							item.amount.toLocaleString($lang, {
								style: "currency",
								currency: currentItem.currency.code,
							})
						}}
					</template>
					<template slot="body.append" v-if="currentItem.products.length">
						<tr>
							<td class="text-right" colspan="3">
								{{ $t("total") }}:
								{{
									totalAmount.toLocaleString($lang, {
										style: "currency",
										currency: currentItem.currency.code,
									})
								}}
								{{ currentItem.currency.symbol }}
							</td>
						</tr>
					</template>
				</common-table>
				<div class="ml-2 py-4">
					<v-btn
						v-if="['template', 'pending_revise'].includes(currentItem.status)"
						color="accent"
						large
						elevation="0"
						outlined
						@click="productDialog.show()"
					>
						<v-icon size="22" class="mr-1">icon-plus</v-icon>
						<div class="d-flex justify-end align-center">
							{{ $t("add_item", { item: $t("product") }) }}
						</div>
					</v-btn>
				</div>
			</v-card-text>
		</v-card>

		<proforma-invoice-product-dialog
			v-model="productDialog.visibility"
			:item="productDialog.item"
			:proformaInvoice="currentItem"
			@saved="productDialog.saved"
			@deleted="productDialog.deleted"
		/>
	</v-container>
</template>

<script>
import ProformaInvoiceProductDialog from "../dialogs/ProformaInvoiceProductDialog.vue";
import CommonTable from "../../../partials/CommonTable.vue";
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";

export default {
	name: "ProformaInvoiceProducts",
	mixins: [paginationMixin],
	components: {
		ProformaInvoiceProductDialog,
		CommonTable,
	},
	props: {
		currentItem: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			productListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("quantity"),
					value: "quantity",
					align: "end",
					sortable: false,
				},
				{
					text: this.$t("amount"),
					value: "amount",
					align: "end",
					sortable: false,
				},
			],
			productDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.productDialog.item = item;
					this.productDialog.visibility = true;
				},
				hide: () => {
					this.productDialog.item = null;
					this.productDialog.visibility = false;
				},
				saved: (_) => {
					this.$emit("reloadCurrentItem");
					this.productDialog.hide();
				},
				deleted: (_) => {
					this.$emit("reloadCurrentItem");
					this.productDialog.hide();
				},
			},
		};
	},
	computed: {
		$lang() {
			return this.$i18n.locale;
		},
		totalAmount() {
			if (this.currentItem && this.currentItem.products) {
				let total = 0;
				for (const item of this.currentItem.products) {
					total += item.quantity * item.amount;
				}
				return total;
			} else return null;
		},
	},
};
</script>
