<template>
	<ext-dialog
		v-model="value"
		:title="title"
		:primaryButtonText="$t('save')"
		dialogType="large"
		@primaryButtonAction="saveAction"
		@closed="closeAction"
	>
		<template #body>
			<v-container v-if="currentItem">
				<v-form ref="form" v-model="valid">
					<v-row>
						<!-- Proforma Invoice Number -->
						<v-col cols="12" lg="6" class="pb-0">
							<v-text-field
								v-model="currentItem.no"
								:label="$t('proforma_number')"
								prepend-icon="icon-document"
								:rules="rules.proformaNumber"
							/>
						</v-col>

						<!-- Receiver -->
						<v-col cols="12" lg="6" class="pb-0">
							<partner-select
								v-if="!currentItem.id || currentItem.status === 'template'"
								v-model="currentItem.receiver.id"
								:label="$t('receiver')"
								:rules="rules.receiver"
								:eager="true"
								:types="[partnerTypes.Company, partnerTypes.AbstractCompany]"
								prepend-icon="icon-company"
								@change="receiverChangeAction"
							/>
						</v-col>
					</v-row>
					<v-row>
						<!-- Date -->
						<v-col cols="12" lg="6" class="pb-0">
							<date-picker-moment v-model="currentItem.date" showPrependIcon :label="$t('date')" :rules="rules.date" />
						</v-col>
						<!-- Currency -->
						<v-col cols="12" lg="4" class="pb-0">
							<currency-select
								v-model="currentItem.currency.code"
								:eager="true"
								:label="$t('currency')"
								:rules="rules.currency"
								prepend-icon="icon-money"
								@change="currencyChangeAction"
							/>
						</v-col>
						<!-- Status -->
						<v-col cols="12" lg="2" class="pb-8" align-self="center">
							<v-chip
								:ripple="false"
								:color="getProformaChipColor(currentItem.status)"
								:text-color="getProformaChipTextColor(currentItem.status)"
							>
								{{ $t(`$proformaInvoice.$status.${currentItem.status}`) }}
							</v-chip>
						</v-col>
					</v-row>

					<v-divider class="my-3" />

					<v-subheader>{{ $t("shipment") }}</v-subheader>
					<v-row>
						<!-- Shipment Method -->
						<v-col cols="12" lg="6" class="pb-0">
							<shipment-method-select
								v-model="currentItem.shipmentMethod"
								:rules="rules.shipmentMethod"
								:disabled="!hasReceiver"
								@change="shipmentMethodChangeAction"
							/>
						</v-col>

						<!-- Shipping Term -->
						<v-col cols="12" lg="6" class="pb-0">
							<shipping-term-select
								v-model="currentItem.shippingTerm.code"
								:label="$t('shipping_term')"
								:rules="rules.shippingTerm"
								:clearable="true"
								:eager="true"
								:disabled="!hasReceiver"
								prepend-icon="icon-document"
								@change="shippingTermChangeAction"
							/>
						</v-col>
					</v-row>

					<v-row v-if="hasShipmentMethod">
						<!-- Loading Country -->
						<v-col cols="12" lg="6" class="pb-0">
							<country-select
								v-model="currentItem.loadingCountry.id"
								:options="countries"
								:label="$t('loading_country')"
								prepend-icon="icon-world"
								:rules="rules.loadingCountry"
								@change="loadingCountryChangeAction"
							/>
						</v-col>

						<!-- Loading Port / Loading Point -->
						<v-col cols="12" lg="6" class="pb-0">
							<port-select
								v-if="isSeaShipment"
								v-model="currentItem.loadingPort.code"
								:label="$t('loading_port')"
								:countryId="loadingCountryId"
								:disabled="!loadingCountryId"
								:eager="Boolean(loadingCountryId)"
								:rules="rules.loadingPort"
								prepend-icon="icon-load"
								@change="loadingPortChangeAction"
							/>
							<v-text-field
								v-else-if="hasShipmentMethod"
								v-model="currentItem.loadingPoint"
								:label="$t('loading_point')"
								:rules="rules.loadingPoint"
								prepend-icon="icon-load"
							/>
						</v-col>

						<!-- Unloading Country -->
						<v-col cols="12" lg="6" class="pb-0">
							<country-select
								v-model="currentItem.unloadingCountry.id"
								:disabled="!hasReceiver"
								:options="countries"
								:label="$t('unloading_country')"
								:rules="rules.loadingCountry"
								prepend-icon="icon-world"
								@change="unloadingCountryChangeAction"
							/>
						</v-col>

						<!-- Unloading Port / Unloading Point -->
						<v-col cols="12" lg="6" class="pb-0">
							<port-select
								v-if="isSeaShipment"
								v-model="currentItem.unloadingPort.code"
								:label="$t('unloading_port')"
								:countryId="unloadingCountryId"
								:disabled="!unloadingCountryId"
								:eager="Boolean(unloadingCountryId)"
								:rules="rules.unloadingPort"
								prepend-icon="icon-unload"
								@change="unloadingPortChangeAction"
							/>
							<v-text-field
								v-else-if="hasShipmentMethod"
								v-model="currentItem.unloadingPoint"
								:label="$t('unloading_point')"
								:rules="rules.unloadingPort"
								prepend-icon="icon-unload"
							/>
						</v-col>
					</v-row>
				</v-form>
			</v-container>
		</template>
	</ext-dialog>
</template>

<script>
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import { mapState, mapGetters } from "vuex";
import PartnerSelect from "../../../controls/PartnerSelect";
import PortSelect from "../../../controls/PortSelect";
import ShippingTermSelect from "../../../controls/ShippingTermSelect";
import CurrencySelect from "../../../controls/CurrencySelect";
import CountrySelect from "../../../controls/CountrySelect";
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect.vue";
import DatePickerMoment from "../../../controls/DatePickerMoment";
import moment from "moment";
import {
	getProformaChipColor,
	getProformaChipTextColor,
	iconHelper as iconHelperMixin,
} from "../../../mixins/utils.mixin";
import { partnerTypes } from "../../../../utils/enum";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	props: { value: Boolean },
	components: {
		ExtDialog,
		PartnerSelect,
		PortSelect,
		ShippingTermSelect,
		CurrencySelect,
		CountrySelect,
		ShipmentMethodSelect,
		DatePickerMoment,
	},
	mixins: [uiMixin, iconHelperMixin],
	data() {
		return {
			rules: {
				receiver: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("receiver") })],
				loadingCountry: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("loading_country"),
						}),
				],
				unloadingCountry: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_country"),
						}),
				],
				loadingPort: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_port") })],
				unloadingPort: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_port"),
						}),
				],
				loadingPoint: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_point") })],
				unloadingPoint: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_point"),
						}),
				],
				proformaNumber: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("proforma_number"),
							});
						} else if (v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("proforma_number"),
								length: "25",
							});
						}
						return true;
					},
				],
				date: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("date") })],
				currency: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("currency") })],
				shipmentMethod: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("shipment_method"),
						}),
				],
				shippingTerm: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("shipping_term") })],
			},
			currentItem: null,
			loadingCountryId: null,
			unloadingCountryId: null,
			valid: true,
		};
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company"]),
		partnerTypes() {
			return partnerTypes;
		},
		hasReceiver() {
			return Boolean(this.currentItem.receiver.referenceId);
		},
		hasShipmentMethod() {
			return Boolean(this.currentItem.shipmentMethod);
		},
		isSeaShipment() {
			return this.hasShipmentMethod ? this.currentItem.shipmentMethod == "sea" : false;
		},
		title() {
			return `${this.$t("new")} ${this.$t("proforma_invoice")}`
		},
	},
	watch: {
		value(val) {
			val ? this.useItemTemplate() : this.resetForm();
		},
	},

	methods: {
		handleProps() {
			if (this.itemId || this.item) this.loadItem(this.itemId || this.item.id);
			else {
				this.useItemTemplate();
				const country = this.countries.find((c) => c.id === this.company.countryId);
				this.loadingCountryId = country.id;
				this.currentItem.loadingCountry.id = country.id;
				this.currentItem.loadingCountry.name = country.name;
				this.currentItem.loadingCountry.code = country.code;
				this.editMode = true;
			}
		},
		getProformaChipColor,
		getProformaChipTextColor,
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
			this.menuItems = null;
			this.editMode = false;
			this.loadingCountryId = null;
			this.unloadingCountryId = null;
		},
		useItemTemplate() {
			this.currentItem = {
				status: "template",
				sender: {
					referenceId: this.company.id,
					name: this.company.name,
					countryId: this.company.countryId,
				},
				receiver: {
					referenceId: "",
					isAbstract: undefined,
					name: null,
					countryId: "",
				},
				date: moment(),
				no: "",
				loadingCountry: { id: null },
				loadingPort: { code: null },
				loadingPoint: null,
				unloadingCountry: { id: null },
				unloadingPort: { code: null },
				unloadingPoint: null,
				shippingTerm: { code: null },
				currency: { code: null },
			};
		},
		resetForm() {
			this.$refs.form.reset();
			this.currentItem = null;
			this.loadingCountryId = null;
			this.unloadingCountryId = null;
		},
		saveAction() {
			if (this.valid) {
				this.showProgressDialog();
				const onSuccess = (result) => {
					this.$addEvent("proforma_invoice");
					this.showSnackBar({
						message: this.$t("$message.created", {
							item: this.$t("proforma_invoice"),
						}),
					});
					this.resetForm();
					this.hideProgressDialog();
					this.$emit("saved", result);
				};
				const onFail = (_) => this.hideProgressDialog();

				remote.proformaInvoices.create(this.currentItem, {
					onSuccess,
					onFail,
				});
			} else {
				this.$refs.form.validate();
			}
		},
		closeAction() {
			this.$emit("input", false);
		},
		receiverChangeAction(val) {
			var isAbstract = val.type == this.partnerTypes.AbstractCompany;
			this.currentItem.receiver.referenceId = !isAbstract ? val.corporationId : val.id;
			this.currentItem.receiver.name = val.name;
			this.currentItem.receiver.countryId = val.countryId;
			this.currentItem.receiver.isAbstract = isAbstract;
			const country = val ? this.countries.find((i) => i.id === val.countryId) : null;
			this.unloadingCountryId = country.id || undefined;
			this.currentItem.unloadingCountry.id = country.id || undefined;
			this.currentItem.unloadingCountry.code = country.code || undefined;
			this.currentItem.unloadingCountry.name = country.name || undefined;
		},
		dateChangeAction(val) {
			this.currentItem.date = val;
		},
		shipmentMethodChangeAction() {
			this.currentItem.loadingPort = { code: null };
			this.currentItem.loadingPoint = null;
			this.currentItem.unloadingPort = { code: null };
			this.currentItem.unloadingPoint = null;
		},
		loadingCountryChangeAction(val) {
			this.loadingCountryId = val.id;
			this.currentItem.loadingCountry = {
				id: val.id,
				name: val.name,
				code: val.code,
			};
			if (this.isSeaShipment) {
				this.currentItem.loadingPort.code = null;
				this.currentItem.loadingPort.name = "";
				this.currentItem.loadingPort.portId = null;
				this.currentItem.loadingPort.type = null;
			} else {
				this.currentItem.loadingCountryId = val.id;
				this.currentItem.loadingPoint = null;
			}
		},
		loadingPortChangeAction(val) {
			this.currentItem.loadingPort.portId = val?.id;
			this.currentItem.loadingPort.name = val?.name;
			this.currentItem.loadingPort.code = val?.code;
			this.currentItem.loadingPort.type = val?.type;
		},
		unloadingCountryChangeAction(val) {
			this.unloadingCountryId = val.id;
			this.currentItem.unloadingCountry = {
				id: val.id,
				name: val.name,
				code: val.code,
			};
			if (this.isSeaShipment) {
				this.currentItem.unloadingPort.code = null;
				this.currentItem.unloadingPort.name = "";
				this.currentItem.unloadingPort.portId = null;
				this.currentItem.unloadingPort.type = null;
			} else {
				this.currentItem.unloadingCountryId = val.id;
				this.currentItem.unloadingPoint = null;
			}
		},
		unloadingPortChangeAction(val) {
			this.currentItem.unloadingPort.portId = val?.id;
			this.currentItem.unloadingPort.name = val?.name;
			this.currentItem.unloadingPort.code = val?.code;
			this.currentItem.unloadingPort.type = val?.type;
		},
		shippingTermChangeAction(val) {
			this.currentItem.shippingTerm.name = val?.name ?? null;
		},
		currencyChangeAction(val) {
			this.currentItem.currency.name = val.name;
			this.currentItem.currency.symbol = val.symbol;
		},
	},
	created() {
		// if (this.itemId || this.item) this.handleProps();
	},
};
</script>
