<template>
	<v-autocomplete
		v-model="currentValue"
		:items="currentOptions"
		:item-text="(i) => `${i.name} (${i.code})`"
		item-value="code"
		outlined
		:label="label || $t('port')"
		:disabled="!ready || disabled"
		:loading="!ready && !disabled"
		:clearable="clearable"
		clear-icon="icon-cross"
		append-icon="icon-angle-down"
		:rules="rules"
		:prepend-icon="prependIcon"
		@change="changeAction"
	/>
</template>

<script>
import remote from "../../data/remote";
import { mapState } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
	props: {
		value: String,
		options: Array,
		countryId: String,
		eager: Boolean,
		label: String,
		clearable: Boolean,
		disabled: Boolean,
		rules: Array,
		prependIcon: String,
	},
	data() {
		return {
			currentValue: null,
			currentOptions: [],
			ready: false,
		};
	},
	computed: {
		...mapState(["countries"]),
	},
	watch: {
		currentValue(val) {
			this.$emit("input", val);
		},
		value(val) {
			this.currentValue = val || null;
		},
		options(val) {
			this.handleProps();
		},
		countryId(val) {
			this.handleProps();
		},
	},
	methods: {
		handleProps() {
			if (this.options) {
				this.handleOptions(this.options);
				this.ready = true;
			} else if (this.eager) this.loadOptions();
			// else if (this.currentOptions && this.currentOptions.length) {
			// 	this.currentOptions = [];
			// 	this.ready = false;
			// }
		},
		handleOptions(options) {
			this.currentOptions = options;
		},
		loadOptions() {
			if (this.countryId) {
				let options = cloneDeep((this.currentOptions = this.countries.find((i) => i.id === this.countryId).ports));
				this.handleOptions(options);
				this.ready = true;
			} else {
				remote.structures.getCountries({
					onSuccess: (countries) => {
						let options = [];
						for (const country of countries.items) for (const port of country.ports) options.push(port);
						this.handleOptions(options);
						this.ready = true;
					},
				});
			}
		},
		changeAction(code) {
			this.$emit(
				"change",
				this.currentOptions.find((i) => i.code === code)
			);
		},
	},
	created() {
		this.currentValue = this.value || null;
		this.handleProps();
	},
};
</script>
