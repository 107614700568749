<template>
	<page-template>
		<template slot="content" v-if="currentItem">
			<v-container>
				<v-row class="d-flex align-center justify-space-between flex-wrap px-4" no-gutters>
					<v-col cols="auto">
						<v-btn text outlined @click="backAction">
							<v-icon left>icon-angle-left</v-icon>
							{{ $t("proforma_invoices") }}
						</v-btn>
					</v-col>

					<v-col
						v-if="menuItems && menuItems.length && !editMode"
						cols="auto"
						class="d-flex align-center justify-end flex-wrap"
						:class="{
							'pt-2': $vuetify.breakpoint.mdAndDown,
						}"
					>
						<v-btn
							v-for="(item, index) in menuItems"
							:key="index"
							outlined
							text
							@click="menuItemAction(item.key)"
							class="mx-1"
						>
							<v-icon :color="item.color">{{ item.icon }}</v-icon>
							<span class="ml-1 v-typography--body-sm">{{ item.title }}</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col sm="12" lg="7">
						<proforma-invoice-details
							:currentItem="currentItem"
							:iAmSender="iAmSender"
							:iAmReceiver="iAmReceiver"
							:editMode="editMode"
							@start-edit="editMode = true"
							@cancel-edit="cancelAction"
							@save="saveActionFromDetails"
						/>
						<proforma-invoice-action-timeline sm="12" lg="7" v-model="currentItem" v-if="!isMobile" />
					</v-col>
					<v-col sm="12" lg="5">
						<proforma-invoice-products :currentItem="currentItem" @reloadCurrentItem="loadItem(currentItem.id)" />

						<proforma-invoice-attachments :proformaInvoice="currentItem" @changed="attachmentListChanged" />
						<proforma-invoice-action-timeline sm="12" lg="7" v-model="currentItem" v-if="isMobile" />
					</v-col>
				</v-row>
			</v-container>
		</template>
	</page-template>
</template>

<script>
import remote from "../../../data/remote";
import uiMixin from "../../../store/interfaces/ui.mixin";
import { mapState, mapGetters } from "vuex";
import TitleValueListItem from "../../partials/TitleValueListItem.vue";
import PartnerSelect from "../../controls/PartnerSelect.vue";
import PortSelect from "../../controls/PortSelect.vue";
import ShippingTermSelect from "../../controls/ShippingTermSelect";
import CurrencySelect from "../../controls/CurrencySelect";
import CountrySelect from "../../controls/CountrySelect";
import ShipmentMethodSelect from "../../controls/ShipmentMethodSelect.vue";
import ProformaInvoiceActionTimeline from "./partial/ProformaInvoiceActionTimeline.vue";
import ProformaInvoiceAttachments from "./partial/ProformaInvoiceAttachments.vue";
import DatePickerMoment from "../../controls/DatePickerMoment.vue";
import moment from "moment";
import { iconHelper as iconHelperMixin } from "../../mixins/utils.mixin";
import { partnerTypes } from "../../../utils/enum";
import PageTemplate from "../../templates/PageTemplate.vue";
import ProformaInvoiceDetails from "./partial/ProformaInvoiceDetails.vue";
import ProformaInvoiceProducts from "./partial/ProformaInvoiceProducts.vue";

export default {
	name: "ProformaInvoice",
	components: {
		PageTemplate,
		TitleValueListItem,
		PartnerSelect,
		PortSelect,
		ShippingTermSelect,
		CurrencySelect,
		CountrySelect,
		ShipmentMethodSelect,
		ProformaInvoiceActionTimeline,
		ProformaInvoiceAttachments,
		DatePickerMoment,
		ProformaInvoiceDetails,
		ProformaInvoiceProducts,
	},
	mixins: [uiMixin, iconHelperMixin],
	data() {
		return {
			rules: {
				receiver: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("receiver") })],
				loadingCountry: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("loading_country"),
						}),
				],
				unloadingCountry: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_country"),
						}),
				],
				loadingPort: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_port") })],
				unloadingPort: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_port"),
						}),
				],
				loadingPoint: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_point") })],
				unloadingPoint: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("unloading_point") })],
				proformaNumber: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("proforma_number"),
							});
						} else if (v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("proforma_number"),
								length: "25",
							});
						}
						return true;
					},
				],
				date: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("date") })],
				currency: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("currency") })],
				shipmentMethod: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("shipment_method"),
						}),
				],
				shippingTerm: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("shipping_term") })],
			},
			menuItems: [],
			editMode: false,
			currentItem: null,
			loadingCountryId: null,
			unloadingCountryId: null,
			valid: true,
		};
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company"]),
		partnerTypes: () => partnerTypes,
		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
		iAmSender() {
			return this.currentItem && (!this.currentItem.sender || this.company.id === this.currentItem.sender.referenceId);
		},
		iAmReceiver() {
			return this.currentItem && this.company.id === this.currentItem.receiver.referenceId;
		},
		hasReceiver() {
			return Boolean(this.currentItem.receiver.referenceId);
		},
		hasShipmentMethod() {
			return Boolean(this.currentItem.shipmentMethod);
		},
		isSeaShipment() {
			return this.hasShipmentMethod ? this.currentItem.shipmentMethod == "sea" : false;
		},
		currentItemLoadingCountryName() {
			if (this.isSeaShipment) {
				return this.currentItem.loadingCountry
					? `${this.$t(`$country.${this.currentItem.loadingCountry.name}`)} (${this.currentItem.loadingCountry.code})`
					: null;
			} else if (this.hasShipmentMethod) {
				if (this.currentItem.loadingCountry.id) {
					let country = this.countries.find((c) => c.id == this.currentItem.loadingCountry.id);
					return country ? `${this.$t(`$country.${country.name}`)} (${country.code})` : null;
				}
			}
			return undefined;
		},
		currentItemUnloadingCountryName() {
			if (this.isSeaShipment) {
				return this.currentItem.unloadingCountry
					? `${this.$t(`$country.${this.currentItem.unloadingCountry.name}`)} (${
							this.currentItem.unloadingCountry.code
					  })`
					: null;
			} else if (this.hasShipmentMethod) {
				if (this.currentItem.unloadingCountryId) {
					let country = this.countries.find((c) => c.id == this.currentItem.unloadingCountryId);
					return country ? `${this.$t(`$country.${country.name}`)} (${country.code})` : null;
				}
			}
			return undefined;
		},

		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
	},
	watch: {
		currentItem(val) {
			if (val && val.id) {
				this.prepareMenu();
			}
		},
	},
	methods: {
		loadItem(id) {
			this.showProgressDialog();
			remote.proformaInvoices.find(id, {
				onSuccess: (result) => {
					this.handleItem(result);
					this.hideProgressDialog();
				},
				onFail: (_) => this.hideProgressDialog(),
			});
		},
		handleItem(item) {
			this.currentItem = item;
			let isLoadingCountryFound = null;
			let isUnloadingCountryFound = null;
			if (this.isSeaShipment && !this.currentItem.shippingTerm) this.currentItem.shippingTerm = { code: "", name: "" };
			for (const country of this.countries) {
				if (
					!isLoadingCountryFound &&
					this.isSeaShipment &&
					country.ports.find((i) => i.code === item.loadingPort.code)
				) {
					isLoadingCountryFound = true;
					this.loadingCountryId = country.id;
					this.currentItem.loadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				} else if (country.id === item.loadingCountryId) {
					isLoadingCountryFound = true;
					this.loadingCountryId = country.id;
					this.currentItem.loadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				}
				if (
					!isUnloadingCountryFound &&
					this.isSeaShipment &&
					country.ports.find((i) => i.code === item.unloadingPort.code)
				) {
					isUnloadingCountryFound = true;
					this.unloadingCountryId = country.id;
					this.currentItem.unloadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				} else if (country.id === item.unloadingCountryId) {
					isLoadingCountryFound = true;
					this.unloadingCountryId = country.id;
					this.currentItem.unloadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				}
				if (isLoadingCountryFound && isUnloadingCountryFound) break;
			}
		},
		prepareMenu() {
			const ria = this.currentItem.receiver.isAbstract || false;
			this.menuItems = [];
			const send = {
				key: "send",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_sent" : "send"}`),
				icon: "icon-send",
				color: ria ? undefined : "info",
			};
			const delete_ = {
				key: "delete",
				title: this.$t("delete"),
				icon: "icon-delete",
				color: "error",
			};
			const revoke = {
				key: "revoke",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_revoked" : "revoke"}`),
				icon: "icon-undo",
				color: "error",
			};
			const markAsProcessed = {
				key: "mark_as_processed",
				title: this.$t("$proformaInvoice.$actions.mark_as_processed"),
				icon: "icon-folder",
			};
			const approve = {
				key: "approve",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_approved" : "approve"}`),
				icon: "icon-file-add",
				color: ria ? undefined : "info",
			};
			const demandRevise = {
				key: "demand_revise",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_revise_demanded" : "demand_revise"}`),
				icon: "icon-edit",
			};
			const reject = {
				key: "reject",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_rejected" : "reject"}`),
				icon: "icon-send",
				color: ria ? "warning" : "error",
			};

			if (this.iAmSender) {
				switch (this.currentItem.status) {
					case "template":
						this.menuItems.push(send, delete_);
						break;
					case "pending_approval":
						this.menuItems.push(revoke);
						break;
					case "pending_revise":
						this.menuItems.push(send, revoke);
						break;
					case "approved":
						this.menuItems.push(markAsProcessed);
						break;
					default:
						break;
				}
			}
			if (this.iAmReceiver || this.currentItem.receiver.isAbstract) {
				switch (this.currentItem.status) {
					case "pending_approval":
						this.menuItems.push(approve, demandRevise, reject);
						break;
					default:
						break;
				}
			}
		},
		saveActionFromDetails(editItem) {
			this.currentItem = editItem;
			this.saveAction();
		},
		saveAction() {
			if (this.valid) {
				this.showProgressDialog();
				const onSuccess = (result) => {
					this.showSnackBar({
						message: this.$t(this.currentItem.id ? "$message.updated" : "$message.created", {
							item: this.$t("proforma_invoice"),
						}),
					});
					this.handleItem(result);
					this.editMode = false;
					this.hideProgressDialog();
				};
				const onFail = (_) => this.hideProgressDialog();
				if (this.currentItem.id)
					remote.proformaInvoices.update(this.currentItem, {
						onSuccess,
						onFail,
					});
				else
					remote.proformaInvoices.create(this.currentItem, {
						onSuccess,
						onFail,
					});
			} else this.$refs.form.validate();
		},
		cancelAction() {
			this.editMode = false;
			if (this.currentItem.id) {
				this.loadItem(this.currentItem.id);
			} else {
				this.$router.go(-1);
			}
		},
		backAction() {
			this.$router.go(-1);
		},
		attachmentListChanged() {
			this.loadItem(this.currentItem.id);
		},
		useItemTemplate() {
			this.currentItem = {
				status: "template",
				sender: {
					referenceId: this.company.id,
					name: this.company.name,
					countryId: this.company.countryId,
				},
				receiver: {
					referenceId: "",
					isAbstract: undefined,
					name: null,
					countryId: "",
				},
				date: moment(),
				no: "",
				loadingCountry: { id: null },
				loadingPort: { code: null },
				loadingPoint: null,
				unloadingCountry: { id: null },
				unloadingPort: { code: null },
				unloadingPoint: null,
				shippingTerm: { code: null },
				currency: { code: null },
			};
		},
		menuItemAction(key) {
			const ria = this.currentItem.receiver.isAbstract || false;
			const onFail = (_) => this.hideProgressDialog();
			switch (key) {
				case "delete":
					this.showConfirmDialog({
						message: this.$t("$message.confirm", {
							function: this.$t("toDelete"),
						}),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.delete(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$message.deleted", {
											item: this.$t("proforma_invoice"),
										}),
									});
									this.hideProgressDialog();
									this.$router.push({ name: "proformaInvoices" });
								},
								onFail,
							});
						},
					});
					break;
				case "send":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.send_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.send(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.send_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
								},
								onFail,
							});
						},
					});
					break;
				case "approve":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.approve_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.approve(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.approve_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
								},
								onFail,
							});
						},
					});
					break;
				case "demand_revise":
					this.showTextInputDialog({
						title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_revise_demanded" : "demand_revise"}`),
						message: ria ? undefined : this.$t("$proformaInvoice.demand_revise_description_message"),
						inputTitle: this.$t("description"),
						useTextArea: true,
						onSubmit: (text) => {
							return new Promise((resolve, reject) => {
								this.showProgressDialog();
								remote.proformaInvoices.demandRevise(this.currentItem.id, {
									message: text,
									onSuccess: (result) => {
										this.showSnackBar({
											message: this.$t("$proformaInvoice.demand_revise_success_message"),
										});
										this.hideProgressDialog();
										this.handleItem(result);
										return resolve(true);
									},
									onFail: (e) => {
										this.hideProgressDialog();
										reject(e);
									},
								});
							});
						},
					});
					break;
				case "reject":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.reject_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.reject(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.reject_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
								},
								onFail,
							});
						},
					});
					break;
				case "mark_as_processed":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.mark_as_processed_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.markAsProcessed(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.mark_as_processed_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
								},
								onFail,
							});
						},
					});
					break;
				case "revoke":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.revoke_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.revoke(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.revoke_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
								},
								onFail,
							});
						},
					});
					break;
				default:
					break;
			}
		},
	},
	created() {
		const id = this.$route.params.id;
		if (id) {
			this.loadItem(id);
		} else {
			this.useItemTemplate();
			this.editMode = true;
		}
	},
};
</script>
