<template>
	<ext-dialog
		v-if="shipment"
		v-model="value"
		dialogType="large"
		:title="$t('new_shipment')"
		:primaryButtonText="stepper === 1 ? $t('next') : $t('save')"
		:secondaryButtonText="stepper > 1 ? $t('previous') : null"
		@primaryButtonAction="nextAction"
		@secondaryButtonAction="prevAction"
		@closed="closeAction"
	>
		<template slot="body">
			<v-stepper v-model="stepper" elevation="0">
				<v-stepper-header>
					<v-stepper-step :complete="stepper > 1" step="1">
						{{ $t("initial") }}
					</v-stepper-step>
					<v-divider />
					<v-stepper-step :complete="stepper > 2" step="2">
						{{ $t("booking") }}
					</v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1" class="pt-2">
						<v-form v-model="valid1" ref="form1">
							<v-row>
								<v-col class="pb-0 px-5">
									<label class="d-block">{{ $t("shipment_type") }}</label>
									<v-radio-group v-model="myRole" :rules="rules.type" row @change="handleParams" class="mt-1">
										<v-radio
											:label="$t('outgoing')"
											value="sender"
											on-icon="icon-radio-on"
											off-icon="icon-radio-off"
										></v-radio>
										<v-radio
											:label="$t('incoming')"
											value="receiver"
											on-icon="icon-radio-on"
											off-icon="icon-radio-off"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6" class="pt-0">
									<shipment-method-select v-model="shipment.method" :label="$t('method')" :rules="rules.method" />
								</v-col>
								<v-col cols="6" class="pt-0">
									<currency-select
										v-model="shipment.currency.code"
										:eager="true"
										:label="$t('currency')"
										:rules="rules.currency"
										prepend-icon="icon-money"
										@change="currencyChangeAction"
									/>
								</v-col>
							</v-row>
						</v-form>
					</v-stepper-content>

					<v-stepper-content step="2" class="pt-2">
						<v-form v-model="valid2" ref="form2">
							<v-row class="mt-1">
								<v-col>
									<v-text-field
										v-model="shipment.bookingCode"
										:label="$t('code')"
										prepend-icon="icon-document"
										:rules="rules.bookingCode"
									/>
								</v-col>
								<v-col>
									<shipping-term-select
										v-model="shipment.shippingTerm.code"
										:label="$t('shipping_term')"
										:clearable="true"
										:eager="true"
										:rules="rules.shippingTerm"
										prepend-icon="icon-shuffle"
										@change="shippingTermChangeAction"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<partner-select
										v-model="partnerId"
										:label="$t(iAmReceiver ? 'sender' : 'receiver')"
										:rules="rules.partner"
										:eager="true"
										:types="[partnerTypes.Company, partnerTypes.AbstractCompany]"
										prepend-icon="icon-company"
										@change="partnerChangeAction"
									/>
								</v-col>
								<v-col>
									<partner-select
										v-model="customsAgencyId"
										:label="$t('customs_agency')"
										:eager="true"
										:clearable="true"
										:types="[partnerTypes.CustomsAgency]"
										prepend-icon="icon-company"
										@change="customsPartnerChangeAction"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<country-select
										v-model="shipment.loadingCountry.id"
										:options="countries"
										:label="$t('loading_country')"
										:rules="rules.loadingCountry"
										prepend-icon="icon-world"
										@change="loadingCountryChangeAction"
									/>
								</v-col>
								<v-col>
									<port-select
										v-model="shipment.loadingPort.code"
										v-if="isSeaShipment"
										:label="$t('loading_port')"
										:countryId="shipment.loadingCountry.id"
										:disabled="!shipment.loadingCountry.id"
										:eager="Boolean(shipment.loadingCountry.id)"
										:rules="rules.loadingPort"
										prepend-icon="icon-load"
										@change="loadingPortChangeAction"
									/>
									<v-text-field
										v-model="shipment.loadingPoint"
										v-if="!isSeaShipment"
										:label="$t('loading_point')"
										:rules="rules.loadingPoint"
										prepend-icon="icon-load"
									/>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<country-select
										v-model="shipment.unloadingCountry.id"
										:options="countries"
										:label="$t('unloading_country')"
										:rules="rules.unloadingCountry"
										prepend-icon="icon-world"
										@change="unloadingCountryChangeAction"
									/>
								</v-col>
								<v-col>
									<port-select
										v-model="shipment.unloadingPort.code"
										v-if="isSeaShipment"
										:label="$t('unloading_port')"
										:countryId="shipment.unloadingCountry.id"
										:disabled="!shipment.unloadingCountry.id"
										:eager="Boolean(shipment.unloadingCountry.id)"
										:rules="rules.unloadingPort"
										prepend-icon="icon-unload"
										@change="unloadingPortChangeAction"
									/>
									<v-text-field
										v-model="shipment.unloadingPoint"
										v-if="!isSeaShipment"
										:label="$t('unloading_point')"
										:rules="rules.unloadingPoint"
										prepend-icon="icon-unload"
									/>
								</v-col>
							</v-row>
						</v-form>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import PartnerSelect from "../../../controls/PartnerSelect";
import CountrySelect from "../../../controls/CountrySelect";
import ShippingTermSelect from "../../../controls/ShippingTermSelect";
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect.vue";
import PortSelect from "../../../controls/PortSelect";
import CurrencySelect from "../../../controls/CurrencySelect";
import { mapState, mapGetters } from "vuex";
import { partnerTypes } from "../../../../utils/enum";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	props: { value: Boolean },
	mixins: [uiMixin],
	components: {
		PartnerSelect,
		CountrySelect,
		ShippingTermSelect,
		ShipmentMethodSelect,
		PortSelect,
		CurrencySelect,
		ExtDialog,
	},
	data() {
		return {
			stepper: 1,
			shipment: null,
			partnerId: null,
			customsAgencyId: null,
			valid1: null,
			valid2: null,
			saving: false,
			myRole: null,
			rules: {
				partner: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t(this.iAmSender ? "receiver" : "sender"),
						}),
				],
				type: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("shipment_type"),
						}),
				],
				method: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("method") })],
				currency: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("currency") })],
				shippingTerm: [
					(v) =>
						!this.isLandShipment || Boolean(v) || this.$t("$validation.required", { item: this.$t("shipping_term") }),
				],
				loadingCountry: [
					(v) =>
						!this.shipment.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("loading_country") }),
				],
				loadingPort: [
					(v) =>
						!this.isSeaShipment ||
						!this.shipment.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("loading_port") }),
				],
				loadingPoint: [
					(v) => {
						if (Boolean(this.isSeaShipment) || !this.shipment.bookingCode || Boolean(v)) {
							if (v && v.length > 100) {
								return this.$t("$validation.max_length", {
									item: this.$t("loading_point"),
									length: "100",
								});
							}
						} else {
							return this.$t("$validation.required", {
								item: this.$t("loading_point"),
							});
						}
						return true;
					},
				],

				unloadingCountry: [
					(v) =>
						!this.shipment.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("unloading_country") }),
				],
				unloadingPort: [
					(v) =>
						!this.isSeaShipment ||
						!this.shipment.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_port"),
						}),
				],
				unloadingPoint: [
					(v) => {
						if (!Boolean(this.isSeaShipment) && this.shipment.bookingCode && !Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("unloading_point"),
							});
						} else if (v && v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("unloading_point"),
								length: "100",
							});
						}
						return true;
					},
				],

				bookingCode: [
					(v) => {
						if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("booking_code"),
								length: "50",
							});
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company"]),
		partnerTypes: () => partnerTypes,
		iAmSender() {
			return this.myRole === "sender";
		},
		iAmReceiver() {
			return this.myRole === "receiver";
		},
		isSeaShipment() {
			return this.shipment.method === "sea";
		},
		isLandShipment() {
			return this.shipment.method === "land";
		},
	},
	watch: {
		value(val) {
			if (val) this.handleParams();
			else this.reset();
		},
	},
	methods: {
		handleParams() {
			this.shipment = {
				sender: {
					company: {
						referenceId: this.iAmSender ? this.company.id : null,
						name: this.iAmSender ? this.company.name : "",
						countryId: this.iAmSender ? this.company.countryId : "",
					},
				},
				receiver: {
					company: {
						referenceId: this.iAmReceiver ? this.company.id : null,
						name: this.iAmReceiver ? this.company.name : "",
						countryId: this.iAmReceiver ? this.company.countryId : "",
					},
				},
				currency: { code: null },
				bookingCode: null,
				loadingCountry: { id: null },
				loadingPort: { code: null },
				loadingPoint: null,
				unloadingCountry: { id: null },
				unloadingPort: { code: null },
				unloadingPoint: null,
				shippingTerm: { code: null },
			};
			this.shipment.loadingCountry.id = this.iAmSender ? this.company.countryId : null;
			this.shipment.unloadingCountry.id = this.iAmReceiver ? this.company.countryId : null;
		},
		reset() {
			this.shipment = null;
			this.stepper = 1;
			this.myRole = null;
		},
		closeAction() {
			this.$emit("input", false);
		},
		nextAction() {
			if (this.stepper == 1) {
				if (this.valid1) this.stepper++;
				else this.$refs.form1.validate();
			} else if (this.stepper == 2) {
				if (this.valid2) this.save();
				else this.$refs.form2.validate();
			}
		},
		prevAction() {
			this.stepper--;
		},
		save() {
			this.saving = true;
			remote.shipments.create(this.shipment, {
				onSuccess: (result) => {
					this.saving = false;
					this.$addEvent("shipment");
					this.$emit("saved", result);
				},
				onFail: (_) => (this.saving = false),
			});
		},
		partnerChangeAction(val) {
			if (this.iAmSender) {
				if (val.type == this.partnerTypes.AbstractCompany) {
					this.shipment.receiver.company.referenceId = val.id;
					this.shipment.receiver.company.isAbstract = true;
				} else if (val.type == this.partnerTypes.Company) {
					this.shipment.receiver.company.referenceId = val.corporationId;
					this.shipment.receiver.company.isAbstract = undefined;
				}
				this.shipment.receiver.company.name = val.name;
				this.shipment.receiver.company.countryId = val.countryId;
				this.shipment.unloadingCountry.id = val.countryId;
			} else {
				if (val.type == this.partnerTypes.AbstractCompany) {
					this.shipment.sender.company.referenceId = val.id;
					this.shipment.sender.company.isAbstract = true;
				} else if (val.type == this.partnerTypes.Company) {
					this.shipment.sender.company.referenceId = val.corporationId;
					this.shipment.sender.company.isAbstract = undefined;
				}
				this.shipment.sender.company.name = val.name;
				this.shipment.sender.company.countryId = val.countryId;
				this.shipment.loadingCountry.id = val.countryId;
			}
		},
		customsPartnerChangeAction(val) {
			if (this.iAmSender)
				this.shipment.sender.customsAgency = val
					? {
							customsAgencyId: val.corporationId,
							partnerId: val.id,
							name: val.name,
					  }
					: null;
			else if (this.iAmReceiver)
				this.shipment.receiver.customsAgency = val
					? {
							customsAgencyId: val.corporationId,
							partnerId: val.id,
							name: val.name,
					  }
					: null;
		},
		shippingTermChangeAction(val) {
			this.shipment.shippingTerm.name = val?.name ?? null;
		},
		currencyChangeAction(val) {
			this.shipment.currency.name = val.name;
			this.shipment.currency.symbol = val.symbol;
		},
		loadingCountryChangeAction(_val) {
			this.shipment.loadingPort.code = null;
		},
		unloadingCountryChangeAction(_val) {
			this.shipment.unloadingPort.code = null;
		},
		loadingPortChangeAction(val) {
			this.shipment.loadingPort.portId = val.id;
			this.shipment.loadingPort.name = val.name;
			this.shipment.loadingPort.type = val.type;
		},
		unloadingPortChangeAction(val) {
			this.shipment.unloadingPort.portId = val.id;
			this.shipment.unloadingPort.name = val.name;
			this.shipment.unloadingPort.type = val.type;
		},
	},
	mounted() {
		if (!this.shipment) this.handleParams();
	},
};
</script>

<style scoped>
.v-stepper__header {
	box-shadow: none !important;
}
.v-stepper__content {
	padding: 0 !important;
}
.v-stepper__step:first-of-type {
	padding-left: 0 !important;
}

.v-stepper__step:last-of-type {
	padding-right: 0 !important;
}
.a {
	overflow: visible !important;
}
</style>
