<template>
	<ext-dialog
		v-model="value"
		:title="$t('attachment')"
		:primaryButtonText="$t('save')"
		:dialogType="'large'"
		@primaryButtonAction="saveAction"
		@closed="cancelAction"
	>
		<template v-slot:body>
			<div v-if="currentItem">
				<v-form ref="form" v-model="valid">
					<v-file-input
						v-if="!editMode"
						v-model="localFile"
						:label="$t('select_file')"
						outlined
						:show-size="currentItem.id ? false : true"
						truncate-length="32"
						:disabled="hasAnyProcess"
						:loading="uploading"
						:rules="rules.file"
						@change="localFileChangeAction"
						prepend-icon=""
					>
						<template v-slot:selection="{ text }">
							{{ text }}
						</template>
					</v-file-input>

					<v-row>
						<v-col cols="12" md="6">
							<v-text-field v-model="currentItem.name" :label="$t('name')" :rules="rules.name" />
						</v-col>
						<v-col cols="12" md="6">
							<v-select
								v-model="currentItem.accessModifier"
								outlined
								:items="accessModifierOptions"
								:label="$t('visibility')"
								:rules="rules.accessibility"
								:disabled="hasAnyProcess"
								append-icon="icon-angle-down"
								:menu-props="{ offsetY: true }"
							/>
						</v-col>
					</v-row>

					<v-textarea v-model="currentItem.note" :label="$t('note')" :disabled="hasAnyProcess" rows="2" />
				</v-form>
			</div>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import { mapState } from "vuex";
import cloneDeep from "lodash.clonedeep";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	components: { ExtDialog },
	props: {
		value: Boolean,
		item: Object,
		shipmentId: String,
		myRole: "sender" | "receiver" | "sender_customs_agency" | "receiver_customs_agency",
	},
	mixins: [uiMixin],
	data() {
		return {
			currentItem: null,
			localFile: null,
			valid: null,
			saving: false,
			deleting: false,
			uploading: false,
			downloading: false,
			accessModifierOptions: [],
			rules: {
				file: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("file") }),
					(v) => !v || v.size <= 5242880 || this.$t("$message.file_oversize", { size: "5MB" }),
				],
				name: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("name") })],
				accessibility: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("accessibility") })],
			},
		};
	},
	computed: {
		...mapState(["corporation"]),
		editMode() {
			return Boolean(this.currentItem.id);
		},
		iAmSenderCompany() {
			return this.myRole == "sender";
		},
		iAmReceiverCompany() {
			return this.myRole == "receiver";
		},
		iAmSenderCustomsAgency() {
			return this.myRole == "sender_customs_agency";
		},
		iAmReceiverCustomsAgency() {
			return this.myRole == "receiver_customs_agency";
		},
		iAmAnySender() {
			return this.iAmSenderCompany || this.iAmSenderCustomsAgency;
		},
		iAmAnyReceiver() {
			return this.iAmReceiverCompany || this.iAmReceiverCustomsAgency;
		},
		hasAnyProcess() {
			return this.saving || this.deleting || this.uploading || this.downloading;
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	mounted() {
		if (!this.currentItem) this.handleProps();
	},
	methods: {
		handleProps() {
			this.currentItem = this.item
				? cloneDeep(this.item)
				: {
						file: {
							name: null,
							type: null,
						},
						note: null,
						accessModifier: null,
				  };
			if (this.iAmSenderCompany) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.private"),
						value: "sender_private",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_custom"),
						value: "sender_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_receiver"),
						value: "sender_receiver",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "sender_private";
			} else if (this.iAmReceiverCompany) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.private"),
						value: "receiver_private",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_custom"),
						value: "receiver_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_sender"),
						value: "sender_receiver",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "receiver_private";
			} else if (this.iAmSenderCustomsAgency) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.hidden_from_receiver"),
						value: "sender_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "sender_protected";
			} else if (this.iAmReceiverCustomsAgency) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.hidden_from_sender"),
						value: "receiver_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "receiver_protected";
			}
		},
		reset() {
			this.currentItem = null;
			this.localFile = null;
			this.accessModifierOptions = [];
		},
		localFileChangeAction(file) {
			this.currentItem.name = file.name.split(".")[0];
		},
		cancelAction() {
			this.reset();
			this.$emit("input", false);
			this.$emit("closed");
		},
		saveAction() {
			if (this.valid) {
				if (this.editMode) {
					this.saving = true;
					remote.shipments.attachments.update(this.currentItem, this.shipmentId, {
						onSuccess: (_result) => {
							this.saving = false;
							this.reset();
							this.$emit("saved");
						},
						onFail: (_) => (this.saving = false),
					});
				} else {
					this.uploading = true;
					remote.files.send(this.localFile, this.corporation.id, {
						onSuccess: (response) => {
							this.uploading = false;
							this.currentItem.file = response;
							this.saving = true;
							remote.shipments.attachments.create(this.currentItem, this.shipmentId, {
								onSuccess: (_result) => {
									this.saving = false;
									this.reset();
									this.$emit("saved");
								},
								onFail: (_) => (this.saving = false),
							});
						},
						onFail: (_) => (this.uploading = false),
					});
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("$message.confirm", { function: this.$t("toDelete") }),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.attachments.delete(this.currentItem.id, this.shipmentId, {
						onSuccess: () => {
							this.deleting = false;
							this.$emit("deleted");
						},
						onFail: () => {
							this.deleting = false;
						},
					});
				},
			});
		},
	},
};
</script>
