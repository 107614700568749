export default {
	$accessibility: {
		archived: "Lưu trữ",
		present: "Hiện tại",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Ẩn khỏi người nhận",
			hidden_from_sender: "Ẩn khỏi người gửi",
			private: "Riêng tư",
			shared_with_custom: "Chia sẻ tùy chỉnh",
			shared_with_everyone: "Chia sẻ với mọi người",
			shared_with_receiver: "Chia sẻ với người nhận",
			shared_with_sender: "Chia sẻ với người gửi",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB Dung lượng",
		limitMBStorage: "{limit}MB Dung lượng",
		limitUser: "{limit} Người dùng",
		limitUsers: "{limit} Người dùng",
		renewAtPricePerUnit: "Gia hạn với giá {currency}{price} cho mỗi {unit}",
		unlimitedModule: "Không giới hạn {module}",
		upToLimitModule: "Tối đa {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURKEY",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1svi!2str!4v1701805110461!5m2!1svi!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"Thúc đẩy doanh nghiệp của bạn với Antrego. Quản lý quy trình giao hàng của bạn một cách kỹ thuật số, an toàn và dễ dàng.",
	},
	$country: {
		afghanistan: "Afghanistan",
		aland: "Åland",
		albania: "Albania",
		algeria: "Algeria",
		american_samoa: "Samoa thuộc Mỹ",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Nam Cực",
		antigua_and_barbuda: "Antigua và Barbuda",
		argentina: "Argentina",
		armenia: "Armenia",
		aruba: "Aruba",
		australia: "Úc",
		austria: "Áo",
		azerbaijan: "Azerbaijan",
		bahamas: "Bahamas",
		bahrain: "Bahrain",
		bangladesh: "Bangladesh",
		barbados: "Barbados",
		belarus: "Belarus",
		belgium: "Bỉ",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermuda",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius và Saba",
		bosnia_and_herzegovina: "Bosnia và Herzegovina",
		botswana: "Botswana",
		bouvet_island: "Đảo Bouvet",
		brazil: "Brazil",
		british_indian_ocean_territory: "Lãnh thổ Ấn Độ Dương thuộc Anh",
		british_virgin_islands: "Quần đảo Virgin thuộc Anh",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Cabo Verde",
		cambodia: "Campuchia",
		cameroon: "Cameroon",
		canada: "Canada",
		cayman_islands: "Quần đảo Cayman",
		central_african_republic: "Cộng hòa Trung Phi",
		chad: "Chad",
		chile: "Chile",
		china: "Trung Quốc",
		christmas_island: "Đảo Giáng Sinh",
		cocos_islands: "Quần đảo Cocos (Keeling)",
		colombia: "Colombia",
		comoros: "Comoros",
		congo_republic: "Cộng hòa Congo",
		cook_islands: "Quần đảo Cook",
		costa_rica: "Costa Rica",
		croatia: "Croatia",
		cuba: "Cuba",
		curacao: "Curaçao",
		cyprus: "Síp",
		czechia: "Cộng hòa Séc",
		denmark: "Đan Mạch",
		djibouti: "Djibouti",
		dominica: "Dominica",
		dominican_republic: "Cộng hòa Dominica",
		dr_congo: "Cộng hòa Dân chủ Congo",
		ecuador: "Ecuador",
		egypt: "Ai Cập",
		el_salvador: "El Salvador",
		equatorial_guinea: "Guinea Xích Đạo",
		eritrea: "Eritrea",
		estonia: "Estonia",
		eswatini: "Eswatini",
		ethiopia: "Ethiopia",
		falkland_islands: "Quần đảo Falkland",
		faroe_islands: "Quần đảo Faroe",
		fiji: "Fiji",
		finland: "Phần Lan",
		france: "Pháp",
		french_guiana: "Guiana thuộc Pháp",
		french_polynesia: "Polynesia thuộc Pháp",
		french_southern_territories: "Lãnh thổ phía Nam của Pháp",
		gabon: "Gabon",
		gambia: "Gambia",
		georgia: "Georgia",
		germany: "Đức",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greece: "Hy Lạp",
		greenland: "Greenland",
		grenada: "Grenada",
		guadeloupe: "Guadeloupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guinea",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Đảo Heard và Quần đảo McDonald",
		honduras: "Honduras",
		hong_kong: "Hồng Kông",
		hungary: "Hungary",
		iceland: "Iceland",
		india: "Ấn Độ",
		indonesia: "Indonesia",
		iran: "Iran",
		iraq: "Iraq",
		ireland: "Ireland",
		isle_of_man: "Đảo Man",
		israel: "Israel",
		italy: "Ý",
		ivory_coast: "Bờ Biển Ngà",
		jamaica: "Jamaica",
		japan: "Nhật Bản",
		jersey: "Jersey",
		jordan: "Jordan",
		kazakhstan: "Kazakhstan",
		kenya: "Kenya",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Lào",
		latvia: "Latvia",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		liechtenstein: "Liechtenstein",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao: "Macao",
		madagascar: "Madagascar",
		malawi: "Malawi",
		malaysia: "Malaysia",
		maldives: "Maldives",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Quần đảo Marshall",
		martinique: "Martinique",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mayotte: "Mayotte",
		mexico: "Mexico",
		micronesia: "Micronesia",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mông Cổ",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Ma Rốc",
		mozambique: "Mozambique",
		myanmar: "Myanmar",
		namibia: "Namibia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Hà Lan",
		new_caledonia: "New Caledonia",
		new_zealand: "New Zealand",
		nicaragua: "Nicaragua",
		niger: "Niger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Đảo Norfolk",
		north_korea: "Bắc Triều Tiên",
		north_macedonia: "Bắc Macedonia",
		northern_mariana_islands: "Quần đảo Bắc Mariana",
		norway: "Na Uy",
		oman: "Oman",
		pakistan: "Pakistan",
		palau: "Palau",
		palestine: "Palestine",
		panama: "Panama",
		papua_new_guinea: "Papua New Guinea",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippines",
		pitcairn_islands: "Quần đảo Pitcairn",
		poland: "Ba Lan",
		portugal: "Bồ Đào Nha",
		puerto_rico: "Puerto Rico",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Romania",
		russia: "Nga",
		rwanda: "Rwanda",
		saint_barthelemy: "Saint Barthélemy",
		saint_helena: "Saint Helena",
		saint_lucia: "Saint Lucia",
		saint_martin: "Saint Martin",
		saint_pierre_and_miquelon: "Saint Pierre và Miquelon",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "São Tomé và Príncipe",
		saudi_arabia: "Ả Rập Saudi",
		senegal: "Senegal",
		serbia: "Serbia",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leone",
		singapore: "Singapore",
		sint_maarten: "Sint Maarten",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		solomon_islands: "Quần đảo Solomon",
		somalia: "Somalia",
		south_africa: "Nam Phi",
		south_georgia_and_south_sandwich_islands: "Nam Georgia và Quần đảo Nam Sandwich",
		south_korea: "Hàn Quốc",
		south_sudan: "Nam Sudan",
		spain: "Tây Ban Nha",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "St Kitts và Nevis",
		st_vincent_and_grenadines: "Saint Vincent và Grenadines",
		sudan: "Sudan",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Svalbard và Jan Mayen",
		sweden: "Thụy Điển",
		switzerland: "Thụy Sĩ",
		syria: "Syria",
		taiwan: "Đài Loan",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thái Lan",
		timor_leste: "Đông Timor",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad và Tobago",
		tunisia: "Tunisia",
		turkiye: "Thổ Nhĩ Kỳ",
		turkmenistan: "Turkmenistan",
		turks_and_caicos_islands: "Quần đảo Turks và Caicos",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ukraine",
		united_arab_emirates: "Các Tiểu Vương Quốc Ả Rập Thống Nhất",
		united_kingdom: "Vương quốc Anh",
		united_states: "Hoa Kỳ",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Các đảo xa xôi nhỏ của Mỹ",
		us_virgin_islands: "Quần đảo Virgin thuộc Mỹ",
		uzbekistan: "Uzbekistan",
		vanuatu: "Vanuatu",
		vatican_city: "Thành phố Vatican",
		venezuela: "Venezuela",
		vietnam: "Việt Nam",
		wallis_and_futuna: "Wallis và Futuna",
		western_sahara: "Tây Sahara",
		yemen: "Yemen",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Lira Thổ Nhĩ Kỳ",
		us_dollar: "Đô la Mỹ",
	},
	$employeeType: {
		admin: "Quản trị viên",
		default: "Mặc định",
		exporter: "Nhà xuất khẩu",
		importer: "Nhà nhập khẩu",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:ss",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Tạo công ty của bạn tại đây.",
			create_company_title: "Bạn muốn thêm công ty của mình?",
			description: "Bạn đã đăng nhập thành công. Bây giờ bạn cần tham gia một công ty hoặc tạo một công ty mới.",
			join_company_subtitle: "Nhập mã của bạn và tham gia công ty tại đây.",
			join_company_title: "Bạn muốn tham gia một công ty hiện có?",
			title: "Chào mừng {name}",
		},
		$unauthorized: {
			description: "Kiểm soát các quy trình xuất nhập khẩu của bạn với Antrego",
			forget_password_subtitle: "Nhập email của bạn để đặt lại mật khẩu.",
			sign_in_subtitle: "Đăng nhập tại đây",
			sign_in_title: "Bạn đã có tài khoản?",
			sign_up_subtitle: "Đăng ký tại đây",
			sign_up_title: "Bạn muốn tạo tài khoản?",
			title: "Chào mừng đến với Antrego",
		},
	},
	$invitationType: {
		company_partner: "Lời mời hợp tác thương mại",
		customs_partner: "Lời mời đối tác hải quan",
		employee: "Lời mời nhân viên",
	},
	$landing: {
		$benefits: {
			benefits_title: "Lợi ích",
			business_process: "Tăng tốc quá trình kinh doanh của bạn.",
			control_title: "Kiểm soát với Antrego.",
			highlight_word: "kiểm soát",
			risk_of_error: "Giảm thiểu rủi ro lỗi.",
			text: "Antrego là một chương trình theo dõi vận chuyển giúp các công ty vận chuyển, khách hàng của họ và các nhà môi giới hải quan theo dõi các quy trình vận chuyển thông qua một nền tảng chung.",
			time_and_cost_savings: "Tiết kiệm thời gian và chi phí.",
		},
		$content: {
			$text: {
				0: "Theo dõi tất cả các chuyến hàng của bạn tại một nơi và kiểm soát tài chính của bạn với báo cáo động.",
				1: "Chia sẻ các cập nhật theo thời gian thực của bạn với khách hàng của bạn để cải thiện chất lượng dịch vụ của bạn.",
				2: "Xem và chia sẻ tất cả các tài liệu liên quan đến chuyến hàng của bạn trong một hệ thống.",
				3: "Dễ dàng kiểm soát quá trình bằng cách truy cập Antrego từ tất cả các thiết bị và địa điểm.",
				4: "Lưu trữ tất cả dữ liệu của bạn bằng công nghệ mã hóa SHA-256. Yên tâm về bảo mật.",
				5: "Tham gia theo dõi chung với tất cả các bên liên quan trong quá trình kinh doanh. Quản lý quy trình hiệu quả hơn.",
			},
			$title: {
				0: "Quản lý vận chuyển",
				1: "Quan hệ khách hàng",
				2: "Quản lý tài liệu",
				3: "Truy cập dễ dàng",
				4: "Bảo mật dữ liệu",
				5: "Theo dõi chung",
			},
		},
		$home: {
			text: "Chọn chúng tôi để theo dõi kỹ thuật số liền mạch và kiểm soát chung.",
			title: "Quản lý dễ dàng tất cả các quy trình vận chuyển của bạn một cách kỹ thuật số",
		},
		$lifecycle: {
			headline: "Quy trình",
			info_button: "Nhận thông tin",
			lifecycle_texts: {
				0: "Tải lên các tệp bổ sung để tạo hóa đơn proforma của bạn và chia sẻ ngay lập tức với khách hàng của bạn.",
				1: "Nhập chi tiết chuyến hàng của bạn và thêm tài liệu. Thông báo cho khách hàng của bạn và cùng nhau quản lý tất cả các quy trình vận chuyển qua một kênh duy nhất.",
				2: "Theo dõi tình hình tài chính của bạn liên quan đến xuất nhập khẩu theo thời gian thực.",
			},
			lifecycle_titles: {
				0: "Đăng nhập và tạo hóa đơn Proforma",
				1: "Tạo chuyến hàng",
				2: "Theo dõi hoạt động tài chính",
			},
			productivity_title: "Tăng năng suất và giảm sự phức tạp và lỗi trong 3 bước.",
			tracking_guide_title: "Hướng dẫn theo dõi dễ dàng",
		},
		$logistics: {
			logistics_title: "Các loại hình logistics được hỗ trợ",
			solutions_title: "Giải pháp phù hợp cho mọi hàng hóa",
			text: "Với Antrego, đối tác kỹ thuật số của bạn trong tất cả các loại hình vận tải, chúng tôi luôn sát cánh cùng bạn trong mọi chuyển động toàn cầu của bạn.",
		},
		$process: {
			digital_future: "Từ bỏ các phương pháp truyền thống và bước vào tương lai kỹ thuật số",
			digital_shipping: "Theo dõi giao hàng kỹ thuật số với Antrego",
			digital_shipping_texts: {
				0: "Hóa đơn proforma cho các đơn hàng đến được tạo và theo dõi dễ dàng thông qua một kênh duy nhất.",
				1: "Hóa đơn proforma được gửi đi, thông báo đến khách hàng ngay lập tức và được phê duyệt thông qua Antrego.",
				2: "Các quy trình tài liệu được quản lý thông qua Antrego. Chúng không bị mất, không xảy ra chậm trễ và khả năng xảy ra lỗi được giảm thiểu.",
				3: "Các nhà cung cấp, khách hàng và nhà môi giới hải quan có thể ngay lập tức xem chi tiết chuyến hàng và theo dõi chung.",
				4: "Báo cáo tài chính được tự động tạo hàng tháng và hàng năm.",
			},
			question_title: "Antrego sẽ thay đổi cuộc sống của bạn như thế nào?",
			tracking_method: "Số hóa các phương pháp theo dõi của bạn và tăng tốc quá trình của bạn.",
			traditional_shipping: "Theo dõi giao hàng truyền thống",
			traditional_shipping_texts: {
				0: "Đơn hàng được thu thập qua email, tin nhắn hoặc điện thoại thông qua các kênh khác nhau, gây khó khăn cho việc theo dõi nội bộ.",
				1: "Hóa đơn proforma được tạo bằng các công cụ như Excel và quy trình phê duyệt được xử lý thủ công qua email, WhatsApp hoặc điện thoại.",
				2: "Quy trình tài liệu thủ công dẫn đến lỗi và chậm trễ, gây lãng phí thời gian và tài chính.",
				3: "Không có nền tảng chung cho các nhà cung cấp, khách hàng và nhà môi giới hải quan theo dõi chi tiết chuyến hàng.",
				4: "Dữ liệu tài chính được kiểm tra riêng sau mỗi chuyến hàng, khiến việc báo cáo trở nên khó khăn.",
			},
		},
		$question: {
			$text: {
				0: "<p>Đó là một chương trình theo dõi vận chuyển giúp các công ty và khách hàng của họ theo dõi chung, ngăn ngừa tổn thất do chậm trễ trong giai đoạn theo dõi.</p>",
				1: "<p>- Chia sẻ tất cả các cập nhật của bạn theo thời gian thực với khách hàng. Cải thiện chất lượng dịch vụ của bạn.<br>\n      - Xem và chia sẻ tất cả các tài liệu liên quan đến vận chuyển của bạn trong một hệ thống.<br>\n      - Theo dõi tất cả các chuyến hàng của bạn từ một nơi. Kiểm soát tài chính của bạn với báo cáo động.</p>",
				2: "<p>- Đường biển<br>\n      - Đường hàng không<br>\n      - Đường bộ<br>\n      - Đường sắt</p>",
				3: "<p>Với các dịch vụ của mình, nó luôn sẵn sàng để làm cho quá trình vận chuyển của bạn dễ dàng hơn.<br>\n      <strong>1. Truy cập dễ dàng</strong><br>\n      Có thể truy cập từ tất cả các thiết bị và địa điểm thông qua trình duyệt web.<br>\n      <strong>2. Bảo mật dữ liệu</strong><br>\n      Tất cả dữ liệu được lưu trữ bằng công nghệ mã hóa SHA-256.<br>\n      <strong>3. Theo dõi chung</strong><br>\n      Nó cung cấp cơ hội theo dõi cho tất cả các bên liên quan trong quá trình.</strong>",
				4: "<p>Có, đó là một ứng dụng trả phí. Bằng cách số hóa tất cả các quy trình vận chuyển của bạn, chúng tôi muốn giúp công việc của bạn dễ dàng hơn và tiếp tục phát triển hữu ích cho bạn. Để đảm bảo tính bền vững của các cải tiến này và không gian máy chủ bạn sử dụng, chúng tôi tính phí hàng tháng.</p>",
				5: "<p>Không. Khách hàng của bạn không cần phải trả bất kỳ khoản phí nào để theo dõi các chuyến hàng bạn thực hiện.</p>",
			},
			$title: {
				0: "Antrego là gì?",
				1: "Antrego sẽ mang lại gì cho công ty tôi trong quá trình vận chuyển?",
				2: "Antrego hỗ trợ loại hình logistics nào?",
				3: "Tại sao tôi nên sử dụng Antrego?",
				4: "Antrego có phải là ứng dụng trả phí không?",
				5: "Khách hàng của tôi có cần trả tiền cho Antrego không?",
			},
			save_percentage: "Tiết kiệm 16% hàng tháng",
			text: "Dễ dàng truy cập tất cả thông tin với Antrego và liên hệ với chúng tôi nếu bạn có bất kỳ câu hỏi nào khác.",
			title: "Nhanh chóng tìm hiểu những gì bạn tò mò",
		},
		cancel_anytime: "Hủy bất cứ lúc nào",
		customize_package: "Bạn có thể mua thêm dung lượng và người dùng và tùy chỉnh gói của mình theo nhu cầu",
		free_features_1: "10 chuyến hàng",
		free_features_2: "100 MB Dung lượng",
		free_features_3: "Đối tác thương mại không giới hạn",
		free_features_4: "1 người dùng",
		free_plan: "Miễn phí",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Hàng tháng",
		premium_annual_features_1: "20 Người dùng",
		premium_annual_features_2: "100 Đối tác",
		premium_annual_features_3: "1000 Chuyến hàng",
		premium_annual_features_4: "1000 Hóa đơn",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "Gia hạn hàng năm với $999",
		premium_annual_title: "Hàng năm",
		premium_monthly_features_1: "Chuyến hàng không giới hạn",
		premium_monthly_features_2: "Đối tác thương mại không giới hạn",
		premium_monthly_features_3: "2GB Dung lượng",
		premium_monthly_features_4: "3 Người dùng",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Gia hạn hàng tháng với $99",
		premium_monthly_title: "Hàng tháng",
		premium_plan: "Gói Premium",
		premium_plus_annual_features_1: "Chuyến hàng không giới hạn",
		premium_plus_annual_features_2: "Đối tác thương mại không giới hạn",
		premium_plus_annual_features_3: "5GB Dung lượng",
		premium_plus_annual_features_4: "5 Người dùng",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "Gia hạn hàng năm với $999",
		premium_plus_annual_title: "Hàng năm",
		premium_plus_monthly_features_1: "Chuyến hàng không giới hạn",
		premium_plus_monthly_features_2: "Đối tác thương mại không giới hạn",
		premium_plus_monthly_features_3: "5GB Dung lượng",
		premium_plus_monthly_features_4: "5 Người dùng",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Gia hạn hàng tháng với $249",
		premium_plus_monthly_title: "Hàng tháng",
		premium_plus_plan: "Premium Plus",
		pricing_text:
			"Chúng tôi có thể đáp ứng nhu cầu của bạn theo cách tốt nhất mà không làm ảnh hưởng đến ngân sách của bạn.",
		pricing_title: "Antrego cam kết cung cấp gói và giá tốt nhất cho bạn.",
		save_percentage: "Tiết kiệm 16% hàng tháng",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Tiếng Ả Rập",
		de: "Tiếng Đức",
		en: "Tiếng Anh",
		es: "Tiếng Tây Ban Nha",
		fr: "Tiếng Pháp",
		hi: "Tiếng Hindi",
		ind: "Tiếng Indonesia",
		it: "Tiếng Ý",
		ja: "Tiếng Nhật",
		ko: "Tiếng Hàn",
		pt: "Tiếng Bồ Đào Nha",
		ru: "Tiếng Nga",
		tr: "Tiếng Thổ Nhĩ Kỳ",
		vi: "Tiếng Việt",
		zh: "Tiếng Trung",
	},
	$message: {
		cannot_delete_verified_partner: "Bạn không thể xóa đối tác đã xác minh.",
		confirm: "Bạn có chắc chắn muốn {function}?",
		created: "{item} đã được tạo.",
		deleted: "{item} đã bị xóa.",
		error_on_view_file: "Đã xảy ra lỗi khi xem tệp.",
		file_oversize: "Kích thước tệp tối đa là {size}.",
		invalid: "{item} không hợp lệ",
		migrated: "{item} đã được chuyển. ({value})",
		process_has_been_started: "Quy trình của bạn {item} đã bắt đầu.",
		sent: "{item} đã được gửi.",
		sent_email: "Email đã được gửi. ({value})",
		sign_in_failed: "Email hoặc mật khẩu không chính xác",
		unsupported_file_type: "Loại tệp không được hỗ trợ",
		updated: "{item} đã được cập nhật.",
	},
	$pageTitles: {
		accept_invitation: "Chấp nhận lời mời",
		company_profile: "Hồ sơ công ty",
		home: "Trang chủ",
		landing_page: "Theo dõi kỹ thuật số liền mạch, kiểm soát chia sẻ",
		partners: "Đối tác",
		product_groups: "Nhóm sản phẩm",
		products: "Sản phẩm",
		proforma_invoices: "Hóa đơn Proforma",
		shipment_details: "Chi tiết chuyến hàng",
		shipments: "Chuyến hàng",
		sign_in: "Đăng nhập",
		sign_up: "Đăng ký",
		subscription: "Đăng ký",
		user_profile: "Hồ sơ người dùng",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Không phải đối tác đã đăng ký, bạn đã tạo hồ sơ của họ trên nền tảng của chúng tôi để bạn có thể xem và chỉnh sửa thông tin của họ.",
		createdHereTitle: "Được tạo tại đây:",
		description: "Bạn có thể theo dõi trạng thái mối quan hệ của mình với các đối tác. Đây là những khác biệt:",
		verifiedPartnersDescription:
			"Các đối tác này đã chấp nhận lời mời của bạn và đăng ký trên nền tảng. Thông tin của họ chỉ có thể xem và không thể chỉnh sửa.",
		verifiedPartnersTitle: "Đối tác đã xác minh:",
	},
	$productGroup: {
		add_child: "Thêm nhóm con",
		add_new_product_group: "Thêm nhóm sản phẩm mới",
		add_to_root: "Thêm vào gốc",
		has_children_fail_message: "Một nhóm sản phẩm có nhóm con không thể bị xóa",
		initial_message: "Sử dụng nút {0} này để tạo nhóm sản phẩm đầu tiên của bạn.",
		name: "Tên nhóm sản phẩm",
		number_of_product: "Số lượng sản phẩm",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} đã được phê duyệt.",
			create: "Được tạo bởi {name}.",
			create_attachment: "{name} đã tạo tệp đính kèm.",
			delete_attachment: "{name} đã xóa tệp đính kèm.",
			demand_revise: "{name} đã yêu cầu sửa đổi.",
			mark_as_processed: "{name} được đánh dấu là đã xử lý.",
			reject: "{name} đã bị từ chối.",
			revoke: "{name} đã bị hủy.",
			send: "{name} đã gửi cho người nhận để đánh giá.",
			update: "Được cập nhật bởi {name}.",
			update_attachment: "{name} đã cập nhật tệp đính kèm.",
		},
		$actionDescription2: {
			approve: '{name} được đánh dấu là "Đã phê duyệt".',
			demand_revise: '{name} được đánh dấu là "Yêu cầu sửa đổi".',
			mark_as_processed: '{name} được đánh dấu là "Đã xử lý".',
			reject: "{name} bị từ chối.",
			revoke: '{name} được đánh dấu là "Đã hủy".',
			send: '{name} được đánh dấu là "Đã gửi".',
		},
		$actions: {
			approve: "Phê duyệt",
			demand_revise: "Yêu cầu sửa đổi",
			mark_as_approved: "Đã phê duyệt",
			mark_as_processed: "Đã xử lý",
			mark_as_rejected: "Đã từ chối",
			mark_as_revise_demanded: "Yêu cầu sửa đổi",
			mark_as_revoked: "Đã hủy",
			mark_as_sent: "Đã gửi",
			reject: "Từ chối",
			revoke: "Hủy",
			send: "Gửi",
		},
		$status: {
			approved: "Đã phê duyệt",
			pending_approval: "Đang chờ phê duyệt",
			pending_revise: "Đang chờ sửa đổi",
			processed: "Đã xử lý",
			rejected: "Đã từ chối",
			revoked: "Đã hủy",
			template: "Mẫu",
		},
		approve_confirm_message: "Bạn có chắc chắn muốn phê duyệt hóa đơn proforma không?",
		approve_success_message: "Hóa đơn proforma đã được phê duyệt.",
		demand_revise_description_message: "Vui lòng cung cấp chi tiết về kỳ vọng sửa đổi của bạn:",
		demand_revise_success_message: "Hóa đơn proforma đã được gửi lại cho người gửi để sửa đổi.",
		mark_as_processed_confirm_message: "Bạn có xác nhận đánh dấu hóa đơn proforma là đã xử lý không?",
		mark_as_processed_success_message: "Hóa đơn proforma đã được đánh dấu là đã xử lý.",
		no_attachment_text: "Chưa có tệp đính kèm nào được thêm.",
		reject_confirm_message: "Bạn có chắc chắn muốn từ chối hóa đơn proforma không?",
		reject_success_message: "Hóa đơn proforma đã bị từ chối.",
		revoke_confirm_message: "Bạn có chắc chắn muốn hủy hóa đơn proforma không?",
		revoke_success_message: "Hóa đơn proforma đã bị hủy.",
		send_confirm_message: "Bạn có xác nhận gửi hóa đơn proforma cho người nhận không?",
		send_success_message: "Hóa đơn proforma đã được gửi.",
	},
	$serviceMessage: {
		already_subs_module: "Đã có đăng ký cho mô-đun này.",
		bad_request: "Yêu cầu không hợp lệ",
		code_already_used: "Mã đã được sử dụng.",
		container_status_not_available: "Trạng thái container không khả dụng.",
		existing_email_warning: "Email đã tồn tại.",
		existing_employee_error: "Người dùng này đã được thêm vào công ty của bạn.",
		existing_employee_for_email_warning: "Mã mời đã được gửi đến email này",
		existing_invitation_warning: "Mã mời đã được gửi đến email này.",
		existing_token_already_have_time: "Mã token hiện tại đã có đủ thời gian để sử dụng.",
		existing_user: "Người dùng đã tồn tại.",
		five_min_reset_password_mail: "Bạn phải đợi ít nhất 5 phút sau khi yêu cầu một email đặt lại mật khẩu.",
		forbidden: "Cấm",
		have_no_corporation: "Bạn không có công ty",
		have_no_custom_agency: "Bạn không có cơ quan hải quan",
		have_no_employee: "Bạn không có nhân viên",
		instance_can_no_delete_existing_booking_code: "Không thể xóa thực thể này do mã đặt chỗ hiện có.",
		instance_cannot_delete: "Không thể xóa thực thể này.",
		instance_not_found: "Không tìm thấy thực thể.",
		internal_server_error: "Lỗi máy chủ nội bộ",
		invalid_current_password: "Mật khẩu hiện tại không hợp lệ.",
		invalid_identifier_or_password: "Nhận dạng hoặc mật khẩu không hợp lệ",
		invalid_invitation_code: "Mã mời không chính xác.",
		invalid_moving_request: "Yêu cầu di chuyển không hợp lệ",
		invalid_operation: "Hoạt động không hợp lệ",
		invalid_refresh_token: "Mã làm mới không hợp lệ.",
		invalid_unit: "Đơn vị không hợp lệ",
		invitation_accepting_is_due_to_the_admin: "Chấp nhận lời mời tùy thuộc vào quản trị viên.",
		invitation_already_discarded: "Lời mời đã bị từ chối.",
		invitation_already_used: "Lời mời đã được sử dụng.",
		invitation_has_been_used: "Lời mời đã được sử dụng.",
		invitation_not_exist_email: "Không tồn tại lời mời email này.",
		invitation_not_for_company: "Lời mời này không dành cho công ty.",
		invitation_not_for_custom_agency: "Lời mời này không dành cho cơ quan hải quan.",
		invitation_not_found: "Không tìm thấy lời mời.",
		invitation_not_valid: "Lời mời này không còn hiệu lực.",
		link_has_been_expired: "Liên kết đã hết hạn.",
		link_not_be_used_anymore: "Liên kết này không thể được sử dụng nữa",
		missing_required_fields: "Thiếu các trường bắt buộc.",
		no_employee_found: "Không tìm thấy nhân viên",
		no_proper_partner_found: "Không tìm thấy đối tác thích hợp.",
		not_acceptable: "Không chấp nhận được",
		not_allowed_action: "Bạn không được phép thực hiện hành động này.",
		not_allowed_use_api: "Bạn không được phép sử dụng API này.",
		not_found: "Không tìm thấy",
		not_have_purchase_subscription:
			"Gói đăng ký của bạn đã đạt đến giới hạn tối đa cho các mục. Để thêm nhiều hơn, vui lòng xem xét nâng cấp gói đăng ký của bạn.",
		not_permitted_use_api: "Bạn không được phép sử dụng API này.",
		own_invitation_cannot_accept: "Bạn không thể chấp nhận lời mời của chính mình.",
		parent_group_not_found: "Không tìm thấy nhóm cha",
		partnerId_required: "Yêu cầu ID đối tác.",
		partnership_already_exists: "Đối tác đã được thêm vào.",
		password_cant_involve_space_char: "Mật khẩu không được chứa khoảng trắng.",
		password_min_chars_least: "Mật khẩu phải có ít nhất 6 ký tự.",
		payment_required: "Vui lòng đăng ký cho quy trình này.",
		paytr_integration_error: "Lỗi tích hợp PayTR",
		ports_are_required: "Cần có cổng.",
		product_group_tree_not_found: "Không tìm thấy cây nhóm sản phẩm",
		proforma_invoice_action_not_available: "Trạng thái hóa đơn proforma không khả dụng cho hành động này.",
		proforma_invoice_template_update: "Hóa đơn proforma phải là mẫu để được cập nhật.",
		shipment_email_not_exist: "Vui lòng nhập email hợp lệ.",
		shipment_method_is_required: "Cần có phương thức giao hàng.",
		shipping_term_is_required: "Cần có điều kiện vận chuyển.",
		someone_else_uses_this_email: "Người khác đang sử dụng địa chỉ email này.",
		someone_else_uses_this_username: "Người khác đang sử dụng tên người dùng này.",
		something_went_wrong: "Đã xảy ra lỗi",
		type_can_be_abstract_company: 'Thuộc tính "loại" chỉ có thể là "abstract_company"',
		unauthorized: "Không có quyền",
		undefined_result: "Kết quả không xác định",
		unexpected_error_occurred: "Đã xảy ra lỗi bất ngờ",
		unit_already_added: "Đơn vị đã được thêm.",
		user_already_employee: "Người dùng đã là nhân viên.",
		user_already_exists: "Người dùng đã tồn tại trong công ty.",
		user_does_not_exist: "Người dùng không tồn tại.",
		user_not_found: "Không tìm thấy người dùng.",
		username_min_chars_error: "Tên người dùng phải có ít nhất 6 ký tự.",
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} đã thêm một phương tiện.",
				delete_container: "{name} đã xóa một phương tiện.",
				update_container: "{name} đã cập nhật một phương tiện.",
			},
			create: "Được tạo bởi {name}.",
			create_attachment: "{name} đã thêm một tệp đính kèm.",
			create_container: "{name} đã thêm một container.",
			delete_attachment: "{name} đã xóa một tệp đính kèm.",
			delete_container: "{name} đã xóa một container.",
			update: "Được cập nhật bởi {name}.",
			update_attachment: "{name} đã cập nhật tệp đính kèm.",
			update_container: "{name} đã cập nhật container.",
		},
		$containerStatus: {
			arrived: "Đã đến",
			off_load: "Đã dỡ hàng",
			on_load: "Đang tải",
			on_way: "Đang trên đường",
			reserved: "Đã đặt chỗ",
		},
		$method: {
			aerial: "Đường hàng không",
			land: "Đường bộ",
			railway: "Đường sắt",
			sea: "Đường biển",
		},
		$vehicle: {
			license_number: "Biển số xe",
		},
		archiving_success_message: "Chuyến hàng đã được lưu trữ.",
		leave_message: "Để lại tin nhắn hoặc bình luận của bạn",
		no_booking_info: "Chưa có thông tin đặt chỗ. Sử dụng nút {0} để thêm.",
		no_transportation_info: "Chưa có thông tin chi tiết. Sử dụng nút {0} để thiết lập.",
		un_archiving_success_message: "Chuyến hàng đã được khôi phục.",
	},
	$shortening: {
		number: "Số",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Không thể gia hạn đăng ký của bạn. Vui lòng kiểm tra phương thức thanh toán của bạn.",
			fail: "Đã xảy ra lỗi không mong muốn. Vui lòng thử lại sau. Nếu vấn đề vẫn tiếp diễn, vui lòng báo cáo bằng cách gửi email đến info@antrego.net.",
			ok: "Quá trình mua hàng đã được bắt đầu. Kết quả của quá trình sẽ được thông báo qua email. Đăng ký của bạn sẽ bắt đầu khi thanh toán thành công.",
			payment_process_is_ongoing:
				"Quá trình thanh toán của bạn đang tiếp diễn. Kết quả của quá trình sẽ được thông báo qua email. Đăng ký của bạn sẽ bắt đầu khi thanh toán thành công.",
			success: "Đăng ký của bạn đã được tạo thành công. Bạn có thể bắt đầu sử dụng Antrego dễ dàng.",
			unsubscribed: "Đã hủy đăng ký",
			you_dont_have_any_subscription:
				"Bạn không có bất kỳ đăng ký nào. Vui lòng chọn một gói trên trang đăng ký và bắt đầu sử dụng dễ dàng.",
			you_dont_have_any_subscription_text: "Vui lòng chọn gói để tiếp tục.",
			you_dont_have_any_subscription_title: "Bạn không có đăng ký nào.",
		},
	},
	$unit: {
		acres: "Acre",
		boxes: "Hộp",
		cubic_meter: "Mét khối",
		dozen: "Tá",
		feet: "Feet",
		fluid_ounces: "Ounce chất lỏng",
		gallons: "Gallon",
		gram: "Gam",
		hectare: "Hecta",
		inches: "Inch",
		kilogram: "Kilôgam",
		kilometer: "Kilômét",
		liter: "Lít",
		meter: "Mét",
		miles: "Dặm",
		milligram: "Miligram",
		milliliter: "Mililit",
		millimeter: "Milimét",
		ounces: "Ounce",
		pallets: "Pallet",
		pieces: "Miếng",
		pounds: "Pound",
		quarts: "Quart",
		square_feet: "Feet vuông",
		square_inches: "Inch vuông",
		square_kilometer: "Kilômét vuông",
		square_meter: "Mét vuông",
		square_miles: "Dặm vuông",
		square_yards: "Yard vuông",
		ton: "Tấn",
		yards: "Yard",
	},
	$validation: {
		email: "Vui lòng nhập email hợp lệ",
		invalid: "{item} không hợp lệ",
		max_length: "{item} phải nhỏ hơn {length} ký tự",
		min_length: "{item} phải lớn hơn {length} ký tự",
		must_length: "{item} phải có {length} ký tự",
		only_number: "{item} phải là số",
		password_repeat_warning: "Lặp lại mật khẩu không giống với mật khẩu mới.",
		required: "{item} là bắt buộc",
		required_for_default_language: "{item} là bắt buộc cho ngôn ngữ mặc định của công ty.",
		you_must_approve_distance_sales_agreement: "Bạn phải chấp nhận Hợp đồng Bán hàng từ xa",
	},
	about: "Về chúng tôi",
	accept_invitation: "Chấp nhận lời mời",
	accept_invitation_success_message: "Bạn đã sử dụng lời mời thành công.",
	accessibility: "khả năng tiếp cận",
	actions: "Hành động",
	active: "Hoạt động",
	activities: "Hoạt động",
	add: "Thêm",
	add_item: "Thêm {item}",
	add_new_employee: "Thêm nhân viên mới",
	add_new_product: "Thêm sản phẩm mới",
	address: "Địa chỉ",
	again: "Lại",
	alert: "Cảnh báo",
	already_have_employee_message: "Bạn đã là nhân viên của một công ty.",
	amount: "Số lượng",
	approve: "Phê duyệt",
	archive: "Lưu trữ",
	attachment: "Tệp đính kèm",
	attachments: "Các tệp đính kèm",
	bank: "Ngân hàng",
	benefits: "Lợi ích",
	booking: "Đặt chỗ",
	booking_code: "Mã đặt chỗ",
	booking_details: "Chi tiết đặt chỗ",
	business_partner: "Đối tác kinh doanh",
	business_partners: "Các đối tác kinh doanh",
	cancel: "Hủy",
	change_password: "Đổi mật khẩu",
	checking_invitation_code_message: "Đang kiểm tra mã mời",
	choose_image: "Chọn hình ảnh",
	choose_your_plan: "Chọn gói của bạn",
	clear: "Xóa",
	click_to_play_the_video: "Nhấn để phát video",
	close: "Đóng",
	code: "Mã",
	commercial: "Thương mại",
	commercial_partner: "Đối tác thương mại",
	commercial_partners: "Các đối tác thương mại",
	companies: "Các công ty",
	company: "Công ty",
	company_name: "Tên công ty",
	company_profile: "Hồ sơ công ty",
	configuration: "Cấu hình",
	confirm: "Xác nhận",
	confirm_distance_selling_agreement: "Xác nhận Hợp đồng Bán hàng từ xa.",
	consult_us: "Tham khảo ý kiến ​​chúng tôi",
	contact: "Liên hệ",
	contact_info: "Thông tin liên hệ",
	contact_text: "Đừng lo lắng, chúng tôi sẽ liên hệ với bạn sớm nhất có thể.",
	contact_title: "Liên hệ với chúng tôi, đơn giản hóa quy trình của bạn",
	contact_to_us: "Liên hệ với chúng tôi",
	container: "Container",
	container_no: "Số container",
	containers: "Các container",
	content: "Nội dung",
	contract: "Hợp đồng",
	copy_link: "Sao chép liên kết",
	countries: "Các quốc gia",
	country: "Quốc gia",
	create: "Tạo",
	create_a_new_account: "Tạo tài khoản mới",
	create_company: "Tạo công ty",
	created_at: "Được tạo lúc",
	created_here: "Được tạo ở đây",
	credit_card_info: "Thông tin thẻ tín dụng",
	credit_card_no: "Số thẻ tín dụng",
	currencies: "Các loại tiền tệ",
	currency: "Tiền tệ",
	current_password: "Mật khẩu hiện tại",
	current_plan: "Gói hiện tại",
	customs: "Hải quan",
	customs_agencies: "Các cơ quan hải quan",
	customs_agency: "Cơ quan hải quan",
	cvv: "CVV",
	daily: "Hàng ngày",
	dashboard: "Bảng điều khiển",
	date: "Ngày",
	day: "Ngày",
	default: "Mặc định",
	default_language: "Ngôn ngữ mặc định",
	delete: "Xóa",
	delete_account: "Xóa tài khoản",
	delete_confirm_message: "Bạn có xác nhận xóa không?",
	description: "Mô tả",
	detail: "Chi tiết",
	discard: "Hủy bỏ",
	distance_sales_agreement: "Hợp đồng Bán hàng từ xa",
	document_number: "Số tài liệu",
	download: "Tải xuống",
	edit: "Chỉnh sửa",
	edit_item: "Chỉnh sửa {item}",
	edit_product: "Chỉnh sửa sản phẩm",
	edit_profile: "Chỉnh sửa hồ sơ",
	edit_your_profile_picture: "Chỉnh sửa ảnh hồ sơ",
	email: "Email",
	employee: "Nhân viên",
	employee_type: "Loại nhân viên",
	employees: "Các nhân viên",
	estimated_arrival_day: "Ngày đến dự kiến",
	expense: "Chi phí",
	expenses: "Các chi phí",
	expires_at: "Hết hạn vào",
	exported_containers: "Các container đã xuất",
	fail: "Thất bại",
	failed: "Đã thất bại",
	faq: "Câu hỏi thường gặp",
	feedback: "Phản hồi",
	file: "Tệp",
	file_preview: "Xem trước tệp",
	file_view: "Xem tệp",
	filter: "Bộ lọc",
	first_name: "Tên",
	first_name_last_name: "Họ và tên",
	forget_password: "Quên mật khẩu",
	forget_password_message: "Bạn đã quên mật khẩu?",
	forget_password_subtitle: "Vui lòng nhập địa chỉ email của bạn để đặt lại mật khẩu.",
	free: "Miễn phí",
	from: "Từ",
	get_started_now: "Bắt đầu ngay",
	give_us_feedback: "Đưa cho chúng tôi phản hồi",
	go_to_panel: "Đi đến bảng điều khiển",
	hide: "Ẩn",
	home_page: "Trang chủ",
	i_have_invitation_code: "Tôi có mã mời",
	imported_containers: "Các container đã nhập",
	in_progress: "Đang tiến hành",
	incoming: "Đang đến",
	incoming_shipments: "Các chuyến hàng đang đến",
	initial: "Ban đầu",
	invitation: "Lời mời",
	invitation_code: "Mã mời",
	invitation_mail_sent: "Email mời đã được gửi",
	invitations: "Các lời mời",
	join: "Tham gia",
	join_company: "Tham gia công ty",
	language: "Ngôn ngữ",
	language_options: "Tùy chọn ngôn ngữ",
	last_name: "Họ",
	last_one_year: "Năm vừa qua",
	latitude: "Vĩ độ",
	left_a_comment: "Đã để lại một bình luận",
	link_copied: "Liên kết đã được sao chép",
	link_copy_failed: "Sao chép liên kết thất bại",
	loading_country: "Quốc gia xuất hàng",
	loading_point: "Điểm xuất hàng",
	loading_port: "Cảng xuất hàng",
	log_in: "Đăng nhập",
	logo: "Logo",
	longitude: "Kinh độ",
	mail_sent: "Email đã được gửi",
	manage_profile: "Quản lý hồ sơ",
	message: "Tin nhắn",
	message_company_create: "Công ty đã được tạo.",
	message_company_join: "Vui lòng nhập mã lời mời.",
	message_company_joined: "Bạn đã tham gia công ty",
	message_employee_delete: "Nhân viên đã bị xóa.",
	message_employee_save: "Nhân viên đã được lưu.",
	message_join_company: "Nhập mã của bạn để tham gia công ty hiện có",
	method: "Phương pháp",
	migrate: "Di chuyển",
	migrate_partner: "Di chuyển công ty",
	mobile_number: "Số di động",
	month: "Tháng",
	month_lowercase: "tháng",
	monthly: "Hàng tháng",
	my_profile: "Hồ sơ của tôi",
	my_role: "Vai trò của tôi",
	name: "Tên",
	new: "Mới",
	new_company_package: "Miễn phí",
	new_password: "Mật khẩu mới",
	new_shipment: "Chuyến hàng mới",
	next: "Tiếp theo",
	no: "Không",
	no_content: "Không có nội dung",
	no_entity: "Không có thực thể",
	no_entity_message: "Không tìm thấy thực thể.",
	no_text: "Không có văn bản",
	not_found_page_message: "Xin lỗi, tôi không thể tìm thấy trang này :(",
	note: "Ghi chú",
	notes: "Ghi chú",
	ok: "OK",
	operation_fail: "Thất bại thao tác",
	outgoing: "Đang gửi",
	outgoing_shipments: "Các chuyến hàng đang gửi",
	packages: "Các gói",
	parent_group: "Nhóm cha",
	partner: "Đối tác",
	partners: "Các đối tác",
	partnership_status: "Trạng thái hợp tác",
	passive: "Thụ động",
	password: "Mật khẩu",
	pay: "Thanh toán",
	payback: "Hoàn trả",
	payback_confirm_message: "Bạn có xác nhận hoàn trả không?",
	payback_warning_message: "Nếu bạn tiếp tục, đăng ký của bạn sẽ bị hủy.",
	payment: "Thanh toán",
	payment_amount: "Số tiền thanh toán",
	payment_awaited: "Đang chờ thanh toán",
	payment_history: "Lịch sử thanh toán",
	payment_success: "Thanh toán thành công",
	payment_summary: "Tóm tắt thanh toán",
	payment_will_be_made_3d_secure: "*Thanh toán của bạn sẽ được thực hiện bằng 3D Secure.",
	pending_invitations: "Lời mời đang chờ xử lý",
	per_month: "Mỗi tháng",
	phone_number: "Số điện thoại",
	premium: "Cao cấp",
	premium_plus: "Cao cấp Plus",
	previous: "Trước",
	pricing: "Giá cả",
	process: "Quy trình",
	product: "Sản phẩm",
	product_group: "Nhóm sản phẩm",
	product_group_name: "Tên nhóm sản phẩm",
	product_groups: "Các nhóm sản phẩm",
	product_name: "Tên sản phẩm",
	products: "Các sản phẩm",
	profile: "Hồ sơ",
	proforma_invoice: "Hóa đơn Proforma",
	proforma_invoices: "Các hóa đơn Proforma",
	proforma_number: "Số hóa đơn Proforma",
	purchase: "Mua",
	quantity: "Số lượng",
	receiver: "Người nhận",
	receiver_company: "Công ty người nhận",
	receiver_customs_agency: "Cơ quan hải quan người nhận",
	redirection_to_home_link: "Trang chủ",
	redirection_to_home_message: "Bạn cũng có thể chuyển đến ",
	redirection_to_sign_in_message: "Bạn đã có tài khoản?",
	redirection_to_sign_up_message: "Bạn chưa có tài khoản?",
	reload: "Tải lại",
	renewal: "Gia hạn",
	repaid: "Đã hoàn trả",
	reset_password: "Đặt lại mật khẩu",
	rows_per_page: "Hàng mỗi trang",
	sales: "Bán hàng",
	save: "Lưu",
	save_changes: "Lưu thay đổi",
	saved_card_info: "Thông tin thẻ đã lưu",
	search: "Tìm kiếm",
	select: "Chọn",
	select_file: "Chọn tệp",
	select_file_place_holder: "Vui lòng chọn một hình ảnh",
	select_language: "Chọn ngôn ngữ",
	selected_partner: "Đối tác được chọn",
	send: "Gửi",
	send_again: "Gửi lại",
	send_email: "Gửi email",
	send_feedback: "Gửi phản hồi",
	send_invitation: "Gửi lời mời",
	sender: "Người gửi",
	sender_company: "Công ty người gửi",
	sender_customs_agency: "Cơ quan hải quan người gửi",
	settings: "Cài đặt",
	shipment: "Chuyến hàng",
	shipment_method: "Phương thức vận chuyển",
	shipment_type: "Loại chuyến hàng",
	shipments: "Các chuyến hàng",
	shipments_filter: "Bộ lọc chuyến hàng",
	shipping_term: "Điều kiện giao hàng",
	shipping_terms: "Các điều kiện giao hàng",
	show: "Hiển thị",
	sign_in: "Đăng nhập",
	sign_out: "Đăng xuất",
	sign_out_confirm_message: "Bạn có xác nhận đăng xuất không?",
	sign_out_success_message: "Tạm biệt.",
	sign_up: "Đăng ký",
	social_media: "Mạng xã hội",
	social_media_account: "Tài khoản mạng xã hội",
	start_time: "Thời gian bắt đầu",
	status: "Trạng thái",
	subscribe: "Đăng ký",
	subscription: "Đăng ký",
	subscription_info_message: "Bạn có thể sử dụng tất cả các dịch vụ không giới hạn trong thời gian đăng ký.",
	subscription_warning_message:
		"**Thông tin thẻ của bạn sẽ được lưu trữ an toàn và đăng ký của bạn sẽ được gia hạn thường xuyên trừ khi bạn hủy.",
	success: "Thành công",
	super: "Siêu",
	supported_files_img: "Các tệp được hỗ trợ: JPG, PNG.",
	switch_language: "Chuyển ngôn ngữ",
	switch_theme: "Chuyển chủ đề",
	target: "Mục tiêu",
	target_partner: "Đối tác mục tiêu",
	tax_number: "Mã số thuế",
	theme: "Chủ đề",
	this_month: "Tháng này",
	this_year: "Năm nay",
	title: "Tiêu đề",
	to: "Đến",
	toDelete: "Xóa",
	total: "Tổng cộng",
	total_amount: "Tổng số tiền",
	transaction_no: "Số giao dịch",
	transactions: "Giao dịch",
	try_for_free: "Dùng thử miễn phí",
	type: "Loại",
	types: "Các loại",
	un_archive: "Bỏ lưu trữ",
	unit: "Đơn vị",
	units: "Các đơn vị",
	unloading_country: "Quốc gia dỡ hàng",
	unloading_point: "Điểm dỡ hàng",
	unloading_port: "Cảng dỡ hàng",
	unsubscribe: "Hủy đăng ký",
	unsubscribe_confirm_message:
		"Nếu bạn hủy đăng ký, bạn sẽ không thể sử dụng các tính năng của hệ thống một cách hiệu quả. Bạn có chắc chắn muốn hủy đăng ký không?",
	upload_new_image: "Tải ảnh mới",
	use_code: "Sử dụng mã",
	use_invitation_code: "Sử dụng mã mời",
	use_invitation_code_description: "Nhập mã mời của bạn đã gửi vào hộp thư của bạn.",
	use_invitation_code_success_message: "Mã mời đã được sử dụng thành công.",
	user_profile: "Hồ sơ của tôi",
	vehicle: "Phương tiện",
	vehicles: "Các phương tiện",
	verified_partner: "Đối tác đã xác minh",
	verify_password: "Xác nhận mật khẩu",
	visibility: "Tầm nhìn",
	warehouse: "Kho",
	warehouses: "Các kho",
	website: "Trang web",
	week: "Tuần",
	welcome: "Chào mừng",
	what_we_are_doing: "Chúng tôi đang làm gì",
	year: "Năm",
	year_lowercase: "năm",
	yearly: "Hàng năm",
	yes: "Có",
	you_dont_have_any_active_subscription: "Bạn không có đăng ký nào đang hoạt động.",
};
