<template>
	<v-navigation-drawer v-model="visibility" absolute temporary position="fixed" @input="changeAction">
		<nav aria-label="Main navigation">
			<v-list density="compact" nav tag="ul">
				<v-list-item v-for="(item, i) in items" :key="i" class="my-2 flex-grow-1" tag="li">
					<v-btn
						variant="text"
						elevation="0"
						@click="goToElement(item.tag)"
						:aria-label="`Navigate to ${$t(item.tag)}`"
					>
						<v-icon class="mr-3" color="secondary">
							{{ item.icon }}
						</v-icon>
						<span>{{ $t(`${item.tag}`) }}</span>
					</v-btn>
				</v-list-item>

				<v-divider />

				<v-list-item class="my-2" tag="li">
					<v-btn variant="text" @click="changeLanguageDialog.show" elevation="0" aria-label="Change language">
						<v-icon class="mr-2" color="secondary">icon-translate</v-icon>
						<span>{{ $t(`$language.${currentLanguage}`) }}</span>
					</v-btn>
				</v-list-item>

				<!-- <v-list-item class="mx-2" tag="li">
					<v-switch
						v-model="isDark"
						:label="$t(`theme`)"
						color="secondary"
						@change="switchTheme"
						aria-label="Toggle theme"
					/>
				</v-list-item> -->
			</v-list>
		</nav>
		<change-language-dialog
			v-model="changeLanguageDialog.visibility"
			:value="changeLanguageDialog.visibility"
			@closed="changeLanguageDialog.hide"
		/>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { getSetting, setSetting, Setting } from "../../../../data/local/ls";
import ChangeLanguageDialog from "../../../partials/dialogs/ChangeLanguageDialog.vue";

export default {
	components: { ChangeLanguageDialog },
	props: { value: Boolean },
	emits: ["input", "goTo"],
	data() {
		return {
			visibility: false,
			isDark: null,
			items: [
				{ icon: "icon-home", tag: "home_page" },
				{ icon: "icon-dashboard", tag: "benefits" },
				{ icon: "icon-refresh", tag: "process" },
				{ icon: "icon-money", tag: "pricing" },
				{ icon: "icon-comment", tag: "contact" },
			],
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
		};
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		currentLanguage() {
			return getSetting(Setting.locale);
		},
		logoLocation() {
			return this.isDark ? "@/assets/antrego_logo_light.png" : "@/assets/antrego_logo_dark.png";
		},
	},
	watch: {
		value(val) {
			this.visibility = val;
		},
	},
	methods: {
		changeAction(val) {
			if (!val) this.$emit("input", false);
		},
		// switchTheme() {
		// 	setSetting(Setting.dark_mode, this.isDark);
		// 	location.reload();
		// },
		goToPanel() {
			this.$router.push({ name: this.isAuthenticated ? "home" : "signin" });
		},
		goToElement(tag) {
			this.changeAction(false);
			this.$emit("goTo", tag);
		},
	},
};
</script>
