import { render, staticRenderFns } from "./LandingLifecycle.vue?vue&type=template&id=aa8a8842&scoped=true"
import script from "./LandingLifecycle.vue?vue&type=script&lang=js"
export * from "./LandingLifecycle.vue?vue&type=script&lang=js"
import style0 from "./LandingLifecycle.vue?vue&type=style&index=0&id=aa8a8842&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa8a8842",
  null
  
)

export default component.exports