<template>
	<v-card :dark="dark" elevation="0" :color="backgroundColor" class="ant-card" style="overflow: hidden">
		<v-row class="px-4 py-4" no-gutters>
			<v-col cols="auto" class="pr-2">
				<v-icon> {{ type === "export" ? "icon-unload" : "icon-load" }} </v-icon>
			</v-col>
			<v-col cols="auto" v-if="title" class="pl-0" :class="isMobile ? 'v-typography--body-sm' : 'v-typography--body'">
				{{ title }}
			</v-col>
		</v-row>
		<v-row v-if="data" class="py-1 px-4" no-gutters>
			<v-col :class="isMobile ? 'v-typography--title2' : 'v-typography--title3'">
				{{ data.lastOneYearCount }} <span class="v-typography--body-sm">{{ $t("containers") }} </span>
			</v-col>
			<!-- 
			<v-col cols="4" class="text-h4 text-center pb-0">
				{{ data.currentYearCount }}
			</v-col>
			<v-col cols="4" class="text-h4 text-center pb-0">
				{{ data.currentMonthCount }}
			</v-col> 
			-->
		</v-row>
		<!-- 
		<v-row>
			<v-col cols="4" class="text-caption text-center pt-0">
				{{ $t("last_one_year") }}
			</v-col>
			<v-col cols="4" class="text-caption text-center pt-0">
				{{ $t("this_year") }}
			</v-col>
			<v-col cols="4" class="text-caption text-center pt-0">
				{{ $t("this_month") }}
			</v-col>
		</v-row> 
		-->

		<vue-apex-charts
			v-if="showChart"
			height="120"
			:options="chart.options"
			:series="chart.series"
			style="border-radius: 4px"
		/>
	</v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
	props: {
		data: Object,
		title: String,
		dark: Boolean,
		backgroundColor: String,
		chartColor: String,
		chartGradientColors: Array,
		type: String,
	},
	components: { VueApexCharts },
	data() {
		return {
			showChart: false,
			chart: {
				series: [
					{
						name: this.$t("containers"),
						data: [],
					},
				],
				options: {
					chart: {
						type: "area",
						background: "transparent",
						sparkline: { enabled: true },
					},
					dataLabels: { enabled: false },
					theme: { mode: this.dark ? "dark" : "light" },
					stroke: { curve: "smooth", lineCap: "butt", width: 2 },
					// colors: this.chartColor ? [this.chartColor] : undefined,
					colors: [],
					fill: {
						colors: this.chartGradientColors,
						opacity: 0.3,
						type: "gradient",
					},
					xaxis: {
						categories: [],
						// crosshairs: { width: 0.5 },
					},
					yaxis: {
						min: 0,
						max: undefined,
					},
					tooltip: {
						enabled: true,
						x: {
							formatter: function (value, data) {
								return new moment.utc(data.w.config.series[data.seriesIndex].data[data.dataPointIndex].x).format(
									"MMMM"
								);
							},
						},
					},
				},
			},
		};
	},
	watch: {
		data(newValue) {
			this.handleIncomingData();
		},
		type(newVal) {
			this.updateChartColors();
		},
	},
	created() {
		this.updateChartColors();
		this.handleIncomingData();
	},
	methods: {
		handleIncomingData() {
			if (this.data) {
				this.chart.series[0].data = [];
				let maxY = 0;
				for (const key in this.data.monthBasedCounts) {
					let val = this.data.monthBasedCounts[key];
					this.chart.series[0].data.push({
						x: key,
						y: val,
					});
					if (val * 1.1 > maxY) maxY = val * 1.1;
				}
				this.chart.options.yaxis.max = maxY;
				this.showChart = true;
			}
		},
		updateChartColors() {
			this.chart.options.colors = this.getChartColors;
		},
	},
	computed: {
		getChartColors() {
			return this.type === "export" ? ["#edc264"] : ["#bf86fc"];
		},
		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
	},
};
</script>

<style scoped>
/* Add any additional scoped styles here */
</style>
