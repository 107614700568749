<template>
	<v-container>
		<v-card class="ant-card py-2 px-4" elevation="0">
			<v-toolbar flat dense>
				<v-toolbar-title class="v-typography--title">{{ $t("attachments") }}</v-toolbar-title>
			</v-toolbar>

			<v-divider class="mb-4"></v-divider>

			<v-card-text class="pb-0 px-0">
				<common-table
					:headers="attachmentListHeaders"
					:items="shipment.attachments"
					:options.sync="tableOptions"
					:items-length="shipment.attachments.length"
					:hide-pagination="true"
					:entity-name="$t('attachments')"
					disable-sort
				>
					<template #item.name="{ item }">
						<a @click.stop="handlePreviewFileAction(item)" class="primary--text underlined">
							{{ item.name }}
						</a>
					</template>

					<template #item.accessModifier="{ item }">
						<v-icon small class="ml-1">
							{{ getVisibilityIcon(item.accessModifier) }}
						</v-icon>
						{{ getVisibilityValue(item.accessModifier) }}
					</template>

					<template #item.note="{ item }">
						<span class="v-typography--body-sm">
							{{ truncateNote(item.note, 20) }}
						</span>
					</template>

					<template #item.actions="{ item }">
						<div class="d-flex justify-end">
							<v-btn icon @click="attachmentNoteAction(item)">
								<v-icon small>icon-clip</v-icon>
							</v-btn>
							<v-btn icon @click.stop="attachmentDownloadAction(item)">
								<v-icon small>icon-download</v-icon>
							</v-btn>

							<v-btn icon v-if="getEditableStatus(item)" @click.stop="attachmentDialog.show(item)">
								<v-icon small>icon-edit</v-icon>
							</v-btn>

							<v-btn icon v-if="getEditableStatus(item)" @click.stop="attachmentDeleteAction(item)">
								<v-icon small>icon-delete</v-icon>
							</v-btn>
						</div>
					</template>
				</common-table>
				<v-row class="mt-1 px-2" align="center">
					<v-col>
						<v-btn color="accent" outlined large elevation="0" block @click="attachmentDialog.show()">
							<v-icon left>icon-plus</v-icon>
							{{ $t("add_item", { item: $t("attachments") }) }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<file-view-dialog v-model="fileViewDialogVisibility" :item="fileViewDialogItem" @closed="onFileViewDialogClosed" />

		<shipment-attachment-dialog
			v-model="attachmentDialog.visibility"
			:item="attachmentDialog.item"
			:shipmentId="shipment.id"
			:myRole="myRole"
			@saved="attachmentDialog.saved"
			@deleted="attachmentDialog.deleted"
		/>
	</v-container>
</template>

<script>
import ShipmentAttachmentDialog from "../dialog/ShipmentAttachmentDialog.vue";
import FileViewDialog from "../../../partials/dialogs/FileViewDialog.vue";
import remote from "../../../../data/remote";
import CommonTable from "../../../partials/CommonTable.vue";
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import uiMixin from "../../../../store/interfaces/ui.mixin";

export default {
	mixins: [paginationMixin, uiMixin],
	components: {
		ShipmentAttachmentDialog,
		FileViewDialog,
		CommonTable,
	},
	props: {
		shipment: {
			type: Object,
			required: true,
		},
		myRole: {
			type: String,
			required: true,
		},
		iAmSenderCompany: {
			type: Boolean,
			required: true,
		},
		iAmReceiverCompany: {
			type: Boolean,
			required: true,
		},
		iAmSenderCustomsAgency: {
			type: Boolean,
			required: true,
		},
		iAmReceiverCustomsAgency: {
			type: Boolean,
			required: true,
		},
		corporation: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			downloadingAttachmentId: null,
			attachmentListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					width: 250,
				},
				{
					text: this.$t("visibility"),
					value: "accessModifier",
					width: 300,
				},
				{
					text: this.$t("notes"),
					value: "note",
				},
				{
					value: "actions",
					text: this.$t("actions"),
				},
			],
			attachmentDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.attachmentDialog.item = item;
					this.attachmentDialog.visibility = true;
				},
				hide: () => {
					this.attachmentDialog.item = null;
					this.attachmentDialog.visibility = false;
				},
				saved: () => {
					this.attachmentDialog.hide();
					this.loadAttachments();
					this.$emit("show-snackbar", {
						message: this.$t(this.attachmentDialog.item ? "$message.updated" : "$message.created", {
							item: this.$t("attachment"),
						}),
					});
				},
				deleted: () => {
					this.attachmentDialog.hide();
					this.loadAttachments();
					this.$emit("show-snackbar", {
						message: this.$t("$message.deleted", {
							item: this.$t("attachment"),
						}),
					});
				},
			},
			fileViewDialogVisibility: false,
			fileViewDialogItem: null,
		};
	},
	methods: {
		truncateNote(note, maxLength) {
			if (!note) return "";
			return note.length > maxLength ? note.substring(0, maxLength) + "..." : note;
		},
		loadAttachments() {
			remote.shipments.attachments.filter(this.shipment.id, {
				onSuccess: (result) => (this.shipment.attachments = result),
			});
		},
		getVisibilityIcon(accessModifier) {
			switch (accessModifier) {
				case "sender_private":
				case "receiver_private":
					return "icon-eye-crossed";
				default:
					return "icon-eye";
			}
		},
		getVisibilityValue(accessModifier) {
			if (this.iAmSenderCompany) {
				switch (accessModifier) {
					case "sender_private":
						return this.$t("$accessModifier.$description.private");
					case "sender_protected":
						return this.$t("$accessModifier.$description.shared_with_custom");
					case "sender_receiver":
						return this.$t("$accessModifier.$description.shared_with_receiver");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			} else if (this.iAmReceiverCompany) {
				switch (accessModifier) {
					case "receiver_private":
						return this.$t("$accessModifier.$description.private");
					case "receiver_protected":
						return this.$t("$accessModifier.$description.shared_with_custom");
					case "sender_receiver":
						return this.$t("$accessModifier.$description.shared_with_sender");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			} else if (this.iAmSenderCustomsAgency) {
				switch (accessModifier) {
					case "sender_protected":
						return this.$t("$accessModifier.$description.hidden_from_receiver");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			} else if (this.iAmReceiverCustomsAgency) {
				switch (accessModifier) {
					case "receiver_protected":
						return this.$t("$accessModifier.$description.hidden_from_sender");
					case "public":
						return this.$t("$accessModifier.$description.shared_with_everyone");
					default:
						return accessModifier;
				}
			}

			return accessModifier;
			// if (accessModifier == "public")
			// 	return
		},
		getEditableStatus(attachment) {
			if (this.iAmSenderCompany) {
				return attachment.ownerId === this.corporation.id || this.shipment.sender.customsAgency?.customsAgencyId;
			} else if (this.iAmReceiverCompany) {
				return attachment.ownerId === this.corporation.id || this.shipment.receiver.customsAgency?.customsAgencyId;
			} else if (this.iAmSenderCustomsAgency) {
				return attachment.ownerId === this.corporation.id;
			} else if (this.iAmReceiverCustomsAgency) {
				return attachment.ownerId === this.corporation.id;
			}
			return attachment.ownerId === this.corporation.id || (this.iAmSenderCompany && this);
		},
		attachmentNoteAction(attachment) {
			this.$emit("show-alert-dialog", {
				title: this.$t("note"),
				message: attachment.note,
				buttonText: this.$t("hide"),
			});
		},

		handlePreviewFileAction(item) {
			if (!item || !item.file || !item.file.key) {
				console.error("Invalid file object passed:", item);
				return;
			}

			remote.files.get(item.file.key, {
				onSuccess: (result) => {
					const fileBlob = new Blob([result]);
					const fileUrl = window.URL.createObjectURL(fileBlob);
					this.showFileViewDialog({
						url: fileUrl,
						type: item.file.type,
						name: item.name,
					});
				},
			});
		},
		showFileViewDialog(file) {
			this.fileViewDialogItem = file;
			this.fileViewDialogVisibility = true;
		},
		hideFileViewDialog() {
			this.fileViewDialogItem = null;
			this.fileViewDialogVisibility = false;
		},
		onFileViewDialogClosed() {
			this.hideFileViewDialog();
		},
		attachmentDownloadAction(attachment) {
			this.downloadingAttachmentId = attachment.id;
			remote.files.get(attachment.file.key, {
				onSuccess: (result) => {
					this.downloadingAttachmentId = null;
					const url = window.URL.createObjectURL(new Blob([result]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", attachment.file.key); //or any other extension
					document.body.appendChild(link);
					link.click();
					link.remove();
				},
				onFail: (_) => (this.downloadingAttachmentId = null),
			});
		},
		attachmentDeleteAction(item) {
			this.showConfirmDialog({
				message: this.$t("$message.confirm", {
					function: this.$t("toDelete"),
				}),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.attachments.delete(item.id, this.shipment.id, {
						onSuccess: () => {
							this.deleting = false;
							this.loadAttachments();
							this.$emit("show-snackbar", {
								message: this.$t("$message.deleted", {
									item: this.$t("attachment"),
								}),
							});
						},
						onFail: (_) => {
							this.deleting = false;
							this.$emit("show-snackbar", {
								message: this.$t("$message.delete_failed", {
									item: this.$t("attachment"),
								}),
								color: "error",
							});
						},
					});
				},
			});
		},
		showFileViewDialog(file) {
			this.fileViewDialogItem = file;
			this.fileViewDialogVisibility = true;
		},
		hideFileViewDialog() {
			this.fileViewDialogItem = null;
			this.fileViewDialogVisibility = false;
		},
		onFileViewDialogClosed() {
			this.hideFileViewDialog();
		},
	},
};
</script>
