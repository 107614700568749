<template>
	<page-template>
		<template #content>
			<v-container v-if="user" class="pa-8" style="max-width: 800px">
				<v-card-title class="v-typography--title pa-2"> {{ $t("user_profile") }} </v-card-title>

				<v-row
					class="mb-1 align-center"
					:class="{
						'justify-space-between': $vuetify.breakpoint.smAndUp,
						'justify-center': $vuetify.breakpoint.smAndDown,
					}"
				>
					<v-card-subtitle class="pa-4">
						{{ $t("manage_profile") }}
					</v-card-subtitle>

					<v-spacer></v-spacer>

					<v-col
						cols="auto"
						class="d-flex py-2"
						:class="{
							'flex-column': $vuetify.breakpoint.smAndDown,
							'flex-row': $vuetify.breakpoint.smAndUp,
						}"
					>
						<v-btn
							v-if="!editMode"
							@click="dialog.show"
							class="text-capitalize mr-2"
							:class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
							text
							outlined
							elevation="0"
							density="comfortable"
						>
							<v-icon class="mr-2" small>icon-edit</v-icon>
							{{ $t("change_password") }}
						</v-btn>

						<v-btn
							v-if="!editMode"
							class="text-capitalize"
							text
							outlined
							elevation="0"
							density="comfortable"
							@click="editButtonAction()"
						>
							<v-icon class="mr-2" small>icon-edit</v-icon>
							{{ $t("edit_profile") }}
						</v-btn>
					</v-col>
				</v-row>

				<v-card outlined rounded="lg" elevation="0">
					<v-card-text>
						<v-form ref="form" v-model="valid">
							<v-row>
								<v-col cols="12" md="9" class="pa-0 pl-4">
									<v-row no-gutters class="align-center ml-1 mt-5">
										<v-col cols="auto" class="mr-1">
											<title-value-list-item :value="user.firstName" class="font-weight-bold text-h6" />
										</v-col>

										<v-col cols="auto">
											<title-value-list-item :value="user.lastName" class="font-weight-bold text-h6" />
										</v-col>
									</v-row>
									<v-row class="ma-0" align="baseline" v-if="company">
										<v-icon class="mr-1">icon-company-profile</v-icon>
										<title-value-list-item :value="company.name" />
									</v-row>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-card>

				<v-card class="mt-4" outlined rounded="lg" elevation="0">
					<v-form ref="form" v-model="valid" class="pa-6">
						<v-row>
							<v-col>
								<title-value-list-item v-if="!editMode" :title="$t('name')" :value="user.firstName" />
								<v-text-field
									v-if="editMode"
									v-model="editItem.firstName"
									class="rounded-xl"
									:label="$t('name')"
									:rules="rules.firstName"
								/>
							</v-col>
							<v-col>
								<title-value-list-item v-if="!editMode" :title="$t('last_name')" :value="user.lastName" />
								<v-text-field
									v-if="editMode"
									v-model="editItem.lastName"
									class="ml-4 rounded-xl"
									:label="$t('last_name')"
									:rules="rules.last_name"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<title-value-list-item v-if="!editMode" :title="$t('email')" :value="user.email" />
								<v-text-field
									v-if="editMode"
									v-model="editItem.email"
									class="rounded-xl"
									:label="$t('email')"
									:rules="rules.email"
								/>
							</v-col>
						</v-row>
					</v-form>
				</v-card>
				<v-row
					class="mt-2 d-flex align-center flex-wrap"
					:class="{
						'justify-end': $vuetify.breakpoint.mdAndUp,
						'justify-center': $vuetify.breakpoint.smAndDown,
					}"
				>
					<!-- <v-col cols="auto" class="py-2">
						<v-btn text class="red--text text-capitalize">
							{{ $t("delete_account") }}
						</v-btn>
					</v-col> -->

					<v-col v-if="editMode" cols="auto" class="py-2">
						<v-btn class="text-capitalize mx-2" width="120" large elevation="0" text @click="cancelButtonAction()">
							{{ $t("cancel") }}
						</v-btn>
					</v-col>

					<v-col v-if="editMode" cols="auto" class="py-2">
						<v-btn
							variant="text"
							large
							color="primary"
							elevation="0"
							class="text-capitalize text-white rounded-xl"
							@click="updateUser(editItem)"
						>
							{{ $t("save_changes") }}
						</v-btn>
					</v-col>
				</v-row>

				<ext-dialog
					v-model="dialog.visibility"
					:title="$t('change_password')"
					:primaryButtonText="$t('save')"
					@primaryButtonAction="dialog.run"
					:secondaryButtonText="$t('cancel')"
					@secondaryButtonAction="dialog.hide"
					@closed="dialog.hide"
				>
					<template v-slot:body>
						<v-form ref="dialogForm">
							<v-text-field
								v-model="dialog.item.oldPassword"
								:label="$t('current_password')"
								:rules="dialog.rules.oldPassword"
								type="password"
								@keydown.enter="dialog.run()"
							/>
							<v-text-field
								v-model="dialog.item.newPassword"
								:label="$t('new_password')"
								:rules="dialog.rules.newPassword"
								type="password"
								@keydown.enter="dialog.run()"
							/>
							<v-text-field
								v-model="dialog.item.newPasswordAgain"
								:label="`${$t('new_password')} (${$t('again')})`"
								:rules="dialog.rules.newPasswordAgain"
								type="password"
								@keydown.enter="dialog.run()"
							/>
						</v-form>
					</template>
				</ext-dialog>
			</v-container>
		</template>
	</page-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import PageTemplate from "../templates/PageTemplate";
import TitleValueListItem from "../partials/TitleValueListItem";
import remote from "../../data/remote";
import ExtDialog from "../partials/dialogs/ExtDialog.vue";
export default {
	mixins: [uiMixin],

	components: { PageTemplate, TitleValueListItem, ExtDialog },

	data() {
		return {
			user: null,
			editItem: null,
			editMode: false,
			valid: null,
			newPass: "",
			newPassValid: true,
			rules: {
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("email"),
								length: "50",
							});
						return true;
					},
				],
				firstName: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("first_name"),
							});
						if (v && v.length > 25)
							return this.$t("$validation.max_length", {
								item: this.$t("first_name"),
								length: "25",
							});
						return true;
					},
				],
				last_name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("last_name"),
							});
						if (v && v.length > 25)
							return this.$t("$validation.max_length", {
								item: this.$t("last_name"),
								length: "25",
							});
						return true;
					},
				],
			},

			dialog: {
				visibility: false,
				showErrors: false,
				running: false,
				item: {
					oldPassword: "",
					newPassword: "",
					newPasswordRepeat: "",
				},
				rules: {
					oldPassword: [
						(v) =>
							!this.dialog.showErrors ||
							Boolean(v) ||
							this.$t("$validation.required", {
								item: this.$t("current_password"),
							}),
					],
					newPassword: [
						(v) => {
							this.newPass = v;
							this.newPassValid = false;
							if (this.dialog.showErrors) {
								if (!v)
									return this.$t("$validation.required", {
										item: this.$t("new_password"),
									});
								else if (v && v.length < 6)
									return this.$t("$validation.min_length", {
										item: this.$t("new_password"),
										length: "6",
									});
							}
							this.newPassValid = true;
							return true;
						},
					],
					newPasswordAgain: [
						(v) => {
							return (
								!this.dialog.showErrors ||
								!this.newPassValid ||
								v === this.newPass ||
								this.$t("$validation.password_repeat_warning")
							);
						},
					],
				},
				show: () => {
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.visibility = false;
					this.dialog.reset();
				},
				reset: () => {
					this.dialog.item.oldPassword = "";
					this.dialog.item.newPassword = "";
					this.dialog.item.newPasswordRepeat = "";
					this.dialog.showErrors = false;
					this.dialog.running = false;
				},
				run: async () => {
					this.dialog.showErrors = true;
					if (await this.$refs.dialogForm.validate()) {
						remote.auth.changePassword(this.dialog.item, {
							onSuccess: () => {
								this.showSnackBar({
									message: this.$t("$message.updated", {
										item: this.$t("password"),
									}),
								});
								this.dialog.hide();
							},
							onFail: (error) => {
								if (error) console.error(error);
							},
						});
					}
				},
			},
		};
	},

	computed: {
		...mapGetters(["company"]),
	},

	methods: {
		...mapActions("auth", ["signOut"]),
		cancelButtonAction() {
			this.editMode = !this.editMode;
			this.editItem = null;
		},
		editButtonAction() {
			this.editItem = Object.assign({}, this.user);
			this.editMode = !this.editMode;
		},
		loadUser() {
			this.showProgressBar();
			remote.auth.getUserProfile({
				onSuccess: (result) => {
					this.user = result;
					this.hideProgressBar();
				},
				onFail: (_) => this.hideProgressBar(),
			});
		},
		updateUser(item) {
			this.showProgressBar();
			if (this.valid) {
				if (this.company) {
					remote.employees.updateMyEmployee(
						{ user: item },
						{
							onSuccess: (employee) => {
								this.user = employee.user;
								this.hideProgressBar();
								this.showSnackBar({
									message: this.$t("$message.updated", {
										item: this.$t("profile"),
									}),
								});
								this.editMode = false;
							},
							onFail: (_) => this.hideProgressBar(),
						}
					);
				} else {
					remote.auth.updateUserProfile(item, {
						onSuccess: (user) => {
							this.user = user;
							this.hideProgressBar();
							this.showSnackBar({
								message: this.$t("$message.updated", {
									item: this.$t("profile"),
								}),
							});
							this.$editEvent("user_profile");
							this.editMode = false;
						},
						onFail: (_) => this.hideProgressBar(),
					});
				}
			} else this.$refs.form.validate();
		},
	},

	created() {
		this.$trackPageView(this.$route.path);
		this.loadUser();
	},
};
</script>
