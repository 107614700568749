<template>
	<v-container class="pt-0" v-if="shipment">
		<v-card elevation="0" class="ant-card px-4 pb-2">
			<v-card-title class="pa-0">
				<v-col class="d-flex align-center grey--text text--darken-2" cols="auto">
					{{ $t("booking_details") }}
				</v-col>
				<v-col class="d-flex align-center" cols="auto">
					<v-text-field v-if="editMode && iAmOwner" v-model="editItem.bookingCode" :rules="rules.bookingCode" />
					<span v-else-if="shipment.bookingCode" class="grey--text text--darken-2"> #{{ shipment.bookingCode }} </span>
				</v-col>
				<v-spacer></v-spacer>
				<v-col class="d-flex justify-end px-0" cols="auto">
					<v-btn v-if="!isMobile" @click="archiveAction" text>
						<v-icon size="15">{{ isArchived ? "icon-archive-arrow-up" : "icon-archive-arrow-down" }}</v-icon>
						<span class="ml-1 v-typography--body-sm">
							{{ $t(isArchived ? "un_archive" : "archive") }}
						</span>
					</v-btn>
					<v-btn text v-if="!editMode" @click="sendEmail">
						<v-icon size="15"> icon-email </v-icon>
						<span class="ml-1 v-typography--body-sm">
							{{ $t("send_email") }}
						</span>
					</v-btn>
					<v-btn text v-if="!editMode" @click="editAction">
						<v-icon size="15"> icon-edit </v-icon>
						<span class="ml-1 v-typography--body-sm">
							{{ $t("edit") }}
						</span>
					</v-btn>
					<v-btn text v-if="editMode" @click="cancelEdit">
						<v-icon size="15"> icon-cross </v-icon>
						<span class="ml-1 v-typography--body-sm">
							{{ $t("cancel") }}
						</span>
					</v-btn>
					<v-btn text v-if="editMode" @click="saveAction">
						<v-icon size="15"> icon-check </v-icon>
						<span class="ml-1 v-typography--body-sm">
							{{ $t("save") }}
						</span>
					</v-btn>
				</v-col>
			</v-card-title>
			<v-divider class="mb-4" />

			<v-form v-model="valid" ref="form" class="pa-4 pb-0">
				<v-row>
					<v-col cols="12" sm="4">
						<info-section
							v-if="iAmReceiverCompany || !editMode"
							icon="icon-company"
							:title="$t('sender')"
							:content="[
								shipment.sender.company.name,
								shipment.sender.customsAgency ? $t('customs') + ': ' + shipment.sender.customsAgency.name : null,
							]"
						/>
						<template v-if="editMode && iAmSenderCompany">
							<partner-select
								v-model="editItem.sender.customsAgency.partnerId"
								:label="$t('sender_customs_agency')"
								:eager="true"
								:types="[partnerTypes.CustomsAgency]"
								clearable
								prepend-icon="icon-company"
								@change="customsPartnerChangeAction"
							/>
						</template>
					</v-col>

					<v-col cols="12" sm="4">
						<info-section
							v-if="iAmSenderCompany || !editMode"
							icon="icon-company"
							:title="$t('receiver')"
							:content="[
								shipment.receiver.company.name,
								shipment.receiver.customsAgency ? $t('customs') + shipment.receiver.customsAgency.name : null,
							]"
						/>
						<template v-if="editMode && iAmReceiverCompany">
							<partner-select
								v-model="editItem.receiver.customsAgency.partnerId"
								:label="$t('receiver_customs_agency')"
								:eager="true"
								:types="[partnerTypes.CustomsAgency]"
								clearable
								prepend-icon="icon-company"
								@change="customsPartnerChangeAction"
							/>
						</template>
					</v-col>

					<v-col cols="12" sm="4">
						<info-section
							v-if="!editMode || !iAmOwner"
							icon="icon-shuffle"
							:title="$t('method')"
							:content="$t(`$shipment.$method.${shipment.method}`)"
						/>
						<template v-else-if="editMode && iAmOwner">
							<shipment-method-select
								v-model="editItem.method"
								:label="$t('method')"
								:rules="rules.method"
								@change="methodChangeAction"
							/>
						</template>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="4">
						<info-section
							v-if="!editMode || !iAmOwner"
							icon="icon-load"
							:title="isSeaShipment ? $t('loading_port') : $t('loading_point')"
							:content="[
								shipment.loadingCountry ? $t(`$country.${shipment.loadingCountry.name}`) : '',
								isSeaShipment ? (shipment.loadingPort ? shipment.loadingPort.name : '') : shipment.loadingPoint,
							]"
						/>
						<template v-else-if="editMode && iAmOwner">
							<country-select
								v-model="editItem.loadingCountry.id"
								:options="countries"
								:label="$t('loading_country')"
								:rules="rules.loadingCountry"
								prepend-icon="icon-world"
								@change="loadingCountryChangeAction"
							/>
							<template v-if="isSeaShipment">
								<port-select
									v-model="editItem.loadingPort.code"
									:label="$t('loading_port')"
									:countryId="editItem.loadingCountry.id"
									:disabled="!editItem.loadingCountry.id"
									:eager="Boolean(editItem.loadingCountry.id)"
									:rules="rules.loadingPort"
									prepend-icon="icon-load"
									@change="loadingPortChangeAction"
								/>
							</template>
							<template v-else>
								<v-text-field
									v-model="editItem.loadingPoint"
									:label="$t('loading_point')"
									:rules="rules.loadingPoint"
									prepend-icon="icon-load"
								/>
							</template>
						</template>
					</v-col>

					<v-col cols="12" sm="4">
						<info-section
							v-if="!editMode || !iAmOwner"
							icon="icon-unload"
							:title="isSeaShipment ? $t('unloading_port') : $t('unloading_point')"
							:content="[
								shipment.unloadingCountry ? $t(`$country.${shipment.unloadingCountry.name}`) : '',
								isSeaShipment ? (shipment.unloadingPort ? shipment.unloadingPort.name : '') : shipment.unloadingPoint,
							]"
						/>
						<template v-else-if="editMode && iAmOwner">
							<country-select
								v-model="editItem.unloadingCountry.id"
								:options="countries"
								:label="$t('unloading_country')"
								:rules="rules.unloadingCountry"
								prepend-icon="icon-world"
								@change="unloadingCountryChangeAction"
							/>
							<template v-if="isSeaShipment">
								<port-select
									v-model="editItem.unloadingPort.code"
									:label="$t('unloading_port')"
									:countryId="editItem.unloadingCountry.id"
									:disabled="!editItem.unloadingCountry.id"
									:eager="Boolean(editItem.unloadingCountry.id)"
									:rules="rules.unloadingPort"
									prepend-icon="icon-unload"
									@change="unloadingPortChangeAction"
								/>
							</template>
							<template v-else>
								<v-text-field
									v-model="editItem.unloadingPoint"
									:label="$t('unloading_point')"
									:rules="rules.unloadingPoint"
									prepend-icon="icon-unload"
								/>
							</template>
						</template>
					</v-col>

					<v-col cols="12" sm="4">
						<info-section
							v-if="!editMode || !iAmOwner"
							icon="icon-document"
							:title="$t('shipping_term')"
							:content="shipment.shippingTerm ? `${shipment.shippingTerm.name} (${shipment.shippingTerm.code})` : ''"
						/>
						<template v-else-if="editMode && iAmOwner">
							<shipping-term-select
								v-model="editItem.shippingTerm.code"
								:label="$t('shipping_term')"
								:clearable="true"
								:eager="true"
								:rules="rules.shippingTerm"
								prepend-icon="icon-shuffle"
								@change="shippingTermChangeAction"
							/>
						</template>
					</v-col>
				</v-row>
				<v-divider class="my-4" />
				<v-row v-if="totalAmount != null" align="center" class="pa-2">
					<v-col cols="6" class="d-flex align-center">
						<v-icon color="black" class="mr-2">icon-money</v-icon>
						<span class="v-typography--body1">{{ $t("total_amount") }}</span>
					</v-col>
					<v-col cols="6" class="text-center">
						<span class="text-lg font-weight-bold">
							{{
								totalAmount.toLocaleString($lang, {
									style: "currency",
									currency: shipment.currency.code,
								})
							}}
						</span>
					</v-col>
				</v-row>
			</v-form>
		</v-card>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CurrencySelect from "../../../controls/CurrencySelect.vue";
import CountrySelect from "../../../controls/CountrySelect.vue";
import PortSelect from "../../../controls/PortSelect.vue";
import ShippingTermSelect from "../../../controls/ShippingTermSelect.vue";
import PartnerSelect from "../../../controls/PartnerSelect.vue";
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect.vue";
import TitleValueListItem from "../../../partials/TitleValueListItem.vue";
import { partnerTypes } from "../../../../../src/utils/enum";
import { iconHelper as iconHelperMixin } from "../../../mixins/utils.mixin";
import { cloneDeep } from "lodash";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import InfoSection from "./InfoSection.vue";

export default {
	name: "BookingDetails",
	components: {
		CurrencySelect,
		CountrySelect,
		PortSelect,
		ShippingTermSelect,
		PartnerSelect,
		ShipmentMethodSelect,
		TitleValueListItem,
		InfoSection,
	},
	mixins: [iconHelperMixin, uiMixin],
	props: {
		shipment: {
			type: Object,
			required: true,
		},
		iAmOwner: {
			type: Boolean,
			required: true,
		},
		isArchived: {
			type: Boolean,
			required: true,
		},
		iAmSenderCompany: {
			type: Boolean,
			required: true,
		},
		iAmReceiverCompany: {
			type: Boolean,
			required: true,
		},
		iAmSenderCustomsAgency: {
			type: Boolean,
			required: true,
		},
		iAmReceiverCustomsAgency: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			editMode: false,
			editItem: null,
			valid: true,
			rules: {
				method: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("method") })],
				bookingCode: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", { item: this.$t("code") });
						} else if (v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("booking_code"),
								length: "25",
							});
						}
						return true;
					},
				],
				shippingTerm: [
					(v) =>
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("shipping_term") }),
				],
				loadingCountry: [
					(v) =>
						this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("loading_country"),
						}),
				],
				loadingPort: [
					(v) =>
						!this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", { item: this.$t("loading_port") }),
				],
				loadingPoint: [
					(v) => {
						if (this.isSeaShipment) return true;
						if (this.editItem.bookingCode && !Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("loading_point"),
							});
						} else if (v && v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("loading_point"),
								length: "100",
							});
						}
						return true;
					},
				],
				unloadingCountry: [
					(v) =>
						this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_country"),
						}),
				],
				unloadingPort: [
					(v) =>
						!this.isSeaShipment ||
						!this.editItem.bookingCode ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_port"),
						}),
				],
				unloadingPoint: [
					(v) => {
						if (this.isSeaShipment) return true;
						if (this.editItem.bookingCode && !Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("unloading_point"),
							});
						} else if (v && v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("unloading_point"),
								length: "100",
							});
						}
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapState(["countries", "corporation"]),
		...mapGetters(["company", "customsAgency"]),
		partnerTypes: () => partnerTypes,
		isLandShipment() {
			return this.editMode ? this.editItem.method === "land" : this.shipment.method === "land";
		},
		isSeaShipment() {
			return this.editMode ? this.editItem.method === "sea" : this.shipment.method === "sea";
		},
		iAmAnySender() {
			return this.iAmSenderCompany || this.iAmSenderCustomsAgency;
		},
		iAmAnyReceiver() {
			return this.iAmReceiverCompany || this.iAmReceiverCustomsAgency;
		},
		totalAmount() {
			let result = 0;
			if (this.shipment.containers.length)
				for (const container of this.shipment.containers) {
					result += container.totalAmount ? container.totalAmount : 0;
				}
			return result;
		},
	},
	methods: {
		sendEmail() {
			this.$emit("send-email");
		},
		cancelEdit() {
			this.editMode = false;
		},
		methodChangeAction(val) {
			if (val !== "sea") {
				this.editItem.loadingPort = { code: null };
				this.editItem.unloadingPort = { code: null };
			}
			if (val === "sea") {
				this.editItem.loadingPoint = null;
				this.editItem.unloadingPoint = null;
			}
		},
		currencyChangeAction(val) {
			this.editItem.currency.name = val?.name;
			this.editItem.currency.symbol = val?.symbol;
		},
		customsPartnerChangeAction(val) {
			if (this.iAmSenderCompany)
				this.editItem.sender.customsAgency = {
					customsAgencyId: val ? val.corporationId : "",
					partnerId: val ? val.id : "",
					name: val ? val.name : "",
				};
			else if (this.iAmReceiverCompany)
				this.editItem.receiver.customsAgency = {
					customsAgencyId: val ? val.corporationId : "",
					partnerId: val ? val.id : "",
					name: val ? val.name : "",
				};
		},
		shippingTermChangeAction(val) {
			this.editItem.shippingTerm.name = val?.name ?? null;
		},
		loadingCountryChangeAction(_val) {
			this.editItem.loadingPort.code = null;
		},
		unloadingCountryChangeAction(_val) {
			this.editItem.unloadingPort.code = null;
		},
		loadingPortChangeAction(val) {
			this.editItem.loadingPort.portId = val.id;
			this.editItem.loadingPort.name = val.name;
			this.editItem.loadingPort.type = val.type;
		},
		unloadingPortChangeAction(val) {
			this.editItem.unloadingPort.portId = val.id;
			this.editItem.unloadingPort.name = val.name;
			this.editItem.unloadingPort.type = val.type;
		},
		editAction() {
			this.editItem = cloneDeep(this.shipment);

			if (this.iAmSenderCompany) {
				if (!this.shipment.sender.customsAgency) this.editItem.sender.customsAgency = { partnerId: null };
				delete this.editItem.receiver.customsAgency;
			}
			if (this.iAmReceiverCompany) {
				if (!this.shipment.receiver.customsAgency) this.editItem.receiver.customsAgency = { partnerId: null };
				delete this.editItem.sender.customsAgency;
			}

			if (!this.editItem.shippingTerm) this.editItem.shippingTerm = { code: null };

			let loadingCountry;
			if (this.editItem.loadingPort && this.editItem.loadingPort.code) {
				loadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.editItem.loadingPort.code));
			} else {
				this.editItem.loadingPort = { code: null };
				loadingCountry = this.countries.find((c) => c.id === this.shipment.sender.company.countryId);
			}
			this.editItem.loadingCountry = cloneDeep(loadingCountry);

			let unloadingCountry;
			if (this.editItem.unloadingPort && this.editItem.unloadingPort.code) {
				unloadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.editItem.unloadingPort.code));
			} else {
				this.editItem.unloadingPort = { code: null };
				unloadingCountry = this.countries.find((c) => c.id === this.shipment.receiver.company.countryId);
			}
			this.editItem.unloadingCountry = cloneDeep(unloadingCountry);

			this.editMode = true;
		},
		saveAction() {
			if (this.valid) {
				this.saving = true;
				remote.shipments.update(this.editItem, {
					onSuccess: (result) => {
						this.$emit("update:shipment", result);
						this.saving = false;
						this.editMode = false;
						this.$editEvent("shipment");
						this.showSnackBar({
							message: this.$t("$message.updated", {
								item: this.$t("shipment"),
							}),
						});
					},
					onError: () => {
						this.saving = false;
					},
				});
			} else {
				this.$refs.form.validate();
			}
		},
		archiveAction() {
			if (this.isArchived) {
				remote.shipments.unarchive(this.shipment, {
					onSuccess: (result) => {
						this.$emit("update:shipment", result);
						this.showSnackBar({
							message: this.$t("$shipment.un_archiving_success_message"),
						});
					},
					onError: (error) => {
						this.showSnackBar({
							message: this.$t("$shipment.un_archiving_failure_message"),
							color: "error",
						});
					},
				});
			} else {
				remote.shipments.archive(this.shipment, {
					onSuccess: (result) => {
						this.$emit("update:shipment", result);
						this.showSnackBar({
							message: this.$t("$shipment.archiving_success_message"),
						});
					},
					onError: (error) => {
						this.showSnackBar({
							message: this.$t("$shipment.archiving_failure_message"),
							color: "error",
						});
					},
				});
			}
		},
	},
};
</script>
