<template>
	<v-main class="su-background">
		<v-container class="fill-height">
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" lg="6" xl="4">
					<router-link class="text-accent" :to="loading ? '#' : '/'">
						<v-img class="mx-auto mb-12" max-width="240" fit src="@/assets/antrego_white_logo.png" alt="Antrego Logo" />
					</router-link>
					<v-form ref="form">
						<v-card class="ant-card pa-8">
							<v-card-text>
								<v-card-title class="v-typography--title1 px-0">{{ $t("sign_up") }}</v-card-title>
								<v-card-subtitle class="v-typography--body px-0">
									{{ $t("$home.$unauthorized.title") }}
								</v-card-subtitle>
								<v-text-field
									v-model="firstName"
									:label="$t('first_name')"
									:rules="rules.firstName"
									@keydown.enter="register"
									outlined
								/>
								<v-text-field
									v-model="lastName"
									:label="$t('last_name')"
									:rules="rules.lastName"
									@keydown.enter="register"
									outlined
								/>
								<v-text-field
									v-model="email"
									:label="$t('email')"
									:rules="rules.email"
									@keydown.enter="register"
									outlined
								/>
								<v-text-field
									:append-icon="showPassword ? 'icon-eye' : 'icon-eye-crossed'"
									:type="showPassword ? 'text' : 'password'"
									@click:append="showPassword = !showPassword"
									v-model="password"
									:label="$t('password')"
									:rules="rules.password"
									@keydown.enter="register"
									outlined
								/>
							</v-card-text>
							<!-- <v-card-actions>
								<v-checkbox 		on-icon="icon-checkbox-checked"
									off-icon="icon-checkbox-unchecked" v-model="isAgreementConfirmed" :rules="rules.distanceSalesContact" />
								<v-btn class="my-4 px-0" text @click="handleContract">
									<v-card-text>
										{{ $t(`confirm_distance_selling_Agreement`) }}
									</v-card-text>
								</v-btn>
							</v-card-actions> -->
							<!-- TODO: ADD CONTRACT FIELD -->
							<v-card-actions>
								<v-btn class="py-7" block color="secondary" @click="register()" :disabled="loading" :loading="loading">
									{{ $t("sign_up") }}
								</v-btn>
							</v-card-actions>

							<v-card-actions>
								<v-spacer />
								<v-card-subtitle>
									{{ $t("redirection_to_sign_in_message") }}
								</v-card-subtitle>
								<v-btn class="subtitle-2" text color="accent" @click="signInButtonAction" :disabled="loading" plain>
									{{ $t("sign_in") }}
								</v-btn>
								<v-spacer />
							</v-card-actions>

							<v-card-actions class="mt-n8 mb-n4">
								<v-spacer />
								<v-card-subtitle>
									{{ $t("forget_password_message") }}
								</v-card-subtitle>
								<v-btn class="subtitle-2" text @click="dialog.show" :disabled="loading" plain>
									{{ $t("reset_password") }}
								</v-btn>
								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-form>
				</v-col>
			</v-row>

			<v-dialog v-model="dialog.visibility" persistent max-width="500px">
				<v-card class="ant-card px-4 py-8">
					<v-card-title class="v-typography--title1">{{ $t("forget_password") }}</v-card-title>
					<v-card-subtitle class="v-typography--body">{{ $t("forget_password") }}</v-card-subtitle>
					<v-card-text>
						<v-text-field :label="this.$t(`email`)" v-model="dialog.item" outlined />
					</v-card-text>
					<v-card-actions>
						<v-btn class="py-7" block color="secondary" @click="sendResetPasswordMail(dialog.item)">
							{{ this.$t("send") }}
						</v-btn>
					</v-card-actions>
					<v-card-actions>
						<v-btn class="subtitle-2" block plain text @click="dialog.hide">
							{{ this.$t("cancel") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</v-main>
</template>

<script>
import { mapActions } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import remote from "../../data/remote";

export default {
	mixins: [uiMixin],
	data() {
		return {
			showPassword: false,
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			invitationType: undefined,
			invitationCode: undefined,
			isAgreementConfirmed: false,
			distanceSalesAgreementFile: null,
			loading: false,
			showErrors: false,
			rules: {
				email: [
					(v) => {
						if (this.showErrors) {
							if (!v)
								return this.$t("$validation.required", {
									item: this.$t("email"),
								});
							else if (!/.+@.+\..+/.test(v))
								return this.$t("$validation.invalid", {
									item: this.$t("email"),
								});
						} else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("email"),
								length: "50",
							});
						return true;
					},
				],
				firstName: [
					(v) =>
						!this.showErrors ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("first_name"),
						}),
					(v) => {
						if (v && v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("first_name"),
								length: "25",
							});
						}
						return true;
					},
				],

				lastName: [
					(v) =>
						!this.showErrors ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("last_name"),
						}),
					(v) => {
						if (v && v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("last_name"),
								length: "25",
							});
						}
						return true;
					},
				],
				password: [
					(v) => {
						if (this.showErrors) {
							if (!v)
								return this.$t("$validation.required", {
									item: this.$t("password"),
								});
							else if (v && v.length < 6)
								return this.$t("$validation.min_length", {
									item: this.$t("password"),
									length: "6",
								});
						}
						return true;
					},
				],
			},
			dialog: {
				item: null,
				visibility: false,
				show: () => {
					this.dialog.item = "";
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
			contractDialog: {
				visibility: false,
				item: null,
				show(file) {
					this.item = file;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				approved: () => {
					this.contractDialog.hide();
					this.isAgreementConfirmed = true;
				},
			},
		};
	},
	methods: {
		...mapActions("auth", ["signUp"]),
		async register() {
			this.showErrors = true;
			if (await this.$refs.form.validate()) {
				this.loading = true;
				this.signUp({
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email,
					password: this.password,
					onSuccess: () => {
						this.loading = false;
						if (this.invitationType && this.invitationCode)
							this.$router.replace({
								name: "accept_invitation",
								params: { type: this.invitationType },
								query: { code: this.invitationCode },
							});
						else this.$router.replace({ name: "home" });
						this.showSnackBar({
							message: this.$t("welcome"),
						});
					},
					onFail: (e) => {
						this.loading = false;
						console.error(e);
					},
				});
			}
		},
		sendResetPasswordMail(item) {
			this.loading = true;
			remote.auth.resetPasswordEmail(item, {
				onSuccess: () => {
					this.showSnackBar({
						message: this.$t("mail_sent"),
					});
					this.loading = false;
					this.dialog.hide();
				},
				onFail: (e) => {
					this.loading = false;
					if (!e.code) this.showAlertDialog({ message: e.message });
				},
			});
		},
		signInButtonAction() {
			this.$router.replace({
				name: "signin",
				query: {
					invitationType: this.invitationType,
					invitationCode: this.invitationCode,
				},
			});
		},

		handleContract() {
			if (this.distanceSalesAgreementFile) {
				this.contractDialog.show(this.distanceSalesAgreementFile);
			} else {
				remote.files.get(contractPath.DistanceSalesAgreement, {
					onSuccess: (result) => {
						const fileBlob = new Blob([result]);
						const fileUrl = window.URL.createObjectURL(fileBlob);
						this.distanceSalesAgreementFile = {
							name: this.$t("distance_sales_agreement"),
							type: "pdf",
							url: fileUrl,
						};
						this.contractDialog.show(this.distanceSalesAgreementFile);
					},
				});
			}
		},
	},
	created() {
		this.invitationType = this.$route.query.invitationType;
		this.invitationCode = this.$route.query.invitationCode;
	},
};
</script>

<style>
.su-background {
	background-image: linear-gradient(to bottom, #43a047, rgba(0, 0, 0, 0.2)),
		url("https://picsum.photos/id/3/1920/1080?blur=7&grayscale");
	background-position: center center;
	background-size: cover;
}
</style>
