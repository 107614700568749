<template>
	<v-row class="d-flex justify-center align-center contact-bg ma-0">
		<v-col cols="12" md="8" lg="6" class="justify-center align-center">
			<v-row class="d-flex justify-center align-center">
				<v-card-subtitle
					:class="isMobile ? 'v-typography--title1' : 'v-typography--title2'"
					class="white--text title-with-line pa-0 text-center"
				>
					{{ $t("contact") }}
				</v-card-subtitle>
			</v-row>
			<v-card-title
				:class="isMobile ? 'v-typography--title' : 'v-typography--title3'"
				class="text-center justify-center white--text pa-0 py-2"
			>
				{{ $t("contact_title") }}
			</v-card-title>
			<p
				:class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'"
				class="text-center justify-center white--text"
			>
				{{ $t("contact_text") }}
			</p>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-row>
						<v-col cols="12" sm="6" class="pb-0">
							<v-text-field
								v-model="currentItem.name"
								:label="$t('first_name_last_name')"
								:rules="rules.name"
								solo
								class="white-error"
							/>
						</v-col>
						<v-col cols="12" sm="6" class="pb-0">
							<v-text-field
								v-model="currentItem.email"
								:label="$t('email')"
								:rules="rules.email"
								solo
								class="white-error"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="pt-0">
							<v-textarea
								v-model="currentItem.message"
								:label="$t('message')"
								:rules="rules.message"
								rows="4"
								solo
								class="bg-white rounded-lg white-error"
							/>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions class="d-flex justify-center mb-6">
				<v-btn color="secondary" elevation="0" class="white--text px-12 py-8" :loading="sending" @click="sendAction">
					{{ $t("send") }}
				</v-btn>
			</v-card-actions>
		</v-col>
	</v-row>
</template>

<script>
import remote from "../../../../data/remote";

export default {
	props: { isMobile: Boolean },
	data() {
		return {
			sending: false,
			valid: true,
			currentItem: {
				name: null,
				email: null,
				message: null,
			},
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("name"),
							});
						return true;
					},
				],
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (v && !/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
				message: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("message"),
							});
						return true;
					},
				],
			},
		};
	},
	methods: {
		sendAction() {
			if (this.valid) {
				this.sending = true;
				remote.feedback.create(this.currentItem, {
					onSuccess: () => {
						this.sending = false;
						this.reset();
						this.showSnackBar({
							message: this.$t("$message.sent", {
								item: this.$t("contact"),
							}),
						});
					},
					onFail: () => {
						this.sending = false;
					},
				});
			} else {
				this.$refs.form.validate();
			}
		},
		reset() {
			this.currentItem = {
				name: null,
				email: null,
				message: null,
			};
			this.$refs.form.reset();
		},
		showSnackBar({ message }) {
			this.$emit("show-snackbar", { message });
		},
	},
};
</script>

<style scoped>
.contact-bg {
	background-image: url("~@/assets/contact_bg.webp");
	width: 100vw;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
}

.v-card {
	background: none;
	box-shadow: none;
}

.title-with-line {
	position: relative;
	display: inline-block;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.3);
	transform-origin: left;
	margin-left: 3px;
}
.white-error >>> .error--text {
	color: rgba(255, 255, 255, 0.7) !important;
}
.white-error >>> input {
	caret-color: white !important;
}

@media (max-width: 600px) {
	.title-with-line::after {
		height: 0;
	}
}
</style>
