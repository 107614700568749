<template>
	<v-card :dark="dark" elevation="0" class="ant-card" style="overflow: hidden">
		<div class="d-flex justify-space-between align-center py-2 px-4">
			<div v-if="title" class="d-flex align-center" :class="isMobile ? 'v-typography--body-sm' : 'v-typography--body'">
				<v-icon>icon-money</v-icon>
				<span class="ml-2"> {{ title }} ({{ currentCurrencyReport.currency.code }}) </span>
			</div>
			<v-select
				v-if="currencyReports && currencyReports.length > 1"
				v-model="selectedCurrencyReport"
				:items="currencyReports"
				:item-text="(i) => $t(`$currency.${i.currency.name}`)"
				item-value="currency.code"
				:label="$t('currency')"
				append-icon="icon-angle-down"
				outlined
				dense
				hide-details
				:menu-props="{ offsetY: true }"
				style="max-width: 200px"
			/>
		</div>

		<v-row v-if="currentCurrencyReport" class="py-1 px-4" no-gutters>
			<v-col cols="6" sm="6" :class="isMobile ? 'v-typography--title2' : 'v-typography--title3'">
				<span class="ma-0">
					{{ currencyFormatter.format(currentCurrencyReport.salesReport.currentYearTotal) }}
				</span>
				<span class="v-typography--body-sm">
					{{ $t("sales") }}
				</span>
			</v-col>

			<v-col cols="6" sm="6" :class="isMobile ? 'v-typography--title2' : 'v-typography--title3'">
				<span class="ma-0">
					{{ currencyFormatter.format(currentCurrencyReport.purchaseReport.currentYearTotal) }}
				</span>
				<span class="v-typography--body-sm">
					{{ $t("purchase") }}
				</span>
			</v-col>

			<!-- <v-col cols="6" sm="3" class="pb-0">
				<v-row class="text-h5 text--darken-1 text-center ma-0 d-block">
					{{ currencyFormatter.format(currentCurrencyReport.salesReport.currentMonthTotal) }}
				</v-row>
				<v-row class="text-caption text-center ma-0 d-block">
					{{ $t("this_month") }}<br />
					({{ $t("sales") }})
				</v-row>
			</v-col>

			<v-col cols="6" sm="3" class="pb-0">
				<v-row class="text-h5 text--darken-1 text-center ma-0 d-block">
					{{ currencyFormatter.format(currentCurrencyReport.purchaseReport.currentMonthTotal) }}
				</v-row>
				<v-row class="text-caption text-center ma-0 d-block">
					{{ $t("this_month") }}<br />
					({{ $t("purchase") }})
				</v-row>
			</v-col> -->
		</v-row>

		<vue-apex-charts v-if="showChart" height="120" :options="chart.options" :series="chart.series" />
	</v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
	props: {
		currencyReports: {
			type: Array,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		dark: {
			type: Boolean,
			default: false,
		},
	},
	components: { VueApexCharts },
	data() {
		return {
			selectedCurrencyReport: null,
			currentCurrencyReport: null,
			showChart: false,
			showSalesChart: false,
			showPurchaseChart: false,
			currencyFormatter: undefined,
			chart: {
				series: [],
				options: {
					chart: {
						type: "area",
						background: "transparent",
						sparkline: { enabled: true },
					},
					dataLabels: { enabled: false },
					theme: { mode: this.dark ? "dark" : "light" },
					stroke: { curve: "smooth", lineCap: "butt", width: 1.5 },
					colors: [this.$vuetify.theme.currentTheme.info, this.$vuetify.theme.currentTheme.accent],
					fill: {
						colors: [this.$vuetify.theme.currentTheme.info, this.$vuetify.theme.currentTheme.accent],
						opacity: 0.3,
						type: "gradient",
					},
					xaxis: {
						categories: [],
					},
					yaxis: {
						min: 0,
						max: undefined,
					},
					tooltip: {
						enabled: true,
						x: {
							formatter: function (value, data) {
								return new moment.utc(data.w.config.series[data.seriesIndex].data[data.dataPointIndex].x).format(
									"MMMM"
								);
							},
						},
						y: {
							formatter: (value) => this.currencyFormatter.format(value),
						},
					},
				},
			},
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
	},
	watch: {
		currencyReports: {
			handler() {
				this.initializeCurrencyReport();
			},
			immediate: true,
		},
		selectedCurrencyReport(newVal) {
			this.initializeCurrencyReport();
		},
	},
	methods: {
		initializeCurrencyReport() {
			if (this.currencyReports && this.currencyReports.length > 0) {
				if (this.currencyReports.length === 1) {
					this.selectedCurrencyReport = this.currencyReports[0].currency.code;
				} else if (!this.selectedCurrencyReport) {
					this.selectedCurrencyReport = this.currencyReports[0].currency.code;
				}
				this.currentCurrencyReport =
					this.currencyReports.find((report) => report.currency.code === this.selectedCurrencyReport) ||
					this.currencyReports[0];
				if (this.currentCurrencyReport) {
					this.currencyFormatter = new Intl.NumberFormat(moment.locale(), {
						style: "currency",
						currency: this.currentCurrencyReport.currency.code,
					});
					this.showSalesChart = Object.values(this.currentCurrencyReport.salesReport.monthBasedTotals).some(
						(val) => val > 0
					);
					this.showPurchaseChart = Object.values(this.currentCurrencyReport.purchaseReport.monthBasedTotals).some(
						(val) => val > 0
					);
					let reports = [];
					if (this.showSalesChart) reports.push("sales");
					if (this.showPurchaseChart) reports.push("purchase");

					let maxY = 0;
					this.chart.series = [];
					reports.forEach((type) => {
						const seriesData = [];
						for (const [key, val] of Object.entries(this.currentCurrencyReport[`${type}Report`].monthBasedTotals)) {
							seriesData.push({ x: key, y: val });
							if (val * 1.1 > maxY) maxY = val * 1.1;
						}
						this.chart.series.push({
							name: this.$t(type),
							data: seriesData,
						});
					});
					this.chart.options.yaxis.max = maxY;

					this.showChart = true;
				} else {
					this.showChart = false;
				}
			} else {
				this.showChart = false;
			}
		},
	},
};
</script>
