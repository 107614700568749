<template>
	<v-container tag="section" aria-label="Features Section">
		<!-- Desktop View -->
		<v-row justify="center">
			<v-col
				v-for="(item, i) in items"
				:key="i"
				cols="12"
				sm="6"
				md="6"
				lg="4"
				class="d-flex align-center justify-center"
				tag="article"
			>
				<v-card class="card-style ant-card px-4 py-2" outlined elevation="0">
					<v-row class="d-flex items-center justify-center py-0">
						<v-col cols="2" class="d-flex align-start pl-4 pa-0 pt-6" tag="figure">
							<v-img :src="item.src" class="card-image" :alt="getAltText(i)" loading="lazy" />
						</v-col>
						<v-col cols="10" class="d-flex flex-column pl-1 py-0" tag="section">
							<v-card-title
								class="pb-0"
								:class="['v-typography--title1', { 'v-typography--title2': $vuetify.breakpoint.mdAndUp }]"
								tag="h3"
							>
								{{ $t(`$landing.$content.$title.${i}`) }}
							</v-card-title>
							<v-card-text
								tag="p"
								class="mb-0 pt-2"
								:class="['v-typography--body', { 'v-typography--paragraph': $vuetify.breakpoint.mdAndUp }]"
							>
								{{ $t(`$landing.$content.$text.${i}`) }}
							</v-card-text>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<!-- Mobile View -->
		<!-- <v-carousel
			v-if="isMobile"
			v-model="currentSlide"
			hide-delimiters
			:items-per-slide="1"
			:show-arrows="false"
			:show-arrows-on-hover="false"
			class="carousel-style"
		>
			<v-carousel-item v-for="(item, i) in items" :key="i">
				<v-card class="card-style"> -->
		<!-- <v-card-title>
							{{ $t(`$landing.$content.$title.${i}`) }}
						</v-card-title>
						<v-card-title>
							{{ $t(`$landing.$content.$text.${i}`) }}
						</v-card-title> -->

		<!-- <v-row class="d-flex items-center justify-center mt-4">
						<v-col cols="auto" class="d-flex align-center">
							<v-img :src="item.src" class="card-image mb-12" />
						</v-col>
						<v-col class="d-flex flex-column">
							<v-card-title class="font-weight-bold pa-0">
								{{ $t(`$landing.$content.$title.${i}`) }}
							</v-card-title>
							<v-card-text class="text-left pa-0">
								{{ $t(`$landing.$content.$text.${i}`) }}
							</v-card-text>
						</v-col>
					</v-row>
				</v-card>
				<div class="carousel-indicators">
					<span
						v-for="(item, i) in items"
						:key="i"
						:class="['indicator', { active: i === currentSlide }]"
					></span>
				</div>
			</v-carousel-item>
		</v-carousel> -->
	</v-container>
</template>

<script>
import accessPng from "@/assets/access.webp";
import packagePng from "@/assets/package.webp";
import securityPng from "@/assets/security.webp";
import commonFollowPng from "@/assets/common_follow.webp";
import servicePng from "@/assets/customer_service.webp";
import shipmentPng from "@/assets/shipping_management.webp";

export default {
	// props: { isMobile: Boolean },
	data() {
		return {
			items: [
				{ src: shipmentPng },
				{ src: servicePng },
				{ src: packagePng },
				{ src: accessPng },
				{ src: securityPng },
				{ src: commonFollowPng },
			],
			currentSlide: 0,
		};
	},
	methods: {
		getAltText(index) {
			const altTexts = [
				"Shipping Management",
				"Customer Service",
				"Package Management",
				"Access Features",
				"Security Features",
				"Common Follow-up",
			];
			return altTexts[index] || "Image Description";
		},
	},
};
</script>

<style scoped>
.card-style {
	width: 100%;
	height: 100%;
	transition: transform 0.2s;
}

.card-style:hover {
	background-color: #00456a;
	color: white;
}

.card-style:hover .card-text {
	color: white;
}

.card-style:hover .card-image {
	filter: brightness(0) invert(1);
}

.card-image {
	width: 60px;
	height: auto;
}

/* .carousel-style {
	max-width: 400px;
	max-height: 200px;
	margin: auto;
	position: relative;
}

.carousel-indicators {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.indicator {
	width: 10px;
	height: 10px;
	background-color: #d9d9d9;
	border-radius: 10px;
	margin: 0 5px;
	display: inline-block;
	transition: background-color 0.3s;
}

.carousel-indicators .indicator.active {
	background-color: #00466a !important;
} */
</style>
