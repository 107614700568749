<template>
	<v-container>
		<v-card class="ant-card py-2 px-4" elevation="0">
			<v-toolbar flat dense>
				<v-toolbar-title class="v-typography--title">{{ $t("activities") }}</v-toolbar-title>
			</v-toolbar>

			<v-divider class="mb-4"></v-divider>

			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="message"
					:label="`${$t('$shipment.leave_message')}...`"
					@keyup.enter="commentSaveAction"
					:rules="rules.message"
					counter="250"
				>
					<template v-slot:append>
						<v-btn
							color="primary"
							class="mb-1"
							@click="commentSaveAction"
							:disabled="message.trim().length === 0"
							:loading="savingMessage"
						>
							{{ $t("save") }}
						</v-btn>
					</template>
				</v-text-field>
			</v-form>

			<div v-if="list && list.length">
				<activity-card
					v-for="item in list"
					:key="item.id"
					:icon="getActivityIcon(item.type)"
					:text="item.text"
					:note="item.note"
					:date="item.date"
				/>
			</div>
			<div v-else>
				<v-alert type="info">{{ $t("no_actions_available") }}</v-alert>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import remote from "../../../../data/remote";
import ActivityCard from "./ActivityCard.vue";

export default {
	components: {
		ActivityCard,
	},
	props: {
		value: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			list: [],
			message: "",
			savingMessage: false,
			valid: true,
			rules: {
				message: [
					(v) => {
						if (v && v.length > 250)
							return this.$t("$validation.max_length", {
								item: this.$t("message"),
								length: "250",
							});
						return true;
					},
				],
			},
			activityIconMap: {
				create: { icon: "icon-plus" },
				update: { icon: "icon-edit" },
				comment: { icon: "icon-unload" },
				create_attachment: { icon: "icon-plus" },
				update_attachment: { icon: "icon-edit" },
				delete_attachment: { icon: "icon-delete" },
				create_container: { icon: "icon-plus" },
				update_container: { icon: "icon-edit" },
				delete_container: { icon: "icon-delete" },
			},
		};
	},
	computed: {
		...mapState("auth", ["user"]),
	},
	watch: {
		value: {
			handler(val) {
				this.handleProps();
			},
			immediate: true,
		},
	},
	methods: {
		handleProps() {
			this.list = [];
			const actions = [...this.value.actions].reverse();
			actions.forEach((action) => {
				const item = {
					text: "",
					note: null,
					date: action.date.format(this.$t("$format.date_time_without_second")),
					type: action.type,
					id: action.id,
					createdBy: action.createdBy,
				};

				if (action.type === "comment") {
					item.text = `${action.createdBy.firstName} ${action.createdBy.lastName} ${this.$t("left_a_comment")}`;
					item.note = `"${action.referenceText}"`;
				} else if (this.value.method === "land" && this.isContainerAction(action)) {
					item.text = this.$t(`$shipment.$actionDescription.$land.${action.type}`, {
						name: `${action.createdBy.firstName} ${action.createdBy.lastName}`,
					});
					item.note = action.referenceText;
				} else {
					item.text = this.$t(`$shipment.$actionDescription.${action.type}`, {
						name: `${action.createdBy.firstName} ${action.createdBy.lastName}`,
					});
					item.note = action.referenceText;
				}

				this.list.push(item);
			});
		},
		getActivityIcon(type) {
			return this.activityIconMap[type]?.icon || "icon-edit";
		},
		commentSaveAction() {
			if (this.valid) {
				if (this.message.trim().length > 0) {
					this.savingMessage = true;
					remote.shipments.comment(this.value, this.message, {
						onSuccess: (shipment) => {
							this.$emit("input", shipment);
							this.savingMessage = false;
							this.message = "";
						},
						onFail: () => {
							this.savingMessage = false;
						},
					});
				}
			} else {
				this.$refs.form.validate();
			}
		},
		isContainerAction(action) {
			return ["create_container", "update_container", "delete_container"].includes(action.type);
		},
		// isAttachmentAction(action) {
		// 	return [
		// 		"create_attachment",
		// 		"update_attachment",
		// 		"delete_attachment",
		// 	].includes(action.type);
		// },
		// isComment(action) {
		// 	return action.type == "comment";
		// },
		// findContainer(action) {
		// 	return action.containers.find((c) => c.id == action.referenceId);
		// },
		// findAttachemtn(action) {
		// 	return action.attachments.find((c) => c.id == action.referenceId);
		// },
	},
	created() {
		this.handleProps();
	},
};
</script>
