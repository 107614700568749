<template>
	<div class="d-flex align-start">
		<div class="d-flex flex-column align-center mr-3">
			<v-icon color="black">{{ icon }}</v-icon>
			<v-icon v-if="isNotAbstractCompany" color="primary" class="mt-2" size="small"> icon-verified </v-icon>
		</div>

		<div>
			<span class="v-typography--body1">{{ title }}</span>
			<div v-for="(line, index) in contentArray" :key="index" class="v-typography--body-sm grey--text mt-1">
				{{ line }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "InfoSection",
	props: {
		icon: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		content: {
			type: [String, Array],
			default: "",
		},
		isNotAbstractCompany: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		contentArray() {
			if (Array.isArray(this.content)) {
				return this.content.filter(Boolean);
			} else if (this.content) {
				return [this.content];
			} else {
				return [];
			}
		},
	},
};
</script>
