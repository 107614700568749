<template>
	<ext-dialog
		v-model="value"
		v-if="currentItem"
		:title="$t('product')"
		:primaryButtonText="primaryButtonText"
		:secondaryButtonText="currentItem.id ? secondaryButtonText : ''"
		@primaryButtonAction="saveAction"
		@secondaryButtonAction="deleteAction(currentItem)"
		@closed="closeAction"
	>
		<template slot="body">
			<v-form ref="form" v-model="valid">
				<product-select
					v-model="currentItem.productId"
					:label="$t('product')"
					:rules="rules.productId"
					:eager="true"
					@change="productChangeAction"
					:disabled="!isEditable"
				/>
				<v-text-field
					v-model="currentItem.quantity"
					:label="$t('quantity')"
					:rules="rules.quantity"
					type="number"
					min="0"
					:disabled="!isEditable"
				/>
				<unit-select
					v-model="currentItem.unitCode"
					:eager="ready"
					:options="unitOptions"
					:rules="rules.unit"
					:disabled="!currentItem.productId || !isEditable"
				/>
				<v-text-field
					v-model="currentItem.amount"
					:label="$t('amount')"
					:rules="rules.amount"
					type="number"
					min="0"
					:suffix="proformaInvoice.currency.symbol"
					:disabled="!isEditable"
				/>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import ProductSelect from "../../../controls/ProductSelect";
import UnitSelect from "../../../controls/UnitSelect";
import remote from "../../../../data/remote";
import cloneDeep from "lodash.clonedeep";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	props: {
		value: Boolean,
		item: Object,
		proformaInvoice: Object,
	},
	components: {
		ProductSelect,
		UnitSelect,
		ExtDialog,
	},
	data() {
		return {
			valid: true,
			rules: {
				productId: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("product") })],
				quantity: [
					(v) => (!isNaN(v) && Number(v) > 0) || this.$t("$validation.required", { item: this.$t("quantity") }),
				],
				unit: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("unit") })],
				amount: [(v) => !Number.isNaN(v) || this.$t("$validation.required", { item: this.$t("amount") })],
			},
			currentItem: null,
			currentProduct: null,
			ready: false,
			saving: false,
			deleting: false,
		};
	},
	computed: {
		unitOptions() {
			return this.currentProduct?.units;
		},
		isEditable() {
			return this.proformaInvoice.status !== "processed" && this.proformaInvoice.status !== "pending_revise";
		},
		primaryButtonText() {
			return this.isEditable ? this.$t("save") : "";
		},
		secondaryButtonText() {
			return this.isEditable ? this.$t("delete") : "";
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item) {
				this.currentItem = cloneDeep(this.item);
				this.loadCurrentProduct();
			} else this.useItemTemplate();
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
			this.currentProduct = null;
		},
		useItemTemplate() {
			this.currentItem = {
				productId: null,
				name: "",
				unitCode: "",
				quantity: null,
				amount: null,
			};
		},
		loadCurrentProduct() {
			if (this.currentItem?.productId) {
				remote.products.find(this.currentItem.productId, {
					onSuccess: (result) => {
						this.currentProduct = result;
						this.ready = true;
					},
					onFail: (_) => (this.ready = true),
				});
			}
		},
		productChangeAction(product) {
			this.currentItem.name = product.name;
			this.currentProduct = product;
			if (product.units.length === 1) {
				this.currentItem.unitCode = product.units[0].code;
			} else {
				this.currentItem.unitCode = null;
			}
		},
		saveAction() {
			if (!this.saving && !this.deleting) {
				if (this.valid) {
					const onSuccess = (result) => this.$emit("saved", result);
					if (this.currentItem.amount == null || this.currentItem.amount === "") {
						this.currentItem.amount = 0;
					}
					if (this.currentItem.id) {
						remote.proformaInvoices.products.update(this.proformaInvoice.id, this.currentItem, {
							onSuccess,
						});
					} else {
						remote.proformaInvoices.products.create(this.proformaInvoice.id, this.currentItem, {
							onSuccess,
						});
					}
				} else {
					this.$refs.form.validate();
				}
			}
		},
		deleteAction(item) {
			if (!this.saving && !this.deleting) {
				remote.proformaInvoices.products.delete(this.proformaInvoice.id, item.id, {
					onSuccess: (result) => this.$emit("deleted", result),
				});
			}
		},
		closeAction() {
			if (!this.saving && !this.deleting) this.$emit("input", false);
		},
	},
};
</script>
