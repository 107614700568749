<template>
	<v-container v-if="currentItem">
		<v-card elevation="0" class="ant-card pa-2">
			<v-card-title class="pa-2">
				<v-row class="d-flex justify-between align-center" no-gutters>
					<v-col class="d-flex align-center grey--text text--darken-2 pl-2 pr-4" cols="auto">
						{{ title() }}
					</v-col>

					<v-col class="d-flex align-center pb-0" cols="12" sm="auto">
						<v-text-field
							v-if="editMode && iAmSender"
							v-model="editItem.no"
							:rules="rules.proformaNumber"
							:label="$t('proforma_number')"
							hide-details
						/>
						<span v-else-if="currentItem.no" class="grey--text text--darken-2"> #{{ currentItem.no }} </span>
					</v-col>
					<v-col class="d-flex justify-end align-center pt-2">
						<v-btn text v-if="!editMode && isEditable" @click="editAction" class="mr-2">
							<v-icon size="15">icon-edit</v-icon>
							<span class="ml-1 v-typography--body-sm">{{ $t("edit") }}</span>
						</v-btn>
						<v-btn text v-if="editMode" @click="cancelEdit" class="mr-2">
							<v-icon size="15">icon-cross</v-icon>
							<span class="ml-1 v-typography--body-sm">{{ $t("cancel") }}</span>
						</v-btn>
						<v-btn text v-if="editMode" @click="saveAction" class="mr-2">
							<v-icon size="15">icon-check</v-icon>
							<span class="ml-1 v-typography--body-sm">{{ $t("save") }}</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>

			<v-divider class="mb-2" />

			<v-form v-model="valid" ref="form" class="pa-4">
				<v-row>
					<!-- Sender -->
					<v-col cols="12" sm="6">
						<info-section
							icon="icon-company"
							:title="$t('sender')"
							:content="[currentItem.sender.name]"
							:isNotAbstractCompany="!currentItem.sender.isAbstract"
						/>
					</v-col>

					<!-- Receiver -->
					<v-col cols="12" sm="6">
						<info-section
							v-if="!editMode || !iAmSender"
							icon="icon-company"
							:title="$t('receiver')"
							:content="[currentItem.receiver.name]"
							:isNotAbstractCompany="!currentItem.receiver.isAbstract"
						/>
						<template v-else-if="editMode && iAmSender">
							<partner-select
								v-model="editItem.receiver.referenceId"
								:label="$t('receiver')"
								:rules="rules.receiver"
								:eager="true"
								:types="[partnerTypes.Company, partnerTypes.AbstractCompany]"
								prepend-icon="icon-company"
								@change="receiverChangeAction"
							/>
						</template>
					</v-col>
				</v-row>

				<v-row>
					<!-- Shipment Method -->
					<v-col cols="12" sm="6">
						<info-section
							v-if="!editMode || !iAmSender"
							icon="icon-shuffle"
							:title="$t('shipment_method')"
							:content="$t(`$shipment.$method.${currentItem.shipmentMethod}`)"
						/>
						<template v-else-if="editMode && iAmSender">
							<shipment-method-select
								v-model="editItem.shipmentMethod"
								:label="$t('shipment_method')"
								:rules="rules.shipmentMethod"
								@change="shipmentMethodChangeAction"
							/>
						</template>
					</v-col>

					<!-- Shipping Term -->
					<v-col cols="12" sm="6">
						<info-section
							v-if="!editMode || !iAmSender"
							icon="icon-document"
							:title="$t('shipping_term')"
							:content="
								currentItem.shippingTerm ? `${currentItem.shippingTerm.name} (${currentItem.shippingTerm.code})` : ''
							"
						/>
						<template v-else-if="editMode && iAmSender">
							<shipping-term-select
								v-model="editItem.shippingTerm.code"
								:label="$t('shipping_term')"
								:rules="rules.shippingTerm"
								:clearable="true"
								:eager="true"
								prepend-icon="icon-document"
								@change="shippingTermChangeAction"
							/>
						</template>
					</v-col>
				</v-row>

				<v-row>
					<!-- Loading Point/Port -->
					<v-col cols="12" sm="6">
						<info-section
							v-if="!editMode || !iAmSender"
							icon="icon-load"
							:title="isSeaShipment ? $t('loading_port') : $t('loading_point')"
							:content="[
								currentItem.loadingCountry ? $t(`$country.${currentItem.loadingCountry.name}`) : '',
								isSeaShipment
									? currentItem.loadingPort
										? currentItem.loadingPort.name
										: ''
									: currentItem.loadingPoint,
							]"
						/>
						<template v-else-if="editMode && iAmSender">
							<country-select
								v-model="editItem.loadingCountry.id"
								:options="countries"
								:label="$t('loading_country')"
								:rules="rules.loadingCountry"
								prepend-icon="icon-world"
								@change="loadingCountryChangeAction"
							/>
							<template v-if="isSeaShipment">
								<port-select
									v-model="editItem.loadingPort.code"
									:label="$t('loading_port')"
									:countryId="editItem.loadingCountry.id"
									:disabled="!editItem.loadingCountry.id"
									:eager="Boolean(editItem.loadingCountry.id)"
									:rules="rules.loadingPort"
									prepend-icon="icon-load"
									@change="loadingPortChangeAction"
								/>
							</template>
							<template v-else>
								<v-text-field
									v-model="editItem.loadingPoint"
									:label="$t('loading_point')"
									:rules="rules.loadingPoint"
									prepend-icon="icon-load"
								/>
							</template>
						</template>
					</v-col>

					<!-- Unloading Point/Port -->
					<v-col cols="12" sm="6">
						<info-section
							v-if="!editMode || !iAmSender"
							icon="icon-load"
							:title="isSeaShipment ? $t('unloading_port') : $t('unloading_point')"
							:content="[
								currentItem.unloadingCountry ? $t(`$country.${currentItem.unloadingCountry.name}`) : '',
								isSeaShipment
									? currentItem.unloadingPort
										? currentItem.unloadingPort.name
										: ''
									: currentItem.unloadingPoint,
							]"
						/>
						<template v-else-if="editMode && iAmSender">
							<country-select
								v-model="editItem.unloadingCountry.id"
								:options="countries"
								:label="$t('unloading_country')"
								:rules="rules.unloadingCountry"
								prepend-icon="icon-world"
								@change="unloadingCountryChangeAction"
							/>
							<template v-if="isSeaShipment">
								<port-select
									v-model="editItem.unloadingPort.code"
									:label="$t('unloading_port')"
									:countryId="editItem.unloadingCountry.id"
									:disabled="!editItem.unloadingCountry.id"
									:eager="Boolean(editItem.unloadingCountry.id)"
									:rules="rules.unloadingPort"
									prepend-icon="icon-unload"
									@change="unloadingPortChangeAction"
								/>
							</template>
							<template v-else>
								<v-text-field
									v-model="editItem.unloadingPoint"
									:label="$t('unloading_point')"
									:rules="rules.unloadingPoint"
									prepend-icon="icon-unload"
								/>
							</template>
						</template>
					</v-col>
				</v-row>
			</v-form>
		</v-card>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CountrySelect from "../../../controls/CountrySelect.vue";
import PortSelect from "../../../controls/PortSelect.vue";
import PartnerSelect from "../../../controls/PartnerSelect.vue";
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect.vue";
import ShippingTermSelect from "../../../controls/ShippingTermSelect.vue";
import InfoSection from "../../shipment/partial/InfoSection.vue";
import { partnerTypes } from "../../../../utils/enum";
import { iconHelper as iconHelperMixin } from "../../../mixins/utils.mixin";
import { cloneDeep } from "lodash";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";

export default {
	name: "ProformaInvoiceDetails",
	components: {
		CountrySelect,
		PortSelect,
		PartnerSelect,
		ShipmentMethodSelect,
		ShippingTermSelect,
		InfoSection,
	},
	mixins: [iconHelperMixin, uiMixin],
	props: {
		currentItem: {
			type: Object,
			required: true,
		},
		iAmSender: {
			type: Boolean,
			required: true,
		},
		iAmReceiver: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			editMode: false,
			editItem: null,
			valid: true,
			menuItems: [],
			rules: {
				proformaNumber: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("proforma_number"),
							});
						} else if (v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("proforma_number"),
								length: "25",
							});
						}
						return true;
					},
				],
				receiver: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("receiver") })],
				shipmentMethod: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("shipment_method") })],
				shippingTerm: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("shipping_term") })],
				loadingCountry: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_country") })],
				unloadingCountry: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("unloading_country") }),
				],
				loadingPort: [
					(v) => {
						if (this.isSeaShipment && !Boolean(v)) {
							return this.$t("$validation.required", { item: this.$t("loading_port") });
						}
						return true;
					},
				],
				unloadingPort: [
					(v) => {
						if (this.isSeaShipment && !Boolean(v)) {
							return this.$t("$validation.required", { item: this.$t("unloading_port") });
						}
						return true;
					},
				],
				loadingPoint: [
					(v) => {
						if (!this.isSeaShipment && !Boolean(v)) {
							return this.$t("$validation.required", { item: this.$t("loading_point") });
						}
						return true;
					},
				],
				unloadingPoint: [
					(v) => {
						if (!this.isSeaShipment && !Boolean(v)) {
							return this.$t("$validation.required", { item: this.$t("unloading_point") });
						}
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company"]),
		partnerTypes: () => partnerTypes,
		isEditable() {
			return this.iAmSender && this.currentItem.status === "template";
		},
		isSeaShipment() {
			return this.editMode ? this.editItem.shipmentMethod === "sea" : this.currentItem.shipmentMethod === "sea";
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
	},
	watch: {
		currentItem: {
			handler(val) {
				if (val) {
					this.prepareMenu();
				}
			},
			immediate: true,
		},
	},
	methods: {
		title() {
			if (this.currentItem)
				if (this.currentItem.id)
					return `${this.$t(this.iAmSender ? "outgoing" : "incoming")} ${this.$t("proforma_invoice")}`;
				else return `${this.$t("new")} ${this.$t("proforma_invoice")}`;
		},
		editAction() {
			this.editMode = true;
			this.editItem = cloneDeep(this.currentItem);
		},

		cancelEdit() {
			this.editMode = false;
			this.editItem = null;
		},
		saveAction() {
			if (this.valid) {
				this.$emit("save", this.editItem);
				this.editMode = false;
				this.editItem = null;
			} else {
				this.$refs.form.validate();
			}
		},
		receiverChangeAction(val) {
			var isAbstract = val.type == this.partnerTypes.AbstractCompany;
			this.editItem.receiver.referenceId = !isAbstract ? val.corporationId : val.id;
			this.editItem.receiver.name = val.name;
			this.editItem.receiver.countryId = val.countryId;
			this.editItem.receiver.isAbstract = isAbstract;
			const country = val ? this.countries.find((i) => i.id === val.countryId) : null;
			this.editItem.unloadingCountry.id = country.id || undefined;
			this.editItem.unloadingCountry.code = country.code || undefined;
			this.editItem.unloadingCountry.name = country.name || undefined;
		},
		shipmentMethodChangeAction() {
			if (this.editItem.shipmentMethod !== "sea") {
				this.editItem.loadingPort = { code: null };
				this.editItem.unloadingPort = { code: null };
			}
			if (this.editItem.shipmentMethod === "sea") {
				this.editItem.loadingPoint = null;
				this.editItem.unloadingPoint = null;
			}
		},
		shippingTermChangeAction(val) {
			this.editItem.shippingTerm.name = val?.name ?? null;
		},
		loadingCountryChangeAction(val) {
			this.editItem.loadingCountry = {
				id: val.id,
				name: val.name,
				code: val.code,
			};
			if (this.isSeaShipment) {
				this.editItem.loadingPort.code = null;
				this.editItem.loadingPort.name = "";
				this.editItem.loadingPort.portId = null;
				this.editItem.loadingPort.type = null;
			} else {
				this.editItem.loadingPoint = null;
			}
		},
		loadingPortChangeAction(val) {
			this.editItem.loadingPort.portId = val?.id;
			this.editItem.loadingPort.name = val?.name;
			this.editItem.loadingPort.code = val?.code;
			this.editItem.loadingPort.type = val?.type;
		},
		unloadingCountryChangeAction(val) {
			this.editItem.unloadingCountry = {
				id: val.id,
				name: val.name,
				code: val.code,
			};
			if (this.isSeaShipment) {
				this.editItem.unloadingPort.code = null;
				this.editItem.unloadingPort.name = "";
				this.editItem.unloadingPort.portId = null;
				this.editItem.unloadingPort.type = null;
			} else {
				this.editItem.unloadingPoint = null;
			}
		},
		unloadingPortChangeAction(val) {
			this.editItem.unloadingPort.portId = val?.id;
			this.editItem.unloadingPort.name = val?.name;
			this.editItem.unloadingPort.code = val?.code;
			this.editItem.unloadingPort.type = val?.type;
		},
		prepareMenu() {
			const ria = this.currentItem.receiver.isAbstract || false;
			this.menuItems = [];
			const send = {
				key: "send",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_sent" : "send"}`),
				icon: "icon-send",
				color: ria ? undefined : "info",
			};
			const delete_ = {
				key: "delete",
				title: this.$t("delete"),
				icon: "icon-delete",
				color: "error",
			};
			const revoke = {
				key: "revoke",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_revoked" : "revoke"}`),
				icon: "icon-undo",
				color: "error",
			};
			const markAsProcessed = {
				key: "mark_as_processed",
				title: this.$t("$proformaInvoice.$actions.mark_as_processed"),
				icon: "icon-folder",
			};
			const approve = {
				key: "approve",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_approved" : "approve"}`),
				icon: "icon-file-add",
				color: ria ? undefined : "info",
			};
			const demandRevise = {
				key: "demand_revise",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_revise_demanded" : "demand_revise"}`),
				icon: "icon-edit",
			};
			const reject = {
				key: "reject",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_rejected" : "reject"}`),
				icon: "icon-send",
				color: ria ? "warning" : "error",
			};

			if (this.iAmSender) {
				switch (this.currentItem.status) {
					case "template":
						this.menuItems.push(send, delete_);
						break;
					case "pending_approval":
						this.menuItems.push(revoke);
						break;
					case "pending_revise":
						this.menuItems.push(send, revoke);
						break;
					case "approved":
						this.menuItems.push(markAsProcessed);
						break;
					default:
						break;
				}
			}
			if (this.iAmReceiver || this.currentItem.receiver.isAbstract) {
				switch (this.currentItem.status) {
					case "pending_approval":
						this.menuItems.push(approve, demandRevise, reject);
						break;
					default:
						break;
				}
			}
		},
	},
};
</script>
