<template>
	<v-autocomplete
		v-model="currentValue"
		@change="changeAction"
		:items="currentOptions"
		item-value="id"
		:label="label || $t('product_group')"
		:item-text="(i) => i.name[$lang] || i.name['_default']"
		:disabled="!ready || disabled"
		outlined
		dense
		class="rounded-xl"
		:loading="!ready && !disabled"
		:clearable="clearable"
		clear-icon="icon-cross"
		append-icon="icon-angle-down"
		:rules="rules"
		:prepend-icon="icon"
	>
		<template slot="item" slot-scope="{ item }">
			<span :class="`ml-${Number(item.level) * 4}`">{{ item.name[$lang] || item.name["_default"] }}</span>
		</template>
	</v-autocomplete>
</template>

<script>
import remote from "../../data/remote";

export default {
	props: ["icon", "value", "eager", "tree", "options", "label", "clearable", "disabled", "rules"],
	data() {
		return {
			currentValue: null,
			currentOptions: [],
			ready: false,
		};
	},
	watch: {
		currentValue(val) {
			this.$emit("input", val);
		},
		value(val) {
			this.currentValue = val || null;
		},
		options(val) {
			this.handleProps();
		},
		tree() {
			this.handleProps();
		},
	},
	methods: {
		handleProps() {
			if (this.options) {
				this.currentOptions = this.options;
				this.ready = true;
			} else if (this.tree) this.handleTree(this.tree);
			else if (this.eager) this.loadProductGroupTree();
		},
		handleTree(tree) {
			this.fillCurrentOptions(tree.children, 0);
			this.ready = true;
		},
		fillCurrentOptions(groups, level) {
			for (const group of groups) {
				this.currentOptions.push({
					id: group.id ?? group._id,
					name: group.name,
					level,
				});
				this.fillCurrentOptions(group.children, level + 1);
			}
		},
		loadProductGroupTree() {
			remote.productGroups.getMyProductGroupTree({
				onSuccess: (result) => {
					this.handleTree(result);
				},
				onFail: (error) => console.error(error),
			});
		},
		changeAction(id) {
			this.$emit("change", id || null);
		},
	},
	created() {
		this.currentValue = this.value || null;
		this.handleProps();
	},
};
</script>
