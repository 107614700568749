<template>
	<v-footer class="footer pa-4">
		<v-row :class="{ 'mobile-footer': isMobile }" class="justify-center py-6 v-typography--body">
			<v-col
				cols="12"
				md="3"
				class="d-flex flex-column text--white mt-6"
				:class="{ 'align-center mb-4': isMobile, 'align-start': !isMobile }"
			>
				<v-row
					class="d-flex flex-column pa-0 pr-0 sm-pr-16"
					:class="{ 'align-center mb-4': isMobile, 'align-start': !isMobile }"
				>
					<div
						class="footer-info d-flex flex-column"
						:class="{ 'align-center mb-4': isMobile, 'align-start': !isMobile }"
					>
						<img src="~@/assets/antrego_white_logo.png" alt="Logo" class="mb-2" />
						<p class="white--text w-75">{{ $t("$company.slogan") }}</p>
					</div>
					<!-- <div
						class="footer-info d-flex mt-2"
						:class="{ 'justify-center': isMobile, 'justify-start': !isMobile }"
					>
						<img src="~@/assets/twitter.png" alt="Twitter Icon" class="social-icon" />
						<img src="~@/assets/facebook.png" alt="Facebook Icon" class="social-icon" />
						<img src="~@/assets/linkedin.png" alt="LinkedIn Icon" class="social-icon" />
					</div> -->
				</v-row>
			</v-col>

			<v-col
				cols="12"
				md="3"
				class="d-flex flex-column"
				:class="{ 'align-center': isMobile, 'align-start': !isMobile }"
			>
				<v-row class="d-flex flex-column pa-0" :class="{ 'align-center': isMobile, 'align-start': !isMobile }">
					<v-btn
						v-for="(item, i) in items"
						:key="i"
						text
						class="white--text text-capitalize"
						@click="goToElement(item.tag)"
					>
						{{ $t(`${item.tag}`) }}
					</v-btn>
				</v-row>
			</v-col>

			<v-col
				cols="12"
				md="3"
				class="d-flex flex-column white--text mt-8"
				:class="{ 'align-center': isMobile, 'align-start': !isMobile }"
			>
				<v-row class="d-flex flex-column pa-0" :class="{ 'align-center': isMobile, 'align-start': !isMobile }">
					<div class="footer-info mb-2">
						<img v-if="!isMobile" src="~@/assets/location.webp" alt="Location Icon" class="info-icon" />
						<p class="mb-0">{{ $t("$company.address") }}</p>
					</div>
					<div class="footer-info">
						<img v-if="!isMobile" src="~@/assets/mail.webp" alt="Mail Icon" class="info-icon" />
						<p class="mb-0">info@antrego.net</p>
					</div>
				</v-row>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	props: { isMobile: Boolean },
	emits: ["goTo"],
	data() {
		return {
			items: [
				{ tag: "home_page" },
				{ tag: "what_we_are_doing" },
				{ tag: "pricing" },
				{ tag: "faq" },
				{ tag: "contact" },
			],
		};
	},
	methods: {
		goToElement(tag) {
			this.$emit("goTo", tag);
		},
	},
};
</script>

<style scoped>
.footer {
	width: screen;
	background-color: #1c1b1b;
}

.social-icon {
	width: 24px;
	height: 24px;
	margin: 0 5px;
}

.info-icon {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	vertical-align: middle;
}

.footer-info {
	display: flex;
	align-items: center;
}

.desktop-footer {
	flex-direction: row;
}

.mobile-footer {
	flex-direction: column;
}

.align-start {
	align-items: flex-start;
	text-align: left;
}

.align-center {
	align-items: center;
	text-align: center;
}

.justify-start {
	justify-content: flex-start;
}

.justify-center {
	justify-content: center;
}
</style>
