var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ext-dialog',{attrs:{"title":_vm.$t('send_email'),"primaryButtonText":_vm.$t('send'),"dialogType":'large'},on:{"primaryButtonAction":_vm.sendAction,"closed":_vm.closedAction},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('info-section',{attrs:{"icon":"icon-company","title":_vm.$t('sender'),"content":[
						_vm.shipment.sender.company.name,
						_vm.shipment.sender.customsAgency ? 'Custom: ' + _vm.shipment.sender.customsAgency.name : null,
					]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('info-section',{attrs:{"icon":"icon-company","title":_vm.$t('receiver'),"content":[
						_vm.shipment.receiver.company.name,
						_vm.shipment.receiver.customsAgency ? 'Custom: ' + _vm.shipment.receiver.customsAgency.name : null,
					]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('info-section',{attrs:{"icon":_vm.getShipmentMethodIcon(_vm.shipment.method),"title":_vm.$t('method'),"content":_vm.$t(`$shipment.$method.${_vm.shipment.method}`)}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('info-section',{attrs:{"icon":"icon-load","title":_vm.isSeaShipment ? _vm.$t('loading_port') : _vm.$t('loading_point'),"content":[
						_vm.shipment.loadingCountry ? _vm.$t(`$country.${_vm.shipment.loadingCountry.name}`) : '',
						_vm.isSeaShipment ? (_vm.shipment.loadingPort ? _vm.shipment.loadingPort.name : '') : _vm.shipment.loadingPoint,
					]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('info-section',{attrs:{"icon":"icon-unload","title":_vm.isSeaShipment ? _vm.$t('unloading_port') : _vm.$t('unloading_point'),"content":[
						_vm.shipment.unloadingCountry ? _vm.$t(`$country.${_vm.shipment.unloadingCountry.name}`) : '',
						_vm.isSeaShipment ? (_vm.shipment.unloadingPort ? _vm.shipment.unloadingPort.name : '') : _vm.shipment.unloadingPoint,
					]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('info-section',{attrs:{"icon":"icon-shuffle","title":_vm.$t('shipping_term'),"content":_vm.shipment.shippingTerm ? `${_vm.shipment.shippingTerm.name} (${_vm.shipment.shippingTerm.code})` : ''}})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","large":""},on:{"click":_vm.copyShipmentLink}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("icon-copy")]),_vm._v(" "+_vm._s(_vm.$t("copy_link"))+" ")],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":_vm.$t('email'),"prepend-icon":"icon-email","rules":_vm.rules.email,"disabled":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }