<template>
	<ext-dialog
		v-model="value"
		:title="item?.name || $t('file_preview')"
		:primaryButtonText="$t('close')"
		@primaryButtonAction="closeAction"
		@closed="closeAction"
	>
		<template #body>
			<v-progress-linear v-if="loading" color="accent" />
			<img v-else-if="item && isImage" :src="item.url" alt="Image" style="max-width: 100%" />
			<v-container v-else-if="item && isDocument">
				<vue-pdf v-for="i in pageCount" :key="i" :page="i" :src="item.url" style="display: block; width: 100%" />
			</v-container>
			<v-card-text v-if="errorMessage">{{ errorMessage }}</v-card-text>
		</template>
	</ext-dialog>
</template>

<script>
import VuePdf from "@teckel/vue-pdf";
import ExtDialog from "./ExtDialog.vue";

export default {
	components: { VuePdf, ExtDialog },
	props: {
		value: { type: Boolean },
		item: { type: Object },
	},
	data() {
		return {
			supportedImageTypes: ["image/png", "png", "image/jpg", "jpg", "image/jpeg", "jpeg"],
			supportedDocumentTypes: ["application/pdf", "pdf"],
			loading: true,
			pageCount: null,
			errorMessage: null,
		};
	},
	watch: {
		value(val) {
			if (val) this.prepareFile();
		},
	},
	computed: {
		isImage() {
			return this.supportedImageTypes.includes(this.item.type);
		},
		isDocument() {
			return this.supportedDocumentTypes.includes(this.item.type?.toLowerCase());
		},
	},
	methods: {
		async prepareFile() {
			this.loading = true;
			if (this.item && this.item.url) {
				if (this.isDocument) {
					try {
						await this.fetchTotalPages(this.item.url);
					} catch (error) {
						console.error("Error loading PDF:", error);
						this.errorMessage = this.$t("$message.errorOnViewFile");
					}
				} else if (!this.isImage) {
					this.errorMessage = this.$t("$message.unsupported_file_type");
				}
			}
			this.loading = false;
		},
		async fetchTotalPages(url) {
			try {
				const loadingTask = VuePdf.createLoadingTask({ url });
				const pdf = await loadingTask.promise;
				this.pageCount = pdf.numPages;
			} catch (error) {
				console.error("Error fetching PDF pages:", error);
				this.errorMessage = this.$t("$message.errorOnViewFile");
			}
		},
		closeAction() {
			this.$emit("closed");
		},
	},
};
</script>
