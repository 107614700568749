export default {
	$accessibility: {
		archived: "Arşivlenmiş",
		present: "Devam Eden",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Alıcıdan Gizlenmiş",
			hidden_from_sender: "Göndericiden Gizlenmiş",
			private: "Gizli",
			shared_with_custom: "Gümrükle Paylaşımlı",
			shared_with_everyone: "Herkesle Paylaşımlı",
			shared_with_receiver: "Alıcıyla Paylaşımlı",
			shared_with_sender: "Göndericiyle Paylaşımlı",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB Depolama",
		limitMBStorage: "{limit}MB Depolama",
		limitUser: "{limit} Kullanıcı",
		limitUsers: "{limit} Kullanıcı",
		renewAtPricePerUnit: "{unit} {currency}{price} olarak yenilenecektir.",
		unlimitedModule: "Sınırsız {module}",
		upToLimitModule: "{limit} {module}",
	},
	$company: {
		address: "Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
		name: "Slush Yazılım A.Ş.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "Antrego işlerinizi hızlandırın. Sevkiyat süreçlerinizi güvenli, kolay şekilde dijitalde yönetin.",
	},
	$country: {
		afghanistan: "Afganistan",
		aland: "Aland Adaları",
		albania: "Arnavutluk",
		algeria: "Cezayir",
		american_samoa: "Amerikan Samoası",
		andorra: "Andora",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antarktika",
		antigua_and_barbuda: "Antigua ve Barbuda",
		argentina: "Arjantin",
		armenia: "Ermenistan",
		aruba: "Aruba",
		australia: "Avustralya",
		austria: "Avusturya",
		azerbaijan: "Azerbaycan",
		bahamas: "Bahamalar",
		bahrain: "Bahreyn",
		bangladesh: "Bangladeş",
		barbados: "Barbados",
		belarus: "Beyaz Rusya",
		belgium: "Belçika",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermuda",
		bhutan: "Bhutan",
		bolivia: "Bolivya",
		bonaire_sint_eustatius_and_saba: "Bonaire, Saint Eustatius and Saba",
		bosnia_and_herzegovina: "Bosna Hersek",
		botswana: "Botswana",
		bouvet_island: "Bouvet Adası",
		brazil: "Brezilya",
		british_indian_ocean_territory: "İngiliz Hint Okyanusu Bölgesi",
		british_virgin_islands: "İngiliz Virgin Adaları",
		brunei: "Brunei",
		bulgaria: "Bulgaristan",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Yeşilburun",
		cambodia: "Kamboçya",
		cameroon: "Kamerun",
		canada: "Kanada",
		cayman_islands: "Cayman Adaları",
		central_african_republic: "Orta Afrika Cumhuriyeti",
		chad: "Çad",
		chile: "Şili",
		china: "Çin",
		christmas_island: "Christmas Adası",
		cocos_islands: "Cocos Adaları",
		colombia: "Kolombiya",
		comoros: "Komoros",
		congo_republic: "Kongo - Brazzaville",
		cook_islands: "Cook Adaları",
		costa_rica: "Kosta Rika",
		croatia: "Hırvatistan",
		cuba: "Küba",
		curacao: "Country of Curaçao",
		cyprus: "Kıbrıs",
		czechia: "Çekya",
		denmark: "Danimarka",
		djibouti: "Cibuti",
		dominica: "Dominika",
		dominican_republic: "Dominik Cumhuriyeti",
		dr_congo: "Kongo Cumhuriyeti",
		ecuador: "Ekvador",
		egypt: "Mısır",
		el_salvador: "El Salvador",
		equatorial_guinea: "Ekvator Ginesi",
		eritrea: "Eritre",
		estonia: "Estonya",
		eswatini: "Svaziland",
		ethiopia: "Etiyopya",
		falkland_islands: "Falkland Adaları",
		faroe_islands: "Faroe Adaları",
		fiji: "Fiji",
		finland: "Finlandiya",
		france: "Fransa",
		french_guiana: "Fransız Guyanası",
		french_polynesia: "Fransız Polinezyası",
		french_southern_territories: "Fransız Güney Bölgeleri",
		gabon: "Gabon",
		gambia: "Gambia",
		georgia: "Gürcistan",
		germany: "Almanya",
		ghana: "Gana",
		gibraltar: "Cebelitarık",
		greece: "Yunanistan",
		greenland: "Grönland",
		grenada: "Granada",
		guadeloupe: "Guadalupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Gine",
		guinea_bissau: "Gine-Biso",
		guyana: "Guyana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Heard Adası ve McDonald Adaları",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Macaristan",
		iceland: "İzlanda",
		india: "Hindistan",
		indonesia: "Endonezya",
		iran: "İran",
		iraq: "Irak",
		ireland: "İrlanda",
		isle_of_man: "Man Adası",
		israel: "İsrail",
		italy: "İtalya",
		ivory_coast: "Fildişi Sahilleri",
		jamaica: "Jamaika",
		japan: "Japonya",
		jersey: "Jersey",
		jordan: "Ürdün",
		kazakhstan: "Kazakistan",
		kenya: "Kenya",
		kiribati: "Kiribati",
		kosovo: "Kosova",
		kuwait: "Kuveyt",
		kyrgyzstan: "Kırgızistan",
		laos: "Laos",
		latvia: "Letonya",
		lebanon: "Lübnan",
		lesotho: "Lesotho",
		liberia: "Liberya",
		libya: "Libya",
		liechtenstein: "Liechtenstein",
		lithuania: "Litvanya",
		luxembourg: "Lüksemburg",
		macao: "Macau",
		madagascar: "Madagaskar",
		malawi: "Malavi",
		malaysia: "Malezya",
		maldives: "Maldivler",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Marshall Adaları",
		martinique: "Martinik",
		mauritania: "Moritanya",
		mauritius: "Mauritius",
		mayotte: "Mayotte",
		mexico: "Meksika",
		micronesia: "Mikronezya Federal Eyaletleri",
		moldova: "Moldovya",
		monaco: "Monako",
		mongolia: "Moğolistan",
		montenegro: "Karadağ",
		montserrat: "Montserrat",
		morocco: "Fas",
		mozambique: "Mozambik",
		myanmar: "Birmanya",
		namibia: "Namibya",
		nauru: "Nauru Adası",
		nepal: "Nepal",
		netherlands: "Hollanda",
		new_caledonia: "Yeni Kaledonya",
		new_zealand: "Yeni Zelanda",
		nicaragua: "Nikaragua",
		niger: "Nijer",
		nigeria: "Nijerya",
		niue: "Niue Adaları",
		norfolk_island: "Norfolk Adası",
		north_korea: "Kuzey Kore",
		north_macedonia: "Kuzey Makedonya",
		northern_mariana_islands: "Kuzey Mariana Adaları",
		norway: "Norveç",
		oman: "Umman",
		pakistan: "Pakistan",
		palau: "Palau",
		palestine: "Filistin Bölgesi",
		panama: "Panama",
		papua_new_guinea: "Papua Yeni Gine",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Filipinler",
		pitcairn_islands: "Pitcairn",
		poland: "Polonya",
		portugal: "Portekiz",
		puerto_rico: "Porto Riko",
		qatar: "Katar",
		reunion: "Reunion",
		romania: "Romanya",
		russia: "Rusya",
		rwanda: "Ruanda",
		saint_barthelemy: "Saint Barthelemy",
		saint_helena: "Saint Helena",
		saint_lucia: "Saint Lucia",
		saint_martin: "Saint Martin",
		saint_pierre_and_miquelon: "Saint Pierre ve Miquelon",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "São Tomé and Príncipe",
		saudi_arabia: "Suudi Arabistan",
		senegal: "Senegal",
		serbia: "Sırbistan",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leone",
		singapore: "Singapur",
		sint_maarten: "Sint Maarten",
		slovakia: "Slovakya",
		slovenia: "Slovenya",
		solomon_islands: "Solomon Adaları",
		somalia: "Somali",
		south_africa: "Güney Afrika",
		south_georgia_and_south_sandwich_islands: "Güney Georgia ve Güney Sandwich Adaları",
		south_korea: "Güney Kore",
		south_sudan: "South Sudan",
		spain: "İspanya",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "Saint Kitts ve Nevis",
		st_vincent_and_grenadines: "Saint Vincent ve Grenadines",
		sudan: "Sudan",
		suriname: "Surinam",
		svalbard_and_jan_mayen: "Svalbard ve Jan Mayen Adaları",
		sweden: "İsveç",
		switzerland: "İsviçre",
		syria: "Suriye",
		taiwan: "Çin Cumhuriyeti",
		tajikistan: "Tacikistan",
		tanzania: "Tanzanya",
		thailand: "Tayland",
		timor_leste: "Doğu Timor",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad ve Tobago",
		tunisia: "Tunus",
		turkiye: "Türkiye",
		turkmenistan: "Türkmenistan",
		turks_and_caicos_islands: "Turks ve Caicos Adaları",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ukrayna",
		united_arab_emirates: "Birleşik Arap Emirlikleri",
		united_kingdom: "İngiltere",
		united_states: "Amerika Birleşik Devletleri",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Amerika Birleşik Devletleri Küçük Dış Adaları",
		us_virgin_islands: "ABD Virgin Adaları",
		uzbekistan: "Özbekistan",
		vanuatu: "Vanuatu",
		vatican_city: "Vatikan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		wallis_and_futuna: "Wallis ve Futuna",
		western_sahara: "Batı Sahara",
		yemen: "Yemen",
		zambia: "Zambiya",
		zimbabwe: "Zimbabve",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Türk Lirası",
		us_dollar: "Amerikan Doları",
	},
	$employeeType: {
		admin: "Yönetici",
		default: "Normal",
		exporter: "İhracatçı",
		importer: "İthalatçı",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:ss",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Buradan yeni bir şirket oluşturun.",
			create_company_title: "Şirketinizi oluşturmak mı istiyorsunuz?",
			description:
				"Başarılı şekilde oturum açtınız. Şimdi bir şirkete katılmalı veya kendi şirketinizi oluşturmalısınız.",
			join_company_subtitle: "Buradan davet kodunuzu girin ve şirkete katılın.",
			join_company_title: "Mevcut bir şirkete katılmak mı istiyorsunuz?",
			title: "Hoş geldin {name}",
		},
		$unauthorized: {
			description: "Antrego ile ithalat-ihracat süreçlerinizi kontrol altında tutun!",
			forget_password_subtitle: "Lütfen şifrenizi yenilemek için e-posta adresinizi girin.",
			sign_in_subtitle: "Buradan giriş yapın",
			sign_in_title: "Bir hesabınız var mı?",
			sign_up_subtitle: "Buradan üye olun",
			sign_up_title: "Hesap oluşturmak mı istiyorsunuz?",
			title: "Antrego'ya hoş geldiniz",
		},
	},
	$invitationType: {
		company_partner: "Ticari İş Birliği Daveti",
		customs_partner: "Gümrükçü Daveti",
		employee: "Personel Daveti",
	},
	$landing: {
		$benefits: {
			benefits_title: "Faydalar",
			business_process: "İş süreçlerinizi hızlandırır.",
			control_title: "Antrego ile kontrol sizde olsun.",
			highlight_word: "kontrol",
			risk_of_error: "Hata riskini azaltır.",
			text: "Antrego, sevkiyat yapan firmaların, müşterilerinin ve gümrük müşavirlerinin ortak bir platform üzerinden sevkiyat süreçlerini takip etmelerine yardımcı olan bir sevkiyat takip programıdır.",
			time_and_cost_savings: "Zaman ve maliyet tasarrufu sağlar.",
		},
		$content: {
			$text: {
				0: "Tüm sevkiyatlarınızı aynı yerden takip edin, dinamik raporlamalar sayesinde finansınızı kontrol altında tutun.",
				1: "Anlık güncellemelerinizi müşterilerinizle paylaşarak hizmet kalitenizi artırın.",
				2: "Sevkiyatınıza dair tüm evrakları tek bir sistemde görüntüleyin ve müşterilerinizle paylaşın.",
				3: "Antego ya tüm cihazlardan ve her yerden erişilme imkanına sahip olarak sürecinizi rahatça kontrol edin.",
				4: "Bütün verilerinizi SHA-256 şifreleme teknolojisiyle saklayın. Güvenlikle alakalı içiniz rahat olsun.",
				5: "İş sürecindeki bütün paydaşlarınızla ortak takip yapın. Süreci daha sağlıklı yürütün.",
			},
			$title: {
				0: "Sevkiyat Yönetimi",
				1: "Müşteri İlişkileri",
				2: "Evrak Yönetimi",
				3: "Kolay Erişim",
				4: "Veri Güvenliği",
				5: "Ortak Takip",
			},
		},
		$home: {
			text: "Kesintisiz dijital takip ve ortak kontrol için bizi tercih edin.",
			title: "Tüm Sevkiyat Süreçlerinizi Dijitalde Kolayca Yönetin",
		},
		$lifecycle: {
			headline: "Süreç",
			info_button: "Bilgi Alın",
			lifecycle_texts: {
				0: "Ek dosyalar yükleyerek proforma faturanızı oluşturun ve anında müşterinizle paylaşın.",
				1: "Sevkiyat bilgilerinizi girin ve dökümanları ekleyin. Müşterinize bildirim gitsin ve tüm sevkiyat süreçlerinizi tek kanaldan müşterilerinizle ortak yönetin.",
				2: "İthalat ve ihracat ile ilgili tüm finansal durumunuzu anlık olarak takip edin.",
			},
			lifecycle_titles: {
				0: "Giriş Yapın ve Proforma Fatura Oluşturun",
				1: "Sevkiyat Oluşturun",
				2: "Finansal Faaliyetleri Takip Edin",
			},
			productivity_title: "3 adımda verimliliği artırın, karmaşayı ve hatayı azaltın.",
			tracking_guide_title: "Kolay Takip Rehberi",
		},
		$logistics: {
			logistics_title: "Desteklenen Lojistik Türleri",
			solutions_title: "Her yük için doğru çözüm",
			text: "Taşımacılığın her türündeki dijital ortağınız Antrego ile tüm küresel hareketinizde yanınızdayız.",
		},
		$process: {
			digital_future: "Geleneksel yöntemlerden kurtulun, dijital geleceğe adım atın",
			digital_shipping: "Antrego ile Dijital Sevkiyat Takibi",
			digital_shipping_texts: {
				0: "Gelen siparişlerin proforma faturaları oluşturulur ve tek bir kanaldan kolayca takip edilir.",
				1: "Proforma faturalar gönderilir, müşteriye anında bildirim gider ve müşteri tarafından yine Antrego üzerinden onaylanır.",
				2: "Evrak süreçleri Antrego üzerinden ilerletilir. Kaybolmaz, gecikme yaşanmaz ve hata potansiyeli en aza indirilir.",
				3: "Tedarikçi, müşteri ve gümrükçüler sevkiyat detaylarını anında görür ve ortak takip sağlanır.",
				4: "Finansal raporlamalar aylık ve yıllık bazda otomatik olarak oluşturulur.",
			},
			question_title: "Antrego ile Hayatınızda Neler Değişir?",
			tracking_method: "Takip yöntemlerinizi dijitalleştirin ve hızınıza hız katın.",
			traditional_shipping: "Geleneksel Sevkiyat Takibi",
			traditional_shipping_texts: {
				0: "Siparişler email, mesaj veya telefonla farklı kanallardan toplanır. Şirket içi takip zorlukları yaşanır.",
				1: "Siparişin proforma faturası Excel gibi araçlarla oluşturulur ve onay süreci yine email, WhatsApp veya telefonla manuel gerçekleştirilir",
				2: "Manuel evrak süreçleri hatalara ve gecikmelere yol açar, zaman ve para kaybına neden olur.",
				3: "Tedarikçi, müşteri ve gümrükçülerin sevkiyat detaylarını takip edebileceği ortak bir platform bulunmaz.",
				4: "Finansal veriler her sevkiyat sonrası tek tek kontrol edilir, raporlama zorlaşır.",
			},
		},
		$question: {
			$text: {
				0: "<p>Sevkiyat yapan firmaların ve müşterilerinin ortak takip yapmasına yardımcı olurken takip aşamasında gecikme kaynaklı yaşanabilecek zararların önüne geçen bir sevkiyat takip programıdır.</p>",
				1: "<p>- Tüm anlık güncellemelerini müşterinle paylaş. Hizmet kaliteni artır.<br>\n\t\t\t\t- Sevkiyat ile ilgili tüm evraklarını tek bir sistemde gör, müşterinle paylaş.<br>\n\t\t\t\t- Tüm sevkiyatlarını aynı yerden takip et. Dinamik raporlamalarla finansını kontrol altında tut.</p>",
				2: "<p>- Deniz<br>\n\t\t\t\t- Hava<br>\n\t\t\t\t- Kara<br>\n\t\t\t\t- Tren</p>",
				3: "<p>Sunduğu hizmetler ile sevkiyat sürecini kolaylaştırmak için her an elinin altında.<br>\n\t\t\t\t<strong>1. Kolay Erişim</strong><br>\n\t\t\t\tWeb tarayıcı üzerinden tüm cihazlardan ve her yerden erişilebilir.<br>\n\t\t\t\t<strong>2. Veri Güvenliği</strong><br>\n\t\t\t\tBütün veriler SHA-256 şifreleme teknolojisiyle saklanır.<br>\n\t\t\t\t<strong>3. Ortak Takip</strong><br>\n\t\t\t\tİş sürecindeki bütün paydaşlara takip imkanı sunar.</strong>",
				4: "<p>Evet ücretli bir uygulamadır. Sevkiyatlarınızın tüm süreçlerini dijitale taşıyarak, hem işlerinizi kolaylaştırmak, hem de size faydalı geliştirmeler yapmaya devam etmek istiyoruz. Bu geliştirmelerin devam edebilmesi ve sunucu üzerinde kullandığınız alan için sizden aylık olarak bir ücret talep ediyoruz.</p>",
				5: "<p>Hayır. Müşterileriniz sizin yaptığınız sevkiyatların takibini yaparken herhangi bir ücret ödemesine gerek yoktur.</p>",
			},
			$title: {
				0: "Antrego Nedir?",
				1: "Antrego sevkiyat sürecinde firmama ne katkı sağlayacak?",
				2: "Antrego’nun desteklediği lojistik türleri neler?",
				3: "Antrego’yu neden kullanmalıyım?",
				4: "Antrego ücretli bir uygulama mıdır?",
				5: "Antrego için müşterilerimizin de ücret ödemesi gerekiyor mu?",
			},
			save_percentage: "Aylık %16 Tasarruf Edin",
			text: "Antrego ile tüm bilgilere kolayca erişebilir, aklınıza takılan diğer sorular için bizimle iletişime geçebilirsiniz.",
			title: "Merak ettiğiniz ne varsa hızla öğrenin",
		},
		cancel_anytime: "İstediğin zaman iptal edebilirsin",
		customize_package:
			"Ek depolama alanı ve kullanıcı satın alabilir ve paketini ihtiyaçlarına göre özelleştirebilirsin",
		free_features_1: "10 Sevkiyat",
		free_features_2: "100 MB Depolama Alanı",
		free_features_3: "Sınırsız Ticari Partner",
		free_features_4: "1 Kullanıcı",
		free_plan: "Ücretsiz",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Aylık",
		premium_annual_features_1: "20 Kullanıcı",
		premium_annual_features_2: "100 Ortak",
		premium_annual_features_3: "1000 Sevkiyat",
		premium_annual_features_4: "1000 Fatura",
		premium_annual_price: "$83,25",
		premium_annual_regeneration: "Yıllık $999 olarak yenilenecektir",
		premium_annual_title: "Yıllık",
		premium_monthly_features_1: "Sınırsız Sevkiyat",
		premium_monthly_features_2: "Sınırsız Ticari Partner",
		premium_monthly_features_3: "2GB Depolama Alanı",
		premium_monthly_features_4: "3 Kullanıcı",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Aylık $99 olarak yenilecektir",
		premium_monthly_title: "Aylık",
		premium_plan: "Premium Plan",
		premium_plus_annual_features_1: "Sınırsız Sevkiyat",
		premium_plus_annual_features_2: "Sınırsız Ticari Partner",
		premium_plus_annual_features_3: "5GB Depolama Alanı",
		premium_plus_annual_features_4: "5 Kullanıcı",
		premium_plus_annual_price: "$83,25",
		premium_plus_annual_regeneration: "Yıllık $999 olarak yenilenecektir",
		premium_plus_annual_title: "Yıllık",
		premium_plus_monthly_features_1: "Sınırsız Sevkiyat",
		premium_plus_monthly_features_2: "Sınırsız Ticari Partner",
		premium_plus_monthly_features_3: "5GB Depolama Alanı",
		premium_plus_monthly_features_4: "5 Kullanıcı",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Aylık $249 olarak yenilecektir",
		premium_plus_monthly_title: "Aylık",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Bütçenizi zorlamadan ihtiyaçlarınıza en iyi şekilde karşılık verebiliriz.",
		pricing_title: "Antrego sizin için en uygun paketi ve fiyatı sunmayı garanti eder.",
		save_percentage: "Aylık %16 Tasarruf Et",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Arapça",
		de: "Almanca",
		en: "İngilizce",
		es: "İspanyolca",
		fr: "Fransızca",
		hi: "Hintçe",
		ind: "Endonezce",
		it: "İtalyanca",
		ja: "Japonca",
		ko: "Korece",
		pt: "Portekizce",
		ru: "Rusça",
		tr: "Türkçe",
		vi: "Vietnamca",
		zh: "Çince",
	},
	$message: {
		cannot_delete_verified_partner: "Doğrulanmış bir ortağı silemezsiniz.",
		confirm: "{function} istediğinize emin misiniz?",
		created: "{item} oluşturuldu.",
		deleted: "{item} silindi.",
		error_on_view_file: "Dosya görüntüleme sırasında bir hata oluştu",
		file_oversize: "Dosya boyutu en fazla {size} olmalı.",
		invalid: "Geçersiz {item}",
		migrated: "{item} taşındı. ({value})",
		process_has_been_started: "{item} işleminiz başlatıldı.",
		sent: "{item} gönderildi.",
		sent_email: "E-posta gönderildi. ({value})",
		sign_in_failed: "E-posta veya şifre yanlış",
		unsupported_file_type: "Desteklenmeyen dosya türü",
		updated: "{item} güncellendi.",
	},
	$pageTitles: {
		accept_invitation: "Davet Kabulü",
		company_profile: "Şirket Profili",
		home: "Ana Sayfa",
		landing_page: "Kesintisiz Dijital Takip, Ortak Kontrol",
		partners: "Ortaklar",
		product_groups: "Ürün Grupları",
		products: "Ürünler",
		proforma_invoices: "Proforma Faturaları",
		shipment_details: "Sevkiyat Detayları",
		shipments: "Sevkiyatlar",
		sign_in: "Oturum Aç",
		sign_up: "Üye ol",
		subscription: "Abonelik",
		user_profile: "Kullanıcı Profili",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Kayıtlı olmayan ortaklar, profilini platformumuzda siz oluşturdunuz, bu yüzden bilgilerini görüntüleyebilir ve düzenleyebilirsiniz.",
		createdHereTitle: "Burada Oluşturuldu:",
		description: "Ortaklarınızla olan ilişkinizin durumunu takip edebilirsiniz. İşte farklar:",
		verifiedPartnersDescription:
			"Bu ortaklar davetinizi kabul etti ve platformda kaydoldu. Bilgileri yalnızca görüntülenebilir ve düzenlenemez.",
		verifiedPartnersTitle: "Doğrulanmış Ortaklar:",
	},
	$productGroup: {
		add_child: "Alt Grup Ekle",
		add_new_product_group: "Yeni Ürün Grubu Ekle",
		add_to_root: "İlk Seviyeye Ekle",
		has_children_fail_message: "Alt grubu olan ürün grubu silinemez",
		initial_message: "İlk ürün grubunuzu eklemek için {0} butonuna basın.",
		name: "İsim",
		number_of_product: "Ürün Sayısı",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} onayladı.",
			create: "{name} tarafından oluşturuldu.",
			create_attachment: "{name} bir eklenti oluşturdu.",
			delete_attachment: "{name} bir eklentiyi sildi.",
			demand_revise: "{name} revize talep etti.",
			mark_as_processed: "{name} işlendi olarak işaretledi.",
			reject: "{name} reddetti.",
			revoke: "{name} geri çekti.",
			send: "{name} onay için alıcıya gönderdi.",
			update: "{name} tarafından güncellendi.",
			update_attachment: "{name} bir eklentiyi güncelledi.",
		},
		$actionDescription2: {
			approve: '{name} "Onaylandı" olarak işaretlendi.',
			demand_revise: '{name} "Revizyon Bekleniyor" olarak işaretlendi.',
			mark_as_processed: '{name} "İşlendi" olarak işaretlendi.',
			reject: "{name} reddedilmiş.",
			revoke: '{name} "İptal Edildi" olarak işaretlendi.',
			send: '{name} "Gönderildi" olarak işaretlendi.',
		},
		$actions: {
			approve: "Onayla",
			demand_revise: "Revize İste",
			mark_as_approved: "Onaylandı",
			mark_as_processed: "İşlendi",
			mark_as_rejected: "Reddedildi",
			mark_as_revise_demanded: "Revize İstendi",
			mark_as_revoked: "Geri Çekildi",
			mark_as_sent: "Gönderildi",
			reject: "Reddet",
			revoke: "Geri Çek",
			send: "Gönder",
		},
		$status: {
			approved: "Onaylı",
			pending_approval: "Onay Bekliyor",
			pending_revise: "Revize Bekliyor",
			processed: "İşlendi",
			rejected: "Reddedildi",
			revoked: "Geri Çekildi",
			template: "Şablon",
		},
		approve_confirm_message: "Proforma faturayı onaylamak istediğinize emin misiniz?",
		approve_success_message: "Proforma fatura onaylandı.",
		demand_revise_description_message: "Lütfen nasıl bir revizyon beklediğinizi açıklayın:",
		demand_revise_success_message: "Proforma fatura revize edilmek üzere geri gönderildi.",
		mark_as_processed_confirm_message: "Proforma faturayı işlendi olarak işaretlemeyi onaylıyor musunuz?",
		mark_as_processed_success_message: "Proforma fatura işlendi olarak işaretlendi.",
		no_attachment_text: "Henüz eklenti yüklenmedi.",
		reject_confirm_message: "Proforma faturayı reddetmek istediğinize emin misiniz?",
		reject_success_message: "Proforma fatura reddedildi.",
		revoke_confirm_message: "Proforma faturayı geri çekmek istediğinize emin misiniz?",
		revoke_success_message: "Proforma fatura geri çekildi.",
		send_confirm_message: "Proforma faturayı alıcıya göndermeyi onaylıyor musunuz?",
		send_success_message: "Proforma fatura alıcıya gönderildi.",
	},
	$serviceMessage: {
		already_subs_module: "Bu modül için zaten bir abonelik var.",
		bad_request: "Kötü istek",
		code_already_used: "Kod zaten kullanılmış.",
		container_status_not_available: "Konteyner statüsü geçerli değil.",
		existing_email_warning: "E-mail zaten var.",
		existing_employee_error: "Bu kullanıcı şirketinize zaten eklenmiş.",
		existing_employee_for_email_warning: "Davet kodu bu e-postaya zaten gönderildi.",
		existing_invitation_warning: "Davet kodu bu e-postaya zaten gönderildi.",
		existing_token_already_have_time: "Mevcut jetonun kullanılmak için zaten yeterli zamanı var.",
		existing_user: "Kullanıcı zaten var.",
		five_min_reset_password_mail: "Bir kez şifre sıfırlama maili talep ettikten sonra en az 5 dakika beklemelisiniz.",
		forbidden: "Yasak",
		have_no_corporation: "Şirketiniz bulunmamaktadır.",
		have_no_custom_agency: "Gümrük acenteniz bulunmamaktadır.",
		have_no_employee: "Çalışanınız bulunmamaktadır.",
		instance_can_no_delete_existing_booking_code: "Öğe, mevcut rezervasyon kodu nedeniyle artık silinemez.",
		instance_cannot_delete: "Öğe artık silinemez.",
		instance_not_found: "Öğe bulunamadı.",
		internal_server_error: "Dahili sunucu hatası.",
		invalid_current_password: "Mevcut parolanız hatalı.",
		invalid_identifier_or_password: "Geçersiz email veya şifre",
		invalid_invitation_code: "Davet kodu geçersiz.",
		invalid_moving_request: "Geçersiz taşıma isteği.",
		invalid_operation: "Geçersiz işlem.",
		invalid_refresh_token: "Geçersiz yenileme jetonu.",
		invalid_unit: "Geçersiz birim.",
		invitation_accepting_is_due_to_the_admin: "Davetiyenin kabul edilmesi yöneticiye bağlıdır.",
		invitation_already_discarded: "Davetiye zaten iptal edildi.",
		invitation_already_used: "Davetiye kullanılmıştır.",
		invitation_has_been_used: "Davetiye kullanılmıştır.",
		invitation_not_exist_email: "Davetiye e-posta adresi mevcut değil.",
		invitation_not_for_company: "Bu davetiye bir şirket için değildir.",
		invitation_not_for_custom_agency: "Bu davetiye bir gümrük acentesi için değildir.",
		invitation_not_found: "Davetiye bulunamadı.",
		invitation_not_valid: "Davetiye artık geçerli değil.",
		link_has_been_expired: "Bağlantının süresi doldu.",
		link_not_be_used_anymore: "Bu bağlantı artık kullanılmayabilir.",
		missing_required_fields: "Gerekli alanlar doldurulmalıdır.",
		no_employee_found: "Çalışan bulunamadı.",
		no_proper_partner_found: "Uygun ortak bulunamadı.",
		not_acceptable: "Kabul edilemez.",
		not_allowed_action: "Bu eylemi gerçekleştirme izniniz yok.",
		not_allowed_use_api: "Bu alanı kullanmaya yetkiniz yok.",
		not_found: "Bulunamadı.",
		not_have_purchase_subscription:
			"Abonelik paketiniz, tanımlanabilecek maksimum limite ulaşmıştır. Daha fazla tanımlama yapabilmek için abonelik paketinizi yükseltebilirsiniz.",
		not_permitted_use_api: "Bu alanı kullanmaya yetkiniz yok",
		own_invitation_cannot_accept: "Kendinize davet gönderemezsiniz.",
		parent_group_not_found: "Ürün grubu bulunamadı.",
		partnerId_required: "Partner ID alanı gereklidir.",
		partnership_already_exists: "İş ortağı zaten eklendi.",
		password_cant_involve_space_char: "Şifre boşluk karakteri içeremez.",
		password_min_chars_least: "Şifre en az 6 karakter olmalıdır.",
		payment_required: "Bu işlemi yapabilmeniz için lütfen abone olunuz.",
		paytr_integration_error: "Ödeme altyapısında bir sorun ile karşılaşıldı. En kısa zamanda düzeltilecek.",
		ports_are_required: "Port gereklidir.",
		product_group_tree_not_found: "Ürün grubu ağacı bulunamadı.",
		proforma_invoice_action_not_available: "Proforma faturanın durumu bu işlem için mevcut değil.",
		proforma_invoice_template_update: "Proforma faturanın güncellenmesi için şablon olması gerekir.",
		shipment_email_not_exist: "Lütfen geçerli bir e-posta giriniz",
		shipment_method_is_required: "Sevkiyat yöntemi gereklidir.",
		shipping_term_is_required: "Sevkiyat şartı gereklidir.",
		someone_else_uses_this_email: "Bu e-posta adresini başkası kullanıyor.",
		someone_else_uses_this_username: "Bu kullanıcı adını başkası kullanıyor.",
		something_went_wrong: "Bir şeyler yanlış gitti",
		type_can_be_abstract_company: '"type" özelliği sadece "abstract_company" olabilir.',
		unauthorized: "Yetkisiz",
		undefined_result: "Tanımsız sonuç.",
		unexpected_error_occurred: "Beklenmeyen bir hata oluştu.",
		unit_already_added: "Birim zaten eklendi.",
		user_already_employee: "Kullanıcı zaten bir çalışan.",
		user_already_exists: "Kullanıcı zaten bir şirketin çalışanı olarak yer almaktadır.",
		user_does_not_exist: "Kullanıcı mevcut değil.",
		user_not_found: "Kullanıcı bulunamadı.",
		username_min_chars_error: '"Kullanıcı Adı" en az 6 karakter olmalıdır.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} bir araç ekledi.",
				delete_container: "{name} bir aracı sildi.",
				update_container: "{name} bir aracı güncelledi.",
			},
			create: "{name} tarafından oluşturuldu.",
			create_attachment: "{name} bir eklenti oluşturdu.",
			create_container: "{name} bir konteyner ekledi.",
			delete_attachment: "{name} bir eklentiyi sildi.",
			delete_container: "{name} bir konteyneri sildi.",
			update: "{name} tarafından güncellendi.",
			update_attachment: "{name} bir eklentiyi güncelledi.",
			update_container: "{name} bir konteyneri güncelledi.",
		},
		$containerStatus: {
			arrived: "Vardı",
			off_load: "İndirildi",
			on_load: "Yüklendi",
			on_way: "Yolda",
			reserved: "Rezerve",
		},
		$method: {
			aerial: "Hava",
			land: "Kara",
			railway: "Tren",
			sea: "Deniz",
		},
		$vehicle: {
			license_number: "Plaka No",
		},
		archiving_success_message: "Sevkiyat arşivlendi.",
		leave_message: "Mesaj ya da yorumunuzu bırakın",
		no_booking_info: "Henüz booking bilgisi tanımlı değil. Eklemek için {0} butonuna basın.",
		no_transportation_info: "Henüz booking sevkiyat detayları tanımlı değil. Tanımlamak için {0} butonuna basın.",
		un_archiving_success_message: "Sevkiyat arşivden çıkarıldı.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Aboneliğiniz yenilenmesi yapılamadı. Lütfen ödeme yönteminizi kontrol ediniz.",
			fail: "Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyiniz. Sorun devam ediyorsa 'info@antrego.net' adresine mail atarak bildiriniz.",
			ok: "Satın alma işlemi başlatıldı. İşlem sonucu e-posta ile bildirilecektir. Ödeme başarılı şekilde tamamlandığı zaman aboneliğiniz başlatılacaktır.",
			payment_process_is_ongoing:
				"Ödeme işleminiz devam etmektedir. İşlem sonucu e-posta ile bildirilecektir. Ödeme başarılı şekilde tamamlandığı zaman aboneliğiniz başlatılacaktır.",
			success: "Aboneliğiniz başarıyla oluşturulmuştur. Antrego'yu özgürce kullanmaya başlayabilirsiniz.",
			try_to_get_paid_again:
				"Herhangi bir aboneliğiniz yok. Lütfen abonelik sayfasından bir paket seçin ve kolayca kullanmaya başlayın.",
			unsubscribed: "Abonelikten Çıkıldı",
			you_dont_have_any_subscription:
				"Herhangi bir aboneliğiniz bulunmamaktadır. Abonelik sayfasından size uygun paketi seçip rahatça kullanmaya başlayabilirsiniz",
			you_dont_have_any_subscription_text:
				"Sizin için en uygun üyelik planını seçin ve fırsatlardan tam olarak yararlanın.",
			you_dont_have_any_subscription_title: "Planlarımızla Daha Fazlasını Yapın",
		},
	},
	$unit: {
		acres: "Acre",
		boxes: "Kutu",
		cubic_meter: "Metreküp",
		dozen: "Düzine",
		feet: "Feet",
		fluid_ounces: "Sıvı Ons",
		gallons: "Galon",
		gram: "Gram",
		hectare: "Hektar",
		inches: "İnç",
		kilogram: "Kilogram",
		kilometer: "Kilometre",
		liter: "Litre",
		meter: "Metre",
		miles: "Mil",
		milligram: "Miligram",
		milliliter: "Mililitre",
		millimeter: "Milimetre",
		ounces: "Ons",
		pallets: "Palet",
		pieces: "Parça",
		pounds: "Pound",
		quarts: "Quart",
		square_feet: "Fit kare",
		square_inches: "İnç kare",
		square_kilometer: "Kilometrekare",
		square_meter: "Metrekare",
		square_miles: "Mil kare",
		square_yards: "Yard kare",
		ton: "Ton",
		yards: "Yard",
	},
	$validation: {
		email: "Lütfen geçerli bir e-posta giriniz",
		invalid: "{item} geçersiz",
		max_length: "{item} en fazla {length} karakter olmalı",
		min_length: "{item} en az {length} karakter olmalı",
		must_length: "{item} {length} karakter olmalı",
		only_number: "{item} sadece sayılardan oluşmalı",
		password_repeat_warning: "Parola tekrarı yeni parola ile aynı olmalı.",
		required: "{item} boş bırakılamaz",
		required_for_default_language: "{item} bilgisi şirketin varsayılan dili için tanımlı olmalı.",
		you_must_approve_distance_sales_agreement: "Mesafeli Satış Sözleşmesini onaylamalısınız",
	},
	about: "Hakkında",
	accept_invitation: "Daveti Kabul Et",
	accept_invitation_success_message: "Davetiyeyi başarılı şekilde kullandınız.",
	accessibility: "Erişilebilirlik",
	actions: "Eylemler",
	active: "Aktif",
	activities: "Etkinlikler",
	add: "Ekle",
	add_item: "{item} Ekle",
	add_new_employee: "Yeni Çalışan Ekle",
	add_new_product: "Yeni Ürün Ekle",
	address: "Adres",
	again: "Tekrar",
	alert: "Uyarı",
	already_have_employee_message: "Zaten bir şirketin çalışanısınız.",
	amount: "Tutar",
	approve: "Onayla",
	archive: "Arşivle",
	attachment: "Ek Dosya",
	attachments: "Ek Dosyalar",
	bank: "Banka",
	benefits: "Faydalar",
	booking: "Booking",
	booking_code: "Sevkiyat Kodu",
	booking_details: "Sevkiyat Detayları",
	business_partner: "İş Ortağı",
	business_partners: "İş Ortakları",
	cancel: "Vazgeç",
	change_password: "Şifre Değiştir",
	checking_invitation_code_message: "Davetiye kodu kontrol ediliyor",
	choose_image: "Görüntü Seç",
	choose_your_plan: "Planınızı Seçin",
	clear: "Temizle",
	click_to_play_the_video: "Videoyu oynatmak için tıklayın",
	close: "Kapat",
	code: "Kod",
	commercial: "Ticari",
	commercial_partner: "Ticari Ortak",
	commercial_partners: "Ticari Ortaklar",
	companies: "Şirketler",
	company: "Şirket",
	company_name: "Şirket Adı",
	company_profile: "Şirket Profili",
	configuration: "Yapılandırma",
	confirm: "Onayla",
	confirm_distance_selling_agreement: "Mesafeli Satış Sözleşmesini onayla.",
	consult_us: "Bize Danışın",
	contact: "İletişim",
	contact_info: "İletişim Bilgileri",
	contact_text: "En kısa zamanda size ulaşacağımızdan emin olabilirsiniz.",
	contact_title: "Bize Danışın, Süreçlerinizi Kolaylaştıralım",
	contact_to_us: "Bizimle İletişime Geçin",
	container: "Konteyner",
	container_no: "Konteyner No",
	containers: "Konteynerler",
	content: "İçerik",
	contract: "Sözleşme",
	copy_link: "Bağlantıyı Kopyala",
	countries: "Ülkeler",
	country: "Ülke",
	create: "Oluştur",
	create_a_new_account: "Yeni bir hesap oluştur",
	create_company: "Şirket Oluştur",
	created_at: "Oluşturulma Zamanı",
	created_here: "Burada Oluşturuldu",
	credit_card_info: "Kredi Kart Bilgileri",
	credit_card_no: "Kredi Kart Numarası",
	currencies: "Para Birimleri",
	currency: "Para Birimi",
	current_password: "Mevcut Şifre",
	current_plan: "Mevcut Plan",
	customs: "Gümrük",
	customs_agencies: "Gümrük Şirketleri",
	customs_agency: "Gümrük Şirketi",
	cvv: "CVV",
	daily: "Günlük",
	dashboard: "Pano",
	date: "Tarih",
	day: "Gün",
	default: "Varsayılan",
	default_language: "Varsayılan Dil",
	delete: "Sil",
	delete_account: "Hesabı Sil",
	delete_confirm_message: "Silme işlemini onaylıyor musunuz?",
	description: "Açıklama",
	detail: "Detay",
	discard: "Geri Çek",
	distance_sales_agreement: "Mesafeli Satış Sözleşmesi",
	document_number: "Belge No",
	download: "İndir",
	edit: "Düzenle",
	edit_item: "{item} Düzenle",
	edit_product: "Ürün Düzenle",
	edit_profile: "Profili Düzenle",
	edit_your_profile_picture: "Profil resmini düzenle",
	email: "E-posta",
	employee: "Personel",
	employee_type: "Personel Tipi",
	employees: "Personeller",
	estimated_arrival_day: "Tahmini Varış Günü",
	expense: "Maliyet",
	expenses: "Maliyetler",
	expires_at: "Sona Erme Tarihi",
	exported_containers: "İhracat Konteynerleri",
	fail: "Başarısız",
	failed: "Başarısız",
	faq: "S.S.S.",
	feedback: "Geri Bildirim",
	file: "Dosya",
	file_preview: "Dosya Önizlemesi",
	file_view: "Dosya Görüntüle",
	filter: "Filtre",
	first_name: "Ad",
	first_name_last_name: "Ad Soyad",
	forget_password: "Şifremi Unuttum",
	forget_password_message: "Şifreni mi unuttun?",
	free: "Ücretsiz",
	from: "Nereden",
	get_started_now: "Hemen Başlayın",
	give_us_feedback: "Bize Geri Bildirim Verin",
	go_to_panel: "Panele Git",
	hide: "Gizle",
	home_page: "Ana Sayfa",
	i_have_invitation_code: "Davetiye kodum var",
	imported_containers: "İthalat Konteynerleri",
	in_progress: "Devam Ediyor",
	incoming: "Gelen",
	incoming_shipments: "Gelen Sevkiyatlar",
	initial: "Başlangıç",
	invitation: "Davetiye",
	invitation_code: "Davetiye Kodu",
	invitation_mail_sent: "Davetiye e-postası gönderildi",
	invitations: "Davetiyeler",
	join: "Katıl",
	join_company: "Şirkete Katıl",
	language: "Dil",
	language_options: "Dil Seçenekleri",
	last_name: "Soyadı",
	last_one_year: "Son 1 Yıl",
	latitude: "Enlem",
	left_a_comment: "Bir yorum bıraktı",
	link_copied: "Bağlantı kopyalandı",
	link_copy_failed: "Bağlantı kopyalanamadı",
	loading_country: "Yükleme Ülkesi",
	loading_point: "Yükleme Noktası",
	loading_port: "Yükleme Limanı",
	log_in: "Giriş Yapın",
	logo: "Logo",
	longitude: "Boylam",
	mail_sent: "Posta gönderildi",
	manage_profile: "Antrego Profilinizi Yönetin",
	message: "Mesaj",
	message_company_create: "Şirket oluşturuldu.",
	message_company_join: "Lütfen davetiye kodunu giriniz.",
	message_company_joined: "Şirkete katıldın",
	message_employee_delete: "Personel silindi.",
	message_employee_save: "Personel kaydedildi.",
	message_join_company: "Bir şirkete kaydolmak için davet kodunuzu giriniz.",
	method: "Yöntem",
	migrate: "Taşı",
	migrate_partner: "Ortağı taşı",
	mobile_number: "Cep Numarası",
	month: "Ay",
	month_lowercase: "Aylık",
	monthly: "Aylık",
	my_profile: "Profilim",
	my_role: "Rolüm",
	name: "İsim",
	new: "Yeni",
	new_company_package: "Ücretsiz",
	new_password: "Yeni Şifre",
	new_shipment: "Yeni Sevkiyat",
	next: "İleri",
	no: "Hayır",
	no_content: "İçerik yok",
	no_entity: "Henüz {entityName} yok",
	no_entity_message: "Oluşturulan {entityName} ve detayları burada listelenecek",
	no_text: "Metin yok",
	not_found_page_message: "Üzgünüm, bu sayfayı bulamadım :(",
	note: "Not",
	notes: "Notlar",
	ok: "Tamam",
	operation_fail: "İşlem Başarısız",
	outgoing: "Giden",
	outgoing_shipments: "Giden Sevkiyatlar",
	packages: "Paketler",
	parent_group: "Üst Grup",
	partner: "Ortak",
	partners: "Ortaklar",
	partnership_status: "Ortaklık Durumu",
	passive: "Pasif",
	password: "Parola",
	pay: "Ödeme Yap",
	payback: "Geri Ödeme",
	payback_confirm_message: "Geri ödeme işlemini onaylıyor musunuz?",
	payback_warning_message: "Devam ettiğiniz durumda aboneliğiniz sonlandırılacaktır.",
	payment: "Ödeme",
	payment_amount: "Payment Tutarı",
	payment_awaited: "Ödeme Bekleniyor",
	payment_history: "Ödeme Geçmişi",
	payment_success: "Ödeme Başarılı",
	payment_summary: "Ödeme Özeti",
	payment_will_be_made_3d_secure: "*Ödeme işleminiz 3d Secure ile gerçekleştirilecektir.",
	pending_invitations: "Bekleyen Davetler",
	per_month: "aylık",
	phone_number: "Telefon Numarası",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Önceki",
	pricing: "Fiyatlandırma",
	process: "Süreç",
	product: "Ürün",
	product_group: "Ürün Grubu",
	product_group_name: "Ürün Grubu Adı",
	product_groups: "Ürün Grupları",
	product_name: "Ürün Adı",
	products: "Ürünler",
	profile: "Profil",
	proforma_invoice: "Proforma Fatura",
	proforma_invoices: "Proforma Faturalar",
	proforma_number: "Proforma No",
	purchase: "Satın Alma",
	quantity: "Miktar",
	receiver: "Alıcı",
	receiver_company: "Alıcı Şirket",
	receiver_customs_agency: "Alıcı Gümrük Şirketi",
	redirection_to_home_link: "tıklayınız",
	redirection_to_home_message: "Ana sayfaya dönmek için ",
	redirection_to_sign_in_message: "Bir hesabın var mı?",
	redirection_to_sign_up_message: "Üye değil misin?",
	reload: "Yenile",
	renewal: "Yenileme",
	repaid: "Geri Ödendi",
	reset_password: "Şifre Sıfırla",
	rows_per_page: "Sayfa başına satır",
	sales: "Satış",
	save: "Kaydet",
	save_changes: "Değişiklikleri Kaydet",
	saved_card_info: "Kayıtlı Kart Bilgisi",
	search: "Ara",
	select: "Seç",
	select_file: "Dosya Seç",
	select_file_place_holder: "Lütfen bir görüntü seçiniz",
	select_language: "Dil seçin",
	selected: "Seçildi",
	selected_partner: "Seçilen Ortak",
	send: "Gönder",
	send_again: "Tekrar Gönder",
	send_email: "E-posta Gönder",
	send_feedback: "Geri Bildirim Gönder",
	send_invitation: "Davet Gönder",
	sender: "Gönderici",
	sender_company: "Gönderici Şirket",
	sender_customs_agency: "Gönderici Gümrük Şirketi",
	settings: "Ayarlar",
	shipment: "Sevkiyat",
	shipment_method: "Sevkiyat Yöntemi",
	shipment_type: "Sevkiyat Tipi",
	shipments: "Sevkiyatlar",
	shipments_filter: "Sevkiyat Filtresi",
	shipping_term: "Sevkiyat Şartı",
	shipping_terms: "Sevkiyat Şartları",
	show: "Görüntüle",
	sign_in: "Giriş Yap",
	sign_out: "Çıkış Yap",
	sign_out_confirm_message: "Oturum kapatma işlemini onaylıyor musunuz?",
	sign_out_success_message: "Güle Güle.",
	sign_up: "Üye Ol",
	social_media: "Sosyal Medya",
	social_media_account: "Sosyal Medya Hesabı",
	start_time: "Başlangıç Zamanı",
	status: "Durum",
	subscribe: "Abone Ol",
	subscription: "Abonelik",
	subscription_info_message: "Abonelik süresi boyunca bütün hizmetlerinden sınırsız şekilde faydalanabilirsiniz.",
	subscription_warning_message:
		"**Kart bilgileriniz güvenli şekilde kaydedilecektir ve iptal etmediğiniz sürece aboneliğiniz düzenli olarak yenilenecektir.",
	success: "Başarılı",
	super: "Süper",
	supported_files_img: "Desteklenen dosya türleri: JPG, PNG.",
	switch_language: "Dili Değiştir",
	switch_theme: "Temayı Değiştir",
	target: "Hedef",
	target_partner: "Hedef Ortak",
	tax_number: "Vergi Numarası",
	theme: "Tema",
	this_month: "Bu Ay",
	this_year: "Bu Yıl",
	title: "Başlık",
	to: "Nereye",
	toDelete: "Silmek",
	total: "Toplam",
	total_amount: "Toplam Tutar",
	transaction_no: "İşlem Numarası",
	transactions: "İşlemler",
	try_for_free: "Ücretsiz Deneyin",
	type: "Tip",
	types: "Tipler",
	un_archive: "Arşivden Çıkar",
	unit: "Birim",
	units: "Birimler",
	unloading_country: "İndirme Ülkesi",
	unloading_point: "İndirme Noktası",
	unloading_port: "İndirme Limanı",
	unsubscribe: "Abonelikten Çık",
	unsubscribe_confirm_message:
		"Abonelikten çıktığınız durumda sistem özelliklerini efektif şekilde kullanamayacaksınız. Abonelikten çıkmak istediğinize emin misiniz?",
	upload_new_image: "Yeni Resim Yükle",
	use_code: "Kod Kullan",
	use_invitation_code: "Davet Kodu Kullan",
	use_invitation_code_description: "Mail kutunuza gönderilen davetiye kodunuzu girin.",
	use_invitation_code_success_message: "Davetiye kodu başarılı şekilde kullanıldı.",
	user_profile: "Profilim",
	vehicle: "Araç",
	vehicles: "Araçlar",
	verified_partner: "Doğrulanmış Ortak",
	verify_password: "Şifreyi Doğrula",
	visibility: "Görünürlük",
	warehouse: "Depo",
	warehouses: "Depolar",
	website: "Web Sitesi",
	week: "Hafta",
	welcome: "Hoş geldin",
	what_we_are_doing: "Neler Yapıyoruz",
	year: "Yıl",
	year_lowercase: "Yıllık",
	yearly: "Yıllık",
	yes: "Evet",
	you_dont_have_any_active_subscription: "Aktif bir aboneliğiniz bulunmamaktadır",
};
