<template>
	<v-autocomplete
		v-model="currentValue"
		:items="currentOptions"
		item-text="name"
		item-value="id"
		outlined
		:label="label || $t('partner')"
		:disabled="!ready || disabled"
		:loading="!ready && !disabled"
		:clearable="clearable"
		:rules="rules"
		:prepend-icon="prependIcon"
		clear-icon="icon-cross"
		append-icon="icon-angle-down"
		@change="changeAction"
	/>
</template>

<script>
import remote from "../../data/remote";
import { mapState } from "vuex";

export default {
	props: [
		"value",
		"eager",
		"options",
		"label",
		"clearable",
		"disabled",
		"rules",
		"prepend-icon",
		"types", // ["company", "abstract_company", "customs_agency"]
	],
	data() {
		return {
			currentValue: null,
			currentOptions: [],
			ready: false,
		};
	},
	computed: {
		...mapState(["corporation"]),
	},
	watch: {
		currentValue(val) {
			this.$emit("input", val);
		},
		value(val) {
			this.currentValue = val || null;
		},
		options(val) {
			this.handleProps();
		},
	},
	methods: {
		handleProps() {
			if (this.options) {
				this.currentOptions = this.options;
				this.ready = true;
			} else if (this.eager) this.loadOptions();
		},
		loadOptions() {
			remote.corporations.partners.filter(
				this.corporation.id,
				{ filter: { types: this.types } },
				{
					onSuccess: (result) => {
						this.currentOptions = result.items;
						this.ready = true;
					},
				}
			);
		},
		changeAction(id) {
			this.$emit(
				"change",
				this.currentOptions.find((i) => i.id === id)
			);
		},
	},
	created() {
		this.currentValue = this.value || null;
		this.handleProps();
	},
};
</script>
<style scoped>
/* Target the clear-icon button inside v-autocomplete */
.v-input__control .v-input__icon--clear {
	font-size: 16px !important; /* Adjust the size as needed */
	width: 16px !important; /* Adjust icon width */
	height: 16px !important; /* Adjust icon height */
}
</style>
