<template>
	<ext-dialog
		v-model="value"
		:title="$t('use_invitation_code')"
		:primaryButtonText="$t('accept_invitation')"
		@primaryButtonAction="acceptCode"
		@closed="closeDialog"
	>
		<template #body>
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="invitationCode"
					:label="$t('invitation_code')"
					:rules="rules.invitationCode"
					outlined
					class="rounded-xl"
				></v-text-field>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import uiMixin from "@/store/interfaces/ui.mixin";
import remote from "../../../../data/remote";

export default {
	mixins: [uiMixin],
	components: { ExtDialog },
	props: {
		value: Boolean,
	},
	data() {
		return {
			valid: false,
			invitationCode: "",
			saving: false,
			rules: {
				invitationCode: [
					(v) =>
						(Boolean(v) && Boolean(v.trim())) || this.$t("$validation.required", { item: this.$t("invitation_code") }),
				],
			},
		};
	},
	methods: {
		acceptCode() {
			if (this.$refs.form.validate()) {
				this.saving = true;
				this.showProgressDialog();
				remote.invitations.accept(this.invitationCode.trim(), {
					onSuccess: (result) => {
						this.saving = false;
						this.hideProgressDialog();
						this.showSnackBar({
							message: this.$t("accept_invitation_success_message"),
						});
						this.$emit("accepted", result);
						this.closeDialog();
					},
					onFail: (e) => {
						this.saving = false;
						this.hideProgressDialog();
					},
				});
			}
		},
		closeDialog() {
			this.$emit("input", false);
		},
	},
};
</script>
