<template>
	<v-container class="px-2 pb-0">
		<!-- Header and Filter Button -->
		<v-row justify="space-between">
			<v-col v-if="title" cols="auto">
				<v-card-title class="font-weight-bold px-0">
					{{ title }}
				</v-card-title>
			</v-col>
			<v-col v-if="showFilter" cols="auto">
				<v-btn class="filter-button my-4" outlined :color="filtered ? 'primary' : 'grey'" @click="filterButtonAction">
					<v-icon class="icon-filter pr-2" />
					{{ $t("filter") }}
				</v-btn>
			</v-col>
		</v-row>

		<v-card outlined elevation="0" class="ant-card py-2">
			<v-data-table
				:headers="modifiedHeaders"
				:items="items"
				:hide-default-footer="true"
				:loading="loading"
				:disable-sort="true"
				style="cursor: pointer"
				@click:row="handleRowClick"
			>
				<template #header.partnershipStatus>
					<div class="d-flex align-center">
						<span>{{ $t("partnership_status") }}</span>
						<v-tooltip bottom offset-y color="black">
							<template v-slot:activator="{ on, attrs }">
								<v-icon small class="ml-2" v-bind="attrs" v-on="on"> icon-comment-info </v-icon>
							</template>
							<div class="custom-tooltip">
								<p>{{ $t("$partnershipStatusTooltip.description") }}</p>
								<b>{{ $t("$partnershipStatusTooltip.verifiedPartnersTitle") }}</b>
								{{ $t("$partnershipStatusTooltip.verifiedPartnersDescription") }}<br />
								<b>{{ $t("$partnershipStatusTooltip.createdHereTitle") }}</b>
								{{ $t("$partnershipStatusTooltip.createdHereDescription") }}
							</div>
						</v-tooltip>
					</div>
				</template>

				<template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
					<slot :name="`item.${header.value}`" :item="item">
						{{ getItemValue(item, header.value) }}
					</slot>
				</template>

				<template #item.actions="{ item }">
					<slot name="item.actions" :item="item">
						<div class="d-flex justify-end mr-2">
							<v-btn text @click.stop="deleteItem(item)">
								<v-icon class="icon-delete" size="large"></v-icon>
							</v-btn>
							<v-btn v-if="isAttachment" plain text @click.stop="downloadItem(item)">
								<v-icon class="icon-download" size="large"></v-icon>
							</v-btn>
						</div>
					</slot>
				</template>

				<template #no-data>
					<div v-if="!loading" class="no-data-message text-center">
						<v-icon class="icon-empty-state" size="28" color="black"></v-icon>
						<v-col>
							<p class="font-weight-bold mb-1 black--text">
								{{ $t("no_entity", { entityName: entityName }) }}
							</p>
							<span>{{ $t("no_entity_message", { entityName: entityName }) }}</span>
						</v-col>
					</div>
				</template>
			</v-data-table>
		</v-card>

		<v-card-actions class="my-4 pl-0" v-if="!hidePagination && itemsLength">
			<v-row align="center" justify="end">
				<v-col>
					<slot name="actions">
						<v-btn v-if="buttonText" color="accent" outlined large elevation="0" @click="buttonAction">
							<v-icon class="icon-plus mr-2" start size="medium"></v-icon>
							{{ buttonText }}
						</v-btn>
					</slot>
				</v-col>
				<v-col cols="auto" class="d-flex align-center pr-0">
					<label for="rows-per-page" class="mr-2">
						{{ $t("rows_per_page") }}
					</label>
					<v-select
						v-model="computedItemsPerPage"
						:items="itemsPerPageOptions"
						hide-details
						dense
						outlined
						class="mr-4 hide-select-input"
						append-icon="icon-angle-down"
						:menu-props="{ offsetY: true }"
					/>
					<v-pagination
						prev-icon="icon-angle-left"
						next-icon="icon-angle-right"
						v-model="computedPage"
						:length="hidePagination ? 1 : Math.ceil(itemsLength / computedItemsPerPage)"
						circle
					/>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String,
		showFilter: { type: Boolean, default: false },
		filtered: { type: Boolean, default: false },
		headers: Array,
		items: {
			type: Array,
			default: () => [],
		},
		itemsLength: Number,
		itemsPerPageOptions: Array,
		loading: {
			type: Boolean,
			default: false,
		},
		options: Object,
		entityName: { type: String, default: "item" },
		isAttachment: { type: Boolean, default: false },
		buttonText: { type: String, default: null },
		hidePagination: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedItemsPerPage: {
			get() {
				return this.hidePagination ? this.items.length : this.options.itemsPerPage;
			},
			set(value) {
				if (!this.hidePagination) {
					this.$emit("update:options", {
						...this.options,
						itemsPerPage: value,
						page: 1,
					});
				}
			},
		},
		computedPage: {
			get() {
				return this.hidePagination ? 1 : this.options.page;
			},
			set(value) {
				if (!this.hidePagination) {
					this.$emit("update:options", {
						...this.options,
						page: value,
					});
				}
			},
		},
		modifiedHeaders() {
			if (this.items.length === 0 && !this.loading) {
				return [];
			}
			return this.headers.map((header) => {
				if (header.value === "actions") {
					return { ...header, align: "end", class: "actions-column" };
				}
				return header;
			});
		},
	},
	watch: {
		hidePagination(newVal) {
			if (newVal) {
				this.$emit("update:options", {
					...this.options,
					itemsPerPage: this.items.length,
					page: 1,
				});
			}
		},
		itemsLength(newVal) {
			const totalPages = Math.ceil(newVal / this.computedItemsPerPage) || 1;
			if (this.computedPage > totalPages) {
				this.computedPage = totalPages;
			} else if (totalPages === 0) {
				this.computedPage = 1;
			}
		},
	},
	methods: {
		handleRowClick(click, table) {
			this.$emit("row-click", table.item);
		},
		deleteItem(item) {
			this.$emit("delete-item", item);
		},
		buttonAction() {
			this.$emit("button-click");
		},
		downloadItem(item) {
			this.$emit("download-item", item);
		},
		filterButtonAction() {
			this.$emit("filter-button-click");
		},
		getItemValue(obj, path) {
			return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
		},
	},
};
</script>

<style scoped>
.filter-button {
	border-radius: 4px !important;
	font-size: 18px !important;
	line-height: 20px !important;
	font-weight: normal !important;
	letter-spacing: 0px !important;
	/* color: rgb(142, 142, 142); */
	background-color: white !important;
}

/* Styling for the No Data Message */
.no-data-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.54);
}

.icon-empty-state {
	margin-top: 10px;
}

.text-center {
	text-align: center;
}
</style>
