<template>
	<v-select
		v-model="currentValue"
		:items="currentOptions"
		item-value="code"
		:label="label || $t('unit')"
		:disabled="!ready || disabled"
		:loading="!ready && !disabled"
		:clearable="clearable"
		outlined
		clear-icon="icon-cross"
		append-icon="icon-angle-down"
		:rules="rules"
		:prepend-icon="prependIcon"
		@change="changeAction"
		:menu-props="{ offsetY: true }"
	>
	</v-select>
</template>

<script>
import remote from "../../data/remote";
import cloneDeep from "lodash.clonedeep";

export default {
	props: ["value", "options", "productId", "eager", "label", "clearable", "disabled", "rules", "prepend-icon"],
	data() {
		return {
			currentValue: null,
			currentOptions: [],
			ready: false,
		};
	},
	watch: {
		currentValue(val) {
			this.$emit("input", val);
		},
		value(val) {
			this.currentValue = val || null;
		},
		options(val) {
			this.handleProps();
		},
		productId(val) {
			this.handleProps();
		},
		eager(val) {
			this.handleProps();
		},
	},
	methods: {
		handleProps() {
			if (this.options) this.handleOptions(this.options);
			else if (this.productId) this.loadProduct();
			else if (this.eager) this.loadOptions();
		},
		handleOptions(options) {
			this.currentOptions = cloneDeep(options);
			this.currentOptions.forEach((i) => (i.text = this.$t(`$unit.${i.name}`)));
			this.currentOptions = this.currentOptions.sort((a, b) => (a.text < b.text ? -1 : 1));
			this.ready = true;
		},
		loadProduct() {
			remote.products.find(this.productId, {
				onSuccess: (result) => this.handleOptions(result.units),
			});
		},
		loadOptions() {
			remote.structures.getUnits({
				onSuccess: (result) => this.handleOptions(result),
			});
		},
		changeAction(code) {
			this.$emit(
				"change",
				this.currentOptions.find((i) => i.code === code)
			);
		},
	},
	created() {
		this.currentValue = this.value || null;
	},
};
</script>
