<template>
	<v-container class="pa-2">
		<v-card elevation="0" class="ant-card py-2 px-4">
			<v-card-title class="v-typography--title2">{{ $t("dashboard") }}</v-card-title>
			<v-row v-if="dashboard.currencyReports" class="ma-0">
				<v-col cols="12" sm="6" md="6" lg="3" class="pa-2">
					<container-count-report
						:data="dashboard.exportContainerCountReport"
						:title="$t('exported_containers')"
						chart-color="info"
						backgroundColor="anchor"
						type="export"
						:dark="isDark"
					/>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="3" class="pa-2">
					<container-count-report
						:data="dashboard.importContainerCountReport"
						:title="$t('imported_containers')"
						chart-color="info"
						backgroundColor="anchor"
						type="import"
						:dark="isDark"
					/>
				</v-col>

				<v-col cols="12" lg="6" class="pa-2">
					<trade-report
						v-if="dashboard.currencyReports"
						:currencyReports="dashboard.currencyReports"
						:title="$t('total_amount')"
						:dark="isDark"
					/>
				</v-col>
			</v-row>
		</v-card>
		<v-row class="ma-0">
			<v-col class="py-2" :class="isMobile ? 'px-0 ' : 'pl-0'" cols="12" xl="6" v-if="outgoingShipmentsVisibility">
				<v-card elevation="0" class="ant-card pb-2 px-4">
					<v-card-title>{{ $t("outgoing_shipments") }}</v-card-title>
					<shipment-status-table role="sender" />
				</v-card>
			</v-col>
			<v-col class="py-2" :class="isMobile ? 'px-0 ' : 'pr-0'" cols="12" xl="6" v-if="incomingShipmentsVisibility">
				<v-card elevation="0" class="ant-card pb-2 px-4">
					<v-card-title>{{ $t("incoming_shipments") }}</v-card-title>
					<shipment-status-table role="receiver" />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ShipmentStatusTable from "../shipment/partial/ShipmentStatusTable.vue";
import ContainerCountReport from "./partial/ContainerCountReport.vue";
import TradeReport from "./partial/TradeReport.vue";
import { mapGetters, mapState } from "vuex";
import remote from "../../../data/remote";

export default {
	components: { ShipmentStatusTable, ContainerCountReport, TradeReport },
	data() {
		return {
			dashboard: {
				currencyReports: undefined,
				exportContainerCountReport: undefined,
				importContainerCountReport: undefined,
			},
			// Removed selectedCurrencyReport
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState(["employee"]),
		outgoingShipmentsVisibility() {
			return this.employee.type !== "importer";
		},
		incomingShipmentsVisibility() {
			return this.employee.type !== "exporter";
		},
		isDark() {
			return this.$vuetify.theme.isDark;
		},
		isMobile() {
			return this.$vuetify.breakpoint.lgAndDown;
		},
	},
	created() {
		if (this.company) this.loadDashboard();
	},
	methods: {
		loadDashboard() {
			remote.utilities.getDashboard({
				onSuccess: (result) => {
					this.dashboard = result;
					let currencyReports = result.currencyReports;
					this.dashboard.currencyReports = [];
					for (const currencyReport of currencyReports) {
						let add = false;
						for (const key in currencyReport.salesReport.monthBasedTotals) {
							if (currencyReport.salesReport.monthBasedTotals[key] > 0) {
								add = true;
								break;
							}
						}
						if (!add) {
							for (const key in currencyReport.purchaseReport.monthBasedTotals) {
								if (currencyReport.purchaseReport.monthBasedTotals[key] > 0) {
									add = true;
									break;
								}
							}
						}
						if (add) this.dashboard.currencyReports.push(currencyReport);
					}
					// Removed selectedCurrencyReport assignment
				},
				onFail: () => {
					this.loading = false;
				},
			});
		},
	},
};
</script>
