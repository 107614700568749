<template>
	<v-container>
		<v-col class="pb-4">
			<v-row class="d-flex justify-center align-center text-center">
				<v-card-subtitle
					:class="isMobile ? 'v-typography--title' : 'v-typography--title2'"
					class="accent--text title-with-line pa-0"
					tag="h2"
				>
					{{ $t(`$landing.$process.question_title`) }}
				</v-card-subtitle>
			</v-row>
			<v-row class="d-flex justify-center align-center text-center">
				<v-card-title :class="isMobile ? 'v-typography--title' : 'v-typography--title3'" tag="h3">
					{{ $t(`$landing.$process.digital_future`) }}
				</v-card-title>
			</v-row>
			<v-row class="d-flex justify-center align-center text-center">
				<v-card-subtitle :class="isMobile ? 'v-typography--body' : 'v-typography--paragraph'" tag="p">
					{{ $t(`$landing.$process.tracking_method`) }}
				</v-card-subtitle>
			</v-row>
		</v-col>

		<!-- Desktop Layout -->
		<v-container>
			<v-row class="px-2 px-xl-10 justify-center align-center">
				<v-col cols="12" sm="4" md="5" class="d-flex justify-center align-center">
					<v-card-title class="v-typography--title1 accent--text text-center text-md-left" tag="h3">
						{{ $t(`$landing.$process.traditional_shipping`) }}
					</v-card-title>
				</v-col>
				<v-col cols="12" sm="1" class="d-flex justify-center align-center">
					<img
						v-if="$vuetify.breakpoint.xsOnly"
						src="~@/assets/landing_arrow.webp"
						alt="Arrow Icon"
						class="arrow-image small-arrow"
						loading="lazy"
					/>
				</v-col>
				<v-col cols="12" sm="4" md="5" class="d-flex justify-center align-center">
					<v-card-title class="v-typography--title1 accent--text text-center text-md-left" tag="h3">
						{{ $t(`$landing.$process.digital_shipping`) }}
					</v-card-title>
				</v-col>
			</v-row>

			<!-- Content Rows -->
			<v-row v-for="index in 5" :key="index" class="px-2 px-xl-10 justify-center align-center">
				<v-col cols="12" sm="5" class="d-flex justify-center align-center">
					<v-card class="ant-card px-2 py-2 py-0 flex-grow-1" outlined elevation="0">
						<v-card-title :class="isMobile ? 'v-typography--body' : 'v-typography--paragraph'" tag="p">
							{{ $t(`$landing.$process.traditional_shipping_texts.${index - 1}`) }}
						</v-card-title>
					</v-card>
				</v-col>
				<v-col cols="12" sm="1" class="d-flex justify-center align-center arrow-col">
					<img
						src="https://fs.antrego.net/landing/landing_arrow.webp"
						alt="Arrow Icon"
						class="arrow-image small-arrow"
						loading="lazy"
					/>
				</v-col>
				<v-col cols="12" sm="5" class="d-flex justify-center align-center">
					<v-card class="ant-card px-2 py-2 flex-grow-1" outlined elevation="0">
						<v-card-title :class="isMobile ? 'v-typography--body' : 'v-typography--paragraph'" tag="p">
							{{ $t(`$landing.$process.digital_shipping_texts.${index - 1}`) }}
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<!-- Mobile Layout with Carousel -->
		<!-- <v-carousel
			v-if="isMobile"
			v-model="currentSlide"
			hide-delimiters
			:show-arrows="false"
			:show-arrows-on-hover="false"
			class="carousel-container"
		>
			<v-carousel-item v-for="(item, index) in 5" :key="index">
				<v-container class="d-flex flex-column align-center">
					<h3 class="font-weight-bold text-light-blue-darken-4 text-center mb-2 mt-8">
						{{ $t(`$landing.$process.traditional_shipping`) }}
					</h3>

					<v-row class="d-flex justify-center">
						<v-col v-for="i in 1" :key="i" class="d-flex align-center justify-center" cols="12">
							<v-card
								:class="[ 'd-flex align-center justify-center', { 'card-style': !isMobile, 'mobile-card-style': isMobile }]"
							>
								<v-card-text class="card-text">
									{{ $t(`$landing.$process.traditional_shipping_texts.${index}`) }}
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<div class="d-flex justify-center my-2">
						<img
							src="https://fs.antrego.net/landing/landing_arrow.webp"
							alt="Arrow Icon"
							class="arrow-image"
							loading="lazy"
						/>
					</div>

					<h3 class="font-weight-bold text-light-blue-darken-4 text-center mb-2">
						{{ $t(`$landing.$process.digital_shipping`) }}
					</h3>

					<v-row class="d-flex justify-center">
						<v-col v-for="i in 1" :key="i" class="d-flex align-center justify-center" cols="12">
							<v-card
								:class="[ 'd-flex align-center justify-center', { 'card-style': !isMobile, 'mobile-card-style': isMobile }]"
							>
								<v-card-text class="card-text">
									{{ $t(`$landing.$process.digital_shipping_texts.${index}`) }}
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>

				<div class="carousel-indicators">
					<span
						v-for="(item, i) in 5"
						:key="i"
						:class="['indicator', { active: i === currentSlide }]"
					></span>
				</div>
			</v-carousel-item>
		</v-carousel>
		-->
	</v-container>
</template>

<script>
export default {
	props: { isMobile: Boolean },
	data() {
		return {
			currentSlide: 0,
		};
	},
};
</script>

<style scoped>
.card-style {
	width: 500px;
	height: 90px;
	box-shadow: none;
	border: 2px solid rgb(234, 234, 234);
	border-radius: 8px;
	transition: transform 0.2s;
}

/* .mobile-card-style {
	width: 90%;
	height: auto;
	padding: 8px;
	margin-bottom: 8px;
	box-shadow: none;
	border: 2px solid rgb(234, 234, 234);
	border-radius: 8px;
} */

.arrow-image {
	width: 34px;
	height: 34px;
}

.v-container {
	padding-left: 16px;
	padding-right: 16px;
	max-width: 100%;
	overflow-x: hidden;
}

.title-with-line {
	color: #005d8f;
	position: relative;
	display: inline-block;
}

.text-color {
	color: #005d8f;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.1);
	transform-origin: left;
}

.v-application p {
	margin-bottom: 0;
}

/* .carousel-indicators {
	display: flex;
	justify-content: center;
	margin-top: 5px;
}

.indicator {
	width: 10px;
	height: 10px;
	background-color: #d9d9d9;
	border-radius: 10px;
	margin: 0 5px;
	display: inline-block;
	transition: background-color 0.3s;
}

.carousel-indicators .indicator.active {
	background-color: #00466a !important;
} */

@media (max-width: 600px) {
	.arrow-image {
		width: 24px;
		height: 24px;
		transform: rotate(90deg);
	}
	.arrow-col {
		padding-top: 0;
		padding-bottom: 0;
	}
	.title-with-line::after {
		display: none;
	}
}
</style>
